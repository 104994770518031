// @file Types for fetch requests
enum FetchOptionCache {
  default = 'default',
  noCache = 'no-cache',
  reload = 'reload',
  forceCache = 'force-cache',
  onlyIfCached = 'only-if-cached',
}

enum FetchOptionRedirect {
  manual = 'manual',
  follow = 'follow',
  error = 'error',
}

enum FetchOptionMode {
  noCors = 'no-cors',
  cors = 'cors',
  sameOrigin = 'same-origin',
}

enum FetchOptionCredentials {
  include = 'include',
  omit = 'omit',
  sameOrigin = 'same-origin',
}

enum HTTPMethod {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
  patch = 'PATCH',
}

enum HTTPAuthenticationScheme {
  basic = 'Basic',
  bearer = 'Bearer',
  digest = 'Digest',
  hoba = 'HOBA',
  mutual = 'Mutual',
  negotiate = 'Negotiate',
  oauth = 'OAuth',
  scramSha1 = 'SCRAM-SHA-1',
  scramSha256 = 'SCRAM-SHA-256',
  vapid = 'vapid',
}

enum HTTPHeader {
  contentType = 'Content-Type',
  authorization = 'Authorization',
  csrfToken = 'X-CSRF-Token',
  accept = 'Accept',
}

enum HTTPContentType {
  json = 'application/json',
  jsonApi = 'application/vnd.api+json',
  formUrlEncoded = 'application/x-www-form-urlencoded',
  multipartFormData = 'multipart/form-data',
}

interface FetchOptionQueryHash {
  [name: string]: string
}
interface FetchOptionHeaderHash {
  [name: string]: string
}

type FetchOptionHeader = FetchOptionHeaderHash | Headers

/**
 * Options to pass into the Fetch API's fetch function.
 * Read more on [ MDN ](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch).
 */
interface OriginalFetchOptions {
  headers?: FetchOptionHeader
  cache?: FetchOptionCache
  redirect?: FetchOptionRedirect
  referrer?: string
  credentials?: FetchOptionCredentials
  method?: HTTPMethod
  mode?: FetchOptionMode
  body?: string | FormData
  signal?: AbortSignal
}

interface FetchOptions extends OriginalFetchOptions {
  headers?: FetchOptionHeaderHash
  query?: FetchOptionQueryHash
  jsonData?: any
  contentType?: HTTPContentType
  authorization?: [HTTPAuthenticationScheme, string]
  csrfToken?: string
  cookies?: boolean
  cors?: boolean
}

interface FetchResource<T> {
  index: (query: FetchOptionQueryHash, ...options: FetchOptions[]) => Promise<T[]>
  show: (id: string | number, ...options: FetchOptions[]) => Promise<T>
  update: (jsonData: any, ...options: FetchOptions[]) => Promise<T>
  create: (jsonData: any, ...options: FetchOptions[]) => Promise<T>
  destroy: (id: string | number, ...options: FetchOptions[]) => Promise<T>
  get: (relativePath: string, ...options: FetchOptions[]) => Promise<unknown>
  post: (relativePath: string, jsonData: any, ...options: FetchOptions[]) => Promise<unknown>
  put: (relativePath: string, jsonData: any, ...options: FetchOptions[]) => Promise<unknown>
  delete: (relativePath: string, ...options: FetchOptions[]) => Promise<unknown>
  fetch: (relativePath: string, ...options: FetchOptions[]) => Promise<unknown>
}

export {
  FetchOptionCache,
  FetchOptionCredentials,
  FetchOptionMode,
  FetchOptionRedirect,
  HTTPAuthenticationScheme,
  HTTPContentType,
  HTTPHeader,
  HTTPMethod,
  type FetchOptionHeader,
  type FetchOptionHeaderHash,
  type FetchOptionQueryHash,
  type FetchOptions,
  type FetchResource,
  type OriginalFetchOptions,
}
