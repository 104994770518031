/*
 * @file Exports a function that shows if app version allows various actions
 */

import appVersion from '@@/bits/app_version'

// Difference between 1 and the greatest float > 1

const numberEpsilon: number = Number.EPSILON ?? Math.pow(2, -52)
// app version must be greater than the listed version otherwise
// can() should return false.

type AppAction =
  | 'chat'
  | 'newPostModal'
  | 'postMessage'
  | 'postNatively'
  | 'showAddPost'
  | 'showAlertSheet'
  | 'showChildPanel'
  | 'signalStatusWithLocation'
  | 'useNativeHeader'
  | 'useNativeCommentsPanel'
  | 'useNativeMapLocationPicker'
  | 'useNativeReactionDialog'
  | 'nativeExpandedPostV1'
  | 'nativePostActionMenuV2'
  | 'nativeSurfaceBackground'
  | 'nativePostActionMenuWithSharing'
  | 'useNativeCameraAndFilePicker'
  | 'useSurfaceV2'
  | 'useNativeSlideshowWebview'
  | 'surfaceAbuseReportPanel'

const ACTION_TO_VERSION_LIST: {
  [key in AppAction]: number
} = {
  chat: 44.1,
  newPostModal: 158.9,
  postMessage: 19.0,
  postNatively: 30.0,
  showAddPost: 19.0,
  showAlertSheet: 19.0,
  showChildPanel: 18.0,
  signalStatusWithLocation: 19.0,
  useNativeHeader: 59.1,
  useNativeCommentsPanel: 104.9,
  useNativeMapLocationPicker: 106.0,
  useNativeReactionDialog: 169.9,
  nativeExpandedPostV1: 191.9,
  nativePostActionMenuV2: 191.9,
  nativeSurfaceBackground: 190.9,
  nativePostActionMenuWithSharing: 192.1,
  useNativeCameraAndFilePicker: 196.9,
  useSurfaceV2: 9999.9,
  useNativeSlideshowWebview: 199.0,
  surfaceAbuseReportPanel: 200.9,
}

export function appCan(action: AppAction): boolean {
  if (appVersion != null) {
    return appVersion - ACTION_TO_VERSION_LIST[action] > numberEpsilon
  }
  return false
}

export default appCan

function getCapabilities(): string[] {
  const wwAttribute = document.body.getAttribute('ww-capabilities')
  if (wwAttribute == null || wwAttribute === '') return []
  return wwAttribute.split(',')
}

type Capability =
  | 'testCapability'
  | 'pollAttachment'
  | 'appOpenInMap'
  | 'commentModeration'
  | 'mediaComment'
  | 'nativeCommentContentPicker'

export function isAppCapableOf(capability: Capability): boolean {
  const capabilities = getCapabilities()
  return capabilities.includes(capability)
}
