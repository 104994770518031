// @file Surface settings store
import { ALERT_ICON, CONFIRM_CANCEL_UPLOAD_SETTING, CONFIRM_DISCARD_SETTING_CHANGES } from '@@/bits/confirmation_dialog'
import { captureException } from '@@/bits/error_tracker'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceShareLinksStore } from '@@/pinia/surface_share_links'
import PadletApi from '@@/surface/padlet_api'
import type { User, WallBackground, WallLayoutAttributes, WallViz } from '@@/types'
import { SettingsSubpanel } from '@@/vuexstore/modules/surface_settings'
import type { ContentModeration, Wall, WallBackgroundType } from '@padlet/arvo'
import { GroupByTypes } from '@padlet/arvo'
import type { BeethovenLuminance } from '@padlet/beethoven-client/src/types'
import { find, isEqual } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref, toRefs } from 'vue'

export const useSurfaceSettingsStore = defineStore('surfaceSettings', () => {
  const surfaceVuexStore = window.app?.$store // For gradual conversion to pinia
  const globalSnackbarStore = useGlobalSnackbarStore()

  // Access the surface store in a getters to avoid circular dependency.
  // https://pinia.vuejs.org/cookbook/composing-stores.html
  const surfaceStore = useSurfaceStore()
  const wallAttributes = computed<Partial<Wall>>(() => surfaceStore.wallAttributes)
  const surfaceShareLinksStore = useSurfaceShareLinksStore()
  const surfaceSectionsStore = useSurfaceSectionsStore()

  // state
  const isTitleClicked = ref(false)
  const isDescriptionClicked = ref(false)

  // getters
  const isRemakeable = computed<boolean>(() => surfaceVuexStore?.getters.isRemakeable)
  const isReactable = computed<boolean>(() => surfaceVuexStore?.getters.isReactable)
  const isCommentable = computed<boolean>(() => surfaceVuexStore?.getters.isCommentable)
  const isPreviewing = computed<boolean>(() => surfaceVuexStore?.getters['settings/isPreviewing'])
  const wishGroupBy = computed<GroupByTypes>(() => surfaceVuexStore?.getters['settings/wishGroupBy'])
  const isWishGroupedBySection = computed<boolean>(() => wishGroupBy.value === GroupByTypes.Section)
  const activePanel = computed<SettingsSubpanel | null>(() => surfaceVuexStore?.getters['settings/activePanel'])
  const wishSortBy = computed(() => surfaceVuexStore?.getters['settings/wishSortBy'])
  const previewAttributes = computed<Partial<Wall>>(() => surfaceVuexStore?.getters['settings/previewAttributes'])
  const hasUserSettingsChanged = computed(() => {
    const { background: currentBackground, ...previewWallAttributesMinusBackground } = toRefs(previewAttributes?.value)
    const { background: originalBackground, ...originalWallAttributesMinusBackground } = toRefs(wallAttributes?.value)

    // we only need to check for url in background. we add attributes to selected background so it
    // will not have the exact same attributes as the wallpapers in the panel
    return (
      !isEqual(previewWallAttributesMinusBackground, originalWallAttributesMinusBackground) ||
      currentBackground?.value?.url !== originalBackground?.value?.url
    )
  })

  const isReactionDataValid = computed<boolean>(() => surfaceVuexStore?.state.settings.isReactionDataValid)
  const isSavingSettings = computed<boolean>(() => surfaceVuexStore?.state.settings.isSaving)

  const showAuthor = computed<boolean>(() => previewAttributes?.value.show_author ?? false)
  const canConfigNewPostLocation = computed<boolean>(() => surfaceVuexStore?.getters.canConfigNewPostLocation)

  const isWallNameValid = computed<boolean>(() => surfaceVuexStore?.state.settings.isWallNameValid)
  const isWallNameAvailable = computed<boolean>(() => surfaceVuexStore?.state.settings.isWallNameAvailable)
  const contentModeration = computed<ContentModeration>(() => surfaceVuexStore?.getters['settings/contentModeration'])
  const contentModerationOptions = computed<ContentModeration[]>(() => {
    if (surfaceStore.isWhiteboard as boolean) {
      return (surfaceVuexStore?.getters.isUsedInEducationalContext as boolean)
        ? ['none', 'students_only', 'all']
        : ['none', 'all']
    }

    return (surfaceVuexStore?.getters.isUsedInEducationalContext as boolean)
      ? ['none', 'auto', 'students_only', 'all']
      : ['none', 'auto', 'all']
  })

  // In Shelf & old Timeline, the 'Group posts by section' field does not do anything so we hide the whole layout section.
  const canShowSectionLayoutSettings = computed(
    () => surfaceStore.canUseSections && surfaceStore.viz !== 'shelf' && surfaceStore.viz !== 'timeline',
  )

  const orderedLayouts: WallViz[] = ['grid', 'free', 'timeline_v2', 'matrix', 'stream', 'map']
  const layoutConversionOptions = computed<WallLayoutAttributes[]>(() => {
    const allLayouts = surfaceStore.allLayoutsByGroup
    return orderedLayouts.map((layout) => allLayouts.simple.find((l) => l.value === layout)) as WallLayoutAttributes[]
  })

  // actions
  function setIsTitleClicked(isClicked: boolean): void {
    isTitleClicked.value = isClicked
  }

  function setIsDescriptionClicked(isClicked: boolean): void {
    isDescriptionClicked.value = isClicked
  }

  function showConfirmDiscardSettingsDialog(): void {
    void useGlobalConfirmationDialogStore().openConfirmationDialog({
      ...CONFIRM_DISCARD_SETTING_CHANGES,
      ...ALERT_ICON,
      afterDiscardActions: [hideSettingsPanelWithoutConfirmation],
      afterConfirmActions: [
        () => surfaceVuexStore?.dispatch('settings/saveSettings', null, { root: true }),
        hideSettingsPanelWithoutConfirmation,
      ],
      buttonScheme: OzConfirmationDialogBoxButtonScheme.SaveDiscardCancel,
      forceFullWidthButtons: true,
      xShadow: true,
    })
  }

  function showConfirmCancelImageUploadSettingsDialog(
    { shouldHideSettingsPanel }: { shouldHideSettingsPanel: boolean } = { shouldHideSettingsPanel: true },
  ): void {
    void useGlobalConfirmationDialogStore().openConfirmationDialog({
      ...CONFIRM_CANCEL_UPLOAD_SETTING,
      ...ALERT_ICON,
      afterConfirmActions: [
        () =>
          shouldHideSettingsPanel
            ? hideSettingsPanelWithoutConfirmation()
            : surfaceVuexStore?.dispatch('settings/changeActivePanel', SettingsSubpanel.Main, { root: true }),
      ],
      forceFullWidthButtons: true,
      xShadow: true,
    })
  }

  function toggleSettingsPanel(panel: SettingsSubpanel = SettingsSubpanel.Main): void {
    void surfaceVuexStore?.dispatch('settings/toggleSettingsPanel', panel)
  }

  function showSettingsPanel(activePanel: SettingsSubpanel = SettingsSubpanel.Main): void {
    void surfaceVuexStore?.dispatch('settings/showSettingsPanel', activePanel)
  }

  function hideSettingsPanel({ checkForUserSettings } = { checkForUserSettings: true }): void {
    if (checkForUserSettings && hasUserSettingsChanged.value) {
      showConfirmDiscardSettingsDialog()
    } else {
      hideSettingsPanelWithoutConfirmation()
    }
  }

  function hideSettingsPanelWithoutConfirmation(): void {
    void surfaceVuexStore?.dispatch('settings/hideSettingsPanel', null, { root: true })
  }

  function changeActivePanel(activePanel: SettingsSubpanel): void {
    void surfaceVuexStore?.dispatch('settings/changeActivePanel', activePanel)
  }

  function goBackToMainPanel(): void {
    void changeActivePanel(SettingsSubpanel.Main)
  }

  function updateBackground(background: Partial<WallBackground>, isCustom = false): void {
    let updatedBackground
    let updatedIsTitleBarTransparent

    if (isCustom) {
      updatedIsTitleBarTransparent = background.is_full_page
      updatedBackground = {
        ...background,
        effect: background.effects != null ? background.effects[0] : '',
        is_full_page: background.is_title_bar_transparent_by_default ?? false, // Default to false if it is a user selected image
        is_title_bar_transparent: updatedIsTitleBarTransparent,
        thumbnail_url: null, // remove thumbnail_url if it is a user selected image
      }
    } else {
      updatedIsTitleBarTransparent = true
      updatedBackground = {
        ...background,
        effect: '',
        effects: [],
        is_full_page: background.is_title_bar_transparent_by_default ?? true,
        is_title_bar_transparent: updatedIsTitleBarTransparent,
      }
    }

    updatePreviewAttributes({ background: updatedBackground, is_title_bar_transparent: updatedIsTitleBarTransparent })
  }

  function updateBackgroundMetadata(payload: {
    dominantColourAsRgb: string
    imageLuminance: BeethovenLuminance
  }): void {
    void surfaceVuexStore?.dispatch('settings/updateBackgroundMetadata', payload)
  }

  function startWallAttributesPreview(): void {
    void surfaceVuexStore?.dispatch('settings/startWallAttributesPreview')
  }

  function stopWallAttributesPreview(): void {
    void surfaceVuexStore?.dispatch('settings/stopWallAttributesPreview')
  }

  function updatePreviewAttributes(attr: Record<string, any>): void {
    if (!isPreviewing.value) return
    const newPreviewAttributes: Partial<Wall> = { ...previewAttributes.value, ...attr }
    void surfaceVuexStore?.dispatch('settings/updateAndValidatePreviewAttributes', newPreviewAttributes)
  }

  function saveSettings(): void {
    void surfaceVuexStore?.dispatch('settings/updateWallValidity')
    void surfaceVuexStore?.dispatch('settings/saveSettings')
  }

  function updatePreviewAttributesAndSaveSettings(attr: Record<string, any>): void {
    updatePreviewAttributes(attr)
    saveSettings()
  }

  function saveSettingsAndGoBackToMainPanel(): void {
    saveSettings()
    goBackToMainPanel()
  }

  function resetSettings(): void {
    updatePreviewAttributes({ ...wallAttributes.value })
  }

  function discardChangesAndGoBackToMainPanel(): void {
    resetSettings()
    goBackToMainPanel()
  }

  function notifyWallSettingsUpdate(currentUserId: number, actor?: User): void {
    if (actor === undefined || actor.id === currentUserId || activePanel.value === null) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-nullish-coalescing
    const user = actor.name || actor.username
    const message = user != null ? __('%{user} edited settings', { user }) : __('Someone edited settings')

    if (!globalSnackbarStore.isMessageBeingShown(message)) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message,
      })
    }
  }

  function toggleGroupBySection(): void {
    const updatedValue = !isWishGroupedBySection.value
    const wishArrangement = {
      default_section_id: surfaceSectionsStore.defaultSectionId,
      group_by: updatedValue ? GroupByTypes.Section : null, // Set group_by to null for disabling grouping
      is_sectionable: updatedValue,
    }

    // We need to wait for the preview to be updated before saving the settings
    // This is especially true for the case where we update the title and click on sections/layout immediately
    setTimeout(() => {
      updatePreviewAttributesAndSaveSettings({
        ...wallAttributes.value,
        wish_arrangement: wishArrangement,
      })
    }, 300)
  }

  const convertWallLayout = async (newLayout: WallViz): Promise<void> => {
    const wallId = wallAttributes.value.id
    const wallHashid = wallAttributes.value.hashid
    try {
      await PadletApi.Wall.convertLayout({
        wallHashid,
        newLayout,
      })
      updatePreviewAttributesAndSaveSettings({ viz: newLayout })

      /**
       * If switching to a non-sectionable format, we fetch
       * the updated breakout links as they're disabled on backend.
       */
      if (!surfaceStore.canUseSections) {
        void surfaceShareLinksStore.fetchSectionBreakoutLinks()
      }
    } catch (e) {
      captureException(
        `Failed to convert wall layout for wall: ${wallId ?? ''} to ${newLayout}, due to ${asciiSafeStringify(e)}`,
      )
    }
  }

  // comments and reactions settings
  function disableCommentsAndReactions(): void {
    void surfaceVuexStore?.dispatch('updateWall', { is_commentable: false, is_reactable: false })
  }

  async function fetchCommentAndReactionSettings(): Promise<void> {
    const settings = await PadletApi.Wall.fetch(surfaceStore.wallHashid)
    void surfaceVuexStore?.dispatch('updateWall', {
      is_commentable: settings.is_commentable,
      is_reactable: settings.is_reactable,
    })
  }

  const isFetchingWallpapers = ref(false)
  const wallpapersGroupedByType = ref<Record<WallBackgroundType, WallBackground[]>>()
  const wallpaper = computed(() => {
    if (Boolean(surfaceStore.isCustomBackground) || wallpapersGroupedByType.value == null) {
      return previewAttributes.value.background
    }

    // if the background is an image from padlet airtable, we need to find the wallpaper object from the list of wallpapers
    // to get its thumbnail url. otherwise, we just use the background object from surface init state directly.
    const allWallpapers = Object.values(wallpapersGroupedByType.value).flat()
    return find(allWallpapers, { url: previewAttributes.value.background?.url }) ?? previewAttributes.value.background
  })

  return {
    // state
    isTitleClicked,
    isDescriptionClicked,
    isFetchingWallpapers,
    wallpapersGroupedByType,

    // getters
    wallAttributes,
    isRemakeable,
    isReactable,
    isCommentable,
    isPreviewing,
    previewAttributes,
    wishGroupBy,
    isWishGroupedBySection,
    wishSortBy,
    activePanel,
    isReactionDataValid,
    showAuthor,
    canConfigNewPostLocation,
    isSavingSettings,
    isWallNameValid,
    isWallNameAvailable,
    contentModeration,
    contentModerationOptions,
    layoutConversionOptions,
    canShowSectionLayoutSettings,
    wallpaper,

    // actions
    setIsTitleClicked,
    setIsDescriptionClicked,
    startWallAttributesPreview,
    stopWallAttributesPreview,
    toggleSettingsPanel,
    toggleGroupBySection,
    updatePreviewAttributes,
    updateBackground,
    updateBackgroundMetadata,
    convertWallLayout,
    changeActivePanel,
    goBackToMainPanel,
    showSettingsPanel,
    hideSettingsPanel,
    hideSettingsPanelWithoutConfirmation,
    saveSettings,
    saveSettingsAndGoBackToMainPanel,
    discardChangesAndGoBackToMainPanel,
    updatePreviewAttributesAndSaveSettings,
    notifyWallSettingsUpdate,
    showConfirmCancelImageUploadSettingsDialog,
    disableCommentsAndReactions,
    fetchCommentAndReactionSettings,
  }
})
