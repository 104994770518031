// @file Surface post connection store
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { ACCESS_WITHDRAWN } from '@@/bits/snackbar_helper'
import { vDel } from '@@/bits/vue'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import PadletApi from '@@/surface/padlet_api'
import type { Id, PostConnection } from '@@/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

type PostConnectionId = Id | string

export const useSurfacePostConnectionStore = defineStore('surfacePostConnection', () => {
  const surfaceStore = useSurfaceStore()
  const surfacePostsStore = useSurfacePostsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  /* ---------------------- */
  /* STATE                  */
  /* ---------------------- */

  const connectionsFetched = ref<boolean>(false)
  const connectionEntities = ref<Record<PostConnectionId, PostConnection>>({})
  const pendingConnectionIds = ref<string[]>([])
  const postToConnectFromId = ref<Id | null>(null)
  const postToDisconnectFromId = ref<Id | null>(null)
  const snackBarUid = ref<string | null>(null)

  /* ---------------------- */
  /* GETTERS                */
  /* ---------------------- */

  const connections = computed<PostConnection[]>(() => {
    return connectionIds.value.map((id) => connectionEntities.value[id])
  })
  const currentConnections = computed<PostConnection[]>(() => {
    const currentPostIds: Id[] = surfacePostsStore.currentSortedPosts
      .map((p) => p.id)
      .filter((id) => id != null) as Id[]
    return connections.value.filter(
      (c: PostConnection) => currentPostIds.includes(c.from_wish_id) && currentPostIds.includes(c.to_wish_id),
    )
  })
  const connectionIds = computed<PostConnectionId[]>(() => Object.keys(connectionEntities.value))

  const xConnectionCancellation = computed<boolean>((): boolean => postToConnectFromId.value != null)
  const xDisconnectionCancellation = computed<boolean>((): boolean => postToDisconnectFromId.value != null)

  /* ---------------------- */
  /* HELPER FUNCTIONS       */
  /* ---------------------- */

  const saveFetchedConnections = (connections: PostConnection[]): void => {
    if (connections != null) {
      const newConnectionEntities = {}
      connections.forEach((r) => {
        newConnectionEntities[r.id] = r
      })
      connectionEntities.value = newConnectionEntities // Trigger state change once
      pendingConnectionIds.value = []
    }
    connectionsFetched.value = true
  }

  const addPendingConnection = (pendingConnection: Partial<PostConnection>): void => {
    const tempId = 'new_' + String(new Date().valueOf())
    const newPendingConnection = {
      ...pendingConnection,
      id: tempId,
    }
    connectionEntities.value = {
      ...connectionEntities.value,
      // TODO: fix PostConnection type`
      [tempId]: newPendingConnection as any,
    }
    pendingConnectionIds.value.push(tempId)
  }

  const addConnection = (connection: PostConnection): void => {
    const pendingConnectionIdRemoved = pendingConnectionIds.value.some((tempId) => {
      if (
        connectionEntities.value[tempId].from_wish_id === connection.from_wish_id &&
        connectionEntities.value[tempId].to_wish_id === connection.to_wish_id &&
        connectionEntities.value[tempId].label === connection.label
      ) {
        const newConnectionEntities = { ...connectionEntities.value }
        // delete pending connection
        vDel(newConnectionEntities, tempId)
        // put new connection in place of pending connection
        connectionEntities.value = {
          ...newConnectionEntities,
          [connection.id]: connection,
        }
        pendingConnectionIds.value = pendingConnectionIds.value.filter((i) => i !== tempId)
        return true
      }
      return false
    })
    if (!pendingConnectionIdRemoved) {
      connectionEntities.value = {
        ...connectionEntities.value,
        [connection.id]: connection,
      }
    }
  }

  const removeConnection = (id: PostConnectionId): void => {
    const newConnectionEntities = { ...connectionEntities.value }
    vDel(newConnectionEntities, id)
    connectionEntities.value = newConnectionEntities
  }

  const removeConnections = (ids: PostConnectionId[]): void => {
    const newConnectionEntities = { ...connectionEntities.value }
    ids.forEach((id) => delete newConnectionEntities[id])
    connectionEntities.value = newConnectionEntities
  }

  const getConnectionsFromPost = (postId: Id): PostConnection[] => {
    return connections.value.filter((c: PostConnection) => c.from_wish_id === postId)
  }

  const getConnectionsToPost = (postId: Id): PostConnection[] => {
    return connections.value.filter((c: PostConnection) => c.to_wish_id === postId)
  }

  /* ---------------------- */
  /* ACTIONS                */
  /* ---------------------- */

  const fetchConnections = async (): Promise<void> => {
    try {
      const response = await PadletApi.PostConnection.readAll({ wall: { id: surfaceStore.wallId } })
      fetchConnectionsSuccess({ fetchedData: response })
    } catch (e) {
      // Don't capture exception when it's 401, show a snackbar instead
      if (e.status === 401) {
        globalSnackbarStore.setSnackbar(ACCESS_WITHDRAWN)
      } else {
        captureFetchException(e, { source: 'fetchConnections' })
      }
    }
  }

  const fetchConnectionsSuccess = ({ fetchedData }: { fetchedData: JsonAPIResponse<PostConnection> }): void => {
    const connections = (fetchedData.data as Array<JsonAPIResource<PostConnection>>).map(
      (connection) => connection.attributes,
    )
    saveFetchedConnections(connections)
  }

  const cancelConnection = (): void => {
    postToConnectFromId.value = null
  }

  const cancelDisconnection = (): void => {
    postToDisconnectFromId.value = null
  }

  const removeConnectionsForPost = ({ postId }: { postId: Id }): void => {
    const relatedConnections = connectionIds.value.filter((id) => {
      return connectionEntities.value[id].from_wish_id === postId || connectionEntities.value[id].to_wish_id === postId
    })
    removeConnections(relatedConnections)
  }

  const completeConnection = ({ postToConnectToId, label }: { postToConnectToId: Id; label: string }): void => {
    const newConnection = {
      from_wish_id: postToConnectFromId.value ?? undefined,
      to_wish_id: postToConnectToId,
      label,
    }
    postToConnectFromId.value = null
    addPendingConnection(newConnection)
    void PadletApi.PostConnection.create(newConnection).then((connectionData: JsonAPIResponse<PostConnection>) => {
      addConnection((connectionData.data as JsonAPIResource<PostConnection>).attributes)
    })
  }

  const completeDisconnection = ({ connectionId }: { connectionId: Id }): void => {
    const existingConnection = connectionEntities.value[connectionId]
    postToDisconnectFromId.value = null
    if (existingConnection != null) {
      removeConnection(existingConnection.id)
      void PadletApi.PostConnection.delete(existingConnection)
    }
  }

  const newConnectionRemote = (connection: PostConnection): void => {
    addConnection(connection)
  }

  const deleteConnectionRemote = (connection: PostConnection): void => {
    removeConnection(connection.id)
  }

  const startConnection = ({ postId }: { postId: Id }): void => {
    postToDisconnectFromId.value = null
    postToConnectFromId.value = postId
    if (isAppUsing('locationSelectorV2') && snackBarUid.value == null) {
      snackBarUid.value = globalSnackbarStore.setSnackbar({
        message: 'Pick a post to connect to',
        persist: true,
        actionText: 'Cancel',
        actionTextActions: [cancelConnection],
      })
    }
  }

  watch([xConnectionCancellation], () => {
    if (!xConnectionCancellation.value && snackBarUid.value != null) {
      globalSnackbarStore.removeSnackbar(snackBarUid.value)
      snackBarUid.value = null
    }
  })

  const startDisconnection = ({ postId }: { postId: Id }): void => {
    postToConnectFromId.value = null
    postToDisconnectFromId.value = postId
    if (isAppUsing('locationSelectorV2')) {
      snackBarUid.value = globalSnackbarStore.setSnackbar({
        message: 'Pick a connector to remove',
        persist: true,
        actionText: 'Cancel',
        actionTextActions: [cancelDisconnection],
      })
    }
  }

  watch([xDisconnectionCancellation], () => {
    if (!xDisconnectionCancellation.value && snackBarUid.value != null) {
      globalSnackbarStore.removeSnackbar(snackBarUid.value)
      snackBarUid.value = null
    }
  })

  return {
    // Getters
    connections,
    currentConnections,
    postToConnectFromId,
    postToDisconnectFromId,
    xConnectionCancellation,
    xDisconnectionCancellation,

    // Actions
    fetchConnections,
    cancelConnection,
    cancelDisconnection,
    removeConnectionsForPost,
    completeConnection,
    completeDisconnection,
    newConnectionRemote,
    deleteConnectionRemote,
    startConnection,
    startDisconnection,
    getConnectionsFromPost,
    getConnectionsToPost,
  }
})
