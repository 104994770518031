/**
 * @file Allow using app feature flags
 */
import environment from '@@/bits/environment'
import window from '@@/bits/global'
import { isStringTruthy } from '@@/bits/truthy'

type CurrentFlags =
  | 'banksy'
  | 'banksyDev'
  | 'dashboardAnnouncement'
  | 'debugFrontend'
  | 'joypixels8p0'
  | 'goldPlansVisible'
  | 'aiImageSearch'
  | 'googleDrive'
  | 'realtimeFetching'
  | 'brahmsCheck'
  | 'surfaceGroupByPanel'
  | 'reportButton'
  | 'magicPadlet'
  | 'surfaceAiChat'
  | 'surfaceAiDevMode'
  | 'zoom'
  | 'spreadsheetExportsV2'
  | 'remakeSettingV2'
  | 'showCommandPalette'
  | 'publicApi'
  | 'autoContentModeration'
  | 'changeEmailVerification'
  | 'surfaceCommentsPerPost'
  | 'audioComments'
  | 'onboardingPanel'
  | 'galleryTemplates'
  | 'layoutFormatSettingV2'
  | 'templatesWithNewSidebar'
  | 'classlinkSso'
  | 'surfaceLibraryOrgVisitorPermissions'
  | 'surfacePdfExportPanelV2'
  | 'surfacePostPropsCustomDateField'
  | 'surfacePostPropsCustomNumberField'
  | 'surfacePostPropsCustomButtonField'
  | 'surfacePostPropsCustomSingleSelectField'
  | 'editThumbnail'
  | 'virtualizedDashboard'
  | 'commentModeration'
  | 'googleAppLicensing'
  | 'rostering'
  | 'cleverSso'
  | 'stripeCheckout'
  | 'fullClientSurface'
  | 'libraryAnalyticsSettings'
  | 'delinquentChurnBanner'
  | 'personalPricingPlansExperiment'
  | 'personalPricingPlansExperimentV2'
  | 'suggestedTemplates'
  | 'dashboardUpgradeCta'
  | 'surfaceCustomPropsSorting'
  | 'surfacePostPropsReordering'
  | 'surfaceRoleBadges'
  | 'surfaceEditTitleInline'
  | 'surfaceRequiredFields'
  | 'keyboardShortcutsSetting'
  | 'remakeLink'
  | 'virtualizedPostsGrid'
  | 'lazyRenderPosts'
  | 'dashboardFolderDalfe'
  | 'padletHome'
  | 'remakePanelV2'
  | 'signUpSurface'
  | 'playVideoInExpanded'
  | 'surfacePostsAltText'
  | 'grading'
  | 'schoolLibrarySSO'
  | 'surfaceWhiteboard'
  | 'freezeSandbox'
  | 'samlSetup'
  | 'removeArchive'
  | 'automations'
  | 'aiTalk'
  | 'anonymousAttribution'
  | 'locationSelectorV2'
  | 'openInAppBanner'
  | 'demoPadletPanel'
  | 'upgradeModalCopyAExperiment'
  | 'flipImporter'
  | 'showAnonymousUserNames'
  | 'mediaRecorderQuotas'
  | 'padletPickerV2'
  | 'downloadWallAttachments'
  | 'aiGrading'
  | 'upgradeModalSingleStepExperiment'
  | 'signUpFlowV2'
  | 'locationPickerV2'
  | 'surfaceQrCodeV2'
  | 'schoolLibraryPermissions'
  | 'libraryGoogleAppLicensing'
  | 'linkSafetyModal'
  | 'newSignupWelcomePage'
  | 'libraryMembersPageV2'
  | 'prefetchSurface'
  | 'newPadletHome'
  | 'unlimitedSandboxes'
  | 'videoRecordingLimits'
  | 'currentSlideAsImageWithBanksy'
  | 'sandboxR2Storage'

function isAppUsing(feature: CurrentFlags, version?: null | number): boolean {
  const ww = window.ww || {}
  const viteAppFlip =
    window.wwStartingState != null && typeof window.wwStartingState === 'function' ? window.wwStartingState().flip : {}
  const flip = ww.flip || ww.vueStartingState?.flip || viteAppFlip || {}
  if (version) {
    return flip[feature] >= version
  }
  return flip[feature] || false
}

export const isDebugMode =
  environment === 'development' ||
  (environment === 'test' && ['true', 'yes', '1'].includes(process.env.WW_DEBUG ?? '')) ||
  isAppUsing('debugFrontend') ||
  isStringTruthy(new URLSearchParams(window.location.search).get('ww_debug'))

if (environment !== 'production' && isAppUsing('debugFrontend')) {
  console.log('Already debugging frontend in dev.') // goodcheck-disable-line
}

// If on padlet.dev, use the local Banksy server to test PDF exports.
// Banksy and Banksy Dev on Cloud Run won't work because they cannot access a padlet.dev padlet.
// You will need to run the Banksy server first before you can test PDF exports.
// See: /services/banksy/README.md
export const isAppUsingBanksyLocalDev = environment === 'development'

export { isAppUsing }
