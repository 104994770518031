// @file fetch map themes from api.padlet.[dev|io|com]/api/:version/themes/map
import { jsonApiFetch, JsonAPIResponse } from './json_api_fetch'
import { WallTheme } from './types'

/**
 * Fetch Themes that are only for Maps.
 * @return Promise that resolves to a collection of Themes.
 */
export default function fetchMapThemes(): Promise<JsonAPIResponse<WallTheme>> {
  return jsonApiFetch<WallTheme>('themes/map')
}
