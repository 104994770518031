/** @file Encapsulates the connection to Mozart. */

// Token is specific to Mozart
export type MozartInit = {
  mozartHost: string
  mozartToken: string | null
  /** @default false */
  useInsecureHttp?: boolean
  injectedHttpHeaders?: HeadersInit
}

export type MozartProtobufRequestInit<TResult> = RequestInit & {
  parseProtobuf: (buffer: Uint8Array) => TResult
}

export class MozartConnector {
  private rootUrl: URL
  private injectedHttpHeaders: Headers

  constructor(init: MozartInit) {
    const { mozartHost, mozartToken, useInsecureHttp = false, injectedHttpHeaders } = init
    this.rootUrl = new URL(`http${useInsecureHttp ? '' : 's'}://${mozartHost}`)

    // Pre-populate headers
    this.injectedHttpHeaders = new Headers()
    if (mozartToken) {
      this.injectedHttpHeaders.set('Authorization', `Bearer ${mozartToken}`)
    }
    if (injectedHttpHeaders) {
      new Headers(injectedHttpHeaders).forEach((value, key) => {
        this.injectedHttpHeaders.set(key, value)
      })
    }
  }

  async requestProtobuf<TResult>(pathOrUrl: string, init: MozartProtobufRequestInit<TResult>): Promise<TResult> {
    // Resolve URL
    let url: URL
    if (/^https?:\/\//.test(pathOrUrl)) {
      url = new URL(pathOrUrl)
    } else {
      url = new URL(pathOrUrl, this.rootUrl)
    }

    const { parseProtobuf, headers: initHeaders, ...restOfInit } = init

    // Combine headers
    const headers = new Headers(this.injectedHttpHeaders)
    if (initHeaders) {
      new Headers(initHeaders).forEach((value, key) => {
        headers.set(key, value)
      })
    }

    // Execute request
    const resolvedInit: RequestInit = { ...restOfInit, headers }
    const response = await fetch(url.toString(), resolvedInit)
    const base64String = await response.text()
    const message = parseProtobuf(Buffer.from(base64String, 'base64'))
    return message as TResult
  }
}
