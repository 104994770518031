/**
 * @file Prepares fetch arguments for fetching.
 */
import { getDefaultOptions, getDefaultJsonOptions } from './defaults'
import {
  FetchOptionCredentials,
  FetchOptionHeaderHash,
  FetchOptionMode,
  FetchOptionQueryHash,
  FetchOptions,
  HTTPContentType,
  HTTPHeader,
} from './types'

function extractQueryFromOptions(options: FetchOptions[]): FetchOptionQueryHash[] {
  const optionsWithQuery: FetchOptions[] = options.filter((x): boolean => !!x.query)
  const queryHashes: (FetchOptionHeaderHash | undefined)[] = optionsWithQuery.map(
    (x): FetchOptionQueryHash | undefined => x.query,
  )
  return queryHashes.filter((x: FetchOptionQueryHash | undefined): boolean => !!x) as FetchOptionQueryHash[]
}

function generateQueryParam(queryHash: FetchOptionQueryHash): string {
  const queryFragments: string[] = []
  Object.entries(queryHash).forEach(([key, value]): void => {
    if (value !== undefined && value !== null) {
      const encodedValue: string = encodeURIComponent(value.toString())
      queryFragments.push([key, encodedValue].join('='))
    }
  })
  return queryFragments.join('&')
}

function appendQueryStringToUrl(url: string, queryParam: string): string {
  let joinWith = '?'
  if (url.endsWith('?')) {
    joinWith = ''
  } else if (url.includes('?')) {
    joinWith = '&'
  }
  return [url, queryParam].join(joinWith)
}

function extractHeadersFromOptions(options: FetchOptions[]): FetchOptionHeaderHash[] {
  const optionsWithHeaders: FetchOptions[] = options.filter((x): boolean => !!x.headers)
  const headers: (FetchOptionHeaderHash | undefined)[] = optionsWithHeaders.map(
    (x): FetchOptionHeaderHash | undefined => x.headers,
  )
  return headers.filter((x): boolean => !!x) as FetchOptionHeaderHash[]
}

function setHeader(
  headerHash: FetchOptionHeaderHash,
  newHeaderName: string,
  newHeaderValue: string,
): FetchOptionHeaderHash {
  if (!headerHash[newHeaderName]) {
    const lowerCaseNewHeaderName = newHeaderName.toLowerCase()
    const headerKeys = Object.keys(headerHash)
    const lowerCaseHeaderKeys = headerKeys.map((x): string => x.toLocaleLowerCase())
    const index: number = lowerCaseHeaderKeys.indexOf(lowerCaseNewHeaderName)
    if (index >= 0) {
      delete headerHash[headerKeys[index]]
    }
  }
  headerHash[newHeaderName] = newHeaderValue

  return headerHash
}

function mergeHeaders(...args: FetchOptionHeaderHash[]): FetchOptionHeaderHash {
  const allHeaders: FetchOptionHeaderHash = {}
  for (const headerHash of args) {
    for (const [key, value] of Object.entries(headerHash)) {
      setHeader(allHeaders, key, value)
    }
  }
  return allHeaders
}

function prepareJsonFetchArguments(options: FetchOptions[]): FetchOptions[] {
  return [getDefaultJsonOptions()].concat(options)
}

function prepareFetchArguments(url: string, options: FetchOptions[]): [string, FetchOptions] {
  const allOptions: FetchOptions[] = [getDefaultOptions()].concat(options)
  const fetchOptions = Object.assign({}, ...allOptions)

  const optionsHeaders: FetchOptionHeaderHash[] = extractHeadersFromOptions(allOptions)
  fetchOptions.headers = mergeHeaders(...optionsHeaders)

  let fetchUrl = url

  // Append query options to the url itself
  if (fetchOptions.query) {
    const optionQueries: FetchOptionQueryHash[] = extractQueryFromOptions(allOptions)
    fetchOptions.query = Object.assign({}, ...optionQueries)
    const queryParam: string = generateQueryParam(fetchOptions.query)
    fetchUrl = appendQueryStringToUrl(url, queryParam)
    delete fetchOptions.query
  }

  if (fetchOptions.jsonData) {
    fetchOptions.body = JSON.stringify(fetchOptions.jsonData)
    setHeader(fetchOptions.headers, HTTPHeader.contentType, HTTPContentType.json)
    delete fetchOptions.jsonData
  }

  if (fetchOptions.contentType) {
    setHeader(fetchOptions.headers, HTTPHeader.contentType, fetchOptions.contentType)
    delete fetchOptions.contentType
  }

  if (fetchOptions.authorization) {
    const [authScheme, credentials] = fetchOptions.authorization
    setHeader(fetchOptions.headers, HTTPHeader.authorization, `${authScheme} ${credentials}`)
    delete fetchOptions.authorization
  }

  if (fetchOptions.csrfToken) {
    setHeader(fetchOptions.headers, HTTPHeader.csrfToken, fetchOptions.csrfToken)
    delete fetchOptions.csrfToken
  }

  if (fetchOptions.cookies === true) {
    fetchOptions.credentials = FetchOptionCredentials.include
    delete fetchOptions.cookies
  } else if (fetchOptions.cookies === false) {
    fetchOptions.credentials = FetchOptionCredentials.omit
    delete fetchOptions.cookies
  }

  if (fetchOptions.cors === true) {
    fetchOptions.mode = FetchOptionMode.cors
    delete fetchOptions.cors
  } else if (fetchOptions.cors === false) {
    fetchOptions.mode = FetchOptionMode.noCors
    delete fetchOptions.cors
  }

  return [fetchUrl, fetchOptions]
}

export { prepareFetchArguments, prepareJsonFetchArguments }
