import { Wall } from './types'
import { wall } from './resource'

/**
 * Delete a Wall object by its ID.
 * @param id ID of the Wall.
 * @returns A Promise that resolves to a [[Wall]].
 *
 */
export default async function deleteWall (id: number): Promise<Wall> {
  return await wall().destroy(id) as Wall
}
