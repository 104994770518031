enum Platforms {
  REACT_NATIVE = 'REACT_NATIVE',
  BROWSER = 'BROWSER',
  NODE = 'NODE',
}

let platformName = Platforms.NODE
if (typeof document != 'undefined') {
  platformName = Platforms.BROWSER
} else if (typeof navigator != 'undefined' && navigator.product == 'ReactNative') {
  platformName = Platforms.REACT_NATIVE
}

const isReactNative = platformName == Platforms.REACT_NATIVE
const isBrowser = platformName == Platforms.BROWSER
const isNode = platformName == Platforms.NODE

export { platformName, isReactNative, isBrowser, isNode }
