/**
 * @file Fetch emojis
 */
import { EmojiVersion } from '../enums'
import { EmojiCategory } from '../types'

/**
 * Composes a URL to an emoji.
 * @param code A code that can be found in the emoji manifest.
 */
function emojiUrl(code: string, version: EmojiVersion = EmojiVersion.legacy): string {
  if (version == EmojiVersion.legacy) {
    return `https://padlet.net/icons/png/${code}.png`
  } else {
    return `https://padlet.net/icons/${version}/png/${code}.png`
  }
}

/**
 * Parses a list of categories of emojis.
 */
function parseEmojiCategories(categories: string[]): EmojiCategory[] {
  return categories.map(
    (c): EmojiCategory => ({
      label: c,
    }),
  )
}

export { parseEmojiCategories, emojiUrl }
