import { render, staticRenderFns } from "./SurfaceApp.vue?vue&type=template&id=5cbfe53f&scoped=true"
import script from "./SurfaceApp.vue?vue&type=script&setup=true&lang=ts"
export * from "./SurfaceApp.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SurfaceApp.vue?vue&type=style&index=0&id=5cbfe53f&prod&lang=scss"
import style1 from "./SurfaceApp.vue?vue&type=style&index=1&id=5cbfe53f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cbfe53f",
  null
  
)

export default component.exports