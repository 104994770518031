/* eslint-disable @typescript-eslint/no-empty-function */
// @file Vuex Store module for Realtime functionality
import { trackEvent } from '@@/bits/analytics'
import { getSocket } from '@@/bits/brahms'
import { shouldUseBroadcastChannel } from '@@/bits/broadcast_channel'
import environment from '@@/bits/environment'
import { captureException, setScope } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { navigateTo } from '@@/bits/location'
import ping from '@@/bits/ping'
import { NATIVE_HOST } from '@@/bits/url'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useScreenReaderNotificationsStore } from '@@/pinia/screen_reader_notifications'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceAutomationsStore } from '@@/pinia/surface_automations_store'
import { useCommentsStore } from '@@/pinia/surface_comments'
import { useSurfaceCommentAttachmentsStore } from '@@/pinia/surface_comment_attachments'
import { useSurfaceContributingStatusStore } from '@@/pinia/surface_contributing_status'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfaceGradingPanelStore } from '@@/pinia/surface_grading_panel'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfacePostConnectionStore } from '@@/pinia/surface_post_connection'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import { useReactionsStore } from '@@/pinia/surface_reactions'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { useSurfaceShareLinksStore } from '@@/pinia/surface_share_links'
import { useSurfaceSharePanelStore } from '@@/pinia/surface_share_panel'
import type { AccumulatedReactions, UserId, WallCustomPostProperty, WallPostProperties } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import type { Comment, JsonAPIResponse, Wish } from '@padlet/arvo'
import { debounce } from 'lodash-es'
import * as Phoenix from 'phoenix'
import type { Module } from 'vuex'

interface RealtimeState {
  wallChannelLastConnected: number | null
  wallChannelLastDisconnected: number | null
  brahmsToken: string | null
  deviceId: string | null
  brahmsSocket: Phoenix.Socket | null
  wallBrahmsChannel: Phoenix.Channel | null
  deviceBrahmsChannel: Phoenix.Channel | null
  dispatchQueue: any[]
  isClearingQueue: boolean
  notificationUid: string | null
  userId: number | null
  isPresencePresent: boolean
  shouldUseLongPoll: boolean
}

const defaultState = (): RealtimeState => ({
  wallChannelLastConnected: null,
  wallChannelLastDisconnected: null,
  brahmsToken: null,
  deviceId: null,
  brahmsSocket: null,
  wallBrahmsChannel: null,
  deviceBrahmsChannel: null,
  dispatchQueue: [],
  isClearingQueue: false,
  notificationUid: null,
  userId: null,
  isPresencePresent: false,
  shouldUseLongPoll: false,
})

function userNeedsFocus(rootGetters): boolean {
  const isBusyReacting = !!useReactionsStore().postIdBeingReactedTo
  const isBusyCommenting =
    useCommentsStore().isCommenting &&
    rootGetters['comment/inlineCommentInputLastTypedInMs'] &&
    Date.now() - rootGetters['comment/inlineCommentInputLastTypedInMs'] < 30000
  return isBusyReacting || isBusyCommenting
}

const RECONNECT_DELAY = [50, 75, 100, 200, 500, 1000, 2000, 5000]
const MAX_ALIVE_BEACON_WAITTIME_MS_FOR_LONGPOLL = 30000
const MAX_ALIVE_BEACON_WAITTIME_MS_FOR_WEBSOCKET = 5000

let serverClientCheckReceivingTimeout: ReturnType<typeof setTimeout> | null = null

const RealtimeModule: Module<RealtimeState, RootState> = {
  namespaced: true,
  state: defaultState,
  getters: {
    areUpdatesPending(state): boolean {
      return state.dispatchQueue.length > 0
    },
    isConnected(state): boolean {
      const connectedTime = state.wallChannelLastConnected
      const disconnectedTime = state.wallChannelLastDisconnected
      if (!connectedTime) return false
      if (!disconnectedTime) return true
      return connectedTime > disconnectedTime
    },
    isRealtimeDownSinceLastSync(state, getters): (lastSyncTime: number) => boolean {
      return (lastSyncTime: number): boolean => {
        if (!getters.isConnected) return true
        const connectedTime = state.wallChannelLastConnected as number
        return lastSyncTime <= connectedTime
      }
    },
  },
  mutations: {
    INITIALIZE_STATE(state, data): void {
      Object.assign(state, data)
    },
    SET_BRAHMS_TOKEN(state, brahmsToken): void {
      state.brahmsToken = brahmsToken
    },
    SET_BRAHMS_SOCKET(state, brahmsSocket): void {
      state.brahmsSocket = brahmsSocket
    },
    SET_WALL_BRAHMS_CHANNEL(state, wallBrahmsChannel): void {
      state.wallBrahmsChannel = wallBrahmsChannel
    },
    SET_DEVICE_BRAHMS_CHANNEL(state, deviceBrahmsChannel): void {
      state.deviceBrahmsChannel = deviceBrahmsChannel
    },
    QUEUE_UPDATE_ACTION(state, action): void {
      state.dispatchQueue.push(action)
    },
    START_CLEARING_DISPATCH_QUEUE(state): void {
      state.isClearingQueue = true
    },
    CLEAR_DISPATCH_QUEUE(state): void {
      state.dispatchQueue = []
    },
    END_CLEARING_DISPATCH_QUEUE(state): void {
      state.isClearingQueue = false
    },
    SET_NOTIFICATION_UID(state, uid): void {
      state.notificationUid = uid
    },
    CLEAR_NOTIFICATION_UID(state): void {
      state.notificationUid = null
    },
    WALL_CHANNEL_CONNECTED(state): void {
      state.wallChannelLastConnected = Date.now()
    },
    WALL_CHANNEL_DISCONNECTED(state): void {
      state.wallChannelLastDisconnected = Date.now()
    },
    SET_IS_PRESENCE_PRESENT(state, isPresencePresent): void {
      state.isPresencePresent = isPresencePresent
    },
    UPDATE_SHOULD_USE_LONG_POLL(state, shouldUseLongPoll): void {
      state.shouldUseLongPoll = shouldUseLongPoll
    },
    SET_USER_ID(state, userId: UserId): void {
      state.userId = userId
    },
  },
  actions: {
    initializeState({ commit, dispatch }, data): void {
      commit('INITIALIZE_STATE', data)
      void dispatch('checkIfBrahmsMessagesAreReceived')
      void dispatch('checkIfWebsocketIsEnabled')
    },

    checkIfBrahmsMessagesAreReceived({ commit, dispatch }): void {
      serverClientCheckReceivingTimeout = setTimeout(() => {
        if (isAppUsing('brahmsCheck')) {
          trackEvent('Realtime', 'Brahms WebSocket server_client_check failed')
        }

        void dispatch('fallbackToRestApi', null, { root: true })
      }, MAX_ALIVE_BEACON_WAITTIME_MS_FOR_WEBSOCKET)
    },

    checkIfWebsocketIsEnabled({ dispatch, state }): void {
      if (environment !== 'production') return
      const pingPongTimeout = setTimeout(() => {
        if (isAppUsing('brahmsCheck')) {
          trackEvent('Realtime', 'Diagnostics WebSocket failed')
        }
        void dispatch('fallBackToLongPolling')
      }, MAX_ALIVE_BEACON_WAITTIME_MS_FOR_WEBSOCKET)

      const socket = new WebSocket(`wss://${NATIVE_HOST}/diagnostics/websocket`)
      socket.addEventListener('message', (event) => {
        if (event.data === 'pong') {
          clearTimeout(pingPongTimeout)
          socket.close()
        }
      })
      socket.addEventListener('open', () => {
        socket.send('ping')
      })
    },
    fallBackToLongPolling({ commit, state }): void {
      commit('UPDATE_SHOULD_USE_LONG_POLL', true)
      setScope({ fetchingMode: 'LongPoll' })
      state.brahmsSocket?.replaceTransport(Phoenix.LongPoll)
      state.brahmsSocket?.disconnect()
      state.brahmsSocket?.connect()
    },

    connect({ dispatch, state, rootGetters }): void {
      if (!rootGetters.isScreenshotMode) {
        setScope({ fetchingMode: 'WebSocket' })
        dispatch('connectToBrahms')
        dispatch('subscribeToStoreChanges')
      }
    },

    connectToBrahms({ dispatch }): void {
      const brahmsConnection: Phoenix.Socket | undefined = window.ww?.brahms?.socket
      if (brahmsConnection === undefined) {
        void dispatch('createNewBrahmsConnection')
        return
      }
      if (window.ww.brahms?.isUsingLongPolling ?? false) {
        if (serverClientCheckReceivingTimeout != null) {
          clearTimeout(serverClientCheckReceivingTimeout)
          serverClientCheckReceivingTimeout = null
        }

        void dispatch('fallbackToRestApi', null, { root: true })
      }
      void dispatch('updateCurrentBrahmsConnection', brahmsConnection)
    },

    disconnectBrahms({ commit, state }): void {
      state.brahmsSocket != null && state.brahmsSocket.disconnect()
      commit('SET_BRAHMS_SOCKET', null)
    },

    absent({ commit, state }): void {
      if (state.isPresencePresent) {
        commit('SET_IS_PRESENCE_PRESENT', false)
        state.wallBrahmsChannel != null && state.wallBrahmsChannel.push('absent', {})
      }
    },

    present({ commit, state }): void {
      commit('SET_IS_PRESENCE_PRESENT', true)
      state.wallBrahmsChannel != null && state.wallBrahmsChannel.push('present', {})
    },

    clearMessageQueue({ dispatch }): void {
      if (window.ww.brahms == null) return
      const messageQueue = window.ww.brahms?.messageQueue
      if (messageQueue == null) return
      void Promise.all(messageQueue.map(async (message) => await dispatch('handleMessage', message)))
      window.ww.brahms.messageQueue = []
    },

    createNewBrahmsConnection({ state, commit, dispatch }): void {
      if (!state.brahmsToken) return
      let connectionRetries = 0
      const brahmsConnection = getSocket(
        { accessToken: state.brahmsToken, deviceId: state.deviceId, shouldUseLongPoll: state.shouldUseLongPoll },
        (tries) => {
          connectionRetries++
          return RECONNECT_DELAY[tries - 1] || 10000
        },
      )
      brahmsConnection?.onError((_, transport, establishedConnections) => {
        if (transport === WebSocket && establishedConnections === 0 && connectionRetries > 1) {
          brahmsConnection?.replaceTransport(Phoenix.LongPoll)
          brahmsConnection?.connect()
        }
      })
      brahmsConnection?.connect()
      commit('SET_BRAHMS_SOCKET', brahmsConnection)

      void dispatch('createWallBrahmsChannel')
      void dispatch('createDeviceBrahmsChannel')
      void dispatch('createUserBrahmsChannel')
    },

    createNewBrahmsConnectionForUser({ commit, dispatch }, payload: { userId: UserId; brahmsToken: string }): void {
      void dispatch('disconnectBrahms')
      commit('SET_USER_ID', payload.userId)
      commit('SET_BRAHMS_TOKEN', payload.brahmsToken)
      void dispatch('createNewBrahmsConnection')
    },

    async updateCurrentBrahmsConnection({ commit, dispatch }, brahmsConnection: Phoenix.Socket): Promise<void> {
      commit('SET_BRAHMS_SOCKET', brahmsConnection)

      const wallBrahmsChannel = window.ww.brahms?.channels?.wall
      await dispatch('addWallBrahmsChannelHandlers', wallBrahmsChannel)
      wallBrahmsChannel?.off('new_msg', window.ww.brahms?.msgRefs?.wall)
      commit('WALL_CHANNEL_CONNECTED')
      commit('SET_WALL_BRAHMS_CHANNEL', wallBrahmsChannel)

      const deviceBrahmsChannel = window.ww.brahms?.channels?.device
      await dispatch('addDeviceBrahmsChannelHandlers', deviceBrahmsChannel)
      deviceBrahmsChannel?.off('new_msg', window.ww.brahms?.msgRefs?.device)
      commit('SET_DEVICE_BRAHMS_CHANNEL', deviceBrahmsChannel)

      const userBrahmsChannel = window.ww.brahms?.channels?.user
      await dispatch('addUserBrahmsChannelHandlers', userBrahmsChannel)

      void dispatch('clearMessageQueue')
    },

    createWallBrahmsChannel({ state, dispatch, commit, rootGetters }): void {
      const wallId = rootGetters.wallId
      if (state.brahmsSocket == null) return
      try {
        const wallBrahmsChannel: Phoenix.Channel = state.brahmsSocket.channel(`wall:${wallId}`)
        void dispatch('addWallBrahmsChannelHandlers', wallBrahmsChannel)
        wallBrahmsChannel.join().receive('ok', async () => {
          commit('WALL_CHANNEL_CONNECTED')
        })
        commit('SET_WALL_BRAHMS_CHANNEL', wallBrahmsChannel)
      } catch (e) {
        captureException(e)
      }
    },

    addWallBrahmsChannelHandlers({ commit, dispatch }, wallBrahmsChannel: Phoenix.Channel): void {
      wallBrahmsChannel?.on('new_msg', (data) => {
        void dispatch('handleMessage', data)
      })
      wallBrahmsChannel?.onError(() => {
        commit('WALL_CHANNEL_DISCONNECTED')
      })
      wallBrahmsChannel?.onClose(() => {
        commit('WALL_CHANNEL_DISCONNECTED')
      })
    },

    createDeviceBrahmsChannel({ state, commit, dispatch }): void {
      if (state.brahmsSocket == null) return
      try {
        const deviceBrahmsChannel: Phoenix.Channel = state.brahmsSocket.channel(`device:${state.deviceId}`)
        void dispatch('addDeviceBrahmsChannelHandlers', deviceBrahmsChannel)
        deviceBrahmsChannel.join()
        commit('SET_DEVICE_BRAHMS_CHANNEL', deviceBrahmsChannel)
      } catch (e) {
        captureException(e)
      }
    },

    addDeviceBrahmsChannelHandlers({ dispatch, state }, deviceBrahmsChannel: Phoenix.Channel): void {
      deviceBrahmsChannel.on('new_msg', (msg) => {
        void dispatch('handleMessage', msg)
      })
    },

    createUserBrahmsChannel({ state, dispatch }): void {
      if (state.brahmsSocket == null) return
      const userId = state.userId
      if (userId == null) return
      const userChannel: Phoenix.Channel = state.brahmsSocket.channel(`user:${userId}`)
      void dispatch('addUserBrahmsChannelHandlers', userChannel)
      userChannel.join()
    },

    addUserBrahmsChannelHandlers({ state, dispatch }, userChannel: Phoenix.Channel): void {
      userChannel.on('new_msg', (msg) => {
        void dispatch('handleMessage', msg)
      })
    },

    subscribeToStoreChanges({ dispatch, getters, rootGetters }): void {
      const flushUpdates = (): void => {
        if (getters.areUpdatesPending && !userNeedsFocus(rootGetters)) {
          dispatch('dispatchAllQueuedActions')
        }
      }
      const debouncedFlushUpdates = debounce(flushUpdates, 200, { leading: true })
      this.subscribe((): void => debouncedFlushUpdates())
    },

    pingContributingStatus({ state }): void {
      state.wallBrahmsChannel?.push('ping_contributing_status', {})
    },

    updateContributingStatus({ state }, { status }): void {
      state.wallBrahmsChannel?.push('update_contributing_status', { status })
    },

    async fetchAccumulatedReactions({ state }): Promise<AccumulatedReactions> {
      return await new Promise((resolve, reject) => {
        if (state.wallBrahmsChannel == null) return reject(Error('Undefined wall channel'))
        state.wallBrahmsChannel
          .push('fetch_accumulated_reactions', {}, 5000)
          .receive('ok', (data) => {
            resolve(data)
          })
          .receive('error', (error) => {
            reject(error)
          })
          .receive('timeout', () => reject(Error('Timeout')))
      })
    },

    async fetchComments(
      { state },
      query: { wishId?: number; wishHashid?: string; pageStart?: String },
    ): Promise<JsonAPIResponse<Comment>> {
      return await new Promise((resolve, reject) => {
        if (state.wallBrahmsChannel == null) return reject(Error('Undefined wall channel'))
        state.wallBrahmsChannel
          .push('fetch_comments', query, 5000)
          .receive('ok', (data) => {
            resolve(data)
          })
          .receive('error', (error) => {
            reject(error)
          })
          .receive('timeout', () => reject(Error('Timeout')))
      })
    },

    async fetchWishes({ state }, query: { pageStart?: String }): Promise<JsonAPIResponse<Wish>> {
      return await new Promise((resolve, reject) => {
        if (state.wallBrahmsChannel == null) return reject(Error('Undefined wall channel'))
        state.wallBrahmsChannel
          .push('fetch_wishes', query, 5000)
          .receive('ok', (data) => {
            resolve(data)
          })
          .receive('error', (error) => {
            reject(error)
          })
          .receive('timeout', () => reject(Error('Timeout')))
      })
    },

    handleMessage({ dispatch, rootState, rootGetters, state }, messageObj): void {
      if (!messageObj || !messageObj.event || messageObj.uid === rootState.uid) return
      const payload = JSON.parse(messageObj.message)
      // Signal that realtime is working
      ping('realtime', { rate: 20000 })
      const dispatchNow = async (type: string): Promise<any> => await dispatch(type, payload, { root: true })
      const queue = async (type: string): Promise<any> => await dispatch('queueUpdateAction', { type, payload })
      // We add a delay if the post has an attachment, so that we have sufficient
      // lead time for request coalescing to come into play.
      // See original commit: https://github.com/padlet/mozart/commit/9f9e0d60f3934c0e1c6ef411af97e1e24f429151
      const delay = (type: string): void => {
        const upTo1andHalfSecDelay = (Math.random() + 0.5) * 1000
        payload.attachment
          ? setTimeout((): void => {
              queue(type)
            }, upTo1andHalfSecDelay)
          : queue(type)
      }

      // Right now we are calling 'remote' actions in the modules themselves.
      // Eventually, the modules will have no concept of remote updates.
      // They'll only have simple CRUD operations that'll be called by this guy.

      const handlers = {
        edit_wall(): void {
          useScreenReaderNotificationsStore().setScreenReaderMessageBasedOnWallUpdate(payload)

          dispatchNow('updateWall')

          void useSurfaceSettingsStore()?.updatePreviewAttributes(payload)
          const newDefaultSectionId = payload.wish_arrangement?.default_section_id
          if (newDefaultSectionId != null) {
            useSurfaceSectionsStore().setDefaultSectionAndRecentlyTouchedSection(newDefaultSectionId)
          }
        },
        user_edited_wall(): void {
          const { user } = payload
          void useSurfaceSettingsStore()?.notifyWallSettingsUpdate(rootGetters.user.id, user)
        },
        delete_wall(): void {
          if (rootGetters.isLibraryWall as boolean) {
            navigateTo(rootGetters.libraryDashboardUrl)
          } else {
            navigateTo('/')
          }
        },
        add_section(): void {
          useSurfaceSectionsStore().insertSectionRemote(payload)
          void useSurfaceShareLinksStore().fetchSectionBreakoutLinks()
        },
        edit_section(): void {
          useSurfaceSectionsStore().updateSectionRemote(payload)
        },
        delete_section(): void {
          useSurfaceSectionsStore().removeSectionRemote(payload)
          if (rootGetters.breakoutSectionId === payload.id) {
            void useSurfaceShareLinksStore().navigateToSectionBreakoutDisabledPage()
          }
        },
        add_wish(): void {
          delay('post/newPostRemote')
        },
        new_poll_vote(): void {
          delay('post/newPollVoteRemote')
        },
        edit_poll(): void {
          delay('post/editPollRemote')
        },
        edit_wish(): void {
          delay('post/editPostRemote')
        },
        batch_edit_wish(): void {
          delay('post/batchEditPostRemote')
        },
        delete_wish(): void {
          dispatchNow('deletePostRemote')
        },
        wish_hidden_to_wait_for_auto_moderation(): void {
          dispatchNow('post/removePostToWaitForAutoModeration')
        },
        // we don't use delay() for drafts because they only exist for their authors
        // we don't expect that changing an attachment would result in many other connected clients requesting for that new attachment in real time
        add_wish_draft(): void {
          useSurfaceDraftsStore().insertDraftRemote(payload)
        },
        edit_wish_draft(): void {
          useSurfaceDraftsStore().updateDraftRemote(payload)
        },
        delete_wish_draft(): void {
          useSurfaceDraftsStore().removeDraftRemote(payload)
        },
        add_wall_automation(): void {
          useSurfaceAutomationsStore().createAutomationRemote(payload)
        },
        edit_wall_automation(): void {
          useSurfaceAutomationsStore().updateAutomationRemote(payload)
        },
        delete_wall_automation(): void {
          useSurfaceAutomationsStore().deleteAutomationRemote(payload)
        },
        refresh_wall_sections(): void {
          if (isAppUsing('realtimeFetching')) {
            if (isAppUsing('layoutFormatSettingV2') || rootGetters.canUseSections) {
              useSurfaceSectionsStore().updateSectionEntities(payload)
            }
          }
        },
        refresh_wishes(): void {
          if (isAppUsing('realtimeFetching')) {
            dispatchNow('post/refreshPosts')
          }
        },
        refresh_comments(): void {
          if (isAppUsing('realtimeFetching')) {
            dispatchNow('comment/refreshComments')
          }
        },
        refresh_accumulated_reactions(): void {
          if (isAppUsing('realtimeFetching')) {
            void useReactionsStore().refreshAccumulatedReactions(payload)
          }
        },
        refresh_padlet_starting_state(): void {
          if (isAppUsing('realtimeFetching')) {
            dispatchNow('refreshPadletStartingState')
          }
        },
        add_comment(): void {
          queue('comment/newCommentRemote')
        },
        edit_comment(): void {
          queue('comment/editCommentRemote')
        },
        delete_comment(): void {
          queue('comment/deleteCommentRemote')
        },
        update_accumulated_reactions(): void {
          useReactionsStore().updateAccumulatedReactions(payload)
        },
        update_user_reaction(): void {
          useReactionsStore().updateUserReaction(payload)
        },
        add_connection(): void {
          useSurfacePostConnectionStore().newConnectionRemote(payload)
        },
        delete_connection(): void {
          useSurfacePostConnectionStore().deleteConnectionRemote(payload)
        },
        edit_table_layout(): void {
          queue('table/editLayoutRemote')
        },
        edit_table_row(): void {
          queue('table/editRowRemote')
        },
        edit_table_col(): void {
          queue('table/editColRemote')
        },
        contributing_status(): void {
          void useSurfaceContributingStatusStore().updateContributingStatusRemote(payload)
        },
        ping_contributing_status(): void {
          useSurfaceContributingStatusStore().setContributingStatusIfActive()
        },
        login(): void {
          if (shouldUseBroadcastChannel()) return
          useSurfaceGuestStore().handleNewUserCreated(payload)
        },
        logout(): void {
          if (shouldUseBroadcastChannel()) return
          useSurfaceCommentAttachmentsStore().resetCommentAttachments()
          useCommentsStore().resetComments()
          useSurfaceDraftsStore().resetDrafts()
          useSurfaceGuestStore().handleNewUserCreated(payload)
        },
        collaborator_left(): void {
          useSurfaceSharePanelStore().removeCollaboratorRemotely(payload)
        },
        collaborator_create(): void {
          useSurfacePermissionsStore().handleCollaboratorChange(payload)
        },
        collaborator_update(): void {
          useSurfacePermissionsStore().handleCollaboratorChange(payload)
        },
        collaborator_delete(): void {
          useSurfacePermissionsStore().handleCollaboratorChange(payload)
        },
        access_settings_updated(): void {
          useSurfacePermissionsStore().handleAccessSettingsChange()
        },
        wall_follow(): void {
          dispatchNow('remoteUpdateWallFollows')
        },
        wall_follows_count(): void {
          dispatchNow('remoteUpdateWallFollowsCount')
        },
        self_harm_post_detected(): void {
          useSurfacePostsStore().showSelfHarmPostAlert(payload)
        },
        self_harm_comment_detected(): void {
          useCommentsStore().showSelfHarmCommentAlert(payload)
        },
        submission_request_updated(): void {
          void useSurfaceShareLinksStore().handleSubmissionRequestUpdated()
        },
        section_breakout_disabled(): void {
          void useSurfaceShareLinksStore().handleSectionBreakoutDisabled()
        },
        add_wall_custom_post_property(): void {
          const newCustomPostProperty: WallCustomPostProperty = payload.data.attributes
          void useSurfacePostPropertiesStore().insertNewCustomPostProperty(newCustomPostProperty)
        },
        delete_wall_custom_post_property(): void {
          const { property_id: propertyId } = payload
          void useSurfacePostPropertiesStore().removeCustomPostPropertyFromList(propertyId)
        },
        edit_wall_custom_post_property(): void {
          const updateCustomPostProperty: WallCustomPostProperty = payload.data.attributes
          void useSurfacePostPropertiesStore().updateCustomPostPropertyInList(updateCustomPostProperty)
        },
        set_wall_post_properties(): void {
          const wallPostProperties: WallPostProperties = payload.data.attributes
          void useSurfacePostPropertiesStore().setWallPostProperties(wallPostProperties)
        },
        wall_frozen(): void {
          useSurfaceStore().handlePadletFrozen()
        },
        wall_unfrozen(): void {
          useSurfaceStore().handlePadletUnfrozen()
        },
        mark_as_template(): void {
          useSurfaceStore().setWallAsTemplate()
        },
        unmark_as_template(): void {
          useSurfaceStore().unsetWallAsTemplate()
        },
        server_client_check(): void {
          // Sanity check
          if (payload.device_id !== state.deviceId || payload.user_id !== state.userId) {
            return
          }

          if (serverClientCheckReceivingTimeout != null) {
            clearTimeout(serverClientCheckReceivingTimeout)
            serverClientCheckReceivingTimeout = null

            if (isAppUsing('brahmsCheck')) {
              if (state.shouldUseLongPoll) {
                trackEvent('Realtime', 'Brahms LongPoll server_client_check succeeded')
              } else {
                trackEvent('Realtime', 'Brahms WebSocket server_client_check succeeded')
              }
            }
          }
        },
        user_name_update(): void {
          const surfaceGuestStore = useSurfaceGuestStore()
          if (surfaceGuestStore.shouldEnableAnonymousAttribution && payload.user?.id === state.userId) {
            void surfaceGuestStore.updateUserInSurfaceStores(payload.user)
          }
        },
        user_create(): void {
          const surfaceGuestStore = useSurfaceGuestStore()
          if (surfaceGuestStore.shouldEnableAnonymousAttribution) {
            void surfaceGuestStore.handleNewUserCreated(payload)
          }
        },
        grading_context_file_upload_done(): void {
          if (isAppUsing('grading') && payload === rootGetters.wallId) {
            useSurfaceGradingPanelStore().isUploadingContextFile = false
          }
        },
      }

      const handler = handlers[messageObj.event]
      handler && handler()
    },

    queueUpdateAction({ dispatch, commit, getters, rootGetters }, { type, payload }): void {
      // Check comment/editCommentRemote because userNeedsFocus returns true if user has interacted with comment input recently.
      // This makes it impossible for a comment awaiting approval to automatically update (auto moderated wall),
      // and the user should not need to manually hit "update" for such a change.
      if (userNeedsFocus(rootGetters) && type !== 'comment/editCommentRemote') {
        if (!getters.areUpdatesPending) dispatch('setUpdatesPendingNotification')
        commit('QUEUE_UPDATE_ACTION', { type, payload })
      } else {
        dispatch(type, payload, { root: true })
      }
    },

    setUpdatesPendingNotification({ dispatch, commit }): void {
      const uid = useGlobalSnackbarStore().setSnackbar({
        message: __('Padlet updated elsewhere.'),
        actionText: __('Update'),
        actionTextActions: [async () => await dispatch('dispatchAllQueuedActions')],
        persist: true,
      })

      commit('SET_NOTIFICATION_UID', uid)
    },

    clearUpdatesPendingNotification({ dispatch, commit, state }): void {
      if (state.notificationUid != null) {
        useGlobalSnackbarStore().removeSnackbar(state.notificationUid)
      }
      commit('CLEAR_NOTIFICATION_UID')
    },

    dispatchAllQueuedActions({ commit, dispatch, state }): void {
      if (state.isClearingQueue) return
      commit('START_CLEARING_DISPATCH_QUEUE')
      state.dispatchQueue.forEach(
        async ({ type, payload }): Promise<any> => await dispatch(type, payload, { root: true }),
      )
      dispatch('clearDispatchQueue')
      commit('END_CLEARING_DISPATCH_QUEUE')
    },

    clearDispatchQueue({ dispatch, commit }): void {
      dispatch('clearUpdatesPendingNotification')
      commit('CLEAR_DISPATCH_QUEUE')
    },
  },
}

export default RealtimeModule
export type { RealtimeState }
