/* eslint-disable @typescript-eslint/camelcase */
/**
 * @file generate display attributes for a link
 */
import getDescription from './description_maker'
import getEmbedCode from './embed_code_maker'
import { isStatic } from './link_helpers'
import makeMetadata from './metadata_maker'
import getProviderName from './provider_name_maker'
import getImage from './thumbnail_maker'
import getTitle from './title_maker'
import {
  BeethovenContentCategory,
  BeethovenContentSubcategory,
  BeethovenDisplayAttributes,
  BeethovenDisplayOptions,
  Link,
} from './types'

function buildDisplayAttributesFromLink(link: Link, options: BeethovenDisplayOptions): BeethovenDisplayAttributes {
  const attrs: Partial<BeethovenDisplayAttributes> = {}

  attrs.url = link.url
  attrs.content_category = (link.content_category as BeethovenContentCategory) || 'unknown'
  attrs.content_subcategory = (link.content_subcategory as BeethovenContentSubcategory) || ''
  attrs.extension = link.extension || ''
  attrs.title = getTitle(link)
  attrs.description = getDescription(link)
  attrs.metadata = makeMetadata(link)

  const image = getImage(link, options)
  attrs.image_url = image.url
  attrs.image_width = image.width
  attrs.image_height = image.height
  attrs.image_color = image.color
  attrs.image_aspect_ratio = image.aspect_ratio
  attrs.image_luminance = image.luminance
  attrs.original_image_url = link.preview_image?.url
  attrs.original_image_width = image.original_width
  attrs.original_image_height = image.original_height

  const { embedCode, embedWidth, embedHeight } = getEmbedCode(link, image)
  attrs.embed_code = embedCode
  attrs.embed_width = embedWidth || link.embed_width || undefined
  attrs.embed_height = embedHeight || link.embed_height || undefined

  attrs.show_meta = !!attrs.title
  attrs.is_tiny = image.width < 100 && image.height < 100
  attrs.is_downloadable = isStatic(link)
  attrs.is_fallback = !link.preview_image || !link.preview_image.url
  attrs.is_transparent = (link.preview_image && link.preview_image.is_transparent) || false
  attrs.provider_name = getProviderName(link)

  return attrs as BeethovenDisplayAttributes
}

export { buildDisplayAttributesFromLink }
