/**
 * @file Manipulate images using Vivaldi backend.
 */
import { RequestOptions, TransformTuple } from './types'
import { buildTransformUrl, TransformUrlOptions } from './url_builder'
import * as webp from './webp'

const processedUrl = (url: string, options: RequestOptions = {}): string => {
  if (!url) {
    console.info('[vivaldi-client] url is null/undefined')
    url = ''
  }
  const processingOptions: TransformTuple[] = []

  // please keep these alphabetical. Yes, we can sort them using the sort function,
  // but it is also easier to follow for the devs.

  // alignment/rotation
  if (options.align) {
    processingOptions.push(['a', options.align])
  }

  // aspect ratio
  if (options.ar) {
    processingOptions.push(['ar', options.ar])
  }

  // crop
  if (options.width || options.height) {
    if (options.crop) processingOptions.push(['c', options.crop])

    // dpr
    if (options.dpr) processingOptions.push(['dpr', options.dpr])
  }

  // effects
  if (options.effect) {
    processingOptions.push(['e', options.effect])
  } else if (options.trim) {
    processingOptions.push(['e', 'trim'])
  }

  // gravity
  if (options.gravity) {
    processingOptions.push(['g', options.gravity])
  }

  // height
  if (options.height) {
    const intHeight = Math.ceil(options.height)
    processingOptions.push(['h', intHeight])
  }

  // quality
  if (options.quality) {
    processingOptions.push(['q', options.quality])
  }

  // width
  if (options.width) {
    const intWidth = Math.ceil(options.width)
    processingOptions.push(['w', intWidth])
  }

  // format
  let format = ''
  if (options.format) {
    format = options.format
  } else if (webp.isSupported()) {
    format = 'webp'
  }

  // grayscale
  if (options.grayscale) {
    processingOptions.push(['e', 'grayscale'])
  }

  const urlOptions: TransformUrlOptions = { transforms: processingOptions, metadata: !!options.metadata }
  if (format) {
    urlOptions.format = format
  }

  return buildTransformUrl(url, urlOptions)
}

export { processedUrl }
