// @file Detect Pixel ratio for platform in the parent module
/* eslint-disable @typescript-eslint/no-var-requires */

import { isReactNative } from '@padlet/platform'

let pixelRatio = 2

if (isReactNative) {
  const { PixelRatio } = require('react-native')
  pixelRatio = Math.ceil(PixelRatio.get())
} else if (typeof window !== 'undefined' && window.devicePixelRatio !== undefined) {
  pixelRatio = Math.ceil(window.devicePixelRatio)
}

export default pixelRatio
