/**
 * @file Build Vivaldi URLs.
 */
import arrayToQueryString from './array_to_query_string'
import { INHOUSE_HOST, PROXY_HOST } from './hosts'
import { QueryParam, TransformTuple } from './types'

const version = '1'

const buildProxyUrl = (url: string): string => {
  const encodedUrl: string = encodeURIComponent(url)
  return `https://${PROXY_HOST}/${version}/proxy?url=${encodedUrl}`
}

interface TransformUrlOptions {
  transforms: TransformTuple[]
  metadata: boolean
  format?: string
}

const generateVersionFromUrl = (url: string): string => {
  const downcaseUrl = url.toLowerCase()
  if (/(png|jpg|jpeg|webp)$/.exec(downcaseUrl)) return '1'
  if (downcaseUrl.endsWith('gif')) return '2'
  return '3'
}

const buildTransformUrl = (url: string, options: TransformUrlOptions): string => {
  const processingOptionsString: string[] = options.transforms.map((x): string => x.join('_'))
  let version
  const urlQueryParams: QueryParam[] = []
  if (options.metadata) {
    urlQueryParams.push(['m', '1'])
    version = '1'
  } else {
    version = generateVersionFromUrl(url)
  }
  if (processingOptionsString.length > 0) urlQueryParams.push(['t', processingOptionsString.join(',')])
  urlQueryParams.push(['url', url])
  const urlQueryString = arrayToQueryString(urlQueryParams)
  if (options.format) {
    return `https://${INHOUSE_HOST}/${version}/image.${options.format}?${urlQueryString}`
  }
  return `https://${INHOUSE_HOST}/${version}/image?${urlQueryString}`
}

export { buildProxyUrl, buildTransformUrl }
export type { TransformUrlOptions }
