// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file wall_background.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

import { Color } from "./Common";

export namespace WallBackground {
  /**
   * @generated from enum proto.WallBackground.Fill
   */
  export enum Fill {
    /**
     * @generated from enum value: FILL_UNSPECIFIED = 0;
     */
    Unspecified = 0,
    /**
     * @generated from enum value: FILL_COVER = 1;
     */
    Cover = 1,
    /**
     * @generated from enum value: FILL_TILE = 2;
     */
    Tile = 2,
  }

  /**
   * @generated from enum proto.WallBackground.Effect
   */
  export enum Effect {
    /**
     * @generated from enum value: EFFECT_UNSPECIFIED = 0;
     */
    Unspecified = 0,
    /**
     * @generated from enum value: EFFECT_BLUR = 1;
     */
    Blur = 1,
  }

  /**
   * @generated from enum proto.WallBackground.LuminanceName
   */
  export enum LuminanceName {
    /**
     * @generated from enum value: LUMINANCE_NAME_UNSPECIFIED = 0;
     */
    Unspecified = 0,
    /**
     * @generated from enum value: LUMINANCE_NAME_LIGHT = 1;
     */
    Light = 1,
    /**
     * @generated from enum value: LUMINANCE_NAME_DARK = 2;
     */
    Dark = 2,
  }

  /**
   * @generated from enum proto.WallBackground.TextColor
   */
  export enum TextColor {
    /**
     * @generated from enum value: TEXT_COLOR_UNSPECIFIED = 0;
     */
    Unspecified = 0,
    /**
     * @generated from enum value: TEXT_COLOR_BLACK = 1;
     */
    Black = 1,
    /**
     * @generated from enum value: TEXT_COLOR_LIGHT = 2;
     */
    Light = 2,
  }

  /**
   * @generated from enum proto.WallBackground.Type
   */
  export enum Type {
    /**
     * @generated from enum value: TYPE_UNSPECIFIED = 0;
     */
    Unspecified = 0,
    /**
     * @generated from enum value: TYPE_SOLID_COLOR = 1;
     */
    SolidColor = 1,
    /**
     * @generated from enum value: TYPE_GRADIENT = 2;
     */
    Gradient = 2,
    /**
     * @generated from enum value: TYPE_PATTERN = 3;
     */
    Pattern = 3,
    /**
     * @generated from enum value: TYPE_PICTURE = 4;
     */
    Picture = 4,
    /**
     * @generated from enum value: TYPE_ART_ILLUSTRATION = 5;
     */
    ArtIllustration = 5,
  }

  export class WallBackgroundMessage extends Message<WallBackgroundMessage> {
    /**
     * @generated from field: string url = 1;
     */
    url: string = "";
    /**
     * @generated from field: string thumbnail_url = 2;
     */
    thumbnailUrl: string = "";
    /**
     * @generated from field: proto.WallBackground.Fill fill = 3;
     */
    fill: WallBackground.Fill = WallBackground.Fill.Unspecified;
    /**
     * @generated from field: repeated proto.WallBackground.Effect effects = 4;
     */
    effects: WallBackground.Effect[] = [];
    /**
     * @generated from field: bool is_full_page = 5;
     */
    isFullPage: boolean = false;
    /**
     * @generated from field: bool is_custom = 6;
     */
    isCustom: boolean = false;
    /**
     * @generated from field: proto.Color primary_color = 7;
     */
    primaryColor: Color.ColorMessage;
    /**
     * @generated from field: proto.WallBackground.TextColor primary_text_color = 8;
     */
    primaryTextColor: WallBackground.TextColor = WallBackground.TextColor.Unspecified;
    /**
     * @generated from field: proto.Color accent_color = 9;
     */
    accentColor: Color.ColorMessage;
    /**
     * @generated from field: proto.WallBackground.TextColor accent_text_color = 10;
     */
    accentTextColor: WallBackground.TextColor = WallBackground.TextColor.Unspecified;
    /**
     * @generated from field: proto.WallBackground.Type type = 11;
     */
    type: WallBackground.Type = WallBackground.Type.Unspecified;
    /**
     * @generated from field: uint32 width = 12;
     */
    width: number = 0;
    /**
     * @generated from field: uint32 height = 13;
     */
    height: number = 0;
    /**
     * @generated from field: proto.WallBackground.LuminanceName luminance_name = 14;
     */
    luminanceName: WallBackground.LuminanceName = WallBackground.LuminanceName.Unspecified;
    /**
     * @generated from field: proto.Color dominant_color = 15;
     */
    dominantColor: Color.ColorMessage;

    constructor(data?: PartialMessage<WallBackgroundMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.WallBackground";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "thumbnail_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "fill", kind: "enum", T: proto3.getEnumType(WallBackground.Fill) },
      { no: 4, name: "effects", kind: "enum", T: proto3.getEnumType(WallBackground.Effect), repeated: true },
      { no: 5, name: "is_full_page", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 6, name: "is_custom", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 7, name: "primary_color", kind: "message", T: Color.ColorMessage },
      { no: 8, name: "primary_text_color", kind: "enum", T: proto3.getEnumType(WallBackground.TextColor) },
      { no: 9, name: "accent_color", kind: "message", T: Color.ColorMessage },
      { no: 10, name: "accent_text_color", kind: "enum", T: proto3.getEnumType(WallBackground.TextColor) },
      { no: 11, name: "type", kind: "enum", T: proto3.getEnumType(WallBackground.Type) },
      { no: 12, name: "width", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
      { no: 13, name: "height", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
      { no: 14, name: "luminance_name", kind: "enum", T: proto3.getEnumType(WallBackground.LuminanceName) },
      { no: 15, name: "dominant_color", kind: "message", T: Color.ColorMessage },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WallBackgroundMessage {
      return new WallBackgroundMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WallBackgroundMessage {
      return new WallBackgroundMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WallBackgroundMessage {
      return new WallBackgroundMessage().fromJsonString(jsonString, options);
    }

      static equals(a: WallBackgroundMessage | PlainMessage<WallBackgroundMessage> | undefined, b: WallBackgroundMessage | PlainMessage<WallBackgroundMessage> | undefined): boolean {
      return proto3.util.equals(WallBackgroundMessage, a, b);
    }

    toObject(): WallBackground {
      return {
        url: this.url,
        thumbnailUrl: this.thumbnailUrl,
        fill: this.fill,
        effects: this.effects,
        isFullPage: this.isFullPage,
        isCustom: this.isCustom,
        primaryColor: this.primaryColor.toObject(),
        primaryTextColor: this.primaryTextColor,
        accentColor: this.accentColor.toObject(),
        accentTextColor: this.accentTextColor,
        type: this.type,
        width: this.width,
        height: this.height,
        luminanceName: this.luminanceName,
        dominantColor: this.dominantColor.toObject(),
      };
    }

    static fromObject(object: WallBackground): WallBackgroundMessage {
      return new WallBackgroundMessage({
        url: object.url,
        thumbnailUrl: object.thumbnailUrl,
        fill: object.fill,
        effects: object.effects,
        isFullPage: object.isFullPage,
        isCustom: object.isCustom,
        primaryColor: Color.ColorMessage.fromObject(object.primaryColor),
        primaryTextColor: object.primaryTextColor,
        accentColor: Color.ColorMessage.fromObject(object.accentColor),
        accentTextColor: object.accentTextColor,
        type: object.type,
        width: object.width,
        height: object.height,
        luminanceName: object.luminanceName,
        dominantColor: Color.ColorMessage.fromObject(object.dominantColor),
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): WallBackground {
    return WallBackgroundMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: WallBackground): Uint8Array {
    return WallBackgroundMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.WallBackground
 */
export type WallBackground = {
  /**
   * @generated from field: string url = 1;
   */
  url: string;
  /**
   * @generated from field: string thumbnail_url = 2;
   */
  thumbnailUrl: string;
  /**
   * @generated from field: proto.WallBackground.Fill fill = 3;
   */
  fill: WallBackground.Fill;
  /**
   * @generated from field: repeated proto.WallBackground.Effect effects = 4;
   */
  effects: WallBackground.Effect[];
  /**
   * @generated from field: bool is_full_page = 5;
   */
  isFullPage: boolean;
  /**
   * @generated from field: bool is_custom = 6;
   */
  isCustom: boolean;
  /**
   * @generated from field: proto.Color primary_color = 7;
   */
  primaryColor: Color;
  /**
   * @generated from field: proto.WallBackground.TextColor primary_text_color = 8;
   */
  primaryTextColor: WallBackground.TextColor;
  /**
   * @generated from field: proto.Color accent_color = 9;
   */
  accentColor: Color;
  /**
   * @generated from field: proto.WallBackground.TextColor accent_text_color = 10;
   */
  accentTextColor: WallBackground.TextColor;
  /**
   * @generated from field: proto.WallBackground.Type type = 11;
   */
  type: WallBackground.Type;
  /**
   * @generated from field: uint32 width = 12;
   */
  width: number;
  /**
   * @generated from field: uint32 height = 13;
   */
  height: number;
  /**
   * @generated from field: proto.WallBackground.LuminanceName luminance_name = 14;
   */
  luminanceName: WallBackground.LuminanceName;
  /**
   * @generated from field: proto.Color dominant_color = 15;
   */
  dominantColor: Color;
}

proto3.util.setEnumType(WallBackground.Fill, "proto.WallBackground.Fill", [
  { no: 0, name: "FILL_UNSPECIFIED" },
  { no: 1, name: "FILL_COVER" },
  { no: 2, name: "FILL_TILE" },
]);

proto3.util.setEnumType(WallBackground.Effect, "proto.WallBackground.Effect", [
  { no: 0, name: "EFFECT_UNSPECIFIED" },
  { no: 1, name: "EFFECT_BLUR" },
]);

proto3.util.setEnumType(WallBackground.LuminanceName, "proto.WallBackground.LuminanceName", [
  { no: 0, name: "LUMINANCE_NAME_UNSPECIFIED" },
  { no: 1, name: "LUMINANCE_NAME_LIGHT" },
  { no: 2, name: "LUMINANCE_NAME_DARK" },
]);

proto3.util.setEnumType(WallBackground.TextColor, "proto.WallBackground.TextColor", [
  { no: 0, name: "TEXT_COLOR_UNSPECIFIED" },
  { no: 1, name: "TEXT_COLOR_BLACK" },
  { no: 2, name: "TEXT_COLOR_LIGHT" },
]);

proto3.util.setEnumType(WallBackground.Type, "proto.WallBackground.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_SOLID_COLOR" },
  { no: 2, name: "TYPE_GRADIENT" },
  { no: 3, name: "TYPE_PATTERN" },
  { no: 4, name: "TYPE_PICTURE" },
  { no: 5, name: "TYPE_ART_ILLUSTRATION" },
]);

