/**
 * @file Composable for methods related to styling surface using settings page (with preview data and real data)
 */
import appCan from '@@/bits/app_can'
import { getDisplayAttributes } from '@@/bits/beethoven'
import { $ } from '@@/bits/jquery'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { processedUrl } from '@padlet/vivaldi-client'
import { storeToRefs } from 'pinia'
import { onMounted, ref, watch } from 'vue'

enum Fill {
  cover = 'cover',
  tile = 'auto',
  auto = 'auto',
}

export const useSurfacePageStyling = (): void => {
  const { title, portrait, fontId, originalBackground, background, backgroundColor, backgroundLuminance, colorScheme } =
    storeToRefs(useSurfaceStore())
  const surfaceSettingsStore = useSurfaceSettingsStore()
  const { isPreviewing } = storeToRefs(surfaceSettingsStore)
  const { updateBackgroundMetadata } = surfaceSettingsStore

  const latestBackgroudUpdateTime = ref<Date>(new Date())

  function isUpdateStale(timestamp): boolean {
    return latestBackgroudUpdateTime.value !== timestamp
  }

  async function fetchBackgroundMetadata(url, timestamp): Promise<void> {
    const data = await getDisplayAttributes(url, { dpr: 1.0, locale: 'en' })
    if (data != null) {
      const { image_color: dominantColourAsRgb, image_luminance: imageLuminance } = data
      if (isUpdateStale(timestamp)) return
      updateBackgroundMetadata({ dominantColourAsRgb, imageLuminance })
    }
  }

  function setBackground(url, fill: string): void {
    $('[data-behavior~="background-holder"]').css({
      backgroundSize: Fill[fill],
      backgroundImage: `url(${url})`,
    })
  }

  watch(title, (newValue) => {
    document.title = newValue
  })

  watch(portrait, (newPortrait) => {
    const faviconElement = document.querySelector("link[rel='icon']") as HTMLLinkElement
    if (faviconElement) {
      faviconElement.href = newPortrait || '/favicon.ico'
    }

    const appleTouchIconElement = document.querySelector("link[rel='apple-touch-icon']") as HTMLLinkElement
    if (appleTouchIconElement) {
      appleTouchIconElement.href = newPortrait || '/apple-touch-icon.png'
    }
  })

  watch(fontId, (newFontId) => {
    $('[data-font]').attr('data-font', newFontId)
  })

  watch(
    backgroundLuminance,
    (luminanceName) => {
      $('[data-behavior~="background-holder"]').attr('data-background-luminance', luminanceName)
    },
    { immediate: true },
  )

  watch(backgroundColor, (colorAsRgb) => {
    $('meta[name=theme-color]').attr('content', colorAsRgb)
    if (appCan('nativeSurfaceBackground')) {
      return
    }
    document.documentElement.style.backgroundColor = colorAsRgb
  })

  watch(colorScheme, (newColorScheme) => {
    $('[data-color-scheme]').attr('data-color-scheme', newColorScheme)
  })

  watch(background, (newBackground, oldBackground) => {
    if (appCan('nativeSurfaceBackground')) {
      return
    }

    if (
      oldBackground.url === newBackground.url &&
      oldBackground.fill === newBackground.fill &&
      oldBackground.effect === newBackground.effect
    )
      return

    // Show tiny version of the new background first, in case background is huge
    const obscureBackgroundUrl = processedUrl(newBackground.url, { preset: 'obscure' })
    setBackground(obscureBackgroundUrl, Fill.cover)

    const thisUpdateTimestamp = new Date()
    latestBackgroudUpdateTime.value = thisUpdateTimestamp

    const transforms = {
      preset: 'background',
      width: window.screen.width,
      height: window.screen.height,
      effect: newBackground.effect === 'blur' ? 'blur:10' : undefined,
    }
    const processedBackgroundUrl = processedUrl(newBackground.url, transforms)

    const image = new Image()
    image.onload = (): void => {
      if (isUpdateStale(thisUpdateTimestamp)) return

      let backgroundSize = Fill.auto
      if (newBackground.fill && newBackground.fill !== 'undefined') {
        backgroundSize = newBackground.fill
      } else if (image.width >= 800) {
        backgroundSize = Fill.cover
      }
      // Expect metadata to be provided when change is triggered remotely.
      // only update background metadata if wallpaper is custom
      // non custom wallpapers metadata is provided by wallpaper airtable
      if (isPreviewing.value && originalBackground.value.url !== newBackground.url && newBackground.is_custom) {
        fetchBackgroundMetadata(newBackground.url, thisUpdateTimestamp)
      }

      setBackground(processedBackgroundUrl, backgroundSize)
    }
    image.src = processedBackgroundUrl
  })

  onMounted(() => {
    if (appCan('nativeSurfaceBackground')) {
      $('[data-behavior~="background-holder"]').css({
        background: 'transparent',
      })
    }
  })
}
