/**
 * Rules:
 * 1. Wall names must only consist of _, letters, and numbers.
 * 2. Wall names can only be a maximum of 50 characters
 */
export default async function checkWallAddressValid (wallName: string): Promise<boolean> {
  if (!wallName.match(/^[_A-Za-z0-9]+$/)) {
    return false
  }
  if (wallName.length >= 50 || wallName.trim().length === 0) {
    return false
  }
  return true
}
