/** @file An object representing folders query.  */
import type { BrahmsConnection, MozartConnection } from '../core/DataConnections'
import { Folder } from '../gen'
import { GetFoldersResponse } from '../gen/GetFoldersResponse'

interface FolderRealtimeHandler {
  addFolder: (folder: Folder) => void
  removeFolder: (folder: Folder) => void
  updateFolder: (folder: Folder) => void
}

class FoldersResource {
  constructor(private connections: MozartConnection & BrahmsConnection) {}

  async allV1(): Promise<Folder[]> {
    const message = await this.connections.mozart.requestProtobuf('/api/dal/folders', {
      parseProtobuf: GetFoldersResponse.fromBinary,
    })
    return message.records
  }

  async subscribeV1({ userId }, handler: FolderRealtimeHandler) {
    return await this.connections.brahms.subscribeToChannel('user', userId, (event, record) => {
      const parseFolder = () => Folder.fromBinary(Buffer.from(record, 'base64'))
      switch (event) {
        case 'proto_add_folder':
          handler.addFolder(parseFolder())
          break
        case 'proto_remove_folder':
          handler.removeFolder(parseFolder())
          break
        case 'proto_update_folder':
          handler.updateFolder(parseFolder())
          break
      }
    })
  }
}

export { FoldersResource }
