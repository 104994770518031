// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file realtime_message.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

import { ContributingStatus } from "./ContributingStatus";

/**
 * @generated from enum proto.RealtimeEvent
 */
export enum RealtimeEvent {
  /**
   * @generated from enum value: REALTIME_EVENT_UNSPECIFIED = 0;
   */
  Unspecified = 0,
  /**
   * @generated from enum value: REALTIME_EVENT_PING_CONTRIBUTING_STATUS = 1;
   */
  PingContributingStatus = 1,
  /**
   * @generated from enum value: REALTIME_EVENT_CONTRIBUTING_STATUS = 2;
   */
  ContributingStatus = 2,
}

export namespace RealtimeMessage {
  export class RealtimeMessageMessage extends Message<RealtimeMessageMessage> {
    /**
     * @generated from field: proto.RealtimeEvent event = 1;
     */
    event: RealtimeEvent = RealtimeEvent.Unspecified;
    /**
     * @generated from oneof proto.RealtimeMessage.message
     */
    message:
      | { case: "contributingStatus"; value: ContributingStatus.ContributingStatusMessage }
      | { case: undefined; value?: undefined } = { case: undefined };

    constructor(data?: PartialMessage<RealtimeMessageMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.RealtimeMessage";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "event", kind: "enum", T: proto3.getEnumType(RealtimeEvent) },
      { no: 2, name: "contributing_status", kind: "message", T: ContributingStatus.ContributingStatusMessage, oneof: "message" },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RealtimeMessageMessage {
      return new RealtimeMessageMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RealtimeMessageMessage {
      return new RealtimeMessageMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RealtimeMessageMessage {
      return new RealtimeMessageMessage().fromJsonString(jsonString, options);
    }

      static equals(a: RealtimeMessageMessage | PlainMessage<RealtimeMessageMessage> | undefined, b: RealtimeMessageMessage | PlainMessage<RealtimeMessageMessage> | undefined): boolean {
      return proto3.util.equals(RealtimeMessageMessage, a, b);
    }

    toObject(): RealtimeMessage {
      return {
        event: this.event,
        message: (() => {
          switch (this.message.case) {
            case undefined:
              return { case: undefined };
            case "contributingStatus":
              return { case: "contributingStatus", value: this.message.value.toObject() };
          }
        })(),
      };
    }

    static fromObject(object: RealtimeMessage): RealtimeMessageMessage {
      return new RealtimeMessageMessage({
        event: object.event,
        message: (() => {
          switch (object.message.case) {
            case undefined:
              return { case: undefined };
            case "contributingStatus":
              return { case: "contributingStatus", value: ContributingStatus.ContributingStatusMessage.fromObject(object.message.value) };
          }
        })(),
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): RealtimeMessage {
    return RealtimeMessageMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: RealtimeMessage): Uint8Array {
    return RealtimeMessageMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.RealtimeMessage
 */
export type RealtimeMessage = {
  /**
   * @generated from field: proto.RealtimeEvent event = 1;
   */
  event: RealtimeEvent;
  /**
   * @generated from oneof proto.RealtimeMessage.message
   */
  message:
    | { case: "contributingStatus"; value: ContributingStatus }
    | { case: undefined; value?: undefined };
}

proto3.util.setEnumType(RealtimeEvent, "proto.RealtimeEvent", [
  { no: 0, name: "REALTIME_EVENT_UNSPECIFIED" },
  { no: 1, name: "REALTIME_EVENT_PING_CONTRIBUTING_STATUS" },
  { no: 2, name: "REALTIME_EVENT_CONTRIBUTING_STATUS" },
]);

