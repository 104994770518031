import { RequestOptions, QueryParam } from './types'
import appendParamsToUrl from './append_params_to_url'

const canServe = function(url: string, options: RequestOptions): boolean {
  if (options.metadata) return false
  const isUnsplashUrl = !!url.startsWith('https://images.unsplash.com')
  return isUnsplashUrl
}

const cropToFitMap = function(crop = 'limit'): string {
  const simpleMap: Record<string, string> = {
    fill: 'crop',
    lfill: 'min',
    limit: 'max',
    scale: 'scale',
    fit: 'clip',
    thumb: 'crop',
  }
  return simpleMap[crop]
}

const gravityToCropMap = function(gravity = 'auto'): string {
  const simpleMap: Record<string, string> = {
    north: 'top',
    center: 'center',
    auto: 'faces,edges',
  }
  return simpleMap[gravity]
}

const processedUrl = (url: string, options: RequestOptions = {}): string => {
  if (!url) {
    console.info('[vivaldi-client] url is null/undefined')
    url = ''
  }
  const processingOptions: QueryParam[] = []

  // please keep these alphabetical. Yes, we can sort them using the sort function,
  // but it is also easier to follow for the devs.

  // aspect ratio
  if (options.ar) {
    processingOptions.push(['ar', options.ar.toString()])
  }

  if (!options.format) {
    processingOptions.push(['auto', 'format'])
  }

  // blur
  if (options.effect && options.effect.startsWith('blur')) {
    const [_blur, blurValue] = options.effect.split(':')
    // Whatever our internal system uses for blur is different from imgix.
    // Playing by the eye, our blur is about 9 times more powerful.
    const imgixBlurValue = parseInt(blurValue, 10) * 9
    processingOptions.push(['blur', imgixBlurValue.toString()])
  } else if (options.blur) {
    const imgixBlurValue = options.blur * 9
    processingOptions.push(['blur', imgixBlurValue.toString()])
  }

  // gravity / crop
  if (options.gravity) {
    processingOptions.push(['crop', gravityToCropMap(options.gravity)])
  } else {
    processingOptions.push(['crop', gravityToCropMap('auto')])
  }

  // crop
  if (options.width || options.height) {
    // dpr
    processingOptions.push(['dpr', (options.dpr || '2').toString()])

    // fit
    processingOptions.push(['fit', cropToFitMap(options.crop)])
  }

  // format
  if (options.format) {
    processingOptions.push(['fm', options.format])
  }

  // height
  if (options.height) {
    const intHeight = Math.ceil(options.height)
    processingOptions.push(['h', intHeight.toString()])
  }

  // quality
  if (options.quality) {
    processingOptions.push(['q', options.quality])
  }

  // trim
  if (options.effect === 'trim' || options.trim) {
    processingOptions.push(['trim', 'auto'])
  }

  // width
  if (options.width) {
    const intWidth = Math.ceil(options.width)
    processingOptions.push(['w', intWidth.toString()])
  }

  return appendParamsToUrl(url, processingOptions)
}

export { canServe, processedUrl }
