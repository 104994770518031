// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file contributing_status.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

import { User } from "./User";

export namespace ContributingStatus {
  /**
   * @generated from enum proto.ContributingStatus.Status
   */
  export enum Status {
    /**
     * @generated from enum value: CONTRIBUTING_STATUS_UNSPECIFIED = 0;
     */
    ContributingStatusUnspecified = 0,
    /**
     * @generated from enum value: CONTRIBUTING_STATUS_ACTIVE = 1;
     */
    ContributingStatusActive = 1,
    /**
     * @generated from enum value: CONTRIBUTING_STATUS_INACTIVE = 2;
     */
    ContributingStatusInactive = 2,
  }

  export class ContributingStatusMessage extends Message<ContributingStatusMessage> {
    /**
     * @generated from field: proto.User user = 1;
     */
    user: User.UserMessage;
    /**
     * @generated from field: proto.ContributingStatus.Status status = 2;
     */
    status: ContributingStatus.Status = ContributingStatus.Status.ContributingStatusUnspecified;

    constructor(data?: PartialMessage<ContributingStatusMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.ContributingStatus";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "user", kind: "message", T: User.UserMessage },
      { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(ContributingStatus.Status) },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContributingStatusMessage {
      return new ContributingStatusMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContributingStatusMessage {
      return new ContributingStatusMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContributingStatusMessage {
      return new ContributingStatusMessage().fromJsonString(jsonString, options);
    }

      static equals(a: ContributingStatusMessage | PlainMessage<ContributingStatusMessage> | undefined, b: ContributingStatusMessage | PlainMessage<ContributingStatusMessage> | undefined): boolean {
      return proto3.util.equals(ContributingStatusMessage, a, b);
    }

    toObject(): ContributingStatus {
      return {
        user: this.user.toObject(),
        status: this.status,
      };
    }

    static fromObject(object: ContributingStatus): ContributingStatusMessage {
      return new ContributingStatusMessage({
        user: User.UserMessage.fromObject(object.user),
        status: object.status,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): ContributingStatus {
    return ContributingStatusMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: ContributingStatus): Uint8Array {
    return ContributingStatusMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.ContributingStatus
 */
export type ContributingStatus = {
  /**
   * @generated from field: proto.User user = 1;
   */
  user: User;
  /**
   * @generated from field: proto.ContributingStatus.Status status = 2;
   */
  status: ContributingStatus.Status;
}

proto3.util.setEnumType(ContributingStatus.Status, "proto.ContributingStatus.Status", [
  { no: 0, name: "CONTRIBUTING_STATUS_UNSPECIFIED" },
  { no: 1, name: "CONTRIBUTING_STATUS_ACTIVE" },
  { no: 2, name: "CONTRIBUTING_STATUS_INACTIVE" },
]);

