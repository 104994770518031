// @file Defaults for fetch requests
import {
  FetchOptionCache,
  FetchOptionCredentials,
  FetchOptionMode,
  FetchOptionRedirect,
  FetchOptions,
  HTTPContentType,
  HTTPHeader,
  HTTPMethod,
} from './types'

const defaultOptions: FetchOptions = {
  method: HTTPMethod.get,
  cache: FetchOptionCache.default,
  redirect: FetchOptionRedirect.follow,
  credentials: FetchOptionCredentials.sameOrigin,
  mode: FetchOptionMode.sameOrigin,
}

const defaultJsonOptions: FetchOptions = {
  headers: {
    [HTTPHeader.accept]: [HTTPContentType.json, HTTPContentType.jsonApi].join(', '),
  },
}

function setDefaultOptions(options: FetchOptions): FetchOptions {
  return Object.assign(defaultOptions, options)
}

function getDefaultOptions(): FetchOptions {
  return defaultOptions
}

function getDefaultJsonOptions(): FetchOptions {
  return defaultJsonOptions
}

export { getDefaultJsonOptions, getDefaultOptions, setDefaultOptions }
