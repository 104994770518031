// @file Store for handling Guest ID Card/Modal/Label on the surface
import { getCookie } from '@@/bits/cookie'
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { isInIframe } from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import {
  clearSearchParam,
  getAndClearSearchParam,
  getSearchParam,
  loginWithReferrerUrl,
  signUpWithReferrerUrl,
  transformUrl,
} from '@@/bits/location'
import { safeLocalStorage } from '@@/bits/safe_storage'
import { anonymous, displayNameForUser } from '@@/bits/user_model'
import { HttpCode, SnackbarNotificationType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import type { CreateCommentPayload } from '@@/pinia/surface_comments'
import { useCommentsStore } from '@@/pinia/surface_comments'
import { useSurfaceCommentAttachmentsStore } from '@@/pinia/surface_comment_attachments'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import type { CreateReactionPayload } from '@@/pinia/surface_reactions'
import { useReactionsStore } from '@@/pinia/surface_reactions'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import PadletApi from '@@/surface/padlet_api'
import type { Cid, DraftPost, User, WallId } from '@@/types'
import { configure as configureArvo, ReactionType } from '@padlet/arvo'
import type { ArvoConfig } from '@padlet/universal-post-editor'
import { flatten } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum ContributionType {
  DraftPost = 'draftPost',
  Comment = 'comment',
  Like = ReactionType.Like,
  Vote = ReactionType.Vote,
  Star = ReactionType.Star,
  Grade = ReactionType.Grade,
}

export type ContributionPayload = DraftPost | CreateCommentPayload | CreateReactionPayload

export const useSurfaceGuestStore = defineStore('surfaceGuestStore', () => {
  const surfaceStore = useSurfaceStore()
  const surfacePermissionsStore = useSurfacePermissionsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const surfaceUserContributors = useSurfaceUserContributorsStore()
  const surfaceCommentsStore = useCommentsStore()
  const surfaceReactionsStore = useReactionsStore()
  const surfaceDraftsStore = useSurfaceDraftsStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfaceCommentAttachmentsStore = useSurfaceCommentAttachmentsStore()

  const xGuestIdCard = ref<boolean>(false)
  const xGuestIdModal = ref<boolean>(false)
  const xChangeGuestModal = ref<boolean>(false)
  const userName = ref<string>('')
  const afterSaveNameActions = ref<Array<() => void>>([])
  const userNameErrorMessage = ref<string>('')
  const isAwaitingServerResponse = ref(false)
  const userNameHasViolation = ref<boolean | null>(null)
  const contributionType = ref<ContributionType | null>(null)
  const contributionPayload = ref<ContributionPayload | null>(null)

  // Getters

  const displayName = computed<string>(() => {
    const sessionUserName = getCookie('ww_aun')?.replace(/[^a-zA-Z]/g, ' ')
    const name = surfaceStore.user.name !== '' ? surfaceStore.user.name : sessionUserName
    return name !== '' ? name : anonymous
  })
  const userAvatar = computed<string>(() => surfaceStore.user.avatar)
  const isUserNameUpdated = computed<boolean>(() => surfaceStore.user.name === displayName.value)
  const shouldEnableAnonymousAttribution = computed<boolean>(
    () => !surfaceStore.isEmbedded && !isInIframe() && isAppUsing('anonymousAttribution'),
  )
  const shouldShowGuestIdModal = computed<boolean>(
    () => shouldEnableAnonymousAttribution.value && !surfacePermissionsStore.amIRegistered && !isUserNameUpdated.value,
  )
  const isUserNameValid = computed((): boolean => {
    if (userNameErrorMessage.value !== '') return false
    if (userNameHasViolation.value === true) return false
    return true
  })
  const hasUserPosted = computed<boolean>(
    () => surfaceUserContributors.postAuthorsById[surfaceStore.user.id] !== undefined,
  )
  const hasUserCommented = computed<boolean>(() => {
    const allComments = flatten(Object.values(surfaceCommentsStore.commentsByPostId))
    return allComments.some((comment) => comment.user_id === surfaceStore.user.id)
  })
  const hasUserReacted = computed<boolean>(() => {
    const allReactions = Object.values(surfaceReactionsStore.accumulatedReactionsByWishId).map(
      (reaction) => reaction?.userReaction,
    )
    return allReactions.some((reaction) => reaction?.user_id === surfaceStore.user.id)
  })
  const hasUserContributed = computed<boolean>(
    () => hasUserPosted.value || hasUserCommented.value || hasUserReacted.value,
  )
  const referrerUrl = computed<string>(() => {
    let referrer = surfaceStore.wallAttributes.links?.show != null ? surfaceStore.wallAttributes.links.show : ''

    if (surfaceStore.isSectionBreakout === true && surfaceStore.links?.breakout != null) {
      referrer = surfaceStore.links.breakout
    }

    if (contributionType.value !== null && contributionPayload.value !== null) {
      return transformUrl(referrer, { searchParams: { contribution_type: String(contributionType.value) } })
    }

    return referrer
  })

  const signupUrl = computed<string>(() => {
    return signUpWithReferrerUrl(
      hasUserContributed.value || contributionType.value !== null
        ? transformUrl(referrerUrl.value, { searchParams: { has_user_contributed: 'true' } })
        : referrerUrl.value,
    )
  })

  const loginUrl = computed<string>(() => {
    return loginWithReferrerUrl(referrerUrl.value)
  })
  const contributionPayloadDraftCacheKey = computed<string>(() => {
    return `contributionPayloadDraft-${surfaceStore.wallId as WallId}`
  })

  // Actions
  function showGuestIdCard(): void {
    xGuestIdCard.value = true
  }

  function hideGuestIdCard(): void {
    xGuestIdCard.value = false
  }

  function saveContributionPayloadDraft(): void {
    if (contributionType.value == null || contributionPayload.value == null) return

    safeLocalStorage.setItem(
      contributionPayloadDraftCacheKey.value,
      asciiSafeStringify({ [contributionType.value]: contributionPayload.value }),
    )
  }

  function deleteContributionPayloadDraft(): void {
    safeLocalStorage.removeItem(contributionPayloadDraftCacheKey.value)
  }

  function getContributionPayloadDraft(): Record<ContributionType, ContributionPayload> | null {
    const contributionPayloadDraft = safeLocalStorage.getItem(contributionPayloadDraftCacheKey.value)
    if (contributionPayloadDraft == null) return null
    return JSON.parse(contributionPayloadDraft)
  }

  function showGuestIdModal(options?: {
    afterSaveActions?: Array<() => void>
    contributionType?: ContributionType
    contributionPayload?: ContributionPayload
  }): void {
    xGuestIdModal.value = true

    if (options?.contributionType != null && options?.contributionPayload != null) {
      contributionType.value = options.contributionType
      contributionPayload.value = options.contributionPayload
      saveContributionPayloadDraft()
    }

    if (options?.afterSaveActions != null) {
      afterSaveNameActions.value.push(...options?.afterSaveActions)
    }
  }

  function hideGuestIdModal(): void {
    xGuestIdModal.value = false
    afterSaveNameActions.value = []
    deleteContributionPayloadDraft()
  }

  function showChangeGuestModal(): void {
    xChangeGuestModal.value = true
  }

  function hideChangeGuestModal(): void {
    xChangeGuestModal.value = false
  }

  function setUserName(name: string): void {
    userName.value = name
    userNameErrorMessage.value = ''
    userNameHasViolation.value = null
  }

  async function updateSessionUserName(): Promise<void> {
    const trimmedUserName = userName.value.trim()

    if (trimmedUserName === '' || trimmedUserName === surfaceStore.user.name) return
    try {
      isAwaitingServerResponse.value = true

      await moderateUserName(trimmedUserName)
      if (userNameHasViolation.value !== false) return

      const user = await PadletApi.Powwow.updateSessionUserName({ userId: surfaceStore.user.id, name: trimmedUserName })
      updateUserInSurfaceStores(user)
    } catch (e) {
      if (e.status === HttpCode.UnprocessableEntity) {
        userNameErrorMessage.value = __('Name invalid')
      } else {
        captureFetchException(e, { source: 'SurfaceGuestStoreUpdateSessionUserName' })
        globalSnackbarStore.setSnackbar({
          message: __('Error updating name'),
          notificationType: SnackbarNotificationType.error,
        })
      }
    } finally {
      isAwaitingServerResponse.value = false
    }
  }

  function handleNewUserCreated(payload: { user: User; arvoConfig: ArvoConfig }): void {
    updateUserInSurfaceStores(payload.user)
    void configureArvo(payload.arvoConfig as any)
    surfaceStore.refetchStartingStateAndCreateNewBrahmsConnection()
  }

  async function handleLogIn(): Promise<void> {
    const [user, arvoConfig] = await Promise.all([
      await PadletApi.Powwow.getCurrentUser(),
      await PadletApi.Powwow.getArvoConfig(),
    ])
    handleNewUserCreated({ arvoConfig, user })
  }

  async function handleLogOut(): Promise<void> {
    const [user, arvoConfig] = await Promise.all([
      await PadletApi.Powwow.getCurrentUser(),
      await PadletApi.Powwow.getArvoConfig(),
    ])
    surfaceCommentAttachmentsStore.resetCommentAttachments()
    surfaceCommentsStore.resetComments()
    surfaceDraftsStore.resetDrafts()
    handleNewUserCreated({ arvoConfig, user })
  }

  async function changeGuest(): Promise<void> {
    const trimmedUserName = userName.value.trim()

    try {
      isAwaitingServerResponse.value = true

      await moderateUserName(trimmedUserName)
      if (userNameHasViolation.value !== false) return

      const { user, arvoConfig } = await PadletApi.Powwow.createSessionUser({
        name: trimmedUserName,
      })

      handleNewUserCreated({ user, arvoConfig })

      globalSnackbarStore.setSnackbar({
        message: __('Guest changed to %{userName}', { userName: user.name }),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      if (e.status === HttpCode.UnprocessableEntity) {
        userNameErrorMessage.value = __('Name invalid')
      } else {
        captureFetchException(e, { source: 'SurfaceGuestStoreCreateSessionUserName' })
        globalSnackbarStore.setSnackbar({
          message: __('Error changing guest'),
          notificationType: SnackbarNotificationType.error,
        })
      }
    } finally {
      isAwaitingServerResponse.value = false
    }
  }

  async function moderateUserName(name: string): Promise<void> {
    try {
      const { userNameHasViolation: hasViolation } = await PadletApi.ContentModeration.moderateUserName({
        name,
      })
      userNameHasViolation.value = hasViolation === null ? null : hasViolation

      if (userNameHasViolation.value === true) {
        globalSnackbarStore.setSnackbar({
          message: __('Your name was flagged as inappropriate and was not updated'),
          notificationType: SnackbarNotificationType.error,
        })
      } else if (userNameHasViolation.value === null) {
        globalSnackbarStore.genericFetchError()
      }
    } catch (e) {
      captureFetchException(e, { source: 'SurfaceGuestStoreModerateUserName' })
    }
  }

  function clearContributionPayloadDraft(): void {
    clearSearchParam('contribution_type')
    deleteContributionPayloadDraft()
  }

  function updateUserInSurfaceStores(user: User): void {
    setUserName(user.name as string)
    surfaceUserContributors.updateUser(user)
    surfaceStore.updateUser(user)
  }

  function getDraftPostCid(draftPost: DraftPost): Cid | null {
    const draftCidArray = Object.entries(surfaceDraftsStore.draftByCid).find(
      ([_cid, draft]) => draftPost.id === draft.id,
    )
    return draftCidArray !== undefined ? draftCidArray[0] : null
  }

  async function publishDraftPost(): Promise<void> {
    const contributionType = getSearchParam('contribution_type') as ContributionType
    if (contributionType == null || contributionType !== ContributionType.DraftPost) return

    const contributionPayloadDraft = getContributionPayloadDraft()
    if (contributionPayloadDraft == null || contributionPayloadDraft[contributionType] == null) return
    clearContributionPayloadDraft()
    const contributionPayload = contributionPayloadDraft[contributionType] as DraftPost
    if (contributionPayload.wall_id !== surfaceStore.wallId) return

    let draftPostCid: Cid | null = null

    // All the post drafts are authored by the current user after logging in or signing up
    // So we can just publish the draft stored in the local storage
    draftPostCid = getDraftPostCid(contributionPayload)

    if (draftPostCid == null) return

    try {
      await surfaceDraftsStore.publishDraft({ cid: draftPostCid })
    } catch (e) {
      captureFetchException(e, { source: 'SurfaceGuestPublishContributionDraft' })
    }
  }

  async function publishCommentOrReactionDraft(): Promise<void> {
    const contributionType = getSearchParam('contribution_type') as ContributionType
    if (
      contributionType == null ||
      ![
        ContributionType.Comment,
        ContributionType.Like,
        ContributionType.Vote,
        ContributionType.Star,
        ContributionType.Grade,
      ].includes(contributionType)
    ) {
      return
    }
    const contributionPayloadDraft = getContributionPayloadDraft()
    if (contributionPayloadDraft == null || contributionPayloadDraft[contributionType] == null) return
    clearContributionPayloadDraft()
    const contributionPayload = contributionPayloadDraft[contributionType] as
      | CreateCommentPayload
      | CreateReactionPayload

    const post = surfacePostsStore.getPostByServerId(String(contributionPayload.postId))
    if (post == null) return

    try {
      if (contributionType === ContributionType.Comment) {
        await surfaceCommentsStore.createCommentV2(contributionPayload as CreateCommentPayload)
      } else if (surfaceReactionsStore.originalReactionData.type === contributionType) {
        await surfaceReactionsStore.createReaction(contributionPayload as CreateReactionPayload)
      }
    } catch (e) {
      captureFetchException(e, { source: 'SurfaceGuestPublishCommentOrReactionDraft' })
    }
  }

  function showSnackbarAfterSigningUp(): void {
    const hasUserContributed = getAndClearSearchParam('has_user_contributed')
    if (hasUserContributed !== 'true') return

    useGlobalSnackbarStore().setSnackbar({
      notificationType: SnackbarNotificationType.success,
      message: __('All your guest activities have been merged to %{userName}.', {
        userName: displayNameForUser(surfaceStore.user),
      }),
    })
  }

  return {
    // State
    xGuestIdCard,
    xGuestIdModal,
    xChangeGuestModal,
    userName,
    afterSaveNameActions,
    userNameErrorMessage,
    isAwaitingServerResponse,

    // Getters
    displayName,
    userAvatar,
    isUserNameUpdated,
    shouldEnableAnonymousAttribution,
    shouldShowGuestIdModal,
    isUserNameValid,
    hasUserPosted,
    hasUserCommented,
    hasUserReacted,
    hasUserContributed,
    signupUrl,
    loginUrl,
    contributionType,
    contributionPayload,

    // Actions
    showGuestIdCard,
    hideGuestIdCard,
    showGuestIdModal,
    hideGuestIdModal,
    setUserName,
    updateSessionUserName,
    changeGuest,
    showChangeGuestModal,
    hideChangeGuestModal,
    moderateUserName,
    updateUserInSurfaceStores,
    handleNewUserCreated,
    handleLogIn,
    handleLogOut,
    publishDraftPost,
    publishCommentOrReactionDraft,
    showSnackbarAfterSigningUp,
  }
})
