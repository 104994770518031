// @file Surface reactions store
import appCan from '@@/bits/app_can'
import device from '@@/bits/device'
import { captureNonNetworkFetchError } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import window from '@@/bits/global'
import { getVuexStore } from '@@/bits/pinia'
import { vSet } from '@@/bits/vue'
import { openPostReactionDialog } from '@@/native_bridge/actions'
import postMessage from '@@/native_bridge/post_message'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useNativeAppStore } from '@@/pinia/native_app'
import { useSurfaceStore } from '@@/pinia/surface'
import type { ContributionType } from '@@/pinia/surface_guest_store'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import PadletApi from '@@/surface/padlet_api'
import type { AccumulatedReactionData, AccumulatedReactions, Id, WallReactionData } from '@@/types'
import type { JsonAPIResource, Reaction } from '@padlet/arvo'
import { ReactionType } from '@padlet/arvo'
import { sortBy } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export interface CreateReactionPayload {
  postId: Id
  value: number
}

export const useReactionsStore = defineStore('reactions', () => {
  const surfaceVuexStore = window.app?.$store // For gradual conversion to pinia

  const globalSnackbarStore = useGlobalSnackbarStore()
  const surfaceSettingsStore = useSurfaceSettingsStore()
  const surfaceStore = useSurfaceStore()
  const surfaceGuestStore = useSurfaceGuestStore()

  const originalReactionData = computed<WallReactionData>(
    () => surfaceVuexStore?.state.wall.reaction_data ?? { type: ReactionType.None },
  )
  const currentReactionData = computed<WallReactionData>(
    () => surfaceSettingsStore.previewAttributes.reaction_data ?? { type: ReactionType.None },
  )
  const currentReactionType = computed(() =>
    !surfaceSettingsStore.isReactable ? ReactionType.None : currentReactionData.value.type,
  )

  const shouldFallbackWsDataFetchToRest = computed<boolean>(
    () => surfaceVuexStore?.state.shouldFallbackWsDataFetchToRest,
  )

  /**
   * FETCH REACTIONS
   */
  const accumulatedReactionsByWishId = ref<AccumulatedReactions>({})
  const postIdBeingReactedTo = ref()
  const reactionDropdownTop = ref()
  const reactionDropdownLeft = ref()
  const reactionDropdownWidth = ref()
  // TODO: remove once reactionIdsByPostId props are cleaned up.
  const reactionIdsByPost = ref({})

  const hasReactions = computed<boolean>(() => {
    const wishesWithReactions = Object.keys(accumulatedReactionsByWishId.value)
    if (wishesWithReactions.length === 0) return false
    return wishesWithReactions.some((wishId) => accumulatedReactionsByWishId.value[wishId].totalReactionsCount > 0)
  })

  const fetchAccumulatedReactions = async (): Promise<void> => {
    if (surfaceStore.isSubmissionRequest) return

    try {
      let attributes
      if (!shouldFallbackWsDataFetchToRest.value && isAppUsing('realtimeFetching')) {
        attributes = await getVuexStore()?.dispatch('realtime/fetchAccumulatedReactions', null, { root: true })
      } else {
        const fetchedData = await PadletApi.Reaction.fetchAccumulatedReactions({ wallId: surfaceStore.wallId })
        const { data } = fetchedData
        if (data == null) return
        attributes = (data as JsonAPIResource<AccumulatedReactions>).attributes
      }

      accumulatedReactionsByWishId.value = attributes ?? {}
      void useNativeAppStore().postSurfaceState()
    } catch (error: any) {
      captureNonNetworkFetchError(error, { source: 'fetchAccumulatedReactions' })
      globalSnackbarStore.genericFetchError()
    }
  }

  const accumulatedReactionsTotalSumAverage = computed(() => {
    const reactionWishIds = Object.keys(accumulatedReactionsByWishId.value)
    if (reactionWishIds.length === 0) return 0
    const sum = reactionWishIds.reduce((acc, wishId) => {
      const reactionSum: number = accumulatedReactionsByWishId.value[wishId]?.sum ?? 0
      return acc + reactionSum
    }, 0)
    const totalReactions = reactionWishIds.reduce((acc, wishId) => {
      const reactionsTotal: number = accumulatedReactionsByWishId.value[wishId]?.totalReactionsCount ?? 0
      return acc + reactionsTotal
    }, 0)
    return sum / totalReactions
  })

  const accumulatedReactionsConfidenceNumber = computed(() => {
    // the totalReactions of the median reaction in all reactions for a wall
    // we choose median to be safe can always update if needed
    const reactionsList = Object.values(accumulatedReactionsByWishId.value).filter(
      (r) => r != null,
    ) as AccumulatedReactionData[]
    const numReactions = reactionsList.length
    const sortedReactionList = sortBy(reactionsList, 'totalReactionsCount')
    return sortedReactionList[Math.floor(numReactions / 2)]?.totalReactionsCount
  })

  /** REALTIME HANDLERS */

  function updateAccumulatedReactions(payload: {
    wishId: number
    sumChange: number
    totalReactionCountChange: number
    reactionType: ReactionType
  }): void {
    const { wishId, sumChange, totalReactionCountChange, reactionType } = payload
    const currentAccumulatedReactions = accumulatedReactionsByWishId.value?.[wishId] ?? {
      sum: 0,
      totalReactionsCount: 0,
      reactionType: originalReactionData.value.type,
    }
    vSet(accumulatedReactionsByWishId.value, wishId, {
      ...currentAccumulatedReactions,
      sum: currentAccumulatedReactions.sum + sumChange,
      totalReactionsCount: currentAccumulatedReactions.totalReactionsCount + totalReactionCountChange,
      reactionType,
    })
    void useNativeAppStore().postSurfaceState()
  }

  function updateUserReaction(payload: Partial<Reaction>): void {
    const wishId = payload.wish_id
    if (wishId == null) return
    const currentAccumulatedReactions = accumulatedReactionsByWishId.value?.[wishId] ?? {
      sum: 0,
      totalReactionsCount: 0,
      reactionType: originalReactionData.value.type,
    }
    vSet(accumulatedReactionsByWishId.value, wishId, {
      ...currentAccumulatedReactions,
      userReaction: payload.id != null ? (payload as Reaction) : undefined,
      reactionType: payload.reaction_type ?? originalReactionData.value.type,
    })
    void useNativeAppStore().postSurfaceState()
  }

  function refreshAccumulatedReactions(newAccumulatedReactionsByWishId: AccumulatedReactions): void {
    accumulatedReactionsByWishId.value = newAccumulatedReactionsByWishId
    void useNativeAppStore().postSurfaceState()
  }

  /** ACTIONS */

  const isUpdatingReaction = ref<boolean>(false)

  const createReaction = async (payload: CreateReactionPayload): Promise<void> => {
    if (surfaceGuestStore.shouldShowGuestIdModal) {
      surfaceGuestStore.showGuestIdModal({
        afterSaveActions: [async () => await createReaction(payload)],
        contributionType: originalReactionData.value.type as
          | ContributionType.Like
          | ContributionType.Vote
          | ContributionType.Star
          | ContributionType.Grade,
        contributionPayload: payload,
      })
      return
    }

    if (isUpdatingReaction.value) return

    isUpdatingReaction.value = true
    const { postId, value } = payload
    const newReaction = {
      wish_id: postId,
      value,
      reaction_type: originalReactionData.value.type,
    }

    // Optimistic change
    const oldAccumulatedReactions = accumulatedReactionsByWishId.value[postId] ?? {
      sum: 0,
      totalReactionsCount: 0,
      reactionType: originalReactionData.value.type,
    }

    const hasReactionTypeChanged = oldAccumulatedReactions.reactionType !== newReaction.reaction_type

    const newAccumulatedReactions = {
      sum: newReaction.value + (hasReactionTypeChanged ? 0 : oldAccumulatedReactions.sum),
      totalReactionsCount: 1 + (hasReactionTypeChanged ? 0 : oldAccumulatedReactions.totalReactionsCount),
      reactionType: newReaction.reaction_type,
      userReaction: newReaction,
    }
    vSet(accumulatedReactionsByWishId.value, postId, newAccumulatedReactions)

    try {
      const reactionData = await PadletApi.Reaction.create(newReaction)
      const resultingReaction = (reactionData?.data as JsonAPIResource<Reaction>).attributes

      vSet(accumulatedReactionsByWishId.value, postId, { ...newAccumulatedReactions, userReaction: resultingReaction })
    } catch (error: any) {
      // Revert the optimistic change
      vSet(accumulatedReactionsByWishId.value, postId, oldAccumulatedReactions)

      globalSnackbarStore.genericFetchError()
      captureNonNetworkFetchError(error, { source: 'createReaction' })
    } finally {
      isUpdatingReaction.value = false
    }
  }

  const updateReaction = async (payload: { postId: Id; value: number }): Promise<void> => {
    if (isUpdatingReaction.value) return

    isUpdatingReaction.value = true
    const { postId, value } = payload
    const oldAccumulatedReactions = accumulatedReactionsByWishId.value[postId]
    const userReaction = accumulatedReactionsByWishId.value[postId]?.userReaction
    if (
      oldAccumulatedReactions == null ||
      userReaction == null ||
      userReaction.value === value ||
      userReaction.reaction_type !== originalReactionData.value.type
    ) {
      isUpdatingReaction.value = false
      return
    }

    // Optimistic change
    const newReaction = {
      ...userReaction,
      value,
      reactionType: originalReactionData.value.type,
    }

    const newAccumulatedReactions = {
      sum: oldAccumulatedReactions.sum - userReaction.value + newReaction.value,
      totalReactionsCount: oldAccumulatedReactions.totalReactionsCount,
      reactionType: newReaction.reaction_type,
      userReaction: newReaction,
    }
    vSet(accumulatedReactionsByWishId.value, postId, newAccumulatedReactions)

    const existingReactionId = userReaction.id
    try {
      await PadletApi.Reaction.update({ id: existingReactionId, value })
    } catch (error: any) {
      // Revert the optimistic change
      vSet(accumulatedReactionsByWishId.value, postId, oldAccumulatedReactions)

      globalSnackbarStore.genericFetchError()
      captureNonNetworkFetchError(error, { source: 'deleteReaction' })
    } finally {
      isUpdatingReaction.value = false
    }
  }

  const deleteReaction = async (payload: { postId: Id }): Promise<void> => {
    if (isUpdatingReaction.value) return

    isUpdatingReaction.value = true
    const { postId } = payload
    const oldAccumulatedReactions = accumulatedReactionsByWishId.value[postId]
    const userReaction = accumulatedReactionsByWishId.value[postId]?.userReaction
    if (
      oldAccumulatedReactions == null ||
      userReaction == null ||
      userReaction.reaction_type !== originalReactionData.value.type
    )
      return

    // Optimistic change
    const newAccumulatedReactions = {
      sum: oldAccumulatedReactions.sum - userReaction.value,
      totalReactionsCount: oldAccumulatedReactions.totalReactionsCount - 1,
      reactionType: originalReactionData.value.type,
      userReaction: undefined,
    }
    vSet(accumulatedReactionsByWishId.value, postId, newAccumulatedReactions)

    const existingReactionId = userReaction.id
    try {
      await PadletApi.Reaction.delete({ id: existingReactionId })
    } catch (error: any) {
      // Revert the optimistic change
      vSet(accumulatedReactionsByWishId.value, postId, oldAccumulatedReactions)

      globalSnackbarStore.genericFetchError()
      captureNonNetworkFetchError(error, { source: 'deleteReaction' })
    } finally {
      isUpdatingReaction.value = false
    }
  }

  const startEditingReaction = async (payload: {
    postId: Id
    dropdownLeft?: number
    dropdownWidth?: number
    dropdownTop?: number
  }): Promise<void> => {
    if (device.app && appCan('useNativeReactionDialog')) {
      // update surface state right before editing to make sure reaction data is up to date
      void useNativeAppStore().postSurfaceState()
      postMessage(openPostReactionDialog(payload.postId))
    } else {
      postIdBeingReactedTo.value = payload.postId
      reactionDropdownLeft.value = payload.dropdownLeft ?? 0
      reactionDropdownWidth.value = payload.dropdownWidth ?? 0
      reactionDropdownTop.value = payload.dropdownTop ?? 0
    }
  }

  const stopEditingReaction = (): void => {
    postIdBeingReactedTo.value = undefined
    reactionDropdownLeft.value = 0
    reactionDropdownWidth.value = 0
    reactionDropdownTop.value = 0
  }

  return {
    // state
    isUpdatingReaction,
    accumulatedReactionsByWishId,
    postIdBeingReactedTo,
    reactionDropdownLeft,
    reactionDropdownTop,
    reactionDropdownWidth,
    reactionIdsByPost,
    // getters
    hasReactions,
    originalReactionData,
    currentReactionData,
    currentReactionType,
    accumulatedReactionsTotalSumAverage,
    accumulatedReactionsConfidenceNumber,

    // action
    fetchAccumulatedReactions,
    updateAccumulatedReactions,
    refreshAccumulatedReactions,
    updateUserReaction,
    createReaction,
    updateReaction,
    deleteReaction,
    startEditingReaction,
    stopEditingReaction,
  }
})
