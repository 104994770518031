/**
 * @file Helper for surface settings
 */
import { __ } from '@@/bits/intl'
import type { WallCustomPostProperty } from '@@/types'
import { GroupByTypes, ReactionType, SortByTypes } from '@padlet/arvo'
import { processedUrl } from '@padlet/vivaldi-client'
import { sample } from 'lodash-es'

const DEFAULT_MAX_GRADE = 100
const TITLE_CHAR_LIMIT = 1000

const GROUP_BY_DATA = {
  [GroupByTypes.None]: {
    icon: 'close_small_thin',
    name: __('No groups'),
    description: '',
  },
  [GroupByTypes.Section]: {
    icon: 'section_thin',
    name: __('Sections'),
    description: __('Group posts by their assigned section'),
  },
  [GroupByTypes.Author]: {
    icon: 'edit_account_outline',
    name: __('Author'),
    description: __('Group posts by who wrote them'),
  },
  [GroupByTypes.Color]: {
    icon: 'wallpaper_type_gradient',
    name: __('Color'),
    description: __('Group posts by their assigned color'),
  },
}

const REACTIONS_DATA = {
  [ReactionType.None]: {
    icon: 'border',
    name: __('None'),
    pluralised_name: __('None'),
    description: __('No reactions'),
    previewIcon: 'border',
  },
  [ReactionType.Like]: {
    icon: 'heart_outline_thin',
    name: __('Like'),
    pluralised_name: __('Likes'),
    description: __('Like posts'),
    previewIcon: 'heart_filled',
  },
  [ReactionType.Vote]: {
    icon: 'thumb_up_outline',
    name: __('Vote'),
    pluralised_name: __('Votes'),
    description: __('Upvote or downvote posts'),
    previewIcon: 'thumb_up_filled',
  },
  [ReactionType.Star]: {
    icon: 'star_outline',
    name: __('Star'),
    pluralised_name: __('Stars'),
    description: __('Give posts 1-5 stars'),
    previewIcon: 'star_filled',
  },
  [ReactionType.Grade]: {
    icon: 'checkbox_person_outline',
    name: __('Score'),
    pluralised_name: __('Scores'),
    description: __('Give numeric scores to posts'),
    previewIcon: 'checkbox_person_filled',
  },
}

const SORT_POSTS_POPOVER_MENU_REACTION_OPTIONS = {
  [ReactionType.Like]: {
    icon: REACTIONS_DATA[ReactionType.Like].icon,
    title: __('Likes'),
  },
  [ReactionType.Grade]: {
    icon: REACTIONS_DATA[ReactionType.Grade].icon,
    title: __('Scores'),
  },
  [ReactionType.Vote]: {
    icon: REACTIONS_DATA[ReactionType.Vote].icon,
    title: __('Votes'),
  },
  [ReactionType.Star]: {
    icon: REACTIONS_DATA[ReactionType.Star].icon,
    title: __('Rating'),
  },
}

function processedEmojiUrl(url: string): string {
  return processedUrl(url, { width: 64, height: 64, preset: 'icon' })
}

export type SortOrder = 'asc' | 'desc'

type GetSortByTypePrefix<T> = T extends `${infer U}_${'first' | 'last' | 'asc' | 'desc'}` ? U : T

type SortByAttribute = GetSortByTypePrefix<`${SortByTypes}`> | 'custom_prop'

const getSortByArrayFromAttribute = (attribute: SortByAttribute): SortByTypes[] => {
  switch (attribute) {
    case 'manual_new_posts':
      return ManualSortByArray
    case 'reactions':
      return ReactionSortByArray
    case 'date_published':
      return DatePublishedSortByArray
    case 'post_subject':
      return [SortByTypes.PostSubjectAsc, SortByTypes.PostSubjectDesc]
    case 'shuffle':
      return [SortByTypes.Shuffle]
    default:
      return []
  }
}

const isSortingByCustomProp = (wishSortBy: string): boolean => {
  const [prefix, propertyId, sortOrder] = wishSortBy.split(':')
  return prefix === 'custom_prop' && typeof propertyId === 'string' && (sortOrder === 'asc' || sortOrder === 'desc')
}

const isSortingBy = (wishSortBy: SortByTypes, attribute: SortByAttribute): boolean => {
  if (attribute === 'custom_prop') return isSortingByCustomProp(wishSortBy)
  return getSortByArrayFromAttribute(attribute).includes(wishSortBy)
}

type ManualSortBy = SortByTypes.ManualNewPostsFirst | SortByTypes.ManualNewPostsLast
const ManualSortByArray: ManualSortBy[] = [SortByTypes.ManualNewPostsFirst, SortByTypes.ManualNewPostsLast]

const DatePublishedSortByArray = [SortByTypes.DatePublishedSortAsc, SortByTypes.DatePublishedSortDesc]
type DatePublishedSortBy = typeof DatePublishedSortByArray[number]

type ReactionSortBy = SortByTypes.ReactionsAsc | SortByTypes.ReactionsDesc
const ReactionSortByArray: ReactionSortBy[] = [SortByTypes.ReactionsAsc, SortByTypes.ReactionsDesc]

const ReactionSortByAscKeyArray = ['likes_lowest', 'grades_lowest', 'stars_lowest', 'votes_lowest'] as const

type ReactionSortByAscKey = typeof ReactionSortByAscKeyArray[number]

const ReactionSortByDescKeyArray = ['likes_highest', 'grades_highest', 'stars_highest', 'votes_highest'] as const

type ReactionSortByDescKey = typeof ReactionSortByDescKeyArray[number]

type PostSubjectSortBy = SortByTypes.PostSubjectAsc | SortByTypes.PostSubjectDesc

const REACTION_SORT_BY_DATA_OLD: Record<
  Exclude<ReactionType, 'none'>,
  Array<{ key: ReactionSortByAscKey | ReactionSortByDescKey; icon: string; title: string }>
> = {
  [ReactionType.Like]: [
    {
      key: 'likes_highest',
      icon: 'heart_arrow_down',
      title: __('Most to least'),
    },
    {
      key: 'likes_lowest',
      icon: 'heart_arrow_up',
      title: __('Least to most'),
    },
  ],
  [ReactionType.Grade]: [
    {
      key: 'grades_highest',
      icon: 'checkbox_arrow_down',
      title: __('Highest to lowest'),
    },
    {
      key: 'grades_lowest',
      icon: 'checkbox_arrow_up',
      title: __('Lowest to highest'),
    },
  ],
  [ReactionType.Star]: [
    {
      key: 'stars_highest',
      icon: 'star_arrow_down',
      title: __('Highest to lowest'),
    },
    {
      key: 'stars_lowest',
      icon: 'star_arrow_up',
      title: __('Lowest to highest'),
    },
  ],
  [ReactionType.Vote]: [
    {
      key: 'votes_highest',
      icon: 'thumb_arrow_down',
      title: __('Most to least'),
    },
    {
      key: 'votes_lowest',
      icon: 'thumb_arrow_up',
      title: __('Least to most'),
    },
  ],
}

const REACTION_SORT_BY_DATA: Record<
  Exclude<ReactionType, 'none'>,
  Array<{ key: ReactionSortByAscKey | ReactionSortByDescKey; icon: string; title: string }>
> = {
  [ReactionType.Like]: [
    {
      key: 'likes_highest',
      icon: 'heart_arrow_down',
      title: __('Most first'),
    },
    {
      key: 'likes_lowest',
      icon: 'heart_arrow_up',
      title: __('Least first'),
    },
  ],
  [ReactionType.Grade]: [
    {
      key: 'grades_highest',
      icon: 'checkbox_arrow_down',
      title: __('Highest first'),
    },
    {
      key: 'grades_lowest',
      icon: 'checkbox_arrow_up',
      title: __('Lowest first'),
    },
  ],
  [ReactionType.Star]: [
    {
      key: 'stars_highest',
      icon: 'star_arrow_down',
      title: __('Highest first'),
    },
    {
      key: 'stars_lowest',
      icon: 'star_arrow_up',
      title: __('Lowest first'),
    },
  ],
  [ReactionType.Vote]: [
    {
      key: 'votes_highest',
      icon: 'thumb_arrow_down',
      title: __('Most first'),
    },
    {
      key: 'votes_lowest',
      icon: 'thumb_arrow_up',
      title: __('Least first'),
    },
  ],
}

const MANUAL_SORT_BY_DATA_OLD: Record<ManualSortBy, { key: ManualSortBy; title: string; icon: string }> = {
  manual_new_posts_first: {
    key: SortByTypes.ManualNewPostsFirst,
    title: __('New posts added first'),
    icon: 'create_to_front',
  },
  manual_new_posts_last: {
    key: SortByTypes.ManualNewPostsLast,
    title: __('New posts added last'),
    icon: 'create_to_back',
  },
}

const DATE_PUBLISHED_SORT_BY_DATA_OLD = {
  [SortByTypes.DatePublishedSortDesc]: {
    key: SortByTypes.DatePublishedSortDesc,
    title: __('Newest to oldest'),
    icon: 'clock_arrow_down',
  },
  [SortByTypes.DatePublishedSortAsc]: {
    key: SortByTypes.DatePublishedSortAsc,
    title: __('Oldest to newest'),
    icon: 'clock_arrow_up',
  },
}

const POST_SUBJECT_SORT_BY_DATA_OLD = {
  [SortByTypes.PostSubjectAsc]: {
    key: SortByTypes.PostSubjectAsc,
    title: __('Alphabetical order'),
    icon: 'sort_name_outline',
  },
  [SortByTypes.PostSubjectDesc]: {
    key: SortByTypes.PostSubjectDesc,
    title: __('Reverse alphabetical order'),
    icon: 'sort_name_outline_reverse',
  },
}

const POST_SHUFFLE_SORT_BY_DATA_OLD = {
  [SortByTypes.Shuffle]: {
    key: SortByTypes.Shuffle,
    title: __('Posts appear randomly'),
    icon: 'arrow_2_rectangular_clockwise_thin',
  },
}

const MANUAL_SORT_BY_DATA: Record<ManualSortBy, { key: ManualSortBy; title: string; icon: string }> = {
  manual_new_posts_first: {
    key: SortByTypes.ManualNewPostsFirst,
    title: __('First'),
    icon: 'create_to_front',
  },
  manual_new_posts_last: {
    key: SortByTypes.ManualNewPostsLast,
    title: __('Last'),
    icon: 'create_to_back',
  },
}

const DATE_PUBLISHED_SORT_BY_DATA = {
  [SortByTypes.DatePublishedSortDesc]: {
    key: SortByTypes.DatePublishedSortDesc,
    title: __('Newest first'),
    icon: 'clock_arrow_down',
  },
  [SortByTypes.DatePublishedSortAsc]: {
    key: SortByTypes.DatePublishedSortAsc,
    title: __('Oldest first'),
    icon: 'clock_arrow_up',
  },
}

const POST_SUBJECT_SORT_BY_DATA = {
  [SortByTypes.PostSubjectAsc]: {
    key: SortByTypes.PostSubjectAsc,
    title: __('A to Z'),
    icon: 'sort_name_outline',
  },
  [SortByTypes.PostSubjectDesc]: {
    key: SortByTypes.PostSubjectDesc,
    title: __('Z to A'),
    icon: 'sort_name_outline_reverse',
  },
}

const POST_SHUFFLE_SORT_BY_DATA = {
  [SortByTypes.Shuffle]: {
    key: SortByTypes.Shuffle,
    title: __('Shuffle posts'),
    icon: 'dice',
  },
}

const ReactionSortToKeyMap: Record<
  ReactionSortBy,
  Record<Exclude<ReactionType, 'none'>, ReactionSortByAscKey | ReactionSortByDescKey>
> = {
  reactions_asc: {
    [ReactionType.Like]: 'likes_lowest',
    [ReactionType.Grade]: 'grades_lowest',
    [ReactionType.Star]: 'stars_lowest',
    [ReactionType.Vote]: 'votes_lowest',
  },
  reactions_desc: {
    [ReactionType.Like]: 'likes_highest',
    [ReactionType.Grade]: 'grades_highest',
    [ReactionType.Star]: 'stars_highest',
    [ReactionType.Vote]: 'votes_highest',
  },
}

const CustomPropDataTypeToIconMap = {
  date: 'calendar_outline',
  number: 'numbers',
  text: 'text',
  single_select: 'check_circle_outline',
}

const getCustomPropSortOrderOptions = (
  property: WallCustomPostProperty,
): Array<{ key: CustomPropSortBy; title: string; icon: string }> | null => {
  if (property.id == null) return null
  switch (property.data_type) {
    case 'number':
      return [
        {
          key: `custom_prop:${property.id}:desc`,
          title: __('Highest first'),
          icon: CustomPropDataTypeToIconMap[property.data_type],
        },
        {
          key: `custom_prop:${property.id}:asc`,
          title: __('Lowest first'),
          icon: CustomPropDataTypeToIconMap[property.data_type],
        },
      ]
    case 'date':
      return [
        {
          key: `custom_prop:${property.id}:asc`,
          title: __('Earliest first'),
          icon: CustomPropDataTypeToIconMap[property.data_type],
        },
        {
          key: `custom_prop:${property.id}:desc`,
          title: __('Latest first'),
          icon: CustomPropDataTypeToIconMap[property.data_type],
        },
      ]
    case 'text':
      return [
        {
          key: `custom_prop:${property.id}:asc`,
          title: __('A to Z'),
          icon: CustomPropDataTypeToIconMap[property.data_type],
        },
        {
          key: `custom_prop:${property.id}:desc`,
          title: __('Z to A'),
          icon: CustomPropDataTypeToIconMap[property.data_type],
        },
      ]
    case 'single_select':
      return [
        {
          key: `custom_prop:${property.id}:asc`,
          title: __('First to last'),
          icon: CustomPropDataTypeToIconMap[property.data_type],
        },
        {
          key: `custom_prop:${property.id}:desc`,
          title: __('Last to first'),
          icon: CustomPropDataTypeToIconMap[property.data_type],
        },
      ]
    default:
      return null
  }
}

enum RemakeSetting {
  Everyone = 'Everyone',
  AdminsOnly = 'Admins only',
}

enum SortPostsPopoverOption {
  MANUAL = 'manual',
  REACTIONS = 'reactions',
  DATE_PUBLISHED = 'date_published',
  POST_SUBJECT = 'post_subject',
  RANDOM = 'shuffle',
}

type CustomPropSortBy = `custom_prop:${string}:${SortOrder}`

const DEFAULT_WALL_TITLES: string[] = [
  __('My epic padlet'),
  __('My sweet padlet'),
  __('My luminous padlet'),
  __('My stellar padlet'),
  __('My stunning padlet'),
  __('My grand padlet'),
  __('My glorious padlet'),
  __('My supercalifragilisticexpialidocious padlet'),
  __('My terrific padlet'),
  __('My brilliant padlet'),
  __('My distinguished padlet'),
  __('My delightful padlet'),
  __('My remarkable padlet'),
  __('My phenomenal padlet'),
  __('My funky padlet'),
  __('My swanky padlet'),
  __('My sumptuous padlet'),
  __('My artistic padlet'),
  __('My sublime padlet'),
  __('My exquisite padlet'),
  __('My harmonious padlet'),
  __('My shiny padlet'),
  __('My fancy padlet'),
  __('My radiant padlet'),
  __('My dazzling padlet'),
  __('My smart padlet'),
  __('My bold padlet'),
  __('My fearless padlet'),
  __('My fierce padlet'),
]

/**
 * Generates a random title for a padlet using a list of default title adjectives.
 * The list of default title adjetives are retrieved from app/services/smart_defaults.rb
 * @returns {string} A random padlet title in the format "My {adjective} padlet".
 */
const randomWallTitle = (): string => {
  return sample(DEFAULT_WALL_TITLES) as string
}

export {
  CustomPropDataTypeToIconMap,
  DATE_PUBLISHED_SORT_BY_DATA,
  DATE_PUBLISHED_SORT_BY_DATA_OLD,
  DEFAULT_MAX_GRADE,
  DatePublishedSortByArray,
  GROUP_BY_DATA,
  MANUAL_SORT_BY_DATA,
  MANUAL_SORT_BY_DATA_OLD,
  ManualSortByArray,
  POST_SHUFFLE_SORT_BY_DATA,
  POST_SHUFFLE_SORT_BY_DATA_OLD,
  POST_SUBJECT_SORT_BY_DATA,
  POST_SUBJECT_SORT_BY_DATA_OLD,
  REACTIONS_DATA,
  REACTION_SORT_BY_DATA,
  REACTION_SORT_BY_DATA_OLD,
  ReactionSortByArray,
  ReactionSortByAscKeyArray,
  ReactionSortByDescKeyArray,
  ReactionSortToKeyMap,
  ReactionType,
  RemakeSetting,
  SORT_POSTS_POPOVER_MENU_REACTION_OPTIONS,
  SortByTypes,
  SortPostsPopoverOption,
  TITLE_CHAR_LIMIT,
  getCustomPropSortOrderOptions,
  isSortingBy,
  isSortingByCustomProp,
  processedEmojiUrl,
  randomWallTitle,
}
export type {
  CustomPropSortBy,
  DatePublishedSortBy,
  ManualSortBy,
  PostSubjectSortBy,
  ReactionSortBy,
  ReactionSortByAscKey,
  ReactionSortByDescKey,
}
