var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    // Shape
    _vm.radius === 8 && 'rounded-lg',
    _vm.radius === 10 && 'rounded-[10px]',
    _vm.radius === 12 && 'rounded-xl',
    _vm.radius === 16 && 'rounded-2xl',
    _vm.radius === 20 && 'rounded-2.5xl',
    _vm.radius === 24 && 'rounded-3xl',
    // Colors
    _vm.color === _setup.OzBoxColors.Primary && [
      {
        'bg-white-100': _vm.darkMode === false,
        'bg-nightfall-900': _vm.darkMode === true,
        'bg-white-100 dark:bg-nightfall-900': _vm.darkMode === 'auto',
      },
      {
        'text-dark-text-100': _vm.darkMode === false,
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.Secondary && [
      {
        'bg-light-ui-200': _vm.darkMode === false,
        'bg-dark-ui-200': _vm.darkMode === true,
        'bg-light-ui-200 dark:bg-dark-ui-200': _vm.darkMode === 'auto',
      },
      {
        'text-dark-text-100': _vm.darkMode === false,
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.PrimaryLight && [
      {
        'bg-light-ui-100': _vm.darkMode === false,
        'bg-dark-ui-100': _vm.darkMode === true,
        'bg-light-ui-100 dark:bg-dark-ui-100': _vm.darkMode === 'auto',
      },
      {
        'text-dark-text-100': _vm.darkMode === false,
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.Selection && [
      {
        'bg-grape-500': _vm.darkMode === false,
        'bg-canary-500': _vm.darkMode === true,
        'bg-grape-500 dark:bg-canary-500': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === false,
        'text-dark-text-100': _vm.darkMode === true,
        'text-light-text-100 dark:text-dark-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.Highlight && [
      {
        'bg-canary-0': _vm.darkMode === false,
        'bg-dark-ui-200': _vm.darkMode === true,
        'bg-canary-0 dark:bg-dark-ui-200': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.Destructive && [
      {
        'bg-tangerine-0': _vm.darkMode === false,
        'bg-dark-ui-200': _vm.darkMode === true,
        'bg-tangerine-0 dark:bg-dark-ui-200': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.Pink && [
      {
        'bg-padlet-pink-0': _vm.darkMode === false,
        'bg-dark-ui-200': _vm.darkMode === true,
        'bg-padlet-pink-0 dark:bg-dark-ui-200': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.Green && [
      {
        'bg-park-0': _vm.darkMode === false,
        'bg-dark-ui-200': _vm.darkMode === true,
        'bg-park-0 dark:bg-dark-ui-200': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.Tangerine && [
      {
        'bg-tangerine-0': _vm.darkMode === false,
        'bg-dark-ui-200': _vm.darkMode === true,
        'bg-tangerine-0 dark:bg-dark-ui-200': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.Grape && [
      {
        'bg-grape-0': _vm.darkMode === false,
        'bg-dark-ui-200': _vm.darkMode === true,
        'bg-grape-0 dark:bg-dark-ui-200': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.Oceanic && [
      {
        'bg-oceanic-0': _vm.darkMode === false,
        'bg-dark-ui-200': _vm.darkMode === true,
        'bg-oceanic-0 dark:bg-dark-ui-200': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.Grey && [
      {
        'bg-grey-0': _vm.darkMode === false,
        'bg-grey-850': _vm.darkMode === true,
        'bg-grey-0 dark:bg-grey-850': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-300': _vm.darkMode === true,
        'text-dark-text-300': _vm.darkMode === false,
        'text-dark-text-300 dark:text-light-text-300': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.Scarlet && [
      {
        'bg-scarlet-0': _vm.darkMode === false,
        'bg-dark-ui-200': _vm.darkMode === true,
        'bg-scarlet-0 dark:bg-dark-ui-200': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.PostDefault && [
      {
        'bg-light-ui-200': _vm.darkMode === false,
        'bg-dark-ui-200': _vm.darkMode === true,
        'bg-light-ui-200 dark:bg-dark-ui-200': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.PostRed && [
      {
        'bg-scarlet-200': _vm.darkMode === false,
        'bg-scarlet-800': _vm.darkMode === true,
        'bg-scarlet-200 dark:bg-scarlet-800': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.PostOrange && [
      {
        'bg-canary-200': _vm.darkMode === false,
        'bg-tangerine-800': _vm.darkMode === true,
        'bg-canary-200 dark:bg-tangerine-800': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.PostGreen && [
      {
        'bg-park-200': _vm.darkMode === false,
        'bg-park-800': _vm.darkMode === true,
        'bg-park-200 dark:bg-park-800': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.PostBlue && [
      {
        'bg-oceanic-200': _vm.darkMode === false,
        'bg-oceanic-800': _vm.darkMode === true,
        'bg-oceanic-200 dark:bg-oceanic-800': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.PostPurple && [
      {
        'bg-grape-200': _vm.darkMode === false,
        'bg-grape-800': _vm.darkMode === true,
        'bg-grape-200 dark:bg-grape-800': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.PrimaryBlur && [
      {
        'bg-common-ui-l86': _vm.darkMode === false,
        'bg-common-ui-d86': _vm.darkMode === true,
        'bg-common-ui-l86 dark:bg-common-ui-d86': _vm.darkMode === 'auto',
        'backdrop-filter': true,
        'backdrop-blur-10': true,
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.color === _setup.OzBoxColors.TaskDone && [
      {
        'bg-oceanic-0': _vm.darkMode === false,
        'bg-oceanic-1000': _vm.darkMode === true,
        'bg-oceanic-0 dark:bg-oceanic-1000': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100': _vm.darkMode === false,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
      _vm.color === _setup.OzBoxColors.Popover && [
        {
          'bg-popover-light': _vm.darkMode === false,
          'bg-popover-dark': _vm.darkMode === true,
          'bg-popover-light dark:bg-popover-dark': _vm.darkMode === 'auto',
        },
        {
          'text-light-text-100': _vm.darkMode === true,
          'text-dark-text-100': _vm.darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
        },
      ],
    ],
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }