import { render, staticRenderFns } from "./OzIcon.vue?vue&type=template&id=de2074c0&scoped=true"
import script from "./OzIcon.vue?vue&type=script&setup=true&lang=ts"
export * from "./OzIcon.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./OzIcon.vue?vue&type=style&index=0&id=de2074c0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de2074c0",
  null
  
)

export default component.exports