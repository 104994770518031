import { TransformOptions } from './types'

const presets: Record<string, TransformOptions> = {
  avatar: {
    gravity: 'auto',
    crop: 'thumb',
  },
  icon: {
    gravity: 'auto',
    crop: 'thumb',
  },
  background: {
    gravity: 'auto',
    crop: 'lfill',
  },
  obscure: {
    width: 60,
    height: 60,
    dpr: 1,
    crop: 'scale',
    format: 'jpg',
    effect: 'blur:1000',
  },
  peek: {
    ar: 2,
    crop: 'lfill',
    dpr: 1,
    format: 'jpg',
    gravity: 'auto',
    height: 600,
    width: 1200,
  },
}

export default presets
