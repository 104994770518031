import { Link } from './types'
import { isPadlet, firstAuthorName } from './link_helpers'

function makeProviderName(link: Link): string {
  if (isPadlet(link)) {
    return firstAuthorName(link) || 'Padlet'
  }
  return link.provider_name
}

export default makeProviderName
