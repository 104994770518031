var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.OzContainedButton,{class:[
    // We only apply the `sr-only` class when the element is not focused.
    // We want to avoid `not-sr-only` because it applies some styles that are not desired.
    '[&:not(:focus-visible)]:sr-only',
    'absolute',
    'z-global-snackbar',
    'top-4',
    'start-1/2',
    '-translate-x-1/2',
    'rtl:translate-x-1/2',
  ],attrs:{"href":_vm.contentElementSelector,"size-preset":_setup.isSmallerThanTabletLandscape ? _setup.OzContainedButtonSizePreset.H24px : _setup.OzContainedButtonSizePreset.H32px,"text":_vm.__('Skip to content')},on:{"click":_setup.onClick}})
}
var staticRenderFns = []

export { render, staticRenderFns }