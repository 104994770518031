import { Link } from './types'
import { isPhoto, isStatic } from './link_helpers'

function makeTitle(link: Link): string {
  if (!link.title) {
    return ''
  } else if (isPhoto(link) && isStatic(link)) {
    return ''
  } else if (!!link.extension && link.title.toLowerCase().endsWith(`.${link.extension.toLowerCase()}`)) {
    // If the file is, say, a pdf and the title is Filename.pdf, remove .pdf from the title.
    return link.title
      .replace(new RegExp(`.${link.extension}$`, 'i'), '')
      .replace(/\_/g, ' ')
      .replace(/\s+/g, ' ')
  }
  return link.title
}

export default makeTitle
