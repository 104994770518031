// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file wall_thumbnail.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

import { Color } from "./Common";

export namespace WallThumbnail {
  export class WallThumbnailMessage extends Message<WallThumbnailMessage> {
    /**
     * @generated from field: string url = 1;
     */
    url: string = "";
    /**
     * @generated from field: int32 width = 2;
     */
    width: number = 0;
    /**
     * @generated from field: int32 height = 3;
     */
    height: number = 0;
    /**
     * @generated from field: proto.Color dominant_color = 4;
     */
    dominantColor: Color.ColorMessage;

    constructor(data?: PartialMessage<WallThumbnailMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.WallThumbnail";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
      { no: 3, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
      { no: 4, name: "dominant_color", kind: "message", T: Color.ColorMessage },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WallThumbnailMessage {
      return new WallThumbnailMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WallThumbnailMessage {
      return new WallThumbnailMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WallThumbnailMessage {
      return new WallThumbnailMessage().fromJsonString(jsonString, options);
    }

      static equals(a: WallThumbnailMessage | PlainMessage<WallThumbnailMessage> | undefined, b: WallThumbnailMessage | PlainMessage<WallThumbnailMessage> | undefined): boolean {
      return proto3.util.equals(WallThumbnailMessage, a, b);
    }

    toObject(): WallThumbnail {
      return {
        url: this.url,
        width: this.width,
        height: this.height,
        dominantColor: this.dominantColor.toObject(),
      };
    }

    static fromObject(object: WallThumbnail): WallThumbnailMessage {
      return new WallThumbnailMessage({
        url: object.url,
        width: object.width,
        height: object.height,
        dominantColor: Color.ColorMessage.fromObject(object.dominantColor),
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): WallThumbnail {
    return WallThumbnailMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: WallThumbnail): Uint8Array {
    return WallThumbnailMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.WallThumbnail
 */
export type WallThumbnail = {
  /**
   * @generated from field: string url = 1;
   */
  url: string;
  /**
   * @generated from field: int32 width = 2;
   */
  width: number;
  /**
   * @generated from field: int32 height = 3;
   */
  height: number;
  /**
   * @generated from field: proto.Color dominant_color = 4;
   */
  dominantColor: Color;
}

