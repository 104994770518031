var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.wrapperElement,_vm._b({tag:"component",staticClass:"relative"},'component',_setup.wrapperAttributes,false),[_c('ul',_vm._b({ref:"list",class:[
      'list-none',
      // Placement
      'flex',
      'justify-between',
      'items-center',
      // Spacing
      'm-0',
      'p-0',
      // Custom classes
      _vm.tabsContainerClasses,
    ],attrs:{"role":_setup.listRole}},'ul',_setup.listAttributes,false),[_vm._l((_vm.tabs),function(tab,index){return [_c('li',{key:tab.key,ref:(el) => _setup.setTabRef(el, index, tab),refInFor:true,attrs:{"role":_setup.listItemRole}},[_c(_setup.OzPlainButton,_vm._b({ref:"tabButtonElsRef",refInFor:true,class:[
            'flex items-center group',
            'outline-none',
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H28px && [
              'h-[1.75rem]',
              'rounded-[10px]',
              'px-3',
              'text-body-small',
            ],
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H37px && [
              'h-[2.3125rem]',
              'rounded-xl',
              'px-2',
              'text-body-small',
            ],
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H40px && ['h-10', 'rounded-2xl', 'px-2', 'text-heading-4'],
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H42px && [
              'h-[2.625rem]',
              'rounded-2xl',
              'px-2',
              'text-heading-3',
            ],
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H48px && ['h-12', 'rounded-2xl', 'px-2', 'text-heading-2'],
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H59px && [
              'h-[3.6875rem]',
              'rounded-2.5xl',
              'px-3',
              'text-heading-1',
            ],
            // Font
            'font-semibold',
            // Base
            _setup.currentTabId === tab.key && {
              'text-grape-500': _vm.darkMode === false,
              'text-canary-500': _vm.darkMode === true,
              'text-grape-500 dark:text-canary-500': _vm.darkMode === 'auto',
            },
            _setup.currentTabId !== tab.key && [
              tab.colorPreset == null && {
                'text-dark-text-200': _vm.darkMode === false,
                'text-light-text-200': _vm.darkMode === true,
                'text-dark-text-200 dark:text-light-text-200': _vm.darkMode === 'auto',
              },
              tab.colorPreset === _setup.OzTabsWithSeparatorButtonColorPreset.Primary && {
                'text-padlet-pink': !tab.disabled,
                '!text-padlet-pink-100': tab.disabled,
              },
            ],
            // Hovered
            !tab.disabled && {
              'hhover:text-dark-text-100': _vm.darkMode === false,
              'hhover:text-light-text-100': _vm.darkMode === true,
              'hhover:text-dark-text-100 dark:hhover:text-light-text-100': _vm.darkMode === 'auto',
            },
            // Focused
            'ring-inset',
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H28px && 'focus-visible:ring-2',
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H37px && 'focus-visible:ring-2',
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H40px && 'focus-visible:ring-[2.5px]',
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H42px && 'focus-visible:ring-[2.5px]',
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H48px && 'focus-visible:ring-[2.5px]',
            _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H59px && 'focus-visible:ring-4',
            {
              'focus-visible:ring-grape-500': _vm.darkMode === false,
              'focus-visible:ring-canary-500': _vm.darkMode === true,
              'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500': _vm.darkMode === 'auto',
            },
            // Active
            !tab.disabled && {
              'hactive:text-grape-500': _vm.darkMode === false,
              'hactive:text-canary-500': _vm.darkMode === true,
              'hactive:text-grape-500 hactive:dark:text-canary-500': _vm.darkMode === 'auto',
            },
            // Custom classes
            !tab.disabled && _vm.nonDisabledButtonClasses,
          ],attrs:{"data-testid":tab.testId,"href":tab.href,"href-mode":tab.hrefMode,"size-preset":_setup.OzPlainButtonSizePreset.Bare,"color-scheme":_setup.OzPlainButtonColorScheme.Bare,"dark-mode":_vm.darkMode,"disabled":tab.disabled,"x-title":tab.xTitleTooltip,"aria-labelledby":tab.xTitleTooltip === false ? `custom-tooltip-${index}` : undefined},on:{"click":function($event){return _setup.emit('activate-tab', $event, { key: tab.key })}}},'OzPlainButton',_setup.computeTabAttributes(tab),false),[_c('div',{staticClass:"flex-1 truncate align-middle"},[(tab.startAdornmentText)?_c('span',{staticClass:"me-0.75",attrs:{"aria-hidden":true}},[_vm._v(_vm._s(tab.startAdornmentText))]):_vm._e(),_c('span',[_vm._v(_vm._s(tab.text))])]),_vm._v(" "),(tab.xTitleTooltip === false)?_c('span',{staticClass:"sr-only",attrs:{"id":`custom-tooltip-${index}`}},[_vm._v("\n            "+_vm._s(tab.tooltipText)+"\n          ")]):_vm._e(),_vm._v(" "),(tab.tooltipText && _setup.tabButtonElsRef[index])?_c(_setup.OzTooltip,{staticClass:"hidden hover-hover:group-hover:block w-max",attrs:{"anchor":_setup.tabButtonElsRef[index].$el,"config":{
              placement: 'bottom',
            }}},[_vm._v("\n            "+_vm._s(tab.tooltipText)+"\n          ")]):_vm._e()],1)],1),_vm._v(" "),(index !== _vm.tabs.length - 1)?_c('li',{key:`${tab.key}-divider`,class:[
          _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H28px && 'text-body',
          _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H37px && 'text-body',
          _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H40px && 'text-body',
          _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H42px && 'text-body-large',
          _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H48px && 'text-body-large',
          _vm.sizePreset == _setup.OzTabsWithSeparatorSizePreset.H59px && 'text-heading-1',
          'select-none',
          {
            'text-dark-text-400': _vm.darkMode === false,
            'text-light-text-400': _vm.darkMode === true,
            'text-dark-text-400 dark:text-light-text-400': _vm.darkMode === 'auto',
          },
        ],attrs:{"aria-hidden":"true","tabindex":"-1"}},[_vm._v("\n        /\n      ")]):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }