// @file Composable that manages the animations for the demo padlet onboarding flow
import { trackEvent } from '@@/bits/analytics'
import { emojiUrl } from '@@/bits/emoji'
import { __ } from '@@/bits/intl'
import { unboundedWatch } from '@@/bits/vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useCommentsStore } from '@@/pinia/surface_comments'
import {
  StepStatus,
  useSurfaceOnboardingDemoPadletPanelStore,
} from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { useSurfaceSimulatedAttributionStore } from '@@/pinia/surface_simulated_attribution_store'
import type { Post } from '@@/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const WATERMELON_EMOJI_CODE = '1f349'

export const useSurfaceOnboardingDemoPadletAnimationsStore = defineStore(
  'surfaceOnboardingDemoPadletAnimationsStore',
  () => {
    const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()
    const surfaceSettingsStore = useSurfaceSettingsStore()
    const surfaceSectionsStore = useSurfaceSectionsStore()
    const surfaceStore = useSurfaceStore()
    const surfacePostsStore = useSurfacePostsStore()
    const commentsStore = useCommentsStore()
    const surfaceSimulatedAttributionStore = useSurfaceSimulatedAttributionStore()

    const temporarySectionId = ref(-1) // When sections are enabled we must keep at least one section, so we save this to delete later after we add the new sections
    // This function addresses the corner case of a user refreshing as sections are being added to the page (and thus being taken to the beginning of the step with sections already present)
    async function deleteAllButOneSection(): Promise<void> {
      const sectionIds = [...surfaceSectionsStore.sectionIds]
      const sectionsToDelete: number[] = []

      if (sectionIds.length === 1) {
        temporarySectionId.value = sectionIds[0]
        return
      }

      // Identify the section to keep
      if (surfaceSectionsStore.sectionIds.length > 0) {
        temporarySectionId.value = sectionIds.pop() as number
      }

      // Prepare the list of sections to delete
      while (sectionIds.length > 0) {
        sectionsToDelete.push(sectionIds.pop() as number)
      }

      // Delete all sections in parallel
      await Promise.all(
        sectionsToDelete.map(
          async (sectionId) => await surfaceSectionsStore.deleteSection(sectionId, { showSnackbar: false }),
        ),
      )
    }

    async function addSectionsInOrder(): Promise<void> {
      const sectionNames = surfaceDemoPadletPanelStore.sectionNames
      for (let i = 0; i < sectionNames.length; i++) {
        const sectionName = sectionNames[i]
        await surfaceSectionsStore.createSectionWithName(sectionName)
      }
      await surfaceSectionsStore.deleteSection(temporarySectionId.value, { showSnackbar: false })
    }

    function setSectionsSelected(toggleValue: boolean): void {
      if (toggleValue === surfaceStore.isSectioned) {
        return
      }
      surfaceSettingsStore.startWallAttributesPreview()
      surfaceSettingsStore.toggleGroupBySection()
      setTimeout(() => {
        // Make sure we don't stop the preview before the timeout to toggle sections is done
        surfaceSettingsStore.stopWallAttributesPreview()
      }, 350)
    }

    // ------------------------------
    // Topic step
    // ------------------------------

    const TEXT_TRANSITION_DURATION = 1000 // in ms
    const ADD_EMOJI_BACKGROUND_DURATION = 1500
    const ADD_EMOJI_DURATION = 500
    const REMOVE_EMOJI_BACKGROUND_DURATION = 1000
    const ADD_SECTIONS_DURATION = 1000
    const COMPLETED_TOPIC_STEP_DURATION = 1000

    const xTopicText = ref(true)
    const pulsateTopicText = ref(true)
    const hasClickedTopicText = ref(false)
    const xTopicEmojiBackground = ref(false)
    const xTopicEmoji = ref(false)

    const addEmoji = (): void => {
      surfaceSettingsStore.updatePreviewAttributes({ portrait: emojiUrl('png', WATERMELON_EMOJI_CODE) })
      surfaceSettingsStore.saveSettings()
    }

    const startTopicAnimation = (): void => {
      if (hasClickedTopicText.value) {
        return
      }
      trackEvent('Demo padlet onboarding', 'Clicked start topic animation')

      surfaceDemoPadletPanelStore.setActiveStepStatus(StepStatus.InProgress)
      xTopicText.value = false
      hasClickedTopicText.value = true

      // 1. Fade in the topic text  and update title/description
      setTimeout(() => {
        xTopicText.value = true
        // We don't have to set the initial title and description because those come from the template that we remake from
        surfaceSettingsStore.updatePreviewAttributes({
          title: __('Places'),
          description: __('Let’s explore the world!'),
        })
        surfaceSettingsStore.saveSettings()
      }, TEXT_TRANSITION_DURATION)

      // 2. Pulsate the topic text, add the emoji background
      setTimeout(() => {
        pulsateTopicText.value = false
        xTopicEmojiBackground.value = true
      }, TEXT_TRANSITION_DURATION + ADD_EMOJI_BACKGROUND_DURATION)

      // 3. Add the emoji
      setTimeout(() => {
        addEmoji()
      }, TEXT_TRANSITION_DURATION + ADD_EMOJI_BACKGROUND_DURATION + ADD_EMOJI_DURATION)

      // 4. Remove the emoji background, setup the sections (we want to show them all at once so we don't show until the next step)
      setTimeout(() => {
        xTopicEmoji.value = true
        xTopicEmojiBackground.value = false
        void (async () => {
          await deleteAllButOneSection()
          await addSectionsInOrder()
          await surfaceSectionsStore.deleteSection(temporarySectionId.value, { showSnackbar: false })
          if (surfaceDemoPadletPanelStore.postData[0].wallSectionId !== undefined) {
            surfaceSectionsStore.updateMostRecentlyTouchedSection(surfaceDemoPadletPanelStore.postData[0].wallSectionId)
          }
        })()
      }, TEXT_TRANSITION_DURATION + ADD_EMOJI_BACKGROUND_DURATION + ADD_EMOJI_DURATION + REMOVE_EMOJI_BACKGROUND_DURATION)

      // 5. Add the sections
      setTimeout(() => {
        setSectionsSelected(true)
      }, TEXT_TRANSITION_DURATION + ADD_EMOJI_BACKGROUND_DURATION + ADD_EMOJI_DURATION + REMOVE_EMOJI_BACKGROUND_DURATION + ADD_SECTIONS_DURATION)

      // 6. Complete the step
      setTimeout(() => {
        surfaceSettingsStore.saveSettings()
        surfaceDemoPadletPanelStore.setActiveStepStatus(StepStatus.Complete)
      }, TEXT_TRANSITION_DURATION + ADD_EMOJI_BACKGROUND_DURATION + ADD_EMOJI_DURATION + REMOVE_EMOJI_BACKGROUND_DURATION + ADD_SECTIONS_DURATION + COMPLETED_TOPIC_STEP_DURATION)
    }

    // ------------------------------
    // Post step
    // ------------------------------

    const startPostAnimation = (): void => {
      surfaceDemoPadletPanelStore.setActiveStepStatus(StepStatus.InProgress)
      // Add post steps here as needed
      surfaceDemoPadletPanelStore.setActiveStepStatus(StepStatus.Complete)
    }

    // ------------------------------
    // Share step
    // ------------------------------

    const SENDING_OUT_LINKS_DURATION = 2000 // in ms
    const MAKING_CONTRIBUTIONS_DURATION = 3000
    const COLLABORATE_AND_INTERACT_DURATION = 2000
    const COMPLETED_SHARE_STEP_DURATION = 2000

    const isSendingOutLinksCompleted = ref(false)
    const isMakingContributionsCompleted = ref(false)
    const isCollaboratingAndInteractingCompleted = ref(false)

    // We track the posts that are added so we know immediately which posts to add comments to
    const addedPosts = ref<Post[]>([])

    let stopWatch: any = () => {}
    const startShareAnimation = (): void => {
      surfaceDemoPadletPanelStore.setActiveStepStatus(StepStatus.InProgress)
      trackEvent('Demo padlet onboarding', 'Clicked start share animation')

      stopWatch = unboundedWatch(
        () => surfacePostsStore.newestPostCid,
        (newestPostCid) => {
          if (newestPostCid !== undefined) {
            addedPosts.value.push(surfacePostsStore.postEntitiesByCid[newestPostCid])
          }
        },
      )
      // 1. Send out links
      setTimeout(() => {
        isSendingOutLinksCompleted.value = true
      }, SENDING_OUT_LINKS_DURATION)
      // 2. Make contributions (add posts)
      setTimeout(() => {
        // We start from 1 because the first post holds the composer content from the post step so we skip that
        void (async () => {
          for (let i = 1; i < surfaceDemoPadletPanelStore.postData.length; i++) {
            const post = surfaceDemoPadletPanelStore.postData[i]
            await surfacePostsStore.savePost({ attributes: post })
            // We add the post then immediately save it's id, this way we'll know which posts to add commments onto in the next step in this animation
          }
        })()

        isMakingContributionsCompleted.value = true
      }, SENDING_OUT_LINKS_DURATION + MAKING_CONTRIBUTIONS_DURATION)

      // 3. Collaborate and interact (add comments from simulated users)
      setTimeout(() => {
        for (let i = 0; i < addedPosts.value.length; i++) {
          // We look at each post
          const hashId = addedPosts.value[i].hashid
          if (hashId == null) {
            continue
          }
          const postId = addedPosts.value[i].id

          if (postId == null) {
            continue
          }
          // First we add the attribution at the post level
          surfaceSimulatedAttributionStore.setSimulatedUserForHashId(
            hashId,
            surfaceDemoPadletPanelStore.postAttributionData[i],
          )
          // If the post has comments, we add them
          for (let j = 0; j < surfaceDemoPadletPanelStore.commentData[i].length; j++) {
            void (async () => {
              // First create the comment
              const newComment = await commentsStore.createCommentV2({
                postId,
                htmlBody: surfaceDemoPadletPanelStore.commentData[i][j].commentBody,
                attachment: undefined,
              })
              // Then store that comment with the associated simulated user
              if (newComment !== undefined) {
                surfaceSimulatedAttributionStore.setSimulatedUserForHashId(newComment.hashid, {
                  name: surfaceDemoPadletPanelStore.commentData[i][j].name,
                  avatar: surfaceDemoPadletPanelStore.commentData[i][j].avatar,
                })
              }
            })()
          }
        }

        isCollaboratingAndInteractingCompleted.value = true
      }, SENDING_OUT_LINKS_DURATION + MAKING_CONTRIBUTIONS_DURATION + COLLABORATE_AND_INTERACT_DURATION)

      // 4. Complete the step
      setTimeout(() => {
        surfaceDemoPadletPanelStore.setActiveStepStatus(StepStatus.Complete)
        // Cleanup
        stopWatch()
      }, SENDING_OUT_LINKS_DURATION + MAKING_CONTRIBUTIONS_DURATION + COLLABORATE_AND_INTERACT_DURATION + COMPLETED_SHARE_STEP_DURATION)
    }

    return {
      // ------------------------------
      // Topic step
      // ------------------------------
      startTopicAnimation,
      xTopicText,
      pulsateTopicText,
      hasClickedTopicText,
      xTopicEmojiBackground,
      xTopicEmoji,
      // ------------------------------
      // Post step
      // ------------------------------
      startPostAnimation,
      addedPosts,
      // ------------------------------
      // Share step
      // ------------------------------
      startShareAnimation,
      isSendingOutLinksCompleted,
      isMakingContributionsCompleted,
      isCollaboratingAndInteractingCompleted,
    }
  },
)
