/* eslint-disable @typescript-eslint/camelcase */
/**
 * @file Gets a thumbnail image for a given link
 */

import { processedUrl } from '@padlet/vivaldi-client'
import { isPadlet, isPage, isPhoto, isVideo } from './link_helpers'
import { getName as getLuminanceName } from './luminance'
import {
  BeethovenContentCategory,
  BeethovenContentSubcategory,
  BeethovenDisplayOptions,
  BeethovenLuminance,
  Color,
  Image,
  Link,
} from './types'

const displayAspectRatios: Record<BeethovenContentCategory, number> = {
  photo: 1.333333333,
  audio: 1,
  video: 1.777777778,
  document: 1.414285714,
  file: 1.904761905,
  page: 1.904761905,
  unknown: 1.904761905,
}

const BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

// const fallbackImages: { [key in BeethovenContentCategory]: Image } = {
//   photo: {
//     aspect_ratio: displayAspectRatios.photo,
//   },
//   video: {
//     aspect_ratio: displayAspectRatios.video,
//   },
//   audio: {
//     aspect_ratio: displayAspectRatios.audio,
//   },
//   document: {
//     aspect_ratio: displayAspectRatios.document,
//   },
//   file: {
//     aspect_ratio: displayAspectRatios.file,
//   },
//   page: {
//     aspect_ratio: displayAspectRatios.page,
//   },
//   unknown: {
//     aspect_ratio: displayAspectRatios.unknown,
//   },
// }

function getCropGravity(
  contentCategory: BeethovenContentCategory | string,
  contentSubcategory: BeethovenContentSubcategory | string,
): string {
  if (
    contentCategory === 'document' &&
    (contentSubcategory === 'pdf' || contentSubcategory === 'doc' || contentSubcategory === 'spreadsheet')
  ) {
    return 'north'
  }
  return 'auto'
}

function getCropType(contentCategory: BeethovenContentCategory | string): string {
  if (contentCategory === 'photo') return 'limit'
  return 'lfill'
}

function getRgba(color: Color): string {
  return `rgb(${color[0]},${color[1]},${color[2]})`
}

function getImage(
  link: Link,
  options: BeethovenDisplayOptions,
): Image & { original_width?: number; original_height?: number } {
  if (link.preview_image) {
    const previewImage = link.preview_image
    const width = options.width || 0
    const aspectRatio =
      isPhoto(link) || isVideo(link) || (isPage(link) && !isPadlet(link))
        ? previewImage.width / previewImage.height
        : displayAspectRatios[link.content_category as BeethovenContentCategory]
    // In Vivaldi, when calculating height using width and aspect ratio, we use
    // Math.ceil (see [1] and [2]). Therefore, we should also use Math.ceil
    // here.
    // [1]: https://github.com/padlet/mozart/blob/21a2867e9c2abf5e71db0bb6c3eaa6b86b6cd904/services/vivaldi/src/vips/fill.js#L30
    // [2]: https://github.com/padlet/mozart/blob/21a2867e9c2abf5e71db0bb6c3eaa6b86b6cd904/services/vivaldi/src/vips/limit_fill.js#L39
    const height = Math.ceil(width / aspectRatio)
    const crop = getCropType(link.content_category)
    const gravity = getCropGravity(link.content_category, link.content_subcategory)
    const dpr = options.dpr
    const color = getRgba(previewImage.colors[0].color)
    const isTransparent = previewImage.is_transparent
    const processingOptions: any = { width, dpr, crop }
    if (gravity !== 'auto') {
      processingOptions.gravity = gravity
    }
    if (isVideo(link)) {
      processingOptions.trim = true
    }
    if (isPhoto(link)) {
      processingOptions.height = height
    } else {
      processingOptions.ar = aspectRatio
    }
    return {
      url: processedUrl(previewImage.url, processingOptions),
      width,
      original_width: previewImage.width,
      height,
      original_height: previewImage.height,
      aspect_ratio: aspectRatio,
      color,
      luminance: getLuminanceName(previewImage.colors[0].color),
      is_transparent: isTransparent,
    }
  } else {
    const url = BLANK_IMAGE
    const width = options.width || 0
    const aspectRatio = displayAspectRatios[link.content_category as BeethovenContentCategory]
    const height = Math.floor(width / aspectRatio)
    return {
      url,
      width,
      height,
      aspect_ratio: aspectRatio,
      color: '',
      luminance: BeethovenLuminance.unknown,
      is_transparent: false,
    }
  }
}

export default getImage
