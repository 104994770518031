/**
 * @file Link helpers for identifying the content type of the link.
 */
import { BeethovenLinkForValidation, Link } from './types'

function isStatic(link: Link): boolean {
  return link.content_type != 'text/html'
}

function isPhoto(link: BeethovenLinkForValidation): boolean {
  return link.content_category === 'photo'
}

function isFile(link: BeethovenLinkForValidation): boolean {
  return link.content_category === 'file'
}

function isAudio(link: BeethovenLinkForValidation): boolean {
  return link.content_category === 'audio'
}

function isVideo(link: BeethovenLinkForValidation): boolean {
  return link.content_category === 'video'
}

function isDocument(link: BeethovenLinkForValidation): boolean {
  return link.content_category === 'document'
}

function isWordDocument(link: BeethovenLinkForValidation): boolean {
  const wordExtensions = ['doc', 'docx', 'dot', 'dotx', 'docm', 'dotm']
  return wordExtensions.includes(link.extension || '')
}

function isPowerpointPresentation(link: BeethovenLinkForValidation): boolean {
  const powerpointExtensions = ['ppt', 'pptx', 'ppam', 'potx', 'pps', 'ppsx', 'ppsm', 'pptm']
  return powerpointExtensions.includes(link.extension || '')
}

function isExcelSpreadsheet(link: BeethovenLinkForValidation): boolean {
  const excelExtensions = ['xls', 'xlsx', 'xlsm', 'xlsb']
  return excelExtensions.includes(link.extension || '')
}

function isPdf(link: Link): boolean {
  return link.content_subcategory === 'pdf'
}

function isCode(link: Link): boolean {
  return link.content_subcategory === 'code'
}

function isText(link: Link): boolean {
  return link.content_subcategory === 'text'
}

function isPlainText(link: Link): boolean {
  return isCode(link) || isText(link)
}

function isPage(link: BeethovenLinkForValidation): boolean {
  return link.content_category === 'page'
}

function isAmpablePage(link: Link): boolean {
  return isPage(link) && !!link.amp_url
}

function isPadlet(link: Link): boolean {
  return link.content_subcategory === 'padlet'
}

function firstAuthorName(link: Link): string {
  if (link.authors && link.authors[0] && link.authors[0].name) {
    return link.authors[0].name
  }
  return ''
}

function isPadletWall(link: BeethovenLinkForValidation): boolean {
  return link.content_subcategory === 'padlet' && !!link.provider_name && link.provider_name.toLowerCase() !== 'padlet'
}

function isSlideshow(link: BeethovenLinkForValidation): boolean {
  return link.content_subcategory === 'slideshow'
}

function isYoutube(link: BeethovenLinkForValidation): boolean {
  return link.provider_name.toLowerCase() === 'youtube'
}

function isSpotify(link: BeethovenLinkForValidation): boolean {
  return link.provider_name.toLowerCase() === 'spotify'
}

function isInstagram(link: BeethovenLinkForValidation): boolean {
  return link.provider_name.toLowerCase() === 'instagram'
}

function isGoogleDoc(link: BeethovenLinkForValidation): boolean {
  return link.provider_name.toLowerCase() === 'google docs' || link.provider_name.toLowerCase() === 'docs.google.com'
}

function isGoogleDriveFile(link: BeethovenLinkForValidation): boolean {
  return [
    'google document',
    'google presentation',
    'google spreadsheet',
    'google form',
    'google jam',
    'google drawing',
    'google map',
    'google site',
    'google drive',
  ].includes(link.provider_name.toLowerCase())
}

function isUploadedAudio(link: BeethovenLinkForValidation): boolean {
  return link.content_category === 'audio' && link.provider_name.toLowerCase() === 'padlet drive'
}

function isUploadedVideo(link: BeethovenLinkForValidation): boolean {
  return link.content_category === 'video' && link.provider_name.toLowerCase() === 'padlet drive'
}

export {
  isStatic,
  isPhoto,
  isFile,
  isAudio,
  isVideo,
  isDocument,
  isWordDocument,
  isPowerpointPresentation,
  isExcelSpreadsheet,
  isPdf,
  isCode,
  isText,
  isPlainText,
  isPage,
  isAmpablePage,
  firstAuthorName,
  isPadlet,
  isInstagram,
  isGoogleDoc,
  isGoogleDriveFile,
  isPadletWall,
  isSlideshow,
  isSpotify,
  isYoutube,
  isUploadedAudio,
  isUploadedVideo,
}
