import { Color, BeethovenLuminance } from './types'

function getValue(color: Color) {
  return Math.round(0.299 * color[0] + 0.587 * color[1] + 0.114 * color[2])
}

function getName(color: Color): BeethovenLuminance {
  return getValue(color) > 170 ? BeethovenLuminance.light : BeethovenLuminance.dark
}

function isLight(color: Color): boolean {
  return getName(color) == BeethovenLuminance.light
}

function isDark(color: Color): boolean {
  return getName(color) == BeethovenLuminance.dark
}

export { getValue, getName, isLight, isDark }
