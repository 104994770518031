// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file wish.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

import { Timestamp } from "@bufbuild/protobuf";
import { WishContent } from "./WishContent";

export namespace LocationPoint {
  export class LocationPointMessage extends Message<LocationPointMessage> {
    /**
     * @generated from field: double latitude = 1;
     */
    latitude: number = 0;
    /**
     * @generated from field: double longitude = 2;
     */
    longitude: number = 0;

    constructor(data?: PartialMessage<LocationPointMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.LocationPoint";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
      { no: 2, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LocationPointMessage {
      return new LocationPointMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LocationPointMessage {
      return new LocationPointMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LocationPointMessage {
      return new LocationPointMessage().fromJsonString(jsonString, options);
    }

      static equals(a: LocationPointMessage | PlainMessage<LocationPointMessage> | undefined, b: LocationPointMessage | PlainMessage<LocationPointMessage> | undefined): boolean {
      return proto3.util.equals(LocationPointMessage, a, b);
    }

    toObject(): LocationPoint {
      return {
        latitude: this.latitude,
        longitude: this.longitude,
      };
    }

    static fromObject(object: LocationPoint): LocationPointMessage {
      return new LocationPointMessage({
        latitude: object.latitude,
        longitude: object.longitude,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): LocationPoint {
    return LocationPointMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: LocationPoint): Uint8Array {
    return LocationPointMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.LocationPoint
 */
export type LocationPoint = {
  /**
   * @generated from field: double latitude = 1;
   */
  latitude: number;
  /**
   * @generated from field: double longitude = 2;
   */
  longitude: number;
}

export namespace Wish {
  /**
   * @generated from enum proto.Wish.WishColor
   */
  export enum WishColor {
    /**
     * @generated from enum value: WISH_COLOR_UNSPECIFIED = 0;
     */
    Unspecified = 0,
    /**
     * @generated from enum value: WISH_COLOR_RED = 1;
     */
    Red = 1,
    /**
     * @generated from enum value: WISH_COLOR_ORANGE = 2;
     */
    Orange = 2,
    /**
     * @generated from enum value: WISH_COLOR_GREEN = 3;
     */
    Green = 3,
    /**
     * @generated from enum value: WISH_COLOR_BLUE = 4;
     */
    Blue = 4,
    /**
     * @generated from enum value: WISH_COLOR_PURPLE = 5;
     */
    Purple = 5,
  }

  export namespace CustomProperty {
    export class CustomPropertyMessage extends Message<CustomPropertyMessage> {
      /**
       * @generated from oneof proto.Wish.CustomProperty.value
       */
      value:
        | { case: "stringValue"; value: string }
        | { case: "intValue"; value: bigint }
        | { case: undefined; value?: undefined } = { case: undefined };

      constructor(data?: PartialMessage<CustomPropertyMessage>) {
        super();
        proto3.util.initPartial(data, this);
      }

      static readonly runtime: typeof proto3 = proto3;
      static readonly typeName = "proto.Wish.CustomProperty";
      static readonly fields = proto3.util.newFieldList(() => [
        { no: 1, name: "string_value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
        { no: 2, name: "int_value", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "value" },
      ]);

      static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CustomPropertyMessage {
        return new CustomPropertyMessage().fromBinary(bytes, options);
      }

      static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CustomPropertyMessage {
        return new CustomPropertyMessage().fromJson(jsonValue, options);
      }

      static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CustomPropertyMessage {
        return new CustomPropertyMessage().fromJsonString(jsonString, options);
      }

        static equals(a: CustomPropertyMessage | PlainMessage<CustomPropertyMessage> | undefined, b: CustomPropertyMessage | PlainMessage<CustomPropertyMessage> | undefined): boolean {
        return proto3.util.equals(CustomPropertyMessage, a, b);
      }

      toObject(): CustomProperty {
        return {
          value: (() => {
            switch (this.value.case) {
              case undefined:
                return { case: undefined };
              case "stringValue":
                return { case: "stringValue", value: this.value.value };
              case "intValue":
                return { case: "intValue", value: this.value.value };
            }
          })(),
        };
      }

      static fromObject(object: CustomProperty): CustomPropertyMessage {
        return new CustomPropertyMessage({
          value: (() => {
            switch (object.value.case) {
              case undefined:
                return { case: undefined };
              case "stringValue":
                return { case: "stringValue", value: object.value.value };
              case "intValue":
                return { case: "intValue", value: object.value.value };
            }
          })(),
        });
      }
    }

    export function fromBinary(bytes: Uint8Array): CustomProperty {
      return CustomPropertyMessage.fromBinary(bytes).toObject();
    }

    export function toBinary(json: CustomProperty): Uint8Array {
      return CustomPropertyMessage.fromObject(json).toBinary();
    }

  }

  /**
   * @generated from message proto.Wish.CustomProperty
   */
  export type CustomProperty = {
    /**
     * @generated from oneof proto.Wish.CustomProperty.value
     */
    value:
      | { case: "stringValue"; value: string }
      | { case: "intValue"; value: bigint }
      | { case: undefined; value?: undefined };
  }

  export class WishMessage extends Message<WishMessage> {
    /**
     * @generated from field: int64 id = 1;
     */
    id: bigint = protoInt64.zero;
    /**
     * @generated from field: int64 wall_id = 2;
     */
    wallId: bigint = protoInt64.zero;
    /**
     * @generated from field: bool published = 3;
     */
    published: boolean = false;
    /**
     * @generated from field: int64 row_id = 4;
     */
    rowId: bigint = protoInt64.zero;
    /**
     * @generated from field: int64 col_id = 5;
     */
    colId: bigint = protoInt64.zero;
    /**
     * @generated from field: int64 row_span = 6;
     */
    rowSpan: bigint = protoInt64.zero;
    /**
     * @generated from field: int64 col_span = 7;
     */
    colSpan: bigint = protoInt64.zero;
    /**
     * @generated from field: bool is_content_hidden = 8;
     */
    isContentHidden: boolean = false;
    /**
     * @generated from field: google.protobuf.Timestamp updated_at = 9;
     */
    updatedAt: Timestamp;
    /**
     * @generated from field: string headline = 10;
     */
    headline: string = "";
    /**
     * @generated from field: string subject = 11;
     */
    subject: string = "";
    /**
     * @generated from field: string body = 12;
     */
    body: string = "";
    /**
     * @generated from field: string attachment_url = 13;
     */
    attachmentUrl: string = "";
    /**
     * @generated from field: string permalink = 14;
     */
    permalink: string = "";
    /**
     * @generated from field: int64 author_id = 15;
     */
    authorId: bigint = protoInt64.zero;
    /**
     * @generated from field: google.protobuf.Timestamp created_at = 16;
     */
    createdAt: Timestamp;
    /**
     * @generated from field: google.protobuf.Timestamp content_updated_at = 17;
     */
    contentUpdatedAt: Timestamp;
    /**
     * @generated from field: google.protobuf.Timestamp scheduled_at = 18;
     */
    scheduledAt: Timestamp;
    /**
     * @generated from field: proto.Wish.WishColor color = 19;
     */
    color: Wish.WishColor = Wish.WishColor.Unspecified;
    /**
     * @generated from field: proto.LocationPoint location_point = 20;
     */
    locationPoint: LocationPoint.LocationPointMessage;
    /**
     * @generated from field: string location_name = 21;
     */
    locationName: string = "";
    /**
     * @generated from field: double top = 22;
     */
    top: number = 0;
    /**
     * @generated from field: double left = 23;
     */
    left: number = 0;
    /**
     * @generated from field: double width = 24;
     */
    width: number = 0;
    /**
     * @generated from field: sint64 sort_index = 25;
     */
    sortIndex: bigint = protoInt64.zero;
    /**
     * @generated from field: int64 wall_section_id = 26;
     */
    wallSectionId: bigint = protoInt64.zero;
    /**
     * @generated from field: string rank = 27;
     */
    rank: string = "";
    /**
     * @generated from field: string label = 28;
     */
    label: string = "";
    /**
     * @generated from field: string attachment_caption = 29;
     */
    attachmentCaption: string = "";
    /**
     * @generated from field: proto.WishContent wish_content = 30;
     */
    wishContent: WishContent.WishContentMessage;
    /**
     * @generated from field: map<string, proto.Wish.CustomProperty> custom_properties = 31;
     */
    customProperties: { [key: string]: Wish.CustomProperty.CustomPropertyMessage } = {};

    constructor(data?: PartialMessage<WishMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.Wish";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 2, name: "wall_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 3, name: "published", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 4, name: "row_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 5, name: "col_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 6, name: "row_span", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 7, name: "col_span", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 8, name: "is_content_hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 9, name: "updated_at", kind: "message", T: Timestamp },
      { no: 10, name: "headline", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 11, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 12, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 13, name: "attachment_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 14, name: "permalink", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 15, name: "author_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 16, name: "created_at", kind: "message", T: Timestamp },
      { no: 17, name: "content_updated_at", kind: "message", T: Timestamp },
      { no: 18, name: "scheduled_at", kind: "message", T: Timestamp },
      { no: 19, name: "color", kind: "enum", T: proto3.getEnumType(Wish.WishColor) },
      { no: 20, name: "location_point", kind: "message", T: LocationPoint.LocationPointMessage },
      { no: 21, name: "location_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 22, name: "top", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
      { no: 23, name: "left", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
      { no: 24, name: "width", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
      { no: 25, name: "sort_index", kind: "scalar", T: 18 /* ScalarType.SINT64 */ },
      { no: 26, name: "wall_section_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 27, name: "rank", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 28, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 29, name: "attachment_caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 30, name: "wish_content", kind: "message", T: WishContent.WishContentMessage },
      { no: 31, name: "custom_properties", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: Wish.CustomProperty.CustomPropertyMessage } },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WishMessage {
      return new WishMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WishMessage {
      return new WishMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WishMessage {
      return new WishMessage().fromJsonString(jsonString, options);
    }

      static equals(a: WishMessage | PlainMessage<WishMessage> | undefined, b: WishMessage | PlainMessage<WishMessage> | undefined): boolean {
      return proto3.util.equals(WishMessage, a, b);
    }

    toObject(): Wish {
      return {
        id: this.id,
        wallId: this.wallId,
        published: this.published,
        rowId: this.rowId,
        colId: this.colId,
        rowSpan: this.rowSpan,
        colSpan: this.colSpan,
        isContentHidden: this.isContentHidden,
        updatedAt: this.updatedAt.toJson() as string,
        headline: this.headline,
        subject: this.subject,
        body: this.body,
        attachmentUrl: this.attachmentUrl,
        permalink: this.permalink,
        authorId: this.authorId,
        createdAt: this.createdAt.toJson() as string,
        contentUpdatedAt: this.contentUpdatedAt.toJson() as string,
        scheduledAt: this.scheduledAt.toJson() as string,
        color: this.color,
        locationPoint: this.locationPoint.toObject(),
        locationName: this.locationName,
        top: this.top,
        left: this.left,
        width: this.width,
        sortIndex: this.sortIndex,
        wallSectionId: this.wallSectionId,
        rank: this.rank,
        label: this.label,
        attachmentCaption: this.attachmentCaption,
        wishContent: this.wishContent.toObject(),
        customProperties: Object.fromEntries(Object.entries(this.customProperties).map(([k, v]) => [k, v.toObject()])),
      };
    }

    static fromObject(object: Wish): WishMessage {
      return new WishMessage({
        id: object.id,
        wallId: object.wallId,
        published: object.published,
        rowId: object.rowId,
        colId: object.colId,
        rowSpan: object.rowSpan,
        colSpan: object.colSpan,
        isContentHidden: object.isContentHidden,
        updatedAt: Timestamp.fromJson(object.updatedAt),
        headline: object.headline,
        subject: object.subject,
        body: object.body,
        attachmentUrl: object.attachmentUrl,
        permalink: object.permalink,
        authorId: object.authorId,
        createdAt: Timestamp.fromJson(object.createdAt),
        contentUpdatedAt: Timestamp.fromJson(object.contentUpdatedAt),
        scheduledAt: Timestamp.fromJson(object.scheduledAt),
        color: object.color,
        locationPoint: LocationPoint.LocationPointMessage.fromObject(object.locationPoint),
        locationName: object.locationName,
        top: object.top,
        left: object.left,
        width: object.width,
        sortIndex: object.sortIndex,
        wallSectionId: object.wallSectionId,
        rank: object.rank,
        label: object.label,
        attachmentCaption: object.attachmentCaption,
        wishContent: WishContent.WishContentMessage.fromObject(object.wishContent),
        customProperties: Object.fromEntries(Object.entries(object.customProperties).map(([k, v]) => [k, Wish.CustomProperty.CustomPropertyMessage.fromObject(v)])),
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): Wish {
    return WishMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: Wish): Uint8Array {
    return WishMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.Wish
 */
export type Wish = {
  /**
   * @generated from field: int64 id = 1;
   */
  id: bigint;
  /**
   * @generated from field: int64 wall_id = 2;
   */
  wallId: bigint;
  /**
   * @generated from field: bool published = 3;
   */
  published: boolean;
  /**
   * @generated from field: int64 row_id = 4;
   */
  rowId: bigint;
  /**
   * @generated from field: int64 col_id = 5;
   */
  colId: bigint;
  /**
   * @generated from field: int64 row_span = 6;
   */
  rowSpan: bigint;
  /**
   * @generated from field: int64 col_span = 7;
   */
  colSpan: bigint;
  /**
   * @generated from field: bool is_content_hidden = 8;
   */
  isContentHidden: boolean;
  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 9;
   */
  updatedAt: string;
  /**
   * @generated from field: string headline = 10;
   */
  headline: string;
  /**
   * @generated from field: string subject = 11;
   */
  subject: string;
  /**
   * @generated from field: string body = 12;
   */
  body: string;
  /**
   * @generated from field: string attachment_url = 13;
   */
  attachmentUrl: string;
  /**
   * @generated from field: string permalink = 14;
   */
  permalink: string;
  /**
   * @generated from field: int64 author_id = 15;
   */
  authorId: bigint;
  /**
   * @generated from field: google.protobuf.Timestamp created_at = 16;
   */
  createdAt: string;
  /**
   * @generated from field: google.protobuf.Timestamp content_updated_at = 17;
   */
  contentUpdatedAt: string;
  /**
   * @generated from field: google.protobuf.Timestamp scheduled_at = 18;
   */
  scheduledAt: string;
  /**
   * @generated from field: proto.Wish.WishColor color = 19;
   */
  color: Wish.WishColor;
  /**
   * @generated from field: proto.LocationPoint location_point = 20;
   */
  locationPoint: LocationPoint;
  /**
   * @generated from field: string location_name = 21;
   */
  locationName: string;
  /**
   * @generated from field: double top = 22;
   */
  top: number;
  /**
   * @generated from field: double left = 23;
   */
  left: number;
  /**
   * @generated from field: double width = 24;
   */
  width: number;
  /**
   * @generated from field: sint64 sort_index = 25;
   */
  sortIndex: bigint;
  /**
   * @generated from field: int64 wall_section_id = 26;
   */
  wallSectionId: bigint;
  /**
   * @generated from field: string rank = 27;
   */
  rank: string;
  /**
   * @generated from field: string label = 28;
   */
  label: string;
  /**
   * @generated from field: string attachment_caption = 29;
   */
  attachmentCaption: string;
  /**
   * @generated from field: proto.WishContent wish_content = 30;
   */
  wishContent: WishContent;
  /**
   * @generated from field: map<string, proto.Wish.CustomProperty> custom_properties = 31;
   */
  customProperties: { [key: string]: Wish.CustomProperty };
}

proto3.util.setEnumType(Wish.WishColor, "proto.Wish.WishColor", [
  { no: 0, name: "WISH_COLOR_UNSPECIFIED" },
  { no: 1, name: "WISH_COLOR_RED" },
  { no: 2, name: "WISH_COLOR_ORANGE" },
  { no: 3, name: "WISH_COLOR_GREEN" },
  { no: 4, name: "WISH_COLOR_BLUE" },
  { no: 5, name: "WISH_COLOR_PURPLE" },
]);

