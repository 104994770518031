// @file Surface container size store
import { observeSizeChange } from '@@/bits/resize_observer'
import { observeVisualViewportSize } from '@@/bits/window'
import { BreakPoints } from '@@/pinia/window_size'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSurfaceContainerSizeStore = defineStore('surfaceContainerSize', () => {
  const surfaceContainerWidth = ref(0)
  const surfaceContainerHeight = ref(0)

  const resizeSurfaceContainer = ({ width, height }): void => {
    surfaceContainerWidth.value = width
    surfaceContainerHeight.value = height
  }

  function observeSurfaceContainerSize(surfaceContainerElement: HTMLElement): void {
    observeSizeChange(surfaceContainerElement, (element, contentRect) => {
      resizeSurfaceContainer(contentRect)
    })
    observeVisualViewportSize(() => {
      // On some mobile browsers, e.g. Google Chrome on iOS, when the window resize event is fired on device orientation change,
      // the new width / height is not available in the resize event yet
      // Reference: - https://stackoverflow.com/questions/65648544/clientwidth-and-innerwidth-not-updating-on-ios-chrome-after-orientationchange
      //            - https://stackoverflow.com/questions/12452349/mobile-viewport-height-after-orientation-change
      // We should also check for visual viewport change, this would ensure the window has been resized
      resizeSurfaceContainer(surfaceContainerElement.getBoundingClientRect())
    })
  }

  // BREAKPOINTS
  // If you are looking for something like `isContainerSmallerThanOrEqualTabletLandscape`, negate `isContainerBiggerThanTabletLandscape` instead.
  const containerBiggerThanBreakpoints = {
    isContainerBiggerThanPhone: computed(() => surfaceContainerWidth.value > BreakPoints.Phone),
    isContainerBiggerThanTabletPortrait: computed(() => surfaceContainerWidth.value > BreakPoints.TabletPortrait),
    isContainerBiggerThanTabletLandscape: computed(() => surfaceContainerWidth.value > BreakPoints.TabletLandscape),
    isContainerBiggerThanDesktop: computed(() => surfaceContainerWidth.value > BreakPoints.Desktop),
    isContainerBiggerThanDesktopBig: computed(() => surfaceContainerWidth.value > BreakPoints.DesktopBig),
  }
  const containerSmallerThanBreakpoints = {
    isContainerSmallerThanTabletPortrait: computed(() => surfaceContainerWidth.value < BreakPoints.TabletPortrait),
    isContainerSmallerThanTabletLandscape: computed(() => surfaceContainerWidth.value < BreakPoints.TabletLandscape),
    isContainerSmallerThanDesktop: computed(() => surfaceContainerWidth.value < BreakPoints.Desktop),
    isContainerSmallerThanDesktopBig: computed(() => surfaceContainerWidth.value < BreakPoints.DesktopBig),
    isContainerSmallerThanDesktop2XL: computed(() => surfaceContainerWidth.value < BreakPoints.Desktop2XL),
    isContainerSmallerThanDesktop3XL: computed(() => surfaceContainerWidth.value < BreakPoints.Desktop3XL),
  }

  const isPhone = computed(() => surfaceContainerWidth.value < 768)
  const isSmallPhone = computed(() => surfaceContainerWidth.value <= 375)
  const isLandscapePhone = computed(() => {
    return (
      surfaceContainerWidth.value > surfaceContainerHeight.value &&
      surfaceContainerHeight.value < BreakPoints.TabletPortrait
    )
  })

  const isLandscapeTablet = computed(() => {
    return (
      surfaceContainerWidth.value > surfaceContainerHeight.value &&
      surfaceContainerHeight.value < BreakPoints.Desktop &&
      surfaceContainerHeight.value >= BreakPoints.TabletPortrait
    )
  })

  return {
    surfaceContainerWidth,
    surfaceContainerHeight,
    resizeSurfaceContainer,
    observeSurfaceContainerSize,
    ...containerBiggerThanBreakpoints,
    ...containerSmallerThanBreakpoints,
    isPhone,
    isSmallPhone,
    isLandscapePhone,
    isLandscapeTablet,
  }
})
