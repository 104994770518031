var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.OzBasePopover,{class:[
    // Shape
    'px-3 py-2',

    // Functionality
    'pointer-events-none',

    // Appearance
    'text-body-small',
    'shadow-elevation-1',

    _vm.colorScheme === _setup.ColorScheme.Base && [
      {
        'text-light-text-100 ': _vm.darkMode === false,
        'text-dark-text-100': _vm.darkMode === true,
        'text-light-text-100 dark:text-dark-text-100': _vm.darkMode === 'auto',
      },
      {
        'bg-common-ui-d70 ': _vm.darkMode === false,
        'bg-common-ui-l86': _vm.darkMode === true,
        'bg-common-ui-d70 dark:bg-common-ui-l86': _vm.darkMode === 'auto',
      },
      'backdrop-blur-6',
    ],
  ],attrs:{"role":"tooltip","anchor":_vm.anchor,"config":_vm.config,"dark-mode":_vm.darkMode,"radius":8,"color":_setup.OzBasePopoverColors.None}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }