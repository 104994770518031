// @file fetch user with json api
import { jsonApiFetch, JsonAPIResponse } from './json_api_fetch'
import { User } from './types'

/**
 * Fetch a User object by its ID in json api format
 * Currently only for user contributor data on a wall
 * @param id User Id
 * @returns A Promise that resolves to a [[JsonAPIResponse<User>]].
 *
 */
export default function fetchUser(id: number | string): Promise<JsonAPIResponse<User>> {
  return jsonApiFetch<User>(`users/${id}`)
}
