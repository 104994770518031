import prettyFileSize from 'filesize'
import {
  isStatic,
  isFile,
  isAudio,
  isVideo,
  isDocument,
  isPdf,
  isWordDocument,
  isPowerpointPresentation,
  isExcelSpreadsheet,
  isCode,
} from './link_helpers'
import { Link } from './types'

function humanizeDuration(duration: number): string {
  const hoursNum = Math.floor(duration / 3600)
  const minutesNum = Math.floor((duration % 3600) / 60)
  const secondsNum = duration % 60
  const hoursStr = hoursNum < 10 ? `0${hoursNum}` : hoursNum
  const minutesStr = minutesNum < 10 ? `0${minutesNum}` : minutesNum
  const secondsStr = secondsNum < 10 ? `0${secondsNum}` : secondsNum
  return hoursNum > 0 ? [hoursStr, minutesStr, secondsStr].join(':') : [minutesStr, secondsStr].join(':')
}

function makeDescription(link: Link): string {
  let desc = ''
  if (isFile(link)) {
    const size = link.size
    const prettySize = prettyFileSize(size || 0, { round: 0 })
    const extension = link.extension
    desc = `${prettySize} ${extension} file`
  } else if (isStatic(link)) {
    if (isAudio(link) || isVideo(link)) {
      // @ts-ignore
      if (link.metadata && link.metadata.duration) {
        // @ts-ignore
        const duration = humanizeDuration(link.metadata.duration)
        if (isAudio(link)) {
          desc = `${duration} audio`
        } else {
          desc = `${duration} video`
        }
      }
    } else if (isDocument(link)) {
      if (isCode(link)) {
        const codeType = (link.extension || '').toUpperCase()
        desc = `${codeType} code`
      } else if (isPdf(link)) {
        desc = 'PDF document'
      } else if (isWordDocument(link)) {
        desc = 'Word document'
      } else if (isPowerpointPresentation(link)) {
        desc = 'Powerpoint presentation'
      } else if (isExcelSpreadsheet(link)) {
        desc = 'Excel spreadsheet'
      } else {
        const docType = (link.extension || '').toUpperCase()
        desc = `${docType} document`
      }
    }
  } else if (link.description) {
    desc = link.description
  } else if (link.authors && link.authors[0] && link.authors[0].name) {
    desc = `by ${link.authors[0].name}`
  }
  return desc
}

export default makeDescription
