var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.href ? 'a' : 'button',{ref:"root",tag:"component",class:[
    _vm.href
      ? [
          // Reset <a>
          'no-underline',
        ]
      : [
          // Reset <button>
          'select-none',
          'border-none',
          // Let the parent component reset/override the default user agent padding and background.
          // We will run into specifity issues if we reset them here.
        ],
    // Remove the styles that appear when tapping on button/links on mobile devices.
    'tap-highlight-transparent',
    // Cursor
    !_vm.disabled && 'cursor-pointer',
    _vm.disabled && 'cursor-not-allowed',
    // Override kit.scss styles
    'font-sans',
  ],attrs:{"aria-disabled":_vm.disabled,"href":_vm.href,"rel":_vm.href && 'noopener nofollow'},on:{"mouseenter":function($event){return _vm.$emit('mouseenter', $event)},"mouseleave":function($event){return _vm.$emit('mouseleave', $event)},"mousedown":function($event){!_vm.disabled && _vm.$emit('mousedown', $event)},"keydown":function($event){!_vm.disabled && _vm.$emit('keydown', $event)},"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"contextmenu":function($event){!_vm.disabled && _vm.$emit('contextmenu', $event)},"click":_setup.click,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _setup.handleSpaceKey.apply(null, arguments)},"long-press":function($event){!_vm.disabled && _vm.$emit('long-press', $event)},"touchstart":function($event){!_vm.disabled && _vm.$emit('touchstart', $event)},"touchend":function($event){!_vm.disabled && _vm.$emit('touchend', $event)},"pointerup":function($event){!_vm.disabled && _vm.$emit('pointerup', $event)},"pointerenter":function($event){!_vm.disabled && _vm.$emit('pointerenter', $event)},"pointerleave":function($event){!_vm.disabled && _vm.$emit('pointerleave', $event)},"pointerdown":function($event){!_vm.disabled && _vm.$emit('pointerdown', $event)},"dblclick":function($event){!_vm.disabled && _vm.$emit('dblclick', $event)},"auxclick":function($event){!_vm.disabled && _vm.$emit('auxclick', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }