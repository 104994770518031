var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'transition',
    'flex',
    'flex-1',
    'flex-row',
    'items-center',
    'box-border',
    'relative',
    _setup.backgroundClasses,
    _setup.borderClasses,
    _vm.sizePreset === _setup.OzInputSizePreset.H20px && [
      'rounded',
      'h-5',
      _setup.slots.startAdornment ? 'ps-0' : 'ps-1',
      _setup.slots.endAdornment ? 'pe-0' : 'pe-1',
    ],
    _vm.sizePreset === _setup.OzInputSizePreset.H32px && [
      'rounded-xl',
      'h-8',
      _setup.slots.startAdornment || _vm.noHorizontalPadding ? 'ps-0' : 'ps-2',
      _setup.slots.endAdornment || _vm.noHorizontalPadding ? 'pe-0' : 'pe-2',
    ],
    _vm.sizePreset === _setup.OzInputSizePreset.H40px && [
      'rounded-2xl',
      'h-10',
      _setup.slots.startAdornment || _vm.noHorizontalPadding ? 'ps-0' : 'ps-3.5',
      _setup.slots.endAdornment || _vm.noHorizontalPadding ? 'pe-0' : 'pe-2.5',
    ],
    'font-sans',
  ]},[_vm._t("startAdornment"),_vm._v(" "),((_vm.type)==='checkbox')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_setup.internalValue),expression:"internalValue"}],ref:"inputElement",class:[
      'oz-input',
      {
        '!input-autofill': _vm.darkMode === false,
        '!dark:input-autofill-dark': _vm.darkMode === true,
        '!input-autofill dark:!input-autofill-dark': _vm.darkMode === 'auto',
      },
      {
        '!placeholder-dark-text-300': _vm.darkMode === false,
        '!placeholder-light-text-300': _vm.darkMode === true,
        '!placeholder-dark-text-300 dark:!placeholder-light-text-300': _vm.darkMode === 'auto',
      },
      {
        '!text-input-body-small': _vm.sizePreset === _setup.OzInputSizePreset.H32px || _vm.sizePreset === _setup.OzInputSizePreset.H20px,
        '!text-body-posts': _vm.sizePreset === _setup.OzInputSizePreset.H40px,
      },
      {
        '!text-dark-text-100': _vm.darkMode === false,
        '!text-light-text-100': _vm.darkMode === true,
        '!text-dark-text-100 dark:!text-light-text-100': _vm.darkMode === 'auto',
      },
      _setup.hasExtraContent ? 'input-with-extra-content' : 'input-without-extra-content',
      _vm.disabled && {
        '!placeholder-dark-text-400': _vm.darkMode === false,
        '!placeholder-light-text-400': _vm.darkMode === true,
        '!placeholder-dark-text-400 dark:!placeholder-light-text-400': _vm.darkMode === 'auto',
      },
      _vm.disabled && 'pointer-events-none',
      {
        'text-start': _vm.textAlign === 'start',
        'text-end': _vm.textAlign === 'end',
        'text-center': _vm.textAlign === 'center',
      },
      _vm.type === 'datetime-local' && {
        'datetime-picker-auto': _vm.darkMode === 'auto',
        'datetime-picker-dark': _vm.darkMode === true,
        'datetime-picker-light': _vm.darkMode === false,
        // on iOS mobile, the datetime picker text is rendered in the center by default, and text-align property doesn't work on it.
        // so this is a hack to ensure that the input text is aligned based on the textAlign prop.
        'text-last-start': true,
      },
      _vm.type === 'number' && 'number-input-no-spinner',
      _vm.shouldTruncateText && 'truncate',
    ],attrs:{"data-pw":_vm.testId,"data-testid":_vm.testId,"aria-invalid":_setup.xValidationMessage,"aria-errormessage":_setup.validationMessageId,"aria-describedby":_setup.ariaDescribedbyValue,"type":"checkbox"},domProps:{"checked":Array.isArray(_setup.internalValue)?_vm._i(_setup.internalValue,null)>-1:(_setup.internalValue)},on:{"input":_setup.onInput,"focusin":_setup.focusin,"focusout":_setup.focusout,"blur":_setup.blur,"change":function($event){var $$a=_setup.internalValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_setup.internalValue=$$a.concat([$$v]))}else{$$i>-1&&(_setup.internalValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_setup.internalValue=$$c}}}},'input',_vm.$attrs,false),{ ..._vm.$listeners })):((_vm.type)==='radio')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_setup.internalValue),expression:"internalValue"}],ref:"inputElement",class:[
      'oz-input',
      {
        '!input-autofill': _vm.darkMode === false,
        '!dark:input-autofill-dark': _vm.darkMode === true,
        '!input-autofill dark:!input-autofill-dark': _vm.darkMode === 'auto',
      },
      {
        '!placeholder-dark-text-300': _vm.darkMode === false,
        '!placeholder-light-text-300': _vm.darkMode === true,
        '!placeholder-dark-text-300 dark:!placeholder-light-text-300': _vm.darkMode === 'auto',
      },
      {
        '!text-input-body-small': _vm.sizePreset === _setup.OzInputSizePreset.H32px || _vm.sizePreset === _setup.OzInputSizePreset.H20px,
        '!text-body-posts': _vm.sizePreset === _setup.OzInputSizePreset.H40px,
      },
      {
        '!text-dark-text-100': _vm.darkMode === false,
        '!text-light-text-100': _vm.darkMode === true,
        '!text-dark-text-100 dark:!text-light-text-100': _vm.darkMode === 'auto',
      },
      _setup.hasExtraContent ? 'input-with-extra-content' : 'input-without-extra-content',
      _vm.disabled && {
        '!placeholder-dark-text-400': _vm.darkMode === false,
        '!placeholder-light-text-400': _vm.darkMode === true,
        '!placeholder-dark-text-400 dark:!placeholder-light-text-400': _vm.darkMode === 'auto',
      },
      _vm.disabled && 'pointer-events-none',
      {
        'text-start': _vm.textAlign === 'start',
        'text-end': _vm.textAlign === 'end',
        'text-center': _vm.textAlign === 'center',
      },
      _vm.type === 'datetime-local' && {
        'datetime-picker-auto': _vm.darkMode === 'auto',
        'datetime-picker-dark': _vm.darkMode === true,
        'datetime-picker-light': _vm.darkMode === false,
        // on iOS mobile, the datetime picker text is rendered in the center by default, and text-align property doesn't work on it.
        // so this is a hack to ensure that the input text is aligned based on the textAlign prop.
        'text-last-start': true,
      },
      _vm.type === 'number' && 'number-input-no-spinner',
      _vm.shouldTruncateText && 'truncate',
    ],attrs:{"data-pw":_vm.testId,"data-testid":_vm.testId,"aria-invalid":_setup.xValidationMessage,"aria-errormessage":_setup.validationMessageId,"aria-describedby":_setup.ariaDescribedbyValue,"type":"radio"},domProps:{"checked":_vm._q(_setup.internalValue,null)},on:{"input":_setup.onInput,"focusin":_setup.focusin,"focusout":_setup.focusout,"blur":_setup.blur,"change":function($event){_setup.internalValue=null}}},'input',_vm.$attrs,false),{ ..._vm.$listeners })):_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_setup.internalValue),expression:"internalValue"}],ref:"inputElement",class:[
      'oz-input',
      {
        '!input-autofill': _vm.darkMode === false,
        '!dark:input-autofill-dark': _vm.darkMode === true,
        '!input-autofill dark:!input-autofill-dark': _vm.darkMode === 'auto',
      },
      {
        '!placeholder-dark-text-300': _vm.darkMode === false,
        '!placeholder-light-text-300': _vm.darkMode === true,
        '!placeholder-dark-text-300 dark:!placeholder-light-text-300': _vm.darkMode === 'auto',
      },
      {
        '!text-input-body-small': _vm.sizePreset === _setup.OzInputSizePreset.H32px || _vm.sizePreset === _setup.OzInputSizePreset.H20px,
        '!text-body-posts': _vm.sizePreset === _setup.OzInputSizePreset.H40px,
      },
      {
        '!text-dark-text-100': _vm.darkMode === false,
        '!text-light-text-100': _vm.darkMode === true,
        '!text-dark-text-100 dark:!text-light-text-100': _vm.darkMode === 'auto',
      },
      _setup.hasExtraContent ? 'input-with-extra-content' : 'input-without-extra-content',
      _vm.disabled && {
        '!placeholder-dark-text-400': _vm.darkMode === false,
        '!placeholder-light-text-400': _vm.darkMode === true,
        '!placeholder-dark-text-400 dark:!placeholder-light-text-400': _vm.darkMode === 'auto',
      },
      _vm.disabled && 'pointer-events-none',
      {
        'text-start': _vm.textAlign === 'start',
        'text-end': _vm.textAlign === 'end',
        'text-center': _vm.textAlign === 'center',
      },
      _vm.type === 'datetime-local' && {
        'datetime-picker-auto': _vm.darkMode === 'auto',
        'datetime-picker-dark': _vm.darkMode === true,
        'datetime-picker-light': _vm.darkMode === false,
        // on iOS mobile, the datetime picker text is rendered in the center by default, and text-align property doesn't work on it.
        // so this is a hack to ensure that the input text is aligned based on the textAlign prop.
        'text-last-start': true,
      },
      _vm.type === 'number' && 'number-input-no-spinner',
      _vm.shouldTruncateText && 'truncate',
    ],attrs:{"data-pw":_vm.testId,"data-testid":_vm.testId,"aria-invalid":_setup.xValidationMessage,"aria-errormessage":_setup.validationMessageId,"aria-describedby":_setup.ariaDescribedbyValue,"type":_vm.type},domProps:{"value":(_setup.internalValue)},on:{"input":[function($event){if($event.target.composing)return;_setup.internalValue=$event.target.value},_setup.onInput],"focusin":_setup.focusin,"focusout":_setup.focusout,"blur":_setup.blur}},'input',_vm.$attrs,false),{ ..._vm.$listeners })),_vm._v(" "),_vm._t("endAdornment"),_vm._v(" "),_c('span',{class:[
      'absolute',
      'end-2',
      '-bottom-2',
      'transition',
      'text-12-16',
      'text-danger-100',
      'font-semibold',
      'truncate',
      'max-w-[calc(100%-1.5rem)]',
      'px-1',
      {
        'bg-light-ui-100': _vm.darkMode === false,
        'bg-dark-ui-100': _vm.darkMode === true,
        'bg-light-ui-100 dark:bg-dark-ui-100': _vm.darkMode === 'auto',
      },
    ],attrs:{"id":_setup.validationMessageId,"aria-live":"polite","data-testid":"validationMessage"}},[_vm._v(_vm._s(_setup.xValidationMessage ? _vm.validationMessage : null))])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }