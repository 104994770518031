// @file Entry point for the Beethoven client library
import { Link } from '@padlet/arvo'
import pixelRatio from '@padlet/pixel-ratio'

import blankImageUrl from './blank_image'
import { buildDisplayAttributesFromLink } from './display'
import * as LinkHelpers from './link_helpers'
import { BeethovenDisplayAttributes, BeethovenDisplayOptions, BeethovenDisplayQuery } from './types'

const cache: Record<string, BeethovenDisplayAttributes> = {}

function fetchFromCache(query: BeethovenDisplayQuery): BeethovenDisplayAttributes | null {
  const cachedAttrs: BeethovenDisplayAttributes = cache[JSON.stringify(query)]
  if (!cachedAttrs) {
    return null
  }
  return cachedAttrs
}

function addToCache(query: BeethovenDisplayQuery, attrs: BeethovenDisplayAttributes): void {
  cache[JSON.stringify(query)] = attrs
}

function getDisplayAttributes(link: Link, options: BeethovenDisplayOptions): BeethovenDisplayAttributes {
  const query: BeethovenDisplayQuery = Object.assign(
    { url: link.url, width: 500, locale: 'en', dpr: pixelRatio },
    options,
  )
  let displayAttrs: BeethovenDisplayAttributes | null = fetchFromCache(query)
  if (!displayAttrs) {
    displayAttrs = buildDisplayAttributesFromLink(link, query)
    addToCache(query, displayAttrs)
  }
  return displayAttrs
}

export type { BeethovenDisplayAttributes, BeethovenDisplayOptions }
export { LinkHelpers, blankImageUrl, getDisplayAttributes }
