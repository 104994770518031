var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['font-sans'],attrs:{"role":_vm.accessibilityRole}},[_vm._l((_setup.rowsData),function(row,index){return [_c('div',{key:`${row.icon}-${index}`,ref:(el) => _setup.setRowElRef(index, el),refInFor:true,class:[
        // Always set `display` to `block` to avoid overrides
        'block',

        // This is the space between the hover box and the edge of the row
        'py-1',
        'px-2',
      ],attrs:{"data-testid":row.testId,"data-pw":row.testId,"data-pepin-id":row.analyticsId,"data-pepin-trigger":"click","role":"presentation"}},[_c(row.href ? 'a' : 'div',_vm._b({tag:"component",class:[
          'group',
          // Wanted to use the resets that's already on `<OzBaseButton>`, but can't use a `<button>` element here
          // because we use a `<button>` for the `rightIcon` and nested `<button>`s are invalid.
          'focus-visible:outline-none',

          row.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
          // TEXT COLORS
          row.rowStyle === _setup.OzMenuRowsRowStyle.Default && {
            'text-dark-text-100': _vm.darkMode === false,
            'text-light-text-100': _vm.darkMode === true,
            'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
          },
          row.rowStyle === _setup.OzMenuRowsRowStyle.Disabled &&
            _vm.sizePreset === _setup.OzMenuRowsSizePreset.H44px && {
              'text-dark-text-300': _vm.darkMode === false,
              'text-light-text-300': _vm.darkMode === true,
              'text-dark-text-300 dark:text-light-text-300': _vm.darkMode === 'auto',
            },
          row.rowStyle === _setup.OzMenuRowsRowStyle.Disabled &&
            _vm.sizePreset === _setup.OzMenuRowsSizePreset.H48px && {
              'text-dark-text-400': _vm.darkMode === false,
              'text-light-text-400': _vm.darkMode === true,
              'text-dark-text-400 dark:text-light-text-400': _vm.darkMode === 'auto',
            },
          row.rowStyle === _setup.OzMenuRowsRowStyle.Highlighted && {
            'font-semibold': true,
            'text-grape-500': _vm.darkMode === false,
            'text-canary-500': _vm.darkMode === true,
            'text-grape-500 dark:text-canary-500': _vm.darkMode === 'auto',
          },
          row.rowStyle === _setup.OzMenuRowsRowStyle.Primary && 'text-padlet-pink-500',
          row.rowStyle === _setup.OzMenuRowsRowStyle.Secondary && {
            'text-dark-text-200': _vm.darkMode === false,
            'text-light-text-200': _vm.darkMode === true,
            'text-dark-text-200 dark:text-light-text-200': _vm.darkMode === 'auto',
          },
          row.rowStyle === _setup.OzMenuRowsRowStyle.Danger && 'text-danger-100',
          row.rowStyle === _setup.OzMenuRowsRowStyle.Focused && [
            'ring-2',
            {
              'ring-grape-500': _vm.darkMode === false,
              'ring-canary-500': _vm.darkMode === true,
              'ring-grape-500 dark:ring-canary-500': _vm.darkMode === 'auto',
            },
          ],
          // SHAPE
          'rounded-xl',
          // Combined with the space outside the hover box, the row has 16px of x-padding.
          'p-2',
          // 44px - 2*4px (padding outside hover box) - 2*8px (padding outside hover box) = 20px
          _vm.sizePreset === _setup.OzMenuRowsSizePreset.H44px && 'min-h-[20px]',
          // 48px - 2*4px (padding outside hover box) - 2*8px (padding outside hover box) = 24px
          _vm.sizePreset === _setup.OzMenuRowsSizePreset.H48px && 'min-h-[24px]',
          // 56px - 2*4px (padding outside hover box) - 2*8px (padding outside hover box) = 32px
          _vm.sizePreset === _setup.OzMenuRowsSizePreset.H56px && 'min-h-[32px]',

          // HIGHLIGHT STYLES
          !row.isDisabled && [
            'transition-colors',
            _vm.focusStyle === _setup.OzMenuRowsFocusStyle.Bubble100 && [
              {
                'hover-hover:hover:bg-light-ui-100': _vm.darkMode === false,
                'hover-hover:hover:bg-dark-ui-100': _vm.darkMode === true,
                'hover-hover:hover:bg-light-ui-100 hover-hover:dark:hover:bg-dark-ui-100': _vm.darkMode === 'auto',
              },
              {
                'active:bg-light-ui-100': _vm.darkMode === false,
                'active:bg-dark-ui-100': _vm.darkMode === true,
                'active:bg-light-ui-100 dark:active:bg-dark-ui-100': _vm.darkMode === 'auto',
              },
            ],
            _vm.focusStyle === _setup.OzMenuRowsFocusStyle.Bubble200 && [
              {
                'hover-hover:hover:bg-light-ui-200': _vm.darkMode === false,
                'hover-hover:hover:bg-dark-ui-200': _vm.darkMode === true,
                'hover-hover:hover:bg-light-ui-200 hover-hover:dark:hover:bg-dark-ui-200': _vm.darkMode === 'auto',
              },
              {
                'active:bg-light-ui-200': _vm.darkMode === false,
                'active:bg-dark-ui-200': _vm.darkMode === true,
                'active:bg-light-ui-200 dark:active:bg-dark-ui-200': _vm.darkMode === 'auto',
              },
            ],
            _vm.focusStyle === _setup.OzMenuRowsFocusStyle.Grey100 && [
              {
                'hover-hover:hover:bg-grey-100': _vm.darkMode === false,
                'hover-hover:dark:hover:bg-grey-700': _vm.darkMode === true,
                'hover-hover:hover:bg-grey-100 hover-hover:dark:hover:bg-grey-700': _vm.darkMode === 'auto',
              },
              {
                'active:bg-grey-100': _vm.darkMode === false,
                'active:bg-grey-700': _vm.darkMode === true,
                'active:bg-grey-100 dark:active:bg-grey-700': _vm.darkMode === 'auto',
              },
            ],
            _vm.focusStyle === _setup.OzMenuRowsFocusStyle.ButtonDefault && [
              {
                'hover-hover:hover:bg-button-light': _vm.darkMode === false,
                'hover-hover:hover:bg-button-dark': _vm.darkMode === true,
                'hover-hover:hover:bg-button-light hover-hover:dark:hover:bg-button-dark': _vm.darkMode === 'auto',
              },
              {
                'active:bg-button-light': _vm.darkMode === false,
                'active:bg-button-dark': _vm.darkMode === true,
                'active:bg-button-light dark:active:bg-button-dark': _vm.darkMode === 'auto',
              },
            ],
            _vm.focusStyle === _setup.OzMenuRowsFocusStyle.HighlightText &&
              row.rowStyle === _setup.OzMenuRowsRowStyle.Default &&
              _setup.textHighlightStyles,
          ],

          // FOCUS STYLES
          _setup.shouldShowRowFocusRing(row) && [
            'focus-visible:ring-2',
            {
              'focus-visible:ring-grape-500': _vm.darkMode === false,
              'focus-visible:ring-canary-500': _vm.darkMode === true,
              'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500': _vm.darkMode === 'auto',
            },
          ],

          // INTERNAL LAYOUT
          'flex',
          'items-center',
          row.rowClasses,
        ],attrs:{"tabindex":0,"data-testid":`${row.testId}Button`,"aria-disabled":row.isDisabled,"href":row.href || null,"rel":row.href && 'noopener nofollow'},on:{"click":function($event){$event.preventDefault();!row.isDisabled && _setup.selectRow($event, row)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();!row.isDisabled && _setup.selectRow($event, row)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();!row.isDisabled && _setup.selectRow($event, row)}],"mouseenter":function($event){!row.isDisabled && _vm.$emit('mouseenter-row', $event, row)},"mouseleave":function($event){!row.isDisabled && _vm.$emit('mouseleave-row', $event, row)},"focus":function($event){!row.isDisabled && _vm.$emit('focus-row', $event, row)},"blur":function($event){!row.isDisabled && _vm.$emit('blur-row', $event, row)}}},'component',_setup.getAccessibilityAttrs(row),false),[(_setup.xLeftIcon && row.icon)?_c(_setup.OzIcon,{class:[
            {
              'me-3': _vm.sizePreset === _setup.OzMenuRowsSizePreset.H44px,
              'me-4': _vm.sizePreset === _setup.OzMenuRowsSizePreset.H48px || _vm.sizePreset === _setup.OzMenuRowsSizePreset.H56px,
            },
            !row.isDisabled &&
              [
                _setup.OzMenuRowsFocusStyle.Bubble100,
                _setup.OzMenuRowsFocusStyle.Bubble200,
                _setup.OzMenuRowsFocusStyle.ButtonDefault,
              ].includes(_vm.focusStyle) &&
              row.rowStyle === _setup.OzMenuRowsRowStyle.Default &&
              _setup.textHighlightStyles,
            row.leftIconClasses,
          ],attrs:{"name":row.icon,"size":row.iconSize || _setup.iconSize}}):(_setup.slots.startAdornment)?_vm._t("startAdornment",null,{"row":row,"iconSize":_setup.iconSize}):_vm._e(),_vm._v(" "),_c('div',{class:[
            row.textTruncated && 'truncate',
            'flex-1',
            'min-w-0',
            row.textStyleClass || {
              'text-body-small': _vm.sizePreset === _setup.OzMenuRowsSizePreset.H44px,
              'text-body': _vm.sizePreset === _setup.OzMenuRowsSizePreset.H48px,
            },
            // Active styles
            !row.isDisabled && {
              'group-active:text-grape-500': _vm.darkMode === false,
              'group-active:text-canary-500': _vm.darkMode === true,
              'group-active:text-grape-500 dark:group-active:text-canary-500': _vm.darkMode === 'auto',
            },
            !_setup.xLeftIcon && 'ps-0.5',
          ],attrs:{"title":row.text}},[(row.htmlText)?_c('span',{domProps:{"innerHTML":_vm._s(row.htmlText)}}):_c('span',[_vm._v(_vm._s(row.text))])]),_vm._v(" "),(row.ariaDescription)?_c('span',{staticClass:"sr-only",attrs:{"id":row.ariaDescribedby}},[_vm._v(_vm._s(row.ariaDescription))]):_vm._e(),_vm._v(" "),(row.rightIcon && row.rightLabel)?_c('div',{class:[
            'flex items-center',
            'rounded-lg',
            'self-stretch',
            'px-1 -me-1', // need padding on both sides for displaying border
            // FOCUS STYLES
            'group-focus-visible:ring-2',
            {
              'group-focus-visible:ring-grape-500': _vm.darkMode === false,
              'group-focus-visible:ring-canary-500': _vm.darkMode === true,
              'group-focus-visible:ring-grape-500 dark:group-focus-visible:ring-canary-500': _vm.darkMode === 'auto',
            },
          ]},[_c('span',{staticClass:"text-body-small"},[_vm._v(_vm._s(row.rightLabel))]),_vm._v(" "),(row.rightIcon)?_c(_setup.OzIcon,{class:[
              'ms-2',
              !row.isDisabled &&
                row.rightIconHighlighted && {
                  'text-grape-500': _vm.darkMode === false,
                  'text-canary-500': _vm.darkMode === true,
                  'text-grape-500 dark:text-canary-500': _vm.darkMode === 'auto',
                },
              row.isDisabled &&
                row.rightIconHighlighted && {
                  'text-modal-overlay-grape': _vm.darkMode === false,
                  'text-modal-overlay-canary': _vm.darkMode === true,
                  'text-modal-overlay-grape dark:text-modal-overlay-canary': _vm.darkMode === 'auto',
                },
              !row.isDisabled &&
                !row.rightIconHighlighted &&
                row.rowStyle === _setup.OzMenuRowsRowStyle.Default && {
                  'text-dark-text-200 hover-hover:group-hover:text-dark-text-100': _vm.darkMode === false,
                  'text-light-text-200 hover-hover:group-hover:text-light-text-100': _vm.darkMode === true,
                  'text-dark-text-200 dark:text-light-text-200 hover-hover:group-hover:text-dark-text-100 hover-hover:dark:group-hover:text-light-text-100':
                    _vm.darkMode === 'auto',
                },
              !row.isDisabled &&
                _vm.focusStyle === _setup.OzMenuRowsFocusStyle.HighlightText &&
                row.rowStyle === _setup.OzMenuRowsRowStyle.Default &&
                _setup.textHighlightStyles,
            ],attrs:{"name":row.rightIcon,"size":row.rightIconSize || _setup.iconSize,"aria-label":row.rightIconAriaLabel}}):_vm._e()],1):(row.rightIcon)?_c(_setup.OzIcon,{class:[
            'ms-2',
            !row.isDisabled &&
              row.rightIconHighlighted && {
                'text-grape-500': _vm.darkMode === false,
                'text-canary-500': _vm.darkMode === true,
                'text-grape-500 dark:text-canary-500': _vm.darkMode === 'auto',
              },
            row.isDisabled &&
              row.rightIconHighlighted && {
                'text-modal-overlay-grape': _vm.darkMode === false,
                'text-modal-overlay-canary': _vm.darkMode === true,
                'text-modal-overlay-grape dark:text-modal-overlay-canary': _vm.darkMode === 'auto',
              },
            !row.isDisabled &&
              !row.rightIconHighlighted &&
              row.rowStyle === _setup.OzMenuRowsRowStyle.Default && {
                'text-dark-text-200 hover-hover:group-hover:text-dark-text-100': _vm.darkMode === false,
                'text-light-text-200 hover-hover:group-hover:text-light-text-100': _vm.darkMode === true,
                'text-dark-text-200 dark:text-light-text-200 hover-hover:group-hover:text-dark-text-100 hover-hover:dark:group-hover:text-light-text-100':
                  _vm.darkMode === 'auto',
              },
            !row.isDisabled &&
              _vm.focusStyle === _setup.OzMenuRowsFocusStyle.HighlightText &&
              row.rowStyle === _setup.OzMenuRowsRowStyle.Default &&
              _setup.textHighlightStyles,
          ],attrs:{"data-testid":`${row.testId}RightIcon`,"name":row.rightIcon,"size":row.rightIconSize || _setup.iconSize,"aria-label":row.rightIconAriaLabel}}):(row.rightLabel)?_c('span',{class:[
            'text-body-small',
            'me-1',
            {
              'text-dark-text-200': _vm.darkMode === false,
              'text-light-text-200': _vm.darkMode === true,
              'text-dark-text-200 dark:text-light-text-200': _vm.darkMode === 'auto',
            },
          ]},[_vm._v(_vm._s(row.rightLabel))]):_vm._e()],2)],1),_vm._v(" "),(_vm.xLastRowDivider || index < _vm.rows.length - 1)?_c(_setup.OzDivider,{key:`${row.icon}-${index}-divider`,class:[
        !_setup.xLeftIcon && !_setup.slots.startAdornment && 'mx-4',
        // 16px (left of icon) + 16px (icon) + 10px (right of icon) = ms-10.5
        (_setup.xLeftIcon || _setup.slots.startAdornment) && _vm.sizePreset === _setup.OzMenuRowsSizePreset.H44px && 'ms-10.5 me-4',
        // 16px (left of icon) + 24px (icon) + 14px (right of icon) = ms-13.5
        (_setup.xLeftIcon || _setup.slots.startAdornment) && _vm.sizePreset === _setup.OzMenuRowsSizePreset.H48px && 'ms-13.5 me-4',
        // 16px (left of icon) + 24px (icon) + 14px (right of icon) = ms-13.5
        (_setup.xLeftIcon || _setup.slots.startAdornment) && _vm.sizePreset === _setup.OzMenuRowsSizePreset.H56px && 'ms-13.5 me-2',
      ],attrs:{"color-scheme":_setup.OzDividerColorScheme.Solid,"dark-mode":_vm.darkMode}}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }