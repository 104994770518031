// @file fetch link with json api
import { Billing } from './types'
import { jsonApiFetch, JsonAPIResponse } from './json_api_fetch'

/**
 * Fetch a link object and response in Json API format
 * @param url url to fetch
 */
export default function fetchBilling(): Promise<JsonAPIResponse<Billing>> {
  return jsonApiFetch<Billing>('billing')
}
