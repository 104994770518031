import { Wall } from './types'
import { wall as wallResource } from './resource'

/**
 * Save a Wall.
 * @param Wall Wall with updated attributes to save.
 * @param options Options that might be needed, like an OAuth token, for the operation to succeed.
 * @returns A Promise that resolves to the Wall that our API returns.
 */
export default async function saveWall (wall: Wall): Promise<Wall> {
  return await wallResource().update(wall) as Wall
}
