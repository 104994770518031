/**
 * @file Vue-related helpers
 */
import { initializeAuthenticationChannel } from '@@/bits/auth_broadcast_channel'
import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'
import { DOMContentLoadedPromise } from '@@/bits/dom'
import environment from '@@/bits/environment'
import * as errorTracker from '@@/bits/error_tracker'
import fixMobileViewport from '@@/bits/fix_mobile_viewport'
import { isDebugMode } from '@@/bits/flip'
import window from '@@/bits/global'
import { np__, N__, n__, p__, __ } from '@@/bits/intl'
import { reload } from '@@/bits/location'
import PiniaLogger, { shouldLog } from '@@/pinia/plugin/logger'
import type { Id } from '@@/types'
import { configure as configureArvo } from '@padlet/arvo'
import { MENTION_ELEMENT_TAG_NAME } from '@padlet/universal-post-editor'
import { isEqual, isObject } from 'lodash-es'
import { createPinia, PiniaVuePlugin, type Pinia as PiniaStore } from 'pinia'
import Vue, {
  del as vDel,
  set as vSet,
  watch,
  watchEffect,
  type Component,
  type CreateElement,
  type VNode,
  type VueConstructor,
} from 'vue'
import {
  Component as VuePropertyDecoratorComponent,
  Emit as VuePropertyDecoratorEmit,
  Mixins as VuePropertyDecoratorMixins,
  Model as VuePropertyDecoratorModel,
  Prop as VuePropertyDecoratorProp,
  Vue as VuePropertyDecoratorVue,
  Watch as VuePropertyDecoratorWatch,
} from 'vue-property-decorator'
import type {
  WatchCallback,
  WatchEffect,
  WatchOptions,
  WatchOptionsBase,
  WatchSource,
  WatchStopHandle,
} from 'vue/types/v3-generated'
import Vuex, { type Store as VuexStore, type StoreOptions as VuexStoreOptions } from 'vuex'
import {
  Action as VuexClassAction,
  Getter as VuexClassGetter,
  namespace as VuexClassNamespace,
  State as VuexClassState,
} from 'vuex-class'

// Indicates errors that can be potentially fixed by reloading the page.
export class PageReloadableError extends Error {}

declare module 'vue/types/vue' {
  interface Vue {
    __: (phrase: string, args?: object) => string
    n__: (singularPhrase: string, pluralPhrase: string, num: number, args: object) => string
    p__: (context: string, phrase: string, args?: object) => string
    np__: (context: string, singularPhrase: string, pluralPhrase: string, num: number, args: object) => string
    N__: (phrase: string) => string
  }
}

interface VueAppOptions {
  // Element to mount on
  el?: string

  // Component config
  rootComponent: Component
  rootComponentProps?: () => Record<string, any>
  loadPlugins?: (app: VueConstructor) => Promise<void>
  created?: () => void // initialize store
  mounted?: () => void
  beforeMountPromises?: Array<Promise<any>>
  otherVueOptions?: Record<string, any>

  // Store
  store?: VuexStore<any>
  storeOptions?: VuexStoreOptions<any>
  initializeStore?: (store: VuexStore<any>) => void
  usePinia?: boolean
  /**
   * Use to initialize Pinia store. Use this to setup pinia store with starting data (e.g. from `window`)
   * @param store
   * @returns
   */
  initializePiniaStore?: (store: PiniaStore) => void

  // Error-tracking
  environment?: string
  locale?: string
  release?: string

  // Others
  disableAccessibilityChecks?: boolean
  useVueQuery?: boolean

  // Authentication broadcast channel
  // If they are not provided, the default behavior is to reload the page.
  onGlobalLogin?: () => void | Promise<void>
  onGlobalLogout?: () => void | Promise<void>
}

// Temporary no-op function. Will be replaced when we move to Vue3.
// Context: https://v3-migration.vuejs.org/breaking-changes/async-components.html
export const defineAsyncComponent = (component: any): any => component

export async function setupVueApp(options: VueAppOptions): Promise<Vue | undefined> {
  require('@@/styles/tailwind.css?inline')
  const app = Vue

  if (window?.ww?.vueStartingState?.arvoConfig != null) {
    void configureArvo(window.ww.vueStartingState.arvoConfig)
  }
  setupErrorTracking(app, options)
  setupIntl(app)
  await setupPlugins(app, options)

  if (options.useVueQuery === true) {
    const queryModules = await Promise.all([import('@tanstack/vue-query'), import('@@/bits/query_client')])
    const vueQueryModule = queryModules[0]
    const queryClientModule = queryModules[1]
    const queryClient = await queryClientModule.createQueryClient()

    app.use(vueQueryModule.VueQueryPlugin, { queryClient })
    window.queryClient = queryClient
  }

  const vueInstance = await Promise.all([DOMContentLoadedPromise, ...(options.beforeMountPromises ?? [])])
    .then(() => {
      // Mobile browsers handle 100% height in ways we don't like. Fix that.
      fixMobileViewport()

      // Evaluate props only after DOMContentLoaded to ensure scripts that set globals on `window` have run.
      // Stores might also be initialized with data from `window` or `ww`, so initialize it only after DOMContentLoaded.
      const { vuexStore, piniaStore } = setupStores(app, options)
      const props = typeof options.rootComponentProps === 'function' ? options.rootComponentProps() : undefined
      const vueInstanceOptions = {
        el: options.el,
        store: vuexStore,
        pinia: piniaStore,
        created: options.created,
        mounted: options.mounted,
        render: (h: CreateElement): VNode => h(options.rootComponent, { props }),
        ...options.otherVueOptions,
      }
      const vueApp = new Vue(vueInstanceOptions)
      window.app ??= vueApp
      // Initialize Pinia store only after Vue app is created, otherwise it will throw an error for getActivePinia()
      // when Pinia is not ready yet.
      // https://pinia.vuejs.org/core-concepts/outside-component-usage.html
      if (options.usePinia === true && piniaStore != null && typeof options.initializePiniaStore === 'function') {
        options.initializePiniaStore(piniaStore)
      }

      initializeAuthenticationChannel({
        onLogin: options.onGlobalLogin,
        onLogout: options.onGlobalLogout,
      })

      return vueApp
    })
    .catch((e) => {
      if (e instanceof PageReloadableError) {
        reload()
      } else {
        errorTracker.captureException(e)
      }
      return undefined
    })

  // Allow dev tools to be turned on in production
  if (process.env.NODE_ENV === 'production' && isDebugMode) {
    Vue.config.devtools = true
    Vue.config.performance = true
  }
  Vue.config.ignoredElements = ['trix-editor', 'lottie-player', 'dotlottie-wc', MENTION_ELEMENT_TAG_NAME]

  return vueInstance
}

function setupErrorTracking(app, options: VueAppOptions): void {
  errorTracker.init({
    vue: app,
    environment: options.environment ?? environment,
    locale: options.locale ?? getHyphenatedCurrentLocale(),
    release: options.release ?? document?.documentElement?.dataset?.version,
  })
}

export function setupIntl(app): void {
  const global = app.prototype
  global.__ = __
  global.N__ = N__
  global.p__ = p__
  global.n__ = n__
  global.np__ = np__
}

async function setupPlugins(app, options: VueAppOptions): Promise<void> {
  // Use accessibility tools (in development) by default
  const disableAccessibilityChecks = options.disableAccessibilityChecks ?? false
  if (!disableAccessibilityChecks) void useVueAxePlugin(app)

  // Use other page-specific plugins
  if (options.loadPlugins != null && typeof options.loadPlugins === 'function') {
    await options.loadPlugins(app)
  }
}

async function useVueAxePlugin(app): Promise<void> {
  if (environment === 'production') return
  const VueAxePlugin = (await import('vue-axe')).default
  app.use(VueAxePlugin, {
    allowConsoleClears: false,
    clearConsoleOnUpdate: false,
  })
}

function setupVuexStore(app, options: VueAppOptions): VuexStore<any> | undefined {
  // Allow already-initialized store for now, to make porting Surface easier.
  let store = options.store

  if (isObject(options.storeOptions)) {
    app.use(Vuex)
    store = new Vuex.Store(options.storeOptions)
  }

  if (store !== undefined && typeof options.initializeStore === 'function') {
    options.initializeStore(store)
  }

  return store
}

function setupPiniaStore(app): PiniaStore | undefined {
  app.use(PiniaVuePlugin)
  const store = createPinia()

  if (shouldLog) {
    store.use(
      PiniaLogger({
        expanded: false,
        disabled: !shouldLog,
      }),
    )
  }

  return store
}

function setupStores(
  app,
  options: VueAppOptions,
): { vuexStore: VuexStore<any> | undefined; piniaStore: PiniaStore | undefined } {
  const usePinia = options.usePinia ?? false
  const vuexStore = setupVuexStore(app, options)
  const piniaStore = usePinia ? setupPiniaStore(app) : undefined

  return { vuexStore, piniaStore }
}

type IdArrayMap = Record<Id, Id[]>
function stableSync(target: IdArrayMap, source: IdArrayMap): IdArrayMap {
  new Set([...Object.keys(source), ...Object.keys(target)]).forEach(function (key) {
    const sourceIds = source[key]
    const destIds = target[key]
    if (isEqual(sourceIds, destIds)) return

    if (sourceIds != null) {
      Vue.set(target, key, [...sourceIds])
    } else {
      Vue.delete(target, key)
    }
  })
  return target
}

/**********************
 * Unbounded watchers *
 **********************

 These watcher functions register the watcher asynchronously so that
 they are not bound to any particular component instance. This is
 useful in context like a Pinia store where the store may be used
 in multiple components but you don't want the watchers in the store
 to be stopped automatically when a component is unmounted.

 Read more about it here: https://vuejs.org/guide/essentials/watchers.html#stopping-a-watcher

 To preserve the type safety of these custom watchers, I copied the
 function signatures from node_modules/vue/types/v3-generated.d.ts
 and only changed the return type to be Promise<WatchStopHandle>.
 **********************/

type MultiWatchSources = Array<WatchSource<unknown> | object>

type MapSources<T, Immediate> = {
  [K in keyof T]: T[K] extends WatchSource<infer V>
    ? Immediate extends true
      ? V | undefined
      : V
    : T[K] extends object
    ? Immediate extends true
      ? T[K] | undefined
      : T[K]
    : never
}

async function unboundedWatch<T extends MultiWatchSources, Immediate extends Readonly<boolean> = false>(
  sources: [...T],
  cb: WatchCallback<MapSources<T, false>, MapSources<T, Immediate>>,
  options?: WatchOptions<Immediate>,
): Promise<WatchStopHandle>

async function unboundedWatch<T extends Readonly<MultiWatchSources>, Immediate extends Readonly<boolean> = false>(
  source: T,
  cb: WatchCallback<MapSources<T, false>, MapSources<T, Immediate>>,
  options?: WatchOptions<Immediate>,
): Promise<WatchStopHandle>

async function unboundedWatch<T, Immediate extends Readonly<boolean> = false>(
  source: WatchSource<T>,
  cb: WatchCallback<T, Immediate extends true ? T | undefined : T>,
  options?: WatchOptions<Immediate>,
): Promise<WatchStopHandle>

async function unboundedWatch<T extends object, Immediate extends Readonly<boolean> = false>(
  source: T,
  cb: WatchCallback<T, Immediate extends true ? T | undefined : T>,
  options?: WatchOptions<Immediate>,
): Promise<WatchStopHandle>

async function unboundedWatch(
  sourceOrSources: any,
  cb: WatchCallback,
  options?: WatchOptions,
): Promise<WatchStopHandle> {
  return await new Promise((resolve) => {
    setTimeout(() => {
      const stopHandle = watch(sourceOrSources, cb, options)
      resolve(stopHandle)
    }, 0)
  })
}

async function unboundedWatchEffect(effect: WatchEffect, options?: WatchOptionsBase): Promise<WatchStopHandle> {
  return await new Promise((resolve) => {
    setTimeout(() => {
      const stopHandle = watchEffect(effect, options)
      resolve(stopHandle)
    }, 0)
  })
}

export {
  VuexClassAction as Action,
  VuePropertyDecoratorComponent as Component,
  VuePropertyDecoratorEmit as Emit,
  VuexClassGetter as Getter,
  VuePropertyDecoratorMixins as Mixins,
  VuePropertyDecoratorModel as Model,
  VuexClassNamespace as namespace,
  VuePropertyDecoratorProp as Prop,
  stableSync,
  VuexClassState as State,
  unboundedWatch,
  unboundedWatchEffect,
  vDel,
  vSet,
  VuePropertyDecoratorVue as Vue,
  VuePropertyDecoratorWatch as Watch,
}
