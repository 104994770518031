import { QueryParam } from './types'

export default function arrayToQueryString(paramArray: QueryParam[]): string {
  const mappedArray = paramArray.map((x): string => {
    if (x[1]) {
      return [x[0], encodeURIComponent(x[1])].join('=')
    } else {
      return x[0]
    }
  })
  return mappedArray.join('&')
}
