// @file Surface comment attachments store
import { vDel, vSet } from '@@/bits/vue'
import { useContentPickerStore } from '@@/pinia/content_picker'
import type { Cid, CommentId } from '@@/types'
import { defineStore } from 'pinia'
import { readonly, ref } from 'vue'

export enum CommentAttachmentState {
  /** When the comment is in its normal state before picking attachment. */
  IDLE = 'idle',
  /** When the comment attachment is being loaded. */
  LOADING = 'loading',
  /** When the comment attachment has finished loading and is showing as a preview for the user to decide before submitting.  */
  PREVIEW = 'preview',
}

export const useSurfaceCommentAttachmentsStore = defineStore('surfaceCommentAttachments', () => {
  const contentPickerStore = useContentPickerStore()

  /* ---------------------- */
  /* COMMENT CONTENT PICKER */
  /* ---------------------- */

  // Can be post CID (for new comments) or comment ID (for edited comments).
  const activeCommentContentPickerId = ref<string | null>(null)

  const openCommentContentPicker = (id: string): void => {
    activeCommentContentPickerId.value = id
    contentPickerStore.showContentSourceMenu()
  }

  const hideCommentContentPicker = (): void => {
    activeCommentContentPickerId.value = null
  }

  /* ------------------------ */
  /* COMMENT ATTACHMENT STATE */
  /* ------------------------ */

  // This map is used for unsubmitted comments only (that's why the key is post CID -- each post may only have one unsubmitted comment).
  const newCommentAttachmentStateByPostCid = ref<Record<Cid, CommentAttachmentState>>({})

  const updateNewCommentAttachmentState = ({
    postCid,
    state,
  }: {
    postCid: Cid
    state: CommentAttachmentState
  }): void => {
    vSet(newCommentAttachmentStateByPostCid.value, postCid, state)
  }

  const removeNewCommentAttachmentState = ({ postCid }: { postCid: Cid }): void => {
    vDel(newCommentAttachmentStateByPostCid.value, postCid)
  }

  const newCommentAttachmentStateForPostCid = (postCid: Cid): CommentAttachmentState => {
    return newCommentAttachmentStateByPostCid.value[postCid] ?? CommentAttachmentState.IDLE
  }

  // This map stores the attachment state of comments being edited.
  const editedCommentAttachmentStateById = ref<Record<CommentId, CommentAttachmentState>>({})

  const updateEditedCommentAttachmentState = ({
    commentId,
    state,
  }: {
    commentId: CommentId
    state: CommentAttachmentState
  }): void => {
    vSet(editedCommentAttachmentStateById.value, commentId, state)
  }

  const removeEditedCommentAttachmentState = ({ commentId }: { commentId: CommentId }): void => {
    vDel(editedCommentAttachmentStateById.value, commentId)
  }

  const editedCommentAttachmentStateForId = (commentId: CommentId): CommentAttachmentState => {
    return editedCommentAttachmentStateById.value[commentId] ?? CommentAttachmentState.IDLE
  }

  const resetCommentAttachments = (): void => {
    newCommentAttachmentStateByPostCid.value = {}
  }

  /* ------------------------------ */
  /* DRAGGING FILE TO COMMENT STATE */
  /* ------------------------------ */
  const isDraggingFileToNewCommentByPostCid = ref<Record<Cid, true>>({})

  const startDraggingFileToNewComment = (postCid: Cid): void => {
    vSet(isDraggingFileToNewCommentByPostCid.value, postCid, true)
  }

  const stopDraggingFileToNewComment = (postCid: Cid): void => {
    vDel(isDraggingFileToNewCommentByPostCid.value, postCid)
  }

  return {
    // Comment content picker
    activeCommentContentPickerId: readonly(activeCommentContentPickerId),
    openCommentContentPicker,
    hideCommentContentPicker,

    // Comment attachment state
    newCommentAttachmentStateByPostCid,
    updateNewCommentAttachmentState,
    removeNewCommentAttachmentState,
    newCommentAttachmentStateForPostCid,
    editedCommentAttachmentStateById,
    updateEditedCommentAttachmentState,
    removeEditedCommentAttachmentState,
    editedCommentAttachmentStateForId,
    resetCommentAttachments,

    // Dragging file to comment state
    isDraggingFileToNewCommentByPostCid,
    startDraggingFileToNewComment,
    stopDraggingFileToNewComment,
  }
})
