// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file links.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

export namespace Links {
  export class LinksMessage extends Message<LinksMessage> {
    /**
     * @generated from field: string show = 1;
     */
    show: string = "";
    /**
     * @generated from field: string app = 2;
     */
    app: string = "";
    /**
     * @generated from field: string doodle = 3;
     */
    doodle: string = "";
    /**
     * @generated from field: string permanent = 4;
     */
    permanent: string = "";
    /**
     * @generated from field: string embed = 5;
     */
    embed: string = "";
    /**
     * @generated from field: string preview_embed = 6;
     */
    previewEmbed: string = "";
    /**
     * @generated from field: string snap = 7;
     */
    snap: string = "";
    /**
     * @generated from field: string peek = 8;
     */
    peek: string = "";
    /**
     * @generated from field: string print = 9;
     */
    print: string = "";
    /**
     * @generated from field: string spreadsheet = 10;
     */
    spreadsheet: string = "";
    /**
     * @generated from field: string list = 11;
     */
    list: string = "";
    /**
     * @generated from field: string screenshot = 12;
     */
    screenshot: string = "";
    /**
     * @generated from field: string document = 13;
     */
    document: string = "";

    constructor(data?: PartialMessage<LinksMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.Links";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "show", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "app", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "doodle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 4, name: "permanent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 5, name: "embed", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 6, name: "preview_embed", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 7, name: "snap", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 8, name: "peek", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 9, name: "print", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 10, name: "spreadsheet", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 11, name: "list", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 12, name: "screenshot", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 13, name: "document", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LinksMessage {
      return new LinksMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LinksMessage {
      return new LinksMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LinksMessage {
      return new LinksMessage().fromJsonString(jsonString, options);
    }

      static equals(a: LinksMessage | PlainMessage<LinksMessage> | undefined, b: LinksMessage | PlainMessage<LinksMessage> | undefined): boolean {
      return proto3.util.equals(LinksMessage, a, b);
    }

    toObject(): Links {
      return {
        show: this.show,
        app: this.app,
        doodle: this.doodle,
        permanent: this.permanent,
        embed: this.embed,
        previewEmbed: this.previewEmbed,
        snap: this.snap,
        peek: this.peek,
        print: this.print,
        spreadsheet: this.spreadsheet,
        list: this.list,
        screenshot: this.screenshot,
        document: this.document,
      };
    }

    static fromObject(object: Links): LinksMessage {
      return new LinksMessage({
        show: object.show,
        app: object.app,
        doodle: object.doodle,
        permanent: object.permanent,
        embed: object.embed,
        previewEmbed: object.previewEmbed,
        snap: object.snap,
        peek: object.peek,
        print: object.print,
        spreadsheet: object.spreadsheet,
        list: object.list,
        screenshot: object.screenshot,
        document: object.document,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): Links {
    return LinksMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: Links): Uint8Array {
    return LinksMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.Links
 */
export type Links = {
  /**
   * @generated from field: string show = 1;
   */
  show: string;
  /**
   * @generated from field: string app = 2;
   */
  app: string;
  /**
   * @generated from field: string doodle = 3;
   */
  doodle: string;
  /**
   * @generated from field: string permanent = 4;
   */
  permanent: string;
  /**
   * @generated from field: string embed = 5;
   */
  embed: string;
  /**
   * @generated from field: string preview_embed = 6;
   */
  previewEmbed: string;
  /**
   * @generated from field: string snap = 7;
   */
  snap: string;
  /**
   * @generated from field: string peek = 8;
   */
  peek: string;
  /**
   * @generated from field: string print = 9;
   */
  print: string;
  /**
   * @generated from field: string spreadsheet = 10;
   */
  spreadsheet: string;
  /**
   * @generated from field: string list = 11;
   */
  list: string;
  /**
   * @generated from field: string screenshot = 12;
   */
  screenshot: string;
  /**
   * @generated from field: string document = 13;
   */
  document: string;
}

