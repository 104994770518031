import arrayToQueryString from './array_to_query_string'
import { QueryParam } from './types'

export default function appendParamsToUrl(url: string, params: string | QueryParam[]): string {
  const paramsString = typeof params === 'string' ? params : arrayToQueryString(params)
  if (url.includes('?')) {
    return [url, paramsString].join('&')
  } else {
    return [url, paramsString].join('?')
  }
}
