/**
 * @file Provides fetch functions.
 */
import { setDefaultOptions } from './defaults'
import FetchError from './fetch_error'
import { prepareFetchArguments, prepareJsonFetchArguments } from './prepare_fetch_arguments'
import type { FetchOptionQueryHash, FetchOptions, FetchResource, OriginalFetchOptions } from './types'
import { HTTPAuthenticationScheme, HTTPContentType, HTTPMethod } from './types'

async function originalFetch(url: string, options: OriginalFetchOptions = {}): Promise<Response> {
  return fetch(url, options)
}

async function fetchResponse(url: string, ...options: FetchOptions[]): Promise<Response> {
  const [fetchUrl, fetchOptions] = prepareFetchArguments(url, options)

  let response: Response
  try {
    response = await originalFetch(fetchUrl, fetchOptions)
    if (response && response.status >= 100 && response.status <= 399) {
      return response
    }
  } catch (error) {
    const e = error as Error
    throw new FetchError(0, e.message, undefined, error)
  }
  const body: string = await response.text()
  throw new FetchError(response.status, body, response)
}

async function fetchJson<T>(url: string, ...options: FetchOptions[]): Promise<T> {
  const response: Response = await fetchResponse(url, ...prepareJsonFetchArguments(options))
  const json: T = await response.json()
  return json
}

async function fetchText(url: string, ...options: FetchOptions[]): Promise<string> {
  const response: Response = await fetchResponse(url, ...options)
  return response.text()
}

async function fetchBlob(url: string, ...options: FetchOptions[]): Promise<Blob> {
  const response: Response = await fetchResponse(url, ...options)
  return response.blob()
}

function resource<T>(baseUrlWithoutTrailingSlash: string, ...baseOptions: FetchOptions[]): FetchResource<T> {
  function localFetch<Q>(relativePath: string, ...options: FetchOptions[]): Promise<Q> {
    const fullUrl =
      relativePath === '' ? baseUrlWithoutTrailingSlash : [baseUrlWithoutTrailingSlash, relativePath].join('/')
    return fetchJson<Q>(fullUrl, ...baseOptions, ...options)
  }
  const fetchResource: FetchResource<T> = {
    index(query: FetchOptionQueryHash, ...options: FetchOptions[]): Promise<T[]> {
      return localFetch<T[]>('', ...options, { method: HTTPMethod.get, query })
    },
    show(id: string | number, ...options: FetchOptions[]): Promise<T> {
      return localFetch(id.toString(), ...options, { method: HTTPMethod.get })
    },
    create(jsonData: any, ...options: FetchOptions[]): Promise<T> {
      return localFetch('', ...options, { method: HTTPMethod.post, jsonData })
    },
    update(jsonData: any, ...options: FetchOptions[]): Promise<T> {
      return localFetch(jsonData.id.toString(), ...options, { method: HTTPMethod.put, jsonData })
    },
    destroy(id: string | number, ...options: FetchOptions[]): Promise<T> {
      return localFetch(id.toString(), ...options, { method: HTTPMethod.delete })
    },

    get(relativePath: string, ...options: FetchOptions[]): Promise<unknown> {
      return localFetch<unknown>(relativePath, ...options, { method: HTTPMethod.get })
    },
    post(relativePath: string, jsonData: any, ...options: FetchOptions[]): Promise<unknown> {
      return localFetch<unknown>(relativePath, ...options, { method: HTTPMethod.post, jsonData })
    },
    put(relativePath: string, jsonData: any, ...options: FetchOptions[]): Promise<unknown> {
      return localFetch<unknown>(relativePath, ...options, { method: HTTPMethod.put, jsonData })
    },
    delete(relativePath: string, ...options: FetchOptions[]): Promise<unknown> {
      return localFetch<unknown>(relativePath, ...options, { method: HTTPMethod.delete })
    },

    fetch(relativePath: string, ...options: FetchOptions[]): Promise<unknown> {
      return localFetch<unknown>(relativePath, ...options)
    },
  }
  return fetchResource
}

export {
  FetchError,
  HTTPContentType,
  HTTPAuthenticationScheme,
  HTTPMethod,
  FetchOptions,
  FetchResource,
  originalFetch,
  fetchResponse,
  fetchJson,
  fetchText,
  fetchBlob,
  resource,
  setDefaultOptions as configure,
}
