// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file wall_privacy_policy.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

export namespace WallPrivacyPolicy {
  /**
   * @generated from enum proto.WallPrivacyPolicy.Permission
   */
  export enum Permission {
    /**
     * @generated from enum value: PERMISSION_UNSPECIFIED = 0;
     */
    Unspecified = 0,
    /**
     * @generated from enum value: PERMISSION_CAN_READ = 1;
     */
    CanRead = 1,
    /**
     * @generated from enum value: PERMISSION_CAN_WRITE = 2;
     */
    CanWrite = 2,
    /**
     * @generated from enum value: PERMISSION_CAN_EDIT = 3;
     */
    CanEdit = 3,
    /**
     * @generated from enum value: PERMISSION_CAN_ADMINISTER = 4;
     */
    CanAdminister = 4,
  }

  export class WallPrivacyPolicyMessage extends Message<WallPrivacyPolicyMessage> {
    /**
     * @generated from field: int64 id = 1;
     */
    id: bigint = protoInt64.zero;
    /**
     * @generated from field: int64 wall_id = 2;
     */
    wallId: bigint = protoInt64.zero;
    /**
     * @generated from field: int64 owner_id = 3;
     */
    ownerId: bigint = protoInt64.zero;
    /**
     * @generated from field: proto.WallPrivacyPolicy.Permission tenant_access = 4;
     */
    tenantAccess: WallPrivacyPolicy.Permission = WallPrivacyPolicy.Permission.Unspecified;
    /**
     * @generated from field: map<int64, proto.WallPrivacyPolicy.Permission> users = 5;
     */
    users: { [key: string]: WallPrivacyPolicy.Permission } = {};
    /**
     * @generated from field: bool is_password_protected = 6;
     */
    isPasswordProtected: boolean = false;
    /**
     * @generated from field: bool is_moderated = 7;
     */
    isModerated: boolean = false;
    /**
     * @generated from field: bool is_listed = 8;
     */
    isListed: boolean = false;
    /**
     * @generated from field: proto.WallPrivacyPolicy.Permission public_access = 9;
     */
    publicAccess: WallPrivacyPolicy.Permission = WallPrivacyPolicy.Permission.Unspecified;
    /**
     * @generated from field: int64 tenant_id = 10;
     */
    tenantId: bigint = protoInt64.zero;

    constructor(data?: PartialMessage<WallPrivacyPolicyMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.WallPrivacyPolicy";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 2, name: "wall_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 3, name: "owner_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 4, name: "tenant_access", kind: "enum", T: proto3.getEnumType(WallPrivacyPolicy.Permission) },
      { no: 5, name: "users", kind: "map", K: 3 /* ScalarType.INT64 */, V: { kind: "enum", T: proto3.getEnumType(WallPrivacyPolicy.Permission) } },
      { no: 6, name: "is_password_protected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 7, name: "is_moderated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 8, name: "is_listed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 9, name: "public_access", kind: "enum", T: proto3.getEnumType(WallPrivacyPolicy.Permission) },
      { no: 10, name: "tenant_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WallPrivacyPolicyMessage {
      return new WallPrivacyPolicyMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WallPrivacyPolicyMessage {
      return new WallPrivacyPolicyMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WallPrivacyPolicyMessage {
      return new WallPrivacyPolicyMessage().fromJsonString(jsonString, options);
    }

      static equals(a: WallPrivacyPolicyMessage | PlainMessage<WallPrivacyPolicyMessage> | undefined, b: WallPrivacyPolicyMessage | PlainMessage<WallPrivacyPolicyMessage> | undefined): boolean {
      return proto3.util.equals(WallPrivacyPolicyMessage, a, b);
    }

    toObject(): WallPrivacyPolicy {
      return {
        id: this.id,
        wallId: this.wallId,
        ownerId: this.ownerId,
        tenantAccess: this.tenantAccess,
        users: this.users,
        isPasswordProtected: this.isPasswordProtected,
        isModerated: this.isModerated,
        isListed: this.isListed,
        publicAccess: this.publicAccess,
        tenantId: this.tenantId,
      };
    }

    static fromObject(object: WallPrivacyPolicy): WallPrivacyPolicyMessage {
      return new WallPrivacyPolicyMessage({
        id: object.id,
        wallId: object.wallId,
        ownerId: object.ownerId,
        tenantAccess: object.tenantAccess,
        users: object.users,
        isPasswordProtected: object.isPasswordProtected,
        isModerated: object.isModerated,
        isListed: object.isListed,
        publicAccess: object.publicAccess,
        tenantId: object.tenantId,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): WallPrivacyPolicy {
    return WallPrivacyPolicyMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: WallPrivacyPolicy): Uint8Array {
    return WallPrivacyPolicyMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.WallPrivacyPolicy
 */
export type WallPrivacyPolicy = {
  /**
   * @generated from field: int64 id = 1;
   */
  id: bigint;
  /**
   * @generated from field: int64 wall_id = 2;
   */
  wallId: bigint;
  /**
   * @generated from field: int64 owner_id = 3;
   */
  ownerId: bigint;
  /**
   * @generated from field: proto.WallPrivacyPolicy.Permission tenant_access = 4;
   */
  tenantAccess: WallPrivacyPolicy.Permission;
  /**
   * @generated from field: map<int64, proto.WallPrivacyPolicy.Permission> users = 5;
   */
  users: { [key: string]: WallPrivacyPolicy.Permission };
  /**
   * @generated from field: bool is_password_protected = 6;
   */
  isPasswordProtected: boolean;
  /**
   * @generated from field: bool is_moderated = 7;
   */
  isModerated: boolean;
  /**
   * @generated from field: bool is_listed = 8;
   */
  isListed: boolean;
  /**
   * @generated from field: proto.WallPrivacyPolicy.Permission public_access = 9;
   */
  publicAccess: WallPrivacyPolicy.Permission;
  /**
   * @generated from field: int64 tenant_id = 10;
   */
  tenantId: bigint;
}

proto3.util.setEnumType(WallPrivacyPolicy.Permission, "proto.WallPrivacyPolicy.Permission", [
  { no: 0, name: "PERMISSION_UNSPECIFIED" },
  { no: 1, name: "PERMISSION_CAN_READ" },
  { no: 2, name: "PERMISSION_CAN_WRITE" },
  { no: 3, name: "PERMISSION_CAN_EDIT" },
  { no: 4, name: "PERMISSION_CAN_ADMINISTER" },
]);

