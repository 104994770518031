import pixelRatio from '@padlet/pixel-ratio'

import { TransformOptions, RequestOptions } from './types'
import presets from './presets'

const DEFAULT_BREAKPOINT_WIDTH = 60

export function getTransformOptions(requestedOptions: RequestOptions): TransformOptions {
  const presetOptions: TransformOptions = requestedOptions.preset ? presets[requestedOptions.preset] : {}
  const options: RequestOptions = Object.assign(presetOptions, requestedOptions)

  const transformOptions: TransformOptions = {}

  transformOptions.align = options.align
  transformOptions.ar = options.ar
  transformOptions.effect = options.effect
  transformOptions.crop = options.crop || 'limit'

  transformOptions.dpr =
    options.dpr == 'auto' || !options.dpr ? Math.ceil(pixelRatio) : Math.ceil(parseFloat(options.dpr.toString()))

  if (!options.gravity && (options.crop == 'fill' || options.crop == 'lfill')) {
    transformOptions.gravity = 'auto'
  } else {
    transformOptions.gravity = options.gravity
  }

  transformOptions.format = options.format
  transformOptions.metadata = options.metadata
  transformOptions.trim = options.trim
  transformOptions.blur = options.blur
  transformOptions.quality = options.quality

  if (options.breakpoint && options.width) {
    const breakpointWidth = options.breakpoint === true ? DEFAULT_BREAKPOINT_WIDTH : options.breakpoint
    transformOptions.width = breakpointWidth * Math.ceil(options.width / breakpointWidth)
    if (options.height) {
      transformOptions.height = Math.floor((options.height * transformOptions.width) / options.width)
    }
  } else {
    transformOptions.width = options.width
    transformOptions.height = options.height
  }

  if (options.grayscale) {
    transformOptions.grayscale = options.grayscale
  }

  return transformOptions
}
