// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file user.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

export namespace User {
  /**
   * @generated from enum proto.User.Role
   */
  export enum Role {
    /**
     * @generated from enum value: ROLE_UNSPECIFIED = 0;
     */
    Unspecified = 0,
    /**
     * @generated from enum value: ROLE_ADMIN = 1;
     */
    Admin = 1,
    /**
     * @generated from enum value: ROLE_OWNER = 2;
     */
    Owner = 2,
    /**
     * @generated from enum value: ROLE_STUDENT = 3;
     */
    Student = 3,
    /**
     * @generated from enum value: ROLE_TEACHER = 4;
     */
    Teacher = 4,
    /**
     * @generated from enum value: ROLE_MEMBER = 5;
     */
    Member = 5,
  }

  export class UserMessage extends Message<UserMessage> {
    /**
     * @generated from field: uint64 id = 1;
     */
    id: bigint = protoInt64.zero;
    /**
     * @generated from field: string username = 2;
     */
    username: string = "";
    /**
     * @generated from field: string name = 3;
     */
    name: string = "";
    /**
     * @generated from field: string display_name = 4;
     */
    displayName: string = "";
    /**
     * @generated from field: string short_name = 5;
     */
    shortName: string = "";
    /**
     * @generated from field: string avatar_url = 6;
     */
    avatarUrl: string = "";
    /**
     * @generated from field: proto.User.Role role = 7;
     */
    role: User.Role = User.Role.Unspecified;
    /**
     * @generated from field: bool is_registered = 8;
     */
    isRegistered: boolean = false;

    constructor(data?: PartialMessage<UserMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.User";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 5, name: "short_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 6, name: "avatar_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 7, name: "role", kind: "enum", T: proto3.getEnumType(User.Role) },
      { no: 8, name: "is_registered", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserMessage {
      return new UserMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserMessage {
      return new UserMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserMessage {
      return new UserMessage().fromJsonString(jsonString, options);
    }

      static equals(a: UserMessage | PlainMessage<UserMessage> | undefined, b: UserMessage | PlainMessage<UserMessage> | undefined): boolean {
      return proto3.util.equals(UserMessage, a, b);
    }

    toObject(): User {
      return {
        id: this.id,
        username: this.username,
        name: this.name,
        displayName: this.displayName,
        shortName: this.shortName,
        avatarUrl: this.avatarUrl,
        role: this.role,
        isRegistered: this.isRegistered,
      };
    }

    static fromObject(object: User): UserMessage {
      return new UserMessage({
        id: object.id,
        username: object.username,
        name: object.name,
        displayName: object.displayName,
        shortName: object.shortName,
        avatarUrl: object.avatarUrl,
        role: object.role,
        isRegistered: object.isRegistered,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): User {
    return UserMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: User): Uint8Array {
    return UserMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.User
 */
export type User = {
  /**
   * @generated from field: uint64 id = 1;
   */
  id: bigint;
  /**
   * @generated from field: string username = 2;
   */
  username: string;
  /**
   * @generated from field: string name = 3;
   */
  name: string;
  /**
   * @generated from field: string display_name = 4;
   */
  displayName: string;
  /**
   * @generated from field: string short_name = 5;
   */
  shortName: string;
  /**
   * @generated from field: string avatar_url = 6;
   */
  avatarUrl: string;
  /**
   * @generated from field: proto.User.Role role = 7;
   */
  role: User.Role;
  /**
   * @generated from field: bool is_registered = 8;
   */
  isRegistered: boolean;
}

proto3.util.setEnumType(User.Role, "proto.User.Role", [
  { no: 0, name: "ROLE_UNSPECIFIED" },
  { no: 1, name: "ROLE_ADMIN" },
  { no: 2, name: "ROLE_OWNER" },
  { no: 3, name: "ROLE_STUDENT" },
  { no: 4, name: "ROLE_TEACHER" },
  { no: 5, name: "ROLE_MEMBER" },
]);

