// @file Pinia plugin that listens/triggers changes in the address
import { removeHashidPrefix } from '@@/bits/hashid_helper'
import { getSectionBreakoutPath, getSectionBreakoutPostPath } from '@@/bits/surface_share_links_helper'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceShareLinksStore } from '@@/pinia/surface_share_links'

const history = window.history

export const addressListener = (): void => {
  const expandedPostStore = useExpandedPostStore()
  const surfaceStore = useSurfaceStore()
  const surfacePosts = useSurfacePostsStore()
  const surfaceShareLinksStore = useSurfaceShareLinksStore()

  const watchExpandedPost = (state: {
    xExpandedPostTextPanel: boolean
    expandedPostCid: string | null
    shouldFocusExpandedPostAddCommentInput: boolean
  }): void => {
    if (
      !surfacePosts.hasFetchedPosts ||
      surfaceStore.isSlideshow ||
      surfaceStore.isSubmissionRequest ||
      surfaceStore.isGeneratingPDF ||
      surfaceStore.isWhiteboard
    ) {
      return
    }

    let newPathname = ''
    // Fix an issue with browser being able to load URL with multiple slashes
    // e.g. https://padlet.com//about/us
    const originalPathname = window.location.pathname.replace(/(\/)\1+/g, '$1')

    // Systemic Authorization
    // Prioritise hashid if it is present
    if (expandedPostStore.expandedPostHashid != null) {
      if (surfaceStore.isSectionBreakout) {
        newPathname = getSectionBreakoutPostPath(
          surfaceStore.namespace,
          surfaceShareLinksStore.sectionBreakoutIdentifier ?? '',
          removeHashidPrefix(expandedPostStore.expandedPostHashid),
        )
      } else {
        newPathname = `/${surfaceStore.address}/wish/${removeHashidPrefix(expandedPostStore.expandedPostHashid)}`
      }

      if (window.location.pathname !== newPathname) {
        history.pushState(
          {
            expandedPostCid: state.expandedPostCid,
            expandedPostHashid: expandedPostStore.expandedPostHashid,
          },
          '',
          newPathname,
        )
      }
    } else if (state.expandedPostCid != null) {
      if (expandedPostStore.expandedPostServerId == null) return
      if (surfaceStore.isSectionBreakout) {
        newPathname = getSectionBreakoutPostPath(
          surfaceStore.namespace,
          surfaceShareLinksStore.sectionBreakoutIdentifier ?? '',
          String(expandedPostStore.expandedPostServerId),
        )
      } else {
        newPathname = `/${surfaceStore.address}/wish/${expandedPostStore.expandedPostServerId}`
      }

      if (window.location.pathname !== newPathname) {
        history.pushState(
          {
            expandedPostCid: state.expandedPostCid,
            expandedPostServerId: expandedPostStore.expandedPostServerId,
          },
          '',
          newPathname,
        )
      }
    } else {
      if (surfaceStore.isSectionBreakout) {
        newPathname = getSectionBreakoutPath(
          surfaceStore.namespace,
          surfaceShareLinksStore.sectionBreakoutIdentifier ?? '',
        )
      } else if (surfaceStore.isEmbedded) {
        newPathname = '/embed/' + surfaceStore.publicKey
      } else {
        newPathname = '/' + surfaceStore.address
      }
      if (originalPathname !== newPathname) {
        if (history.state && history.state.expandedPostServerId) {
          history.pushState({}, '', newPathname)
        } else {
          const pathSplited = originalPathname.split('/')
          const expandedPostServerId = pathSplited[pathSplited.length - 1]
          const expandedPostCid =
            surfacePosts.getPostByServerId(expandedPostServerId) != null
              ? surfacePosts.getPostByServerId(expandedPostServerId)?.cid
              : `c${expandedPostServerId}`
          history.replaceState({ expandedPostCid, expandedPostServerId }, '', originalPathname)
          history.pushState({}, '', newPathname)
        }
      }
    }
  }

  let unsubscribeFromWatchingExpandedPost = expandedPostStore.$subscribe((_, state) => {
    watchExpandedPost(state)
  })

  window.addEventListener('popstate', (e) => {
    const eventState = e.state
    unsubscribeFromWatchingExpandedPost()
    if (eventState && eventState.expandedPostCid) {
      expandedPostStore.expandPost({
        postCid: eventState.expandedPostCid,
        xTextPanel: true,
      })
    } else {
      expandedPostStore.unexpandPost()
    }
    unsubscribeFromWatchingExpandedPost = expandedPostStore.$subscribe((_, state) => {
      watchExpandedPost(state)
    })
  })
}
