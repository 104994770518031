/**
 * @file Fetch emojis
 */
import { fetchJson } from '@padlet/fetch'
import { EmojiVersion } from './enums'
import { emojiUrl, parseEmojiCategories } from './icons/emoji'
import { Emoji, EmojiCategory, EmojiCollectionsBundle } from './types'

interface EmojiManifest {
  codes: Record<string, string[]>
  details: Record<string, Emoji>
}

/**
 * Fetches emojis that we can use in our wall settings panel.
 * @param version [EmojiVersion] The version of emojis to fetch.
 * @returns Promise that resolves to an object with labels that map to a collection of emojis.
 */
async function fetchEmojis(version: EmojiVersion = EmojiVersion.legacy): Promise<EmojiCollectionsBundle> {
  const result: EmojiCollectionsBundle = {}
  const emojiManifestUrl =
    version == EmojiVersion.legacy
      ? 'https://padlet.net/icons/emoji_manifest.json'
      : `https://padlet.net/icons/${version}/emoji_manifest.json`
  const { codes: emojiCodes, details: emojiDetails } = await fetchJson<EmojiManifest>(emojiManifestUrl, {
    cors: true,
  })
  const categories = parseEmojiCategories(Object.keys(emojiCodes))
  const typedEmojiCodes = emojiCodes
  categories.forEach((ec: EmojiCategory): void => {
    const emojis = typedEmojiCodes[ec.label].map((code: string): Emoji => {
      const {
        name,
        // shortname,
        keywords,
      } = emojiDetails[code]
      return {
        code,
        name, // TODO: include translations
        // shortname,
        keywords,
        url: emojiUrl(code, version),
        // category: ec,
      }
    })
    result[ec.label] = {
      category: ec,
      emojis,
    }
  })
  return result
}

export default fetchEmojis
