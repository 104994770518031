import { WallBackgroundType, WallBackgroundCollection } from './types'
import fetchPictureBackgrounds from './fetch_picture_backgrounds'
import fetchColorBackgrounds from './fetch_color_backgrounds'
import fetchGradientBackgrounds from './fetch_gradient_backgrounds'
import fetchPatternBackgrounds from './fetch_pattern_backgrounds'
import fetchArtBackgrounds from './fetch_art_backgrounds'

/**
 * Fetch backgrounds as specified by a type. There are a few types of
 * backgrounds -- pictures, solid colors, gradients, patterns (textures) and art & illustrations.
 *
 * If API version is not given, the default corresponds to WALLPAPER_V2_API_VERSION = 6
 * Settings V2 Wallpaper Panel uses WALLPAPER_V2_WITH_ART_API_VERSION = 7
 *
 * @param bgtype The type of background to fetch, as specified by [[WallBackgroundType]].
 * @return Promise that resolves to a collection of backgrounds.
 */
async function fetchBackgrounds(bgtype: WallBackgroundType): Promise<WallBackgroundCollection> {
  switch (bgtype) {
    case 'picture':
      // fetch the default. If we want a specific category, we'll
      // need to use the fetchPictureBackgrounds methods directly.
      return fetchPictureBackgrounds('*')
    case 'solid_color':
      return fetchColorBackgrounds()
    case 'gradient':
      return fetchGradientBackgrounds()
    case 'pattern':
      return fetchPatternBackgrounds()
    case 'art_illustrations':
      return fetchArtBackgrounds()
    default:
      return fetchPictureBackgrounds('*')
  }
}
export default fetchBackgrounds
