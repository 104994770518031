// @file <pdlt-mention> custom HTML5 element. Used to render a mention on Padlet.
import type { Mentioner, UserMentionSuggestionAttributes } from '../types'
import { __ } from './intl'

const MENTION_ELEMENT_TAG_NAME = 'pdlt-mention'
const SUPPORTED_MENTION_TYPES = ['user'] as const
type MentionType = typeof SUPPORTED_MENTION_TYPES[number]
type Mentionee = Pick<UserMentionSuggestionAttributes, 'hashid' | 'username' | 'display_name'>

/**
 * A custom HTML5 element used to render a mention on Padlet.
 * If the type is "user", the element renders an anchor tag that links to the mentioned user's profile.
 *
 * @example
 * <pdlt-mention
 *   type="user"
 *   mentioner-id="yLA6m0oM"
 *   mentionee-id="bUYIm767"
 *   mentionee-username="pizza"
 * >
 *   Pizza Boy
 * </pdlt-mention>
 *
 * @param {string} type - The type of mention. Currently only `user` is supported.
 * @param {string} mentioner-id - The public ID of the user who is mentioning someone.
 * @param {string} mentionee-id - The public ID of the user who is being mentioned.
 * @param {string} mentionee-username - The username of the user who is being mentioned.
 */
class PdltMention extends HTMLElement {
  /**
   * Create a new PdltMention element from a set of attributes.
   */
  static fromAttributes({
    type,
    mentioner,
    mentionee,
  }: {
    type: MentionType
    mentioner: Mentioner
    mentionee: Mentionee
  }): PdltMention {
    // We must register this custom element before we can create a new instance of it.
    // Otherwise, we will get 'Illegal constructor' error. See https://stackoverflow.com/a/61883392/8943850.
    if (window.customElements.get(MENTION_ELEMENT_TAG_NAME) == null) throw Error('PdltMention is not registered yet')

    const pdltMention = new PdltMention()
    pdltMention.setAttribute('type', type)
    pdltMention.setAttribute('mentioner-id', mentioner.hashid)
    pdltMention.setAttribute('mentionee-id', mentionee.hashid)
    pdltMention.setAttribute('mentionee-username', mentionee.username)
    pdltMention.textContent = mentionee.display_name
    return pdltMention
  }

  /**
   * Create a new PdltMention element from an HTML string.
   */
  static fromString(html: string): PdltMention | null {
    // We must register this custom element before we can create a new instance of it.
    // Otherwise, we will get 'Illegal constructor' error. See https://stackoverflow.com/a/61883392/8943850.
    if (window.customElements.get(MENTION_ELEMENT_TAG_NAME) == null) throw Error('PdltMention is not registered yet')

    const template = document.createElement('template')
    template.innerHTML = html

    // The browser can correct invalid HTML (e.g. auto close tags).
    // When it happens, the innerHTML will be updated after being set.
    // We'll be strict here and reject the HTML if it needs to be corrected.
    if (template.innerHTML !== html) {
      return null
    }

    const element = template.content.firstElementChild
    if (element === null) return null
    const interestedAttributes = {
      type: element.getAttribute('type'),
      'mentioner-id': element.getAttribute('mentioner-id'),
      'mentionee-id': element.getAttribute('mentionee-id'),
      'mentionee-username': element.getAttribute('mentionee-username'),
      mentioneeDisplayName: element.textContent,
    }
    const pdltMention = new PdltMention()
    Object.entries(interestedAttributes).forEach(([key, value]) => {
      if (key === 'mentioneeDisplayName') {
        if (value !== null) pdltMention.textContent = value
      } else if (value !== null) {
        pdltMention.setAttribute(key, value)
      }
    })
    return pdltMention
  }

  get isValid(): boolean {
    if (this.type === 'user') {
      return (
        this.mentionerId !== null &&
        this.mentioneeId !== null &&
        this.mentioneeUsername !== null &&
        this.mentioneeDisplayName !== ''
      )
    }
    return false
  }

  get type(): MentionType | null {
    const rawType = this.getAttribute('type')
    if (rawType === null) return null
    const castedType = rawType as MentionType
    if (SUPPORTED_MENTION_TYPES.includes(castedType)) return castedType
    return null
  }

  get mentionerId(): string | null {
    return this.getAttribute('mentioner-id')
  }

  get mentioneeId(): string | null {
    return this.getAttribute('mentionee-id')
  }

  get mentioneeUsername(): string | null {
    return this.getAttribute('mentionee-username')
  }

  get mentioneeDisplayName(): string | null {
    return this.textContent
  }

  toString(): string {
    return this.outerHTML
  }

  connectedCallback(): void {
    if (this.type === 'user') {
      this.renderProfileLink()
    }
  }

  private renderProfileLink(): void {
    if (this.mentioneeUsername != null && this.mentioneeUsername !== '') {
      const profileLink = `https://${window.location.hostname}/${this.mentioneeUsername}`
      this.innerHTML = `<a href="${profileLink}" target="_blank" data-bridged-link="profile" data-bridged-link-username="${
        this.mentioneeUsername
      }">${this.mentioneeDisplayName ?? __('Someone')}</a>`
    }
  }
}

export { MENTION_ELEMENT_TAG_NAME, PdltMention, type MentionType }
