// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file folder.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

import { Timestamp } from "@bufbuild/protobuf";

export namespace Folder {
  export class FolderMessage extends Message<FolderMessage> {
    /**
     * @generated from field: uint64 id = 1;
     */
    id: bigint = protoInt64.zero;
    /**
     * @generated from field: string name = 2;
     */
    name: string = "";
    /**
     * @generated from field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt: Timestamp;
    /**
     * @generated from field: google.protobuf.Timestamp updated_at = 4;
     */
    updatedAt: Timestamp;
    /**
     * @generated from field: uint64 owner_id = 5;
     */
    ownerId: bigint = protoInt64.zero;
    /**
     * @generated from field: uint32 walls_count = 6;
     */
    wallsCount: number = 0;

    constructor(data?: PartialMessage<FolderMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.Folder";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "created_at", kind: "message", T: Timestamp },
      { no: 4, name: "updated_at", kind: "message", T: Timestamp },
      { no: 5, name: "owner_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 6, name: "walls_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FolderMessage {
      return new FolderMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FolderMessage {
      return new FolderMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FolderMessage {
      return new FolderMessage().fromJsonString(jsonString, options);
    }

      static equals(a: FolderMessage | PlainMessage<FolderMessage> | undefined, b: FolderMessage | PlainMessage<FolderMessage> | undefined): boolean {
      return proto3.util.equals(FolderMessage, a, b);
    }

    toObject(): Folder {
      return {
        id: this.id,
        name: this.name,
        createdAt: this.createdAt.toJson() as string,
        updatedAt: this.updatedAt.toJson() as string,
        ownerId: this.ownerId,
        wallsCount: this.wallsCount,
      };
    }

    static fromObject(object: Folder): FolderMessage {
      return new FolderMessage({
        id: object.id,
        name: object.name,
        createdAt: Timestamp.fromJson(object.createdAt),
        updatedAt: Timestamp.fromJson(object.updatedAt),
        ownerId: object.ownerId,
        wallsCount: object.wallsCount,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): Folder {
    return FolderMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: Folder): Uint8Array {
    return FolderMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.Folder
 */
export type Folder = {
  /**
   * @generated from field: uint64 id = 1;
   */
  id: bigint;
  /**
   * @generated from field: string name = 2;
   */
  name: string;
  /**
   * @generated from field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt: string;
  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 4;
   */
  updatedAt: string;
  /**
   * @generated from field: uint64 owner_id = 5;
   */
  ownerId: bigint;
  /**
   * @generated from field: uint32 walls_count = 6;
   */
  wallsCount: number;
}

