/**
 * @file Beethoven client types.
 */
import { Link } from '@padlet/arvo'

export type BeethovenContentCategory = 'photo' | 'video' | 'audio' | 'document' | 'file' | 'page' | 'unknown'
export type BeethovenContentSubcategory =
  | 'html'
  | 'pdf'
  | 'padlet'
  | 'doc'
  | 'spreadsheet'
  | 'presentation'
  | 'article'
  | 'slideshow'
  | 'gif'
export enum BeethovenLuminance {
  dark = 'dark',
  light = 'light',
  unknown = 'unknown',
}

export interface BeethovenDisplayAttributes {
  url: string
  content_category: BeethovenContentCategory
  content_subcategory: BeethovenContentSubcategory
  image_url: string
  image_width: number
  image_height: number
  image_aspect_ratio: number
  image_color: string
  image_luminance: BeethovenLuminance
  original_image_url?: string
  original_image_width?: number
  original_image_height?: number
  extension: string
  embed_code: string
  embed_width?: number
  embed_height?: number
  title: string
  description: string
  provider_image_url: string
  provider_name: string
  // updated_at: Date,
  provider_image_is_fallback: true
  metadata: {
    pretty_size?: string
    latlong?: [number, number]
    album?: string
    artist?: string
    duration?: number
    title?: string
    attachment_props?: Record<string, any>
    transcription_url?: string
  } | null
  show_meta: boolean
  is_tiny: boolean
  is_fallback: boolean
  is_downloadable: boolean
  is_transparent: boolean
}

export interface BeethovenDisplayOptions {
  width?: number
  locale?: string
  dpr?: number
}

export interface BeethovenDisplayQuery extends BeethovenDisplayOptions {
  url: string
}

export type Color = number[]

export interface Image {
  url: string
  width: number
  height: number
  color: string
  aspect_ratio: number
  is_transparent: boolean
  luminance: BeethovenLuminance
}

export type BeethovenLinkForValidation = Pick<
  Link,
  'content_category' | 'extension' | 'content_subcategory' | 'provider_name'
>

export type { Link }
