// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file wall.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

import { Timestamp } from "@bufbuild/protobuf";
import { Links } from "./Links";
import { User } from "./User";
import { WallBackground } from "./WallBackground";
import { WallPrivacyPolicy } from "./WallPrivacyPolicy";
import { WallReactionData } from "./WallReactionData";
import { WallThumbnail } from "./WallThumbnail";
import { WallWishArrangement } from "./WallWishArrangement";

/**
 * @generated from enum proto.ColorScheme
 */
export enum ColorScheme {
  /**
   * @generated from enum value: COLOR_SCHEME_UNSPECIFIED = 0;
   */
  Unspecified = 0,
  /**
   * @generated from enum value: COLOR_SCHEME_LIGHT = 1;
   */
  Light = 1,
  /**
   * @generated from enum value: COLOR_SCHEME_DARK = 2;
   */
  Dark = 2,
}

/**
 * @generated from enum proto.NewWishLocation
 */
export enum NewWishLocation {
  /**
   * @generated from enum value: NEW_WISH_LOCATION_UNSPECIFIED = 0;
   */
  Unspecified = 0,
  /**
   * @generated from enum value: NEW_WISH_LOCATION_TOP = 1;
   */
  Top = 1,
  /**
   * @generated from enum value: NEW_WISH_LOCATION_BOTTOM = 2;
   */
  Bottom = 2,
}

/**
 * @generated from enum proto.WallContentModeration
 */
export enum WallContentModeration {
  /**
   * @generated from enum value: WALL_CONTENT_MODERATION_UNSPECIFIED = 0;
   */
  Unspecified = 0,
  /**
   * @generated from enum value: WALL_CONTENT_MODERATION_AUTO = 1;
   */
  Auto = 1,
  /**
   * @generated from enum value: WALL_CONTENT_MODERATION_STUDENTS_ONLY = 2;
   */
  StudentsOnly = 2,
  /**
   * @generated from enum value: WALL_CONTENT_MODERATION_ALL = 3;
   */
  All = 3,
}

/**
 * @generated from enum proto.WishSize
 */
export enum WishSize {
  /**
   * @generated from enum value: WISH_SIZE_UNSPECIFIED = 0;
   */
  Unspecified = 0,
  /**
   * @generated from enum value: WISH_SIZE_WIDE = 2;
   */
  Wide = 2,
}

/**
 * @generated from enum proto.WallFormat
 */
export enum WallFormat {
  /**
   * @generated from enum value: WALL_FORMAT_UNSPECIFIED = 0;
   */
  Unspecified = 0,
  /**
   * @generated from enum value: WALL_FORMAT_CANVAS = 1;
   */
  Canvas = 1,
  /**
   * @generated from enum value: WALL_FORMAT_TIMELINE = 2 [deprecated = true];
   * @deprecated
   */
  Timeline = 2,
  /**
   * @generated from enum value: WALL_FORMAT_GRID = 3;
   */
  Grid = 3,
  /**
   * @generated from enum value: WALL_FORMAT_STREAM = 4;
   */
  Stream = 4,
  /**
   * @generated from enum value: WALL_FORMAT_WALL = 5;
   */
  Wall = 5,
  /**
   * @generated from enum value: WALL_FORMAT_MAP = 6;
   */
  Map = 6,
  /**
   * @generated from enum value: WALL_FORMAT_TIMELINE_V2 = 7;
   */
  TimelineV2 = 7,
  /**
   * @generated from enum value: WALL_FORMAT_SHELF = 8 [deprecated = true];
   * @deprecated
   */
  Shelf = 8,
  /**
   * @generated from enum value: WALL_FORMAT_TABLE = 9 [deprecated = true];
   * @deprecated
   */
  Table = 9,
}

export namespace Wall {
  export class WallMessage extends Message<WallMessage> {
    /**
     * @generated from field: uint64 id = 1;
     */
    id: bigint = protoInt64.zero;
    /**
     * @generated from field: string name = 2;
     */
    name: string = "";
    /**
     * @generated from field: string address = 3;
     */
    address: string = "";
    /**
     * @generated from field: string namespace = 4;
     */
    namespace: string = "";
    /**
     * @generated from field: string title = 5;
     */
    title: string = "";
    /**
     * @generated from field: string description = 6;
     */
    description: string = "";
    /**
     * @generated from field: proto.WallBackground background = 7;
     */
    background: WallBackground.WallBackgroundMessage;
    /**
     * @generated from field: proto.WallThumbnail thumbnail = 8;
     */
    thumbnail: WallThumbnail.WallThumbnailMessage;
    /**
     * @generated from field: string icon_url = 9;
     */
    iconUrl: string = "";
    /**
     * @generated from field: string headline = 10;
     */
    headline: string = "";
    /**
     * @generated from field: proto.WallFormat format = 11;
     */
    format: WallFormat = WallFormat.Unspecified;
    /**
     * @generated from field: bool should_notify = 12;
     */
    shouldNotify: boolean = false;
    /**
     * @generated from field: string public_key = 13;
     */
    publicKey: string = "";
    /**
     * @generated from field: string domain_name = 14;
     */
    domainName: string = "";
    /**
     * @generated from field: google.protobuf.Timestamp created_at = 15;
     */
    createdAt: Timestamp;
    /**
     * @generated from field: google.protobuf.Timestamp updated_at = 16;
     */
    updatedAt: Timestamp;
    /**
     * @generated from field: uint64 tenant_id = 17;
     */
    tenantId: bigint = protoInt64.zero;
    /**
     * @generated from field: proto.Links links = 18;
     */
    links: Links.LinksMessage;
    /**
     * @generated from field: uint64 theme_id = 19;
     */
    themeId: bigint = protoInt64.zero;
    /**
     * @generated from field: uint64 font_id = 20;
     */
    fontId: bigint = protoInt64.zero;
    /**
     * @generated from field: proto.ColorScheme color_scheme = 21;
     */
    colorScheme: ColorScheme = ColorScheme.Unspecified;
    /**
     * @generated from field: bool should_show_author = 22;
     */
    shouldShowAuthor: boolean = false;
    /**
     * @generated from field: bool is_remakeable = 23;
     */
    isRemakeable: boolean = false;
    /**
     * @generated from field: bool is_commentable = 24;
     */
    isCommentable: boolean = false;
    /**
     * @generated from field: proto.NewWishLocation new_wish_loc = 25;
     */
    newWishLoc: NewWishLocation = NewWishLocation.Unspecified;
    /**
     * @generated from field: proto.WallReactionData reaction_data = 26;
     */
    reactionData: WallReactionData.WallReactionDataMessage;
    /**
     * @generated from field: bool is_reactable = 27;
     */
    isReactable: boolean = false;
    /**
     * @generated from field: bool should_filter_profanity = 28;
     */
    shouldFilterProfanity: boolean = false;
    /**
     * @generated from field: bool should_require_approval = 29;
     */
    shouldRequireApproval: boolean = false;
    /**
     * @generated from field: uint64 cover_wish_id = 30;
     */
    coverWishId: bigint = protoInt64.zero;
    /**
     * @generated from field: bool is_premium = 31;
     */
    isPremium: boolean = false;
    /**
     * @generated from field: bool is_archived = 32;
     */
    isArchived: boolean = false;
    /**
     * @generated from field: uint64 upload_limit = 33;
     */
    uploadLimit: bigint = protoInt64.zero;
    /**
     * @generated from field: bool is_template = 34;
     */
    isTemplate: boolean = false;
    /**
     * @generated from field: bool is_title_bar_transparent = 35;
     */
    isTitleBarTransparent: boolean = false;
    /**
     * @generated from field: proto.User builder = 36;
     */
    builder: User.UserMessage;
    /**
     * @generated from field: proto.WallPrivacyPolicy privacy_policy = 37;
     */
    privacyPolicy: WallPrivacyPolicy.WallPrivacyPolicyMessage;
    /**
     * @generated from field: uint64 library_id = 38;
     */
    libraryId: bigint = protoInt64.zero;
    /**
     * @generated from field: proto.WallWishArrangement wish_arrangement = 39;
     */
    wishArrangement: WallWishArrangement.WallWishArrangementMessage;
    /**
     * @generated from field: proto.WallContentModeration content_moderation = 40;
     */
    contentModeration: WallContentModeration = WallContentModeration.Unspecified;
    /**
     * @generated from field: proto.WishSize wish_size = 41;
     */
    wishSize: WishSize = WishSize.Unspecified;

    constructor(data?: PartialMessage<WallMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.Wall";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 4, name: "namespace", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 5, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 7, name: "background", kind: "message", T: WallBackground.WallBackgroundMessage },
      { no: 8, name: "thumbnail", kind: "message", T: WallThumbnail.WallThumbnailMessage },
      { no: 9, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 10, name: "headline", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 11, name: "format", kind: "enum", T: proto3.getEnumType(WallFormat) },
      { no: 12, name: "should_notify", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 13, name: "public_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 14, name: "domain_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 15, name: "created_at", kind: "message", T: Timestamp },
      { no: 16, name: "updated_at", kind: "message", T: Timestamp },
      { no: 17, name: "tenant_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 18, name: "links", kind: "message", T: Links.LinksMessage },
      { no: 19, name: "theme_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 20, name: "font_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 21, name: "color_scheme", kind: "enum", T: proto3.getEnumType(ColorScheme) },
      { no: 22, name: "should_show_author", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 23, name: "is_remakeable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 24, name: "is_commentable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 25, name: "new_wish_loc", kind: "enum", T: proto3.getEnumType(NewWishLocation) },
      { no: 26, name: "reaction_data", kind: "message", T: WallReactionData.WallReactionDataMessage },
      { no: 27, name: "is_reactable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 28, name: "should_filter_profanity", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 29, name: "should_require_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 30, name: "cover_wish_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 31, name: "is_premium", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 32, name: "is_archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 33, name: "upload_limit", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 34, name: "is_template", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 35, name: "is_title_bar_transparent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 36, name: "builder", kind: "message", T: User.UserMessage },
      { no: 37, name: "privacy_policy", kind: "message", T: WallPrivacyPolicy.WallPrivacyPolicyMessage },
      { no: 38, name: "library_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 39, name: "wish_arrangement", kind: "message", T: WallWishArrangement.WallWishArrangementMessage },
      { no: 40, name: "content_moderation", kind: "enum", T: proto3.getEnumType(WallContentModeration) },
      { no: 41, name: "wish_size", kind: "enum", T: proto3.getEnumType(WishSize) },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WallMessage {
      return new WallMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WallMessage {
      return new WallMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WallMessage {
      return new WallMessage().fromJsonString(jsonString, options);
    }

      static equals(a: WallMessage | PlainMessage<WallMessage> | undefined, b: WallMessage | PlainMessage<WallMessage> | undefined): boolean {
      return proto3.util.equals(WallMessage, a, b);
    }

    toObject(): Wall {
      return {
        id: this.id,
        name: this.name,
        address: this.address,
        namespace: this.namespace,
        title: this.title,
        description: this.description,
        background: this.background.toObject(),
        thumbnail: this.thumbnail.toObject(),
        iconUrl: this.iconUrl,
        headline: this.headline,
        format: this.format,
        shouldNotify: this.shouldNotify,
        publicKey: this.publicKey,
        domainName: this.domainName,
        createdAt: this.createdAt.toJson() as string,
        updatedAt: this.updatedAt.toJson() as string,
        tenantId: this.tenantId,
        links: this.links.toObject(),
        themeId: this.themeId,
        fontId: this.fontId,
        colorScheme: this.colorScheme,
        shouldShowAuthor: this.shouldShowAuthor,
        isRemakeable: this.isRemakeable,
        isCommentable: this.isCommentable,
        newWishLoc: this.newWishLoc,
        reactionData: this.reactionData.toObject(),
        isReactable: this.isReactable,
        shouldFilterProfanity: this.shouldFilterProfanity,
        shouldRequireApproval: this.shouldRequireApproval,
        coverWishId: this.coverWishId,
        isPremium: this.isPremium,
        isArchived: this.isArchived,
        uploadLimit: this.uploadLimit,
        isTemplate: this.isTemplate,
        isTitleBarTransparent: this.isTitleBarTransparent,
        builder: this.builder.toObject(),
        privacyPolicy: this.privacyPolicy.toObject(),
        libraryId: this.libraryId,
        wishArrangement: this.wishArrangement.toObject(),
        contentModeration: this.contentModeration,
        wishSize: this.wishSize,
      };
    }

    static fromObject(object: Wall): WallMessage {
      return new WallMessage({
        id: object.id,
        name: object.name,
        address: object.address,
        namespace: object.namespace,
        title: object.title,
        description: object.description,
        background: WallBackground.WallBackgroundMessage.fromObject(object.background),
        thumbnail: WallThumbnail.WallThumbnailMessage.fromObject(object.thumbnail),
        iconUrl: object.iconUrl,
        headline: object.headline,
        format: object.format,
        shouldNotify: object.shouldNotify,
        publicKey: object.publicKey,
        domainName: object.domainName,
        createdAt: Timestamp.fromJson(object.createdAt),
        updatedAt: Timestamp.fromJson(object.updatedAt),
        tenantId: object.tenantId,
        links: Links.LinksMessage.fromObject(object.links),
        themeId: object.themeId,
        fontId: object.fontId,
        colorScheme: object.colorScheme,
        shouldShowAuthor: object.shouldShowAuthor,
        isRemakeable: object.isRemakeable,
        isCommentable: object.isCommentable,
        newWishLoc: object.newWishLoc,
        reactionData: WallReactionData.WallReactionDataMessage.fromObject(object.reactionData),
        isReactable: object.isReactable,
        shouldFilterProfanity: object.shouldFilterProfanity,
        shouldRequireApproval: object.shouldRequireApproval,
        coverWishId: object.coverWishId,
        isPremium: object.isPremium,
        isArchived: object.isArchived,
        uploadLimit: object.uploadLimit,
        isTemplate: object.isTemplate,
        isTitleBarTransparent: object.isTitleBarTransparent,
        builder: User.UserMessage.fromObject(object.builder),
        privacyPolicy: WallPrivacyPolicy.WallPrivacyPolicyMessage.fromObject(object.privacyPolicy),
        libraryId: object.libraryId,
        wishArrangement: WallWishArrangement.WallWishArrangementMessage.fromObject(object.wishArrangement),
        contentModeration: object.contentModeration,
        wishSize: object.wishSize,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): Wall {
    return WallMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: Wall): Uint8Array {
    return WallMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.Wall
 */
export type Wall = {
  /**
   * @generated from field: uint64 id = 1;
   */
  id: bigint;
  /**
   * @generated from field: string name = 2;
   */
  name: string;
  /**
   * @generated from field: string address = 3;
   */
  address: string;
  /**
   * @generated from field: string namespace = 4;
   */
  namespace: string;
  /**
   * @generated from field: string title = 5;
   */
  title: string;
  /**
   * @generated from field: string description = 6;
   */
  description: string;
  /**
   * @generated from field: proto.WallBackground background = 7;
   */
  background: WallBackground;
  /**
   * @generated from field: proto.WallThumbnail thumbnail = 8;
   */
  thumbnail: WallThumbnail;
  /**
   * @generated from field: string icon_url = 9;
   */
  iconUrl: string;
  /**
   * @generated from field: string headline = 10;
   */
  headline: string;
  /**
   * @generated from field: proto.WallFormat format = 11;
   */
  format: WallFormat;
  /**
   * @generated from field: bool should_notify = 12;
   */
  shouldNotify: boolean;
  /**
   * @generated from field: string public_key = 13;
   */
  publicKey: string;
  /**
   * @generated from field: string domain_name = 14;
   */
  domainName: string;
  /**
   * @generated from field: google.protobuf.Timestamp created_at = 15;
   */
  createdAt: string;
  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 16;
   */
  updatedAt: string;
  /**
   * @generated from field: uint64 tenant_id = 17;
   */
  tenantId: bigint;
  /**
   * @generated from field: proto.Links links = 18;
   */
  links: Links;
  /**
   * @generated from field: uint64 theme_id = 19;
   */
  themeId: bigint;
  /**
   * @generated from field: uint64 font_id = 20;
   */
  fontId: bigint;
  /**
   * @generated from field: proto.ColorScheme color_scheme = 21;
   */
  colorScheme: ColorScheme;
  /**
   * @generated from field: bool should_show_author = 22;
   */
  shouldShowAuthor: boolean;
  /**
   * @generated from field: bool is_remakeable = 23;
   */
  isRemakeable: boolean;
  /**
   * @generated from field: bool is_commentable = 24;
   */
  isCommentable: boolean;
  /**
   * @generated from field: proto.NewWishLocation new_wish_loc = 25;
   */
  newWishLoc: NewWishLocation;
  /**
   * @generated from field: proto.WallReactionData reaction_data = 26;
   */
  reactionData: WallReactionData;
  /**
   * @generated from field: bool is_reactable = 27;
   */
  isReactable: boolean;
  /**
   * @generated from field: bool should_filter_profanity = 28;
   */
  shouldFilterProfanity: boolean;
  /**
   * @generated from field: bool should_require_approval = 29;
   */
  shouldRequireApproval: boolean;
  /**
   * @generated from field: uint64 cover_wish_id = 30;
   */
  coverWishId: bigint;
  /**
   * @generated from field: bool is_premium = 31;
   */
  isPremium: boolean;
  /**
   * @generated from field: bool is_archived = 32;
   */
  isArchived: boolean;
  /**
   * @generated from field: uint64 upload_limit = 33;
   */
  uploadLimit: bigint;
  /**
   * @generated from field: bool is_template = 34;
   */
  isTemplate: boolean;
  /**
   * @generated from field: bool is_title_bar_transparent = 35;
   */
  isTitleBarTransparent: boolean;
  /**
   * @generated from field: proto.User builder = 36;
   */
  builder: User;
  /**
   * @generated from field: proto.WallPrivacyPolicy privacy_policy = 37;
   */
  privacyPolicy: WallPrivacyPolicy;
  /**
   * @generated from field: uint64 library_id = 38;
   */
  libraryId: bigint;
  /**
   * @generated from field: proto.WallWishArrangement wish_arrangement = 39;
   */
  wishArrangement: WallWishArrangement;
  /**
   * @generated from field: proto.WallContentModeration content_moderation = 40;
   */
  contentModeration: WallContentModeration;
  /**
   * @generated from field: proto.WishSize wish_size = 41;
   */
  wishSize: WishSize;
}

proto3.util.setEnumType(ColorScheme, "proto.ColorScheme", [
  { no: 0, name: "COLOR_SCHEME_UNSPECIFIED" },
  { no: 1, name: "COLOR_SCHEME_LIGHT" },
  { no: 2, name: "COLOR_SCHEME_DARK" },
]);

proto3.util.setEnumType(NewWishLocation, "proto.NewWishLocation", [
  { no: 0, name: "NEW_WISH_LOCATION_UNSPECIFIED" },
  { no: 1, name: "NEW_WISH_LOCATION_TOP" },
  { no: 2, name: "NEW_WISH_LOCATION_BOTTOM" },
]);

proto3.util.setEnumType(WallContentModeration, "proto.WallContentModeration", [
  { no: 0, name: "WALL_CONTENT_MODERATION_UNSPECIFIED" },
  { no: 1, name: "WALL_CONTENT_MODERATION_AUTO" },
  { no: 2, name: "WALL_CONTENT_MODERATION_STUDENTS_ONLY" },
  { no: 3, name: "WALL_CONTENT_MODERATION_ALL" },
]);

proto3.util.setEnumType(WishSize, "proto.WishSize", [
  { no: 0, name: "WISH_SIZE_UNSPECIFIED" },
  { no: 2, name: "WISH_SIZE_WIDE" },
]);

proto3.util.setEnumType(WallFormat, "proto.WallFormat", [
  { no: 0, name: "WALL_FORMAT_UNSPECIFIED" },
  { no: 1, name: "WALL_FORMAT_CANVAS" },
  { no: 2, name: "WALL_FORMAT_TIMELINE" },
  { no: 3, name: "WALL_FORMAT_GRID" },
  { no: 4, name: "WALL_FORMAT_STREAM" },
  { no: 5, name: "WALL_FORMAT_WALL" },
  { no: 6, name: "WALL_FORMAT_MAP" },
  { no: 7, name: "WALL_FORMAT_TIMELINE_V2" },
  { no: 8, name: "WALL_FORMAT_SHELF" },
  { no: 9, name: "WALL_FORMAT_TABLE" },
]);

