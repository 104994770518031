// @file setup necessary data for arvo
import { FetchOptions, HTTPAuthenticationScheme } from '@padlet/fetch'
import { AuthenticationToken, Environment } from './types'

const hasWindow = typeof window !== 'undefined' && window !== null
const getDefaultJsonApiHost = (hostname: string): string => {
  if (/\.dev$/.exec(hostname)) {
    return 'api.padlet.dev'
  } else if (/\.io$/.exec(hostname)) {
    if (hostname.endsWith('.padlet.io')) {
      // for per branch staging environments (xxx.padlet.io), just reuse the hostname, api.padlet.io will connect to the "main" staging instance group
      return hostname
    }
    return 'api.padlet.io'
  } else {
    return 'api.padlet.com'
  }
}

// Use when necessary
// const isReactNative = typeof navigator !== 'undefined' && navigator.product === 'ReactNative'
// const isBrowser = typeof document !== 'undefined'
const defaultHost = hasWindow && window.location && window.location.host ? window.location.host : 'padlet.com'
const defaultCdnHost = 'assets.padletcdn.com'
const betaJsonApiHost = 'api-beta.padlet.com'
const defaultJsonApiHost =
  hasWindow && window.location && window.location.hostname
    ? getDefaultJsonApiHost(window.location.hostname)
    : 'api.padlet.com'
const defaultJsonApiVersion = 3
const defaultEnvironment: Environment = {
  host: defaultHost,
  cdnHost: defaultCdnHost,
  jsonApiHost: defaultJsonApiHost,
  jsonApiVersion: defaultJsonApiVersion,
  token: {},
  isBeta: false,
}
let currentEnvironment: Environment = { token: {} }
let configStatePromise: Promise<Environment> | null = null
let isConfigured = false

interface ConfigOptions {
  host?: string
  cdnHost?: string
  jsonApiHost?: string
  jsonApiVersion?: number
  token: string | AuthenticationToken
  isBeta?: boolean
}

/**
 * Setup our environment so Arvo is equipped with data required for
 * successful querying of APIs.
 * @param options Configuration options.
 * @return The same environment (merged with default options) that have been passed.
 */
async function configure(options?: ConfigOptions): Promise<Environment> {
  configStatePromise = new Promise((resolve: Function) => {
    const defaultFetchOptions: FetchOptions = defaultEnvironment.fetchOptions || {}
    let token: AuthenticationToken | string = {}
    const env: Environment = { token }
    if (options) {
      if (options.host) env.host = options.host
      if (options.cdnHost) env.cdnHost = options.cdnHost
      if (options.jsonApiHost) env.jsonApiHost = options.jsonApiHost
      if (options.jsonApiVersion) env.jsonApiVersion = options.jsonApiVersion
      if (options.token) token = options.token
      if (options.isBeta) {
        env.isBeta = options.isBeta
        env.jsonApiHost = betaJsonApiHost
      }
    }

    // A string token is most likely a JSON hash that's been stringified
    if (token && typeof token === 'string') {
      // this will fail loudly if the string isn't a JSON string.
      token = JSON.parse(token) as AuthenticationToken
    }

    env.token = token as AuthenticationToken
    const { oauthToken, csrfToken } = env.token
    if (oauthToken) {
      defaultFetchOptions.authorization = [HTTPAuthenticationScheme.bearer, oauthToken]
    } else if (csrfToken) {
      if (typeof csrfToken !== 'function') {
        const csrf = csrfToken
        env.token.csrfToken = (): string => csrf
      }
    }

    currentEnvironment = {
      ...defaultEnvironment,
      ...env,
      fetchOptions: defaultFetchOptions,
    }

    isConfigured = true
    resolve(currentEnvironment)
  })
  return configStatePromise
}

/**
 * Gets the current environment as it is setup.
 * @returns Curently configured environment.
 */
function getConfiguration(): Environment {
  if (!isConfigured) {
    throw new Error(`Not configured. Arvo requires a call to "configure" before it should be used.`)
  }
  return currentEnvironment
}

function deferredGetConfiguration(): Promise<Environment> {
  if (configStatePromise == null) throw new Error('Arvo is not yet configured.')
  else return configStatePromise
}

/**
 * Resets the configuration, so the environment Arvo operates
 * in is a fresh slate.
 * @returns [[Environment]] we use to prepare for queries to APIs.
 */
function resetConfiguration(): Environment {
  currentEnvironment = { token: {} }
  isConfigured = false
  configStatePromise = null
  return currentEnvironment
}

export { getConfiguration, configure, resetConfiguration, deferredGetConfiguration }
