// @file fetch link with json api
import { Link } from './types'
import { jsonApiFetch, JsonAPIResponse } from './json_api_fetch'

/**
 * Fetch a link object and response in Json API format
 * @param url url to fetch
 */
export default function fetchLink(url: string): Promise<JsonAPIResponse<Link>> {
  return jsonApiFetch<Link>('links', { query: { url } })
}
