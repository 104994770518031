/**
 * @file Registers key bindings used for mention autocomplete. When a
 * certain key is pressed, a custom event is dispatched on the element
 * signaling the respective functionality should be triggered.
 *
 * Tested on input[type=text], textarea, and trix-editor.
 *
 * Example usage:
 *   <script setup>
 *   function showMentionBox(e) {
 *     console.info('showMentionBox event', e)
 *   }
 *   function hideMentionBox(e) {
 *     console.info('hideMentionBox event', e)
 *   }
 *   </script>
 *   <template>
 *     <OzInput
 *       v-mention-autocomplete
 *       @show-mention-box.native="showMentionBox"
 *       @hide-mention-box.native="hideMentionBox"
 *     />
 *   </template>
 *
 *   Notice the `.native` modifier. This is needed because the event we
 *   emit is not a Vue component event, but a native DOM event.
 *
 *   The emitted event:
 *   - has the `type` property to be one of the actions declared in
 *     `KEY_TO_EVENT_NAME_MAP`.
 *   - has a `originalEvent` property which contains the original
 *     `KeyboardEvent` object, allowing `preventDefault` and `stopPropagation`.
 *   - has a `caretCoordinates` property which contains the coordinates of
 *     the text cursor. Only available when the event is `show-mention-box`.
 */

// Vue 3 directive type isn't compatible with how we register directives
// using Options API so we have to import the type from Vue 2. This way,
// the directive can be used in both Vue 2 Options API syntax and Vue 3
// Composition API syntax.
import { getTextCaretPosition } from '@@/bits/dom'
import type { CompatibleInputEvent } from '@@/bits/input'
import { isPrintableCharacter, normalizeInputEvent } from '@@/bits/input'
import { SelectedMentionSuggestionKeyboardEvent } from '@@/bits/mentions'
import type { Id, UserMentionSuggestion } from '@@/types'
import type { DirectiveOptions } from 'vue/types/options'

/*
 * Transform keyboard events into mention events
 */

type MentionEventType =
  | 'show-mention-box'
  | 'hide-mention-box'
  | 'search-suggestions'
  | 'highlight-previous-suggestion'
  | 'highlight-next-suggestion'
  | 'pick-suggestion'

interface Coordinates {
  top: number
  left: number
}

class MentionEvent extends CustomEvent<Event> {
  originalEvent: Event
  postId?: Id
  caretCoordinates?: Coordinates
  mentionRange?: [number, number]
  searchTerm?: string
  selectedSuggestion?: UserMentionSuggestion

  constructor(
    type: MentionEventType,
    originalEvent: Event,
    metadata?: {
      postId?: Id
      caretCoordinates?: Coordinates
      mentionRange?: [number, number]
      searchTerm?: string
      selectedSuggestion?: UserMentionSuggestion
    },
  ) {
    super(type, { detail: originalEvent, bubbles: true, cancelable: true })
    this.originalEvent = originalEvent
    this.postId = metadata?.postId
    this.caretCoordinates = metadata?.caretCoordinates
    this.mentionRange = metadata?.mentionRange
    this.searchTerm = metadata?.searchTerm
    this.selectedSuggestion = metadata?.selectedSuggestion
  }
}

/*
 * Parsing keyboard events
 */

type MentionSupportedElement = HTMLElement & {
  _mentionRange?: [number, number]
  _onInput?: (e: Event) => void
  _onKeydown?: (e: KeyboardEvent) => void
  _onKeyup?: (e: KeyboardEvent) => void
  _onBlur?: (e: FocusEvent) => void
}

const getCaretCoordinates = (element: Node, position?: number): Coordinates | undefined => {
  if (element.nodeName === 'TEXTAREA' || element.nodeName === 'INPUT') {
    const el = element as HTMLTextAreaElement | HTMLInputElement
    return getTextCaretPosition(el, position ?? el.selectionStart ?? 0, { relativeToViewport: true })
  } else {
    const el = element as HTMLElement & { editor: any }
    let rect
    if (el.nodeName === 'TRIX-EDITOR') {
      position = position ?? el.editor.getSelectedRange()[0] ?? 0
      rect = el.editor.getClientRectAtPosition(position)
    } else if (el.classList.contains('ProseMirror')) {
      const selectionStart = el.editor.view.state.selection.from
      rect = el.editor.view.coordsAtPos(selectionStart)
    }

    // When `editor.getClientRectAtPosition` is called when the text editor is previously
    // empty, it may return `null`. In that case, we return the rect of the whole editor.
    if (rect == null) {
      const parentWithDir = el.closest('[dir]')
      const isRtl = parentWithDir?.getAttribute('dir') === 'rtl' ?? false
      const editorRect = el.getBoundingClientRect()
      return {
        top: editorRect.top,
        left: isRtl ? editorRect.right : editorRect.left,
      }
    }
    return rect
  }
}

const getSelectionRange = (element: Node): [number, number] => {
  if (element.nodeName === 'TEXTAREA' || element.nodeName === 'INPUT') {
    const el = element as HTMLTextAreaElement | HTMLInputElement
    if (el.selectionStart == null || el.selectionEnd == null) {
      return [-1, -1]
    }
    return [el.selectionStart, el.selectionEnd]
  }
  if (element.nodeName === 'TRIX-EDITOR') {
    const el = element as HTMLElement & { editor: any }
    const range = el.editor.getSelectedRange()
    return range == null ? [-1, -1] : range
  }
  if ((element as HTMLElement).classList.contains('ProseMirror')) {
    const el = element as HTMLElement & { editor: any }
    const { from, to } = el.editor.view.state.selection
    return [from, to]
  }
  return [-1, -1]
}

const isRangeValid = (range: [number, number]): boolean => {
  return range[0] !== -1 && range[1] !== -1
}

const getTextAtRange = (element: Node, range: [number, number]): string => {
  if (element.nodeName === 'TEXTAREA' || element.nodeName === 'INPUT') {
    const el = element as HTMLTextAreaElement | HTMLInputElement
    el.value.substring(range[0], range[1])
  }
  if (element.nodeName === 'TRIX-EDITOR') {
    const el = element as HTMLElement & { editor: any }
    return el.editor.getDocument()?.getStringAtRange(range) ?? ''
  }
  if ((element as HTMLElement).classList.contains('ProseMirror')) {
    const el = element as HTMLElement & { editor: any }
    return el.editor.state.doc.textBetween(range[0], range[1], '')
  }
  return ''
}

const isCursorOutsideMentionRange = (cursorRange: [number, number], mentionRange: [number, number]): boolean => {
  // We consider the cursor is outside the mention range when:
  // - The cursor range start is before the `@` character (<=)
  // - The cursor range end is after the mention range end (>)
  return cursorRange[0] <= mentionRange[0] || cursorRange[1] > mentionRange[1]
}

const getCharacterAtIndex = (element: Node, index: number): string => {
  if (index < 0) return ''
  if (element.nodeName === 'TEXTAREA' || element.nodeName === 'INPUT') {
    const el = element as HTMLTextAreaElement | HTMLInputElement
    const text = el.value
    return text[index] ?? ''
  }
  if (element.nodeName === 'TRIX-EDITOR') {
    const el = element as HTMLElement & { editor: any }
    const text = el.editor.getDocument().toString()
    return text[index] ?? ''
  }
  if ((element as HTMLElement).classList.contains('ProseMirror')) {
    const el = element as HTMLElement & { editor: any }
    return el.editor.state.doc.textBetween(index, index + 1, '')
  }
  return ''
}

const isPrecededByWhitespace = (element: Node, atCharIndex: number): boolean => {
  if (atCharIndex < 0) return false
  if (atCharIndex === 0) return true
  const char = getCharacterAtIndex(element, atCharIndex - 1)
  return char === '' || /\s/.test(char)
}

const isCursorWithinBlock = (element: Node, blockType: 'sup' | 'sub' | 'math' | 'code'): boolean => {
  if (element.nodeName === 'TRIX-EDITOR') {
    const el = element as HTMLElement & { editor: any }
    return el.editor.attributeIsActive(blockType)
  }
  return false
}

const parseInputEvent = (e: CompatibleInputEvent, el: MentionSupportedElement, postId?: Id): any => {
  const textInputEl = e.originalEvent.target as Node
  const isInsertingText = e.inputType === 'insertText' || e.inputType === 'insertCompositionText'
  // Both `InputEvent` and `KeyboardEvent` are emitted when deleting text with the backspace key so this function
  // is called twice. We only need to handle it once.
  const isDeletingTextBackward =
    e.originalEvent instanceof InputEvent &&
    (e.inputType === 'deleteContentBackward' || e.inputType === 'deleteWordBackward')
  const isDeletingLineBackward = e.originalEvent instanceof InputEvent && e.inputType === 'deleteSoftLineBackward'
  const isNavigatingText = e.navigationType === 'cursorLeft' || e.navigationType === 'cursorRight'
  if (isInsertingText && e.data != null) {
    // Some languages (like Japanese) use a different variation of the `@` character.
    const isStartingNewMention = e.data === '@' || e.data === '＠'
    const isStoppingMention = e.data === ' '
    if (isStartingNewMention) {
      if (
        el._mentionRange != null ||
        isCursorWithinBlock(textInputEl, 'sup') ||
        isCursorWithinBlock(textInputEl, 'sub') ||
        isCursorWithinBlock(textInputEl, 'code') ||
        isCursorWithinBlock(textInputEl, 'math')
      )
        return
      const range = getSelectionRange(textInputEl)
      if (!isRangeValid(range)) return
      // After typing `@`, the selection range is [n, n] but the mention range should be
      // [n - 1, n] because the `@` character is included in the mention. However, some
      // languages may treat the`@` as part of a composition so the selection range already
      // includes it([n - 1, n]).We don't need to correct the range in that case.
      const needsCorrection = e.data.length === 1 && range[0] === range[1]
      if (needsCorrection) range[0] = range[0] - e.data.length
      if (!isPrecededByWhitespace(textInputEl, range[0])) return
      // If the range is at the end of the line (new line character), the calculated coordinates
      // will be incorrect. In this case, we can just shift the range by one character to the start.
      const isAtEndOfLine = getCharacterAtIndex(textInputEl, range[1]) === '\n'
      const caretCoordinates = isAtEndOfLine
        ? getCaretCoordinates(textInputEl, range[1] - 1)
        : getCaretCoordinates(textInputEl)
      const event = new MentionEvent('show-mention-box', e.originalEvent, { postId, caretCoordinates })
      el.dispatchEvent(event)
      el._mentionRange = range
    } else if (isStoppingMention) {
      const event = new MentionEvent('hide-mention-box', e.originalEvent, { postId })
      el.dispatchEvent(event)
      el._mentionRange = undefined
    } else {
      if (el._mentionRange == null) return
      // As we insert more text, the mention range expands.
      const range = getSelectionRange(textInputEl)
      if (isRangeValid(range)) {
        el._mentionRange = [el._mentionRange[0], range[1]]
      }
      const searchTerm = getTextAtRange(textInputEl, el._mentionRange).substring(1)
      const event = new MentionEvent('search-suggestions', e.originalEvent, {
        postId,
        searchTerm,
      })
      el.dispatchEvent(event)
    }
  } else if (isDeletingTextBackward) {
    if (el._mentionRange != null) {
      // As we delete, the mention range shrinks. We can assume that the new range ends at the
      // current cursor position.
      const range = getSelectionRange(textInputEl)
      if (!isRangeValid(range)) return
      el._mentionRange = [el._mentionRange[0], range[1]]
      const isRangeInvalid = el._mentionRange[0] >= el._mentionRange[1]
      const text = getTextAtRange(textInputEl, el._mentionRange)
      const isMentionDeleted = isRangeInvalid || (!text.startsWith('@') && !text.startsWith('＠'))
      if (isMentionDeleted) {
        const event = new MentionEvent('hide-mention-box', e.originalEvent, { postId })
        el.dispatchEvent(event)
        el._mentionRange = undefined
      } else {
        const searchTerm = text.substring(1)
        const event = new MentionEvent('search-suggestions', e.originalEvent, {
          postId,
          searchTerm,
        })
        el.dispatchEvent(event)
      }
    }
  } else if (isDeletingLineBackward) {
    if (el._mentionRange != null) {
      // When user deletes a line, there shouldn't be any text left -> hide the mention box.
      const event = new MentionEvent('hide-mention-box', e.originalEvent, { postId })
      el.dispatchEvent(event)
      el._mentionRange = undefined
    }
  } else if (isNavigatingText) {
    // When user has already typed a mention, if the cursor moves outside the mention, hide the mention box.
    if (el._mentionRange != null) {
      const range = getSelectionRange(textInputEl)
      if (isRangeValid(range) && isCursorOutsideMentionRange(range, el._mentionRange)) {
        const event = new MentionEvent('hide-mention-box', e.originalEvent, { postId })
        el.dispatchEvent(event)
        el._mentionRange = undefined
      }
    }
  }
  // TODO:
  // - handle deletion of an inserted mention -> delete whole text
  // - handle selection of an inserted mention -> select whole text
  // - handle highlighting/picking a suggestion -> insert text
}

/*
 * Initialize the directive
 */

const registerEventHandlers = (el: MentionSupportedElement, postId?: Id): void => {
  el._onInput = (e: Event) => {
    parseInputEvent(normalizeInputEvent(e as InputEvent), el, postId)
  }
  el._onKeydown = (e: KeyboardEvent) => {
    // Android is weird, it emits Unidentified keyup/keydown events :(
    const isUnidentified = e.key === 'Unidentified' || e.keyCode === 229
    if (isUnidentified) return
    const isMetaA = e.metaKey && e.key === 'a'
    const isMetaZ = e.metaKey && e.key === 'z'
    const isEscape = e.key === 'Escape'
    const isArrowUp = e.key === 'ArrowUp'
    const isArrowDown = e.key === 'ArrowDown'
    const isTabOrEnter = e.key === 'Tab' || e.key === 'Enter'
    if (isMetaA && el._mentionRange != null) {
      const event = new MentionEvent('hide-mention-box', e, { postId })
      el.dispatchEvent(event)
      el._mentionRange = undefined
    }
    if ((isMetaZ || isEscape) && el._mentionRange != null) {
      if (isEscape) {
        // Don't propagate this event since it's supposed to hide the mention box only.
        e.stopPropagation()
      }
      const event = new MentionEvent('hide-mention-box', e, { postId })
      el.dispatchEvent(event)
      el._mentionRange = undefined
    }
    if ((isArrowUp || isArrowDown) && el._mentionRange != null) {
      e.stopPropagation()
      e.preventDefault()
      if (isArrowUp) {
        const event = new MentionEvent('highlight-previous-suggestion', e, { postId })
        el.dispatchEvent(event)
      }
      if (isArrowDown) {
        const event = new MentionEvent('highlight-next-suggestion', e, { postId })
        el.dispatchEvent(event)
      }
    }
    if (isTabOrEnter && el._mentionRange != null) {
      e.stopPropagation()
      e.preventDefault()
      const event = new MentionEvent('pick-suggestion', e, {
        postId,
        mentionRange: el._mentionRange,
        selectedSuggestion: e instanceof SelectedMentionSuggestionKeyboardEvent ? e.selectedSuggestion : undefined,
      })
      el.dispatchEvent(event)
      el._mentionRange = undefined
    }
  }
  el._onKeyup = (e: KeyboardEvent) => {
    // Android is weird, it emits Unidentified keyup/keydown events :(
    const isUnidentified = e.key === 'Unidentified' || e.keyCode === 229
    if (!isUnidentified && !isPrintableCharacter(e.key)) {
      parseInputEvent(normalizeInputEvent(e), el, postId)
    }
  }
  el._onBlur = (e: FocusEvent) => {
    const event = new MentionEvent('hide-mention-box', e, { postId })
    el.dispatchEvent(event)
    el._mentionRange = undefined
  }

  el.addEventListener('input', el._onInput)
  el.addEventListener('keydown', el._onKeydown)
  el.addEventListener('keyup', el._onKeyup)
  el.addEventListener('blur', el._onBlur)
}

const removeEventHandlers = (el: MentionSupportedElement): void => {
  if (el._onInput != null) {
    el.removeEventListener('input', el._onInput)
    el._onInput = undefined
  }
  if (el._onKeydown != null) {
    el.removeEventListener('keydown', el._onKeydown)
    el._onKeydown = undefined
  }
  if (el._onKeyup != null) {
    el.removeEventListener('keyup', el._onKeyup)
    el._onKeyup = undefined
  }
  if (el._onBlur != null) {
    el.removeEventListener('blur', el._onBlur)
    el._onBlur = undefined
  }
}

const directive: DirectiveOptions = {
  bind: (el, binding) => {
    if (binding.value === false) return
    registerEventHandlers(el)
  },
  unbind: (el) => {
    removeEventHandlers(el)
  },
}

export { MentionEvent }
export default directive
