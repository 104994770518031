var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"app"}},[(_setup.isSlideshow)?[(!_setup.isOnline)?_c(_setup.SurfaceOffline):_vm._e(),_vm._v(" "),_c(_setup.SurfaceSlideshow)]:(_setup.isGeneratingPDF)?[(!_setup.isOnline)?_c(_setup.SurfaceOffline):_vm._e(),_vm._v(" "),_c(_setup.SurfaceExportsDocumentStatus)]:[(_setup.isDemoPadletLandingStep)?[(!_setup.isOnline)?_c(_setup.SurfaceOffline):_vm._e(),_vm._v(" "),_c(_setup.SurfaceOnboardingDemoPadletLanding,{staticClass:"absolute inset-0 z-10"})]:_vm._e(),_vm._v(" "),(!_setup.isOnline)?_c(_setup.SurfaceOffline):_vm._e(),_vm._v(" "),_c('div',{class:[
        'flex flex-1',
        _setup.hasSidePanelOutsideSurface && {
          'flex-col h-vh100': _setup.isSmallerThanTabletPortrait,
          'h-full': !_setup.isSmallerThanTabletPortrait,
        },
      ],attrs:{"dir":_setup.dir()}},[(!_setup.device.app)?_c(_setup.SkipToContentLink,{attrs:{"content-element-selector":"#wish-list"}}):_vm._e(),_vm._v(" "),(!_setup.isWhiteboard)?_c(_setup.SurfaceContainer,{class:[
          // We add positive mb here and negative mt on the SurfaceOnboardingGalleryPanelMobile to create black background between
          _setup.isSidePanelRounded && {
            'max-h-vh100 h-auto grow': _setup.isSmallerThanTabletPortrait,
            'overflow-x-auto overflow-y-hidden': !_setup.isSmallerThanTabletPortrait,
            // We treat the surface action bar normally when on demo padlet panel, because we don't support mobile demo padlet panel
            'mb-4 tablet-portrait:mb-0': !_setup.isDemoPadletPanel,
          },
        ],style:(_setup.hasSidePanelOutsideSurface && _setup.isSmallerThanTabletPortrait
            ? { height: `calc(var(--vh100) - ${_setup.surfaceMobileSidePanelHeight}` }
            : {}),attrs:{"id":"surface-container","inert":_setup.isDemoPadletLandingStep}}):_c(_setup.WhiteboardContainer,{class:[
          'relative',
          // We add positive mb here and negative mt on the SurfaceOnboardingGalleryPanelMobile to create black background between
          _setup.hasSidePanelOutsideSurface && {
            'max-h-vh100 h-auto grow mb-4': _setup.isSmallerThanTabletPortrait,
            'overflow-x-auto overflow-y-hidden': !_setup.isSmallerThanTabletPortrait,
          },
        ],style:(_setup.hasSidePanelOutsideSurface && _setup.isSmallerThanTabletPortrait
            ? { height: `calc(var(--vh100) - ${_setup.surfaceMobileSidePanelHeight}` }
            : {}),attrs:{"id":"surface-container"}}),_vm._v(" "),(_setup.xOnboardingPanel)?[(_setup.isSmallerThanTabletPortrait)?_c(_setup.SurfaceOnboardingPanelMobile,{ref:"surfaceMobileSidePanel",on:{"hook:mounted":_setup.handleMobileSidePanelMounted}}):_c(_setup.SurfaceOnboardingPanel,{attrs:{"data-testid":"surfaceOnboardingPanel"}})]:_vm._e(),_vm._v(" "),_c('Transition',{attrs:{"name":_setup.isSmallerThanDesktop ? 'bounce-small' : 'bounce'}},[(_setup.xSurfaceAIChatPanel)?_c(_setup.SurfaceAIChatPanel):_vm._e()],1),_vm._v(" "),(_setup.xSurfaceGradingPanel)?[(_setup.isSmallerThanTabletPortrait)?_c(_setup.SurfaceGradingPanelMobile,{ref:"surfaceMobileSidePanel",on:{"hook:mounted":_setup.handleMobileSidePanelMounted}}):_c(_setup.SurfaceGradingPanel,{attrs:{"data-testid":"surfaceGradingPanel"}})]:_vm._e()],2),_vm._v(" "),(_setup.canIPost && !_setup.isWhiteboard)?_c(_setup.SurfaceDragAndDropOverlay):_vm._e(),_vm._v(" "),_c(_setup.GlobalSnackbar),_vm._v(" "),_c(_setup.GlobalMentionAutocomplete),_vm._v(" "),(_setup.isAppUsing('showCommandPalette') && _setup.xCommandDialog && !_setup.isDemoPadletPanelDesktop)?_c(_setup.CommandDialog):_vm._e(),_vm._v(" "),(_setup.xTransferWallDialog)?_c(_setup.SurfaceTransferWallModal):_vm._e(),_vm._v(" "),(_setup.shouldEnableAnonymousAttribution && !_setup.amIRegistered)?[(_setup.xGuestIdModal)?_c(_setup.SurfaceGuestIdModal):_vm._e(),_vm._v(" "),(_setup.xGuestIdCard && _setup.isSmallerThanTabletPortrait)?_c(_setup.SurfaceGuestIdDrawer):_vm._e(),_vm._v(" "),(_setup.xChangeGuestModal && _setup.isSmallerThanTabletPortrait)?_c(_setup.SurfaceChangeGuestModal):_vm._e()]:_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }