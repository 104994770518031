/** @file Main request unit for DALFE. */
import { BrahmsConnector, BrahmsInit } from '../connectors/brahms'
import { MozartConnector, MozartInit } from '../connectors/mozart'
import { FoldersResource } from '../resources/folders'
import { WallFolderMappingsResource } from '../resources/wallFolderMappings'
import { AllConnections } from './DataConnections'

type DataAccessLayerInit = MozartInit & BrahmsInit

class DataAccessLayer implements AllConnections {
  mozart: MozartConnector
  brahms: BrahmsConnector

  constructor(init: DataAccessLayerInit) {
    this.mozart = new MozartConnector(init)
    this.brahms = new BrahmsConnector(init)
  }

  // Resources
  get folders(): FoldersResource {
    return new FoldersResource(this)
  }
  get wallFolderMappings(): WallFolderMappingsResource {
    return new WallFolderMappingsResource(this)
  }
}

export { DataAccessLayer }
export type { DataAccessLayerInit }
