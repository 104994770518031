/**
 * An error thrown by our fetch implementation. It allows for inspection
 * of a response if present. Typically thrown when response has a status
 * code > 399 and <= 599, but could also be thrown when connectivity is
 * an issue.
 */
class FetchError extends Error {
  /**
   * Fetch API response.
   */
  public response?: Response
  /**
   * Status code for an HTTP response.
   */
  public status: number
  /**
   * Original error that was caught, if any.
   */
  public originalError?: any

  public constructor(status: number, msg: string, response?: Response, originalError?: any) {
    super(msg)
    this.status = status
    this.response = response
    this.originalError = originalError
  }
}

export default FetchError
