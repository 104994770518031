// @file Validate Wall fields and attributes
/* eslint-disable camelcase, @typescript-eslint/camelcase */
import checkWallAddressValid from './check_wall_address_valid'
import { FieldValidationError, ValidatedErrorReason, ValidationError } from './errors'
import { Wall } from './types'

/**
 * Rules:
 * 1. Wall names must only consist of _, letters, and numbers.
 * 2. Wall names can only be a maximum of 50 characters
 *
 * @param wall A Wall instance.
 * @returns A Promise that resolves to a ValidatedErrorReason if the wall's field is invalid; null otherwise.
 */
async function checkValidName(wall: Wall): Promise<Wall> {
  const { name } = wall
  if (name) {
    if (!(await checkWallAddressValid(name))) {
      throw new FieldValidationError(
        'name',
        'Must be letters, numbers or underscores, with length of less than 50 characters.',
        'Invalid name',
      )
    }
  }
  if (!name) {
    throw new FieldValidationError('name', 'Must be present', 'Invalid name')
  }
  return wall
}

/**
 * @ignore
 */
const ALLOWED_VIZES = ['stream', 'grid', 'matrix', 'shelf', 'chat', 'free', 'map', 'table', 'timeline', 'timeline_v2']
/**
 * Checks to see if wall format is correct.
 * @param wall Wall instance.
 * @returns A Promise that resolves to a ValidatedErrorReason if the wall's field is invalid; null otherwise.
 */
async function checkValidViz(wall: Wall): Promise<Wall> {
  const { viz } = wall
  if (!ALLOWED_VIZES.includes(viz)) {
    throw new FieldValidationError('viz', `Must be one of ${ALLOWED_VIZES.join(', ')}`, 'Invalid viz')
  }
  return wall
}

/**
 * @ignore
 */
const ALLOWED_NEW_WISH_LOC = ['top', 'bottom']
/**
 * Checks to see if where we populate new fields is a string we recognize.
 * @param wall Wall instance.
 * @returns A Promise that resolves to a ValidatedErrorReason if the wall's field is invalid; null otherwise.
 */
async function checkValidNewWishLoc(wall: Wall): Promise<Wall> {
  const { new_wish_loc } = wall
  if (!ALLOWED_NEW_WISH_LOC.includes(new_wish_loc)) {
    throw new FieldValidationError(
      'new_wish_loc',
      `Must be one of ${ALLOWED_NEW_WISH_LOC.join(', ')}`,
      'Invalid new_wish_loc',
    )
  }
  return wall
}

/**
 * Checks to see if wall's background exists and is (nominally) correct.
 * @param wall Wall instance.
 * @returns A Promise that resolves to a ValidatedErrorReason if the wall's field is invalid; null otherwise.
 */
async function checkValidBackground(wall: Wall): Promise<Wall> {
  const { background } = wall
  if (!background) {
    throw new FieldValidationError('background', 'Must be present', 'Invalid background')
  }
  const { url } = background
  if (!url) {
    throw new FieldValidationError('background', 'Must have URL', 'Invalid background URL')
  }
  return wall
}

/**
 * @ignore
 */
const ALLOWED_REACTION_TYPES = ['like', 'star', 'grade', 'vote']
/**
 * Checks to see if wall's reaction data consists of data that's correct.
 * Rules:
 * 1. If is_reactable is true, then reaction_data must exist.
 * 2. If reaction_data exists, then reaction_data.type must be one of 'like', 'star', 'grade', 'vote'
 * 3. If reaction_data exists and type is grade, then reaction_data.max_value must be greater than 0 and less than or equal to 100.
 * 4. reaction_data.max_value if it exists must be a number.
 * @param wall Wall instance.
 * @returns A Promise that resolves to a ValidatedErrorReason if the wall's field is invalid; null otherwise.
 */
async function checkValidReactionData(wall: Wall): Promise<Wall> {
  const { is_reactable, reaction_data } = wall
  if (is_reactable === true) {
    if (!reaction_data) {
      throw new FieldValidationError('reaction_data', 'Required if is_reactable is true', 'Missing reaction_data')
    }
    if (!reaction_data.type) {
      throw new FieldValidationError('reaction_data', 'Requires a type', 'Missing reaction_data type')
    }
    if (!ALLOWED_REACTION_TYPES.includes(reaction_data.type)) {
      throw new FieldValidationError(
        'reaction_data',
        `Requires that type exist in allowed types: ${ALLOWED_REACTION_TYPES.join(', ')}`,
        'Invalid reaction_data type',
      )
    }
    // validate grade max value
    if (reaction_data.type === 'grade') {
      const { max_value } = reaction_data
      if (!max_value) {
        throw new FieldValidationError('reaction_data', 'Must have max_value for type grade', 'Missing max_value')
      }

      if (typeof max_value !== 'number') {
        throw new FieldValidationError('reaction_data', 'max_value must be a number', 'max_value type is invalid')
      }

      if (max_value < 1 || max_value > 100) {
        throw new FieldValidationError(
          'reaction_data',
          'max_value must be between 1 and 100 inclusive',
          'Invalid max_value',
        )
      }
    } else if (reaction_data.max_value) {
      throw new FieldValidationError('reaction_data', 'Only type grade can have a max_value', 'Unwanted max_value')
    }
  }
  return wall
}

/**
 * Checks to see if wall has a valid.
 * Rules:
 * 1. domain_name cannot end with padlet.com
 * 2. domain_name cannot end with padlet.org
 * @param wall Wall instance.
 * @returns A Promise that resolves to a ValidatedErrorReason if the wall's field is invalid; null otherwise.
 */
async function checkValidDomainName(wall: Wall): Promise<Wall> {
  const { domain_name } = wall
  if (domain_name) {
    if (domain_name.match(/\.padlet\.com$/)) {
      throw new FieldValidationError('domain_name', 'Cannot use padlet.com domain', 'Invalid domain_name')
    }
    if (domain_name.match(/\.padlet\.org$/)) {
      throw new FieldValidationError('domain_name', 'Cannot use padlet.org domain', 'Invalid domain_name')
    }
  }
  return wall
}

/**
 * Throws a validation error when an invalid Wall is passed in.
 * @param wall A Wall instance.
 * @returns A Promise that resolves to the same Wall if it is valid; rejected with a ValidationError otherwise.
 */
export default async function validateWall(wall: Wall): Promise<Wall> {
  const allFns: ((wall: Wall) => Promise<Wall>)[] = [
    checkValidViz,
    checkValidName,
    checkValidDomainName,
    checkValidNewWishLoc,
    checkValidBackground,
    checkValidReactionData,
  ]
  const errorReasons: ValidatedErrorReason[] = []
  await Promise.all(allFns.map((fn): Promise<unknown> => fn(wall).catch((e): unknown => errorReasons.push(e.reason))))

  if (errorReasons.length > 0) {
    throw new ValidationError('Invalid wall', errorReasons as ValidatedErrorReason[])
  }
  return wall
}
