// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file get_folders_response.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

import { Folder } from "./Folder";

export namespace GetFoldersResponse {
  export class GetFoldersResponseMessage extends Message<GetFoldersResponseMessage> {
    /**
     * @generated from field: repeated proto.Folder records = 1;
     */
    records: Folder.FolderMessage[] = [];

    constructor(data?: PartialMessage<GetFoldersResponseMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.GetFoldersResponse";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "records", kind: "message", T: Folder.FolderMessage, repeated: true },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFoldersResponseMessage {
      return new GetFoldersResponseMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFoldersResponseMessage {
      return new GetFoldersResponseMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFoldersResponseMessage {
      return new GetFoldersResponseMessage().fromJsonString(jsonString, options);
    }

      static equals(a: GetFoldersResponseMessage | PlainMessage<GetFoldersResponseMessage> | undefined, b: GetFoldersResponseMessage | PlainMessage<GetFoldersResponseMessage> | undefined): boolean {
      return proto3.util.equals(GetFoldersResponseMessage, a, b);
    }

    toObject(): GetFoldersResponse {
      return {
        records: this.records.map((v) => v.toObject()),
      };
    }

    static fromObject(object: GetFoldersResponse): GetFoldersResponseMessage {
      return new GetFoldersResponseMessage({
        records: object.records.map((v) => Folder.FolderMessage.fromObject(v)),
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): GetFoldersResponse {
    return GetFoldersResponseMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: GetFoldersResponse): Uint8Array {
    return GetFoldersResponseMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.GetFoldersResponse
 */
export type GetFoldersResponse = {
  /**
   * @generated from field: repeated proto.Folder records = 1;
   */
  records: Folder[];
}

