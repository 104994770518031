// @file The store for handling the surface onboarding demo padlet panel and its related logic
import { trackEvent } from '@@/bits/analytics'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { WallCreatedFromType } from '@@/enums'
import { usePostComposerModalStore } from '@@/pinia/post_composer_modal_store'
import { useSurfaceDemoPadletWindowSizeStore } from '@@/pinia/surface_demo_padlet_window_size_store'
import { useSurfaceOnboardingDemoPadletAnimationsStore } from '@@/pinia/surface_onboarding_demo_padlet_animations_store'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceStartingStateStore } from '@@/pinia/surface_starting_state'

import type { PostAttributes, Section } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

interface HeaderContent {
  title: string
  number?: number
  icon?: string
}

interface DemoPadletPanelVisibility {
  showSurfaceActionBar: boolean
  // Note: showSurfaceActionBarButtons does not include the OzAvatar at the top of the SurfaceSearchBar
  showSurfaceActionBarButtons: boolean
  showSurfaceAddPost: boolean
  showSurfaceSearchBar: boolean
  showModifiedSurfaceAddPostButton: boolean
  showSurfaceAiChat: boolean
  showDialogPermitNotification: boolean
  showSurfaceOpenInAppButton: boolean
  showSurfaceModifyGearIcon: boolean
  showModifiedSurfaceActionBarShareButton: boolean
  showModifiedSurfaceActionBarSettingsButton: boolean
  showModifiedSurfaceActionBarSlideshowButton: boolean
}

// Steps are arranging in the order they are displayed, note: 'Done' is not the last step
export enum DemoPadletPanelStep {
  Landing = 'landing',
  Topic = 'topic',
  Post = 'post',
  Share = 'share',
  Done = 'done',
  LearnMore = 'learnmore',
  ShareAndPrivacy = 'shareandprivacy',
  Personalize = 'personalize',
  PresentAsSlideshow = 'presentaslideshow',
}

// Even though the LearnMoreMethod has overlap with DemoPadletPanelStep we use this smaller enum to restrict options in handleShareRowClick on the LearnMore panel, also it's more explicit in case we want to add more options in the future
export enum LearnMoreMethod {
  ShareAndPrivacy = DemoPadletPanelStep.ShareAndPrivacy,
  Personalize = DemoPadletPanelStep.Personalize,
  PresentAsSlideshow = DemoPadletPanelStep.PresentAsSlideshow,
}

export enum StepStatus {
  NotStarted = 'notstarted',
  InProgress = 'inprogress',
  Complete = 'complete',
}

export const LANDING_PANEL_STEPS = [
  { text: __('Set a topic') },
  { text: __('Add some posts') },
  { text: __('Share and collaborate') },
]

function getCommentData(): Array<Array<{ name: string; avatar: string; commentBody: string }>> {
  // These comments are in in the order they are added
  return [
    // First post added comments
    [
      {
        name: __('Minnie'),
        avatar:
          'https://images.unsplash.com/photo-1663250422517-569ac20aec47?q=80&w=128&h=128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        commentBody: __('ditto'),
      },
    ],
    // Second post added comments
    [
      {
        name: __('Guagua'),
        avatar:
          'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F22.png',
        commentBody: __('marked'),
      },
      {
        name: __('Shannon'),
        avatar:
          'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F16.png',
        commentBody: __('Love that movie'),
      },
      {
        name: __('Nitesh'),
        avatar:
          'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F24.png',
        commentBody: __('why is the image not rendering'),
      },
    ],
    // Third post added comments
    [],
  ]
}

function getPostAttributionData(): Array<{ name: string; avatar: string }> {
  // Attributions are listed in the order they're added
  return [
    {
      name: __('Daisy'),
      avatar:
        'https://images.unsplash.com/photo-1641302108391-1cbcc6dde5f0?w=128&h=128&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZGFpc3klMjBkdWNrfGVufDB8fDB8fHwy',
    },
    {
      name: __('Alice'),
      avatar:
        'https://images.unsplash.com/photo-1523183155894-373dda5d733b?q=80&w=128&h=128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      name: __('Sherlock Holmes'),
      avatar:
        'https://images.unsplash.com/photo-1619152338675-a610109d5d4a?q=80&w=128&h=128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
  ]
}

function getPostDataWithSections(sections: Section[]): PostAttributes[] {
  return [
    {
      subject: __('Golden Gate Bridge'),
      attachment:
        'https://images.unsplash.com/photo-1521747116042-5a810fda9664?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      wish_content: {
        attachment_props: {
          url: 'https://images.unsplash.com/photo-1521747116042-5a810fda9664?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
      },
      location_name: __('San Francisco, CA'),
      locationPoint: {
        latitude: 37.7896,
        longitude: -122.4076,
      },
      wallSectionId: sections[0]?.id,
      wall_section_hashid: sections[0]?.hashid,
    },
    {
      subject: __('Disney World'),
      body: __('Daisy here! 🌼 Just had a blast at Disney World. Met so many friends – real and costumed! 🎡'),
      attachment:
        'https://images.unsplash.com/photo-1626816205347-91b13437b39a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGRpc25leWxhbmR8ZW58MHwwfDB8fHwy',
      wish_content: {
        attachment_props: {
          url: 'https://images.unsplash.com/photo-1521747116042-5a810fda9664?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
      },
      attachment_caption: __('30 year anniversary'),
      location_name: __('Disney World, Orlando'),
      locationPoint: {
        latitude: 33.8121,
        longitude: -117.919,
      },
      wallSectionId: sections[0]?.id,
      wall_section_hashid: sections[0]?.hashid,
    },
    {
      subject: __('Wonderland'),
      body: __(
        "Ever since I left, I've been longing to tumble back down the rabbit hole to revisit Wonderland. I miss the enigmatic Cheshire Cat's riddles, the whimsical tea parties with the Mad Hatter, and the Queen's eccentric croquet games. A trip back to that fantastical realm would be a dream come true!",
      ),
      attachment:
        'https://images.unsplash.com/photo-1621461526336-cfe08596d398?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      wish_content: {
        attachment_props: {
          url: 'https://images.unsplash.com/photo-1521747116042-5a810fda9664?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
      },
      wallSectionId: sections[1]?.id,
      wall_section_hashid: sections[1]?.hashid,
    },

    {
      subject: __('Bermuda Triangle'),
      body: __(
        'The Bermuda Triangle? A mystery even I prefer to steer clear of. Too many unsolved disappearances for my taste!',
      ),
      attachment:
        'https://images.unsplash.com/photo-1627408413283-83bd1c42de2d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YmVybXVkYSUyMHRyaWFuZ2xlfGVufDB8MHwwfHx8Mg%3D%3D',
      wish_content: {
        attachment_props: {
          url: 'https://images.unsplash.com/photo-1521747116042-5a810fda9664?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
      },
      location_name: __('Western North Atlantic Ocean'),
      locationPoint: {
        latitude: 25.5117,
        longitude: -70.3493,
      },
      wallSectionId: sections[2]?.id,
      wall_section_hashid: sections[2]?.hashid,
    },
  ]
}

export const useSurfaceOnboardingDemoPadletPanelStore = defineStore('surfaceOnboardingDemoPadletPanelStore', () => {
  const surfaceStartingStateStore = useSurfaceStartingStateStore()
  const postComposerModalStore = usePostComposerModalStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfaceDemoPadletAnimationsStore = useSurfaceOnboardingDemoPadletAnimationsStore()
  const surfaceDemoPadletWindowSizeStore = useSurfaceDemoPadletWindowSizeStore()
  // ------------------------------
  // General
  // ------------------------------

  const isDemoPadletPanel = computed(() => {
    return (
      isAppUsing('demoPadletPanel') &&
      surfaceStartingStateStore.startingState.onboardingFlow?.wallCreatedFromType === WallCreatedFromType.demo
    )
  })
  const isDemoPadletPanelMobile = computed(() => {
    return isDemoPadletPanel.value && !surfaceDemoPadletWindowSizeStore.isDemoPadletPanelScreensize
  })
  const isDemoPadletPanelDesktop = computed(() => {
    // Remember this should evaluate to false when demo padlet onboarding is completed
    return isDemoPadletPanel.value && surfaceDemoPadletWindowSizeStore.isDemoPadletPanelScreensize
  })

  // The activeStep controls which step panel that is displayed, the activeStepStatus tracks the progress of the active step
  // MARK: Update the default starting step here
  const activeStep = ref(DemoPadletPanelStep.Landing) // First step is Landing
  const activeStepStatus = ref(StepStatus.NotStarted)
  function setActiveStepStatus(status: StepStatus): void {
    activeStepStatus.value = status
  }

  const isStepNotStarted = computed(() => {
    return activeStepStatus.value === StepStatus.NotStarted
  })
  const isStepInProgress = computed(() => {
    return activeStepStatus.value === StepStatus.InProgress
  })
  const isStepCompleted = computed(() => {
    return activeStepStatus.value === StepStatus.Complete
  })

  function goToNextStep(learnMoreStepOption?: string): void {
    switch (activeStep.value) {
      case DemoPadletPanelStep.Landing:
        trackEvent('Demo padlet onboarding', 'Go to topic step')
        activeStep.value = DemoPadletPanelStep.Topic
        break
      case DemoPadletPanelStep.Topic:
        trackEvent('Demo padlet onboarding', 'Go to post step')
        activeStep.value = DemoPadletPanelStep.Post
        break
      case DemoPadletPanelStep.Post:
        trackEvent('Demo padlet onboarding', 'Go to share step')
        activeStep.value = DemoPadletPanelStep.Share
        break
      case DemoPadletPanelStep.Share:
        trackEvent('Demo padlet onboarding', 'Go to done step')
        activeStep.value = DemoPadletPanelStep.Done
        break
      case DemoPadletPanelStep.Done:
        trackEvent('Demo padlet onboarding', 'Go to learn more step')
        activeStep.value = DemoPadletPanelStep.LearnMore
        break
      // There are four buttons on the Learn More step, but since only one continues the flow we can maintain a linear progression
      case DemoPadletPanelStep.LearnMore:
        if (learnMoreStepOption === LearnMoreMethod.ShareAndPrivacy) {
          trackEvent('Demo padlet onboarding', 'Go to share and privacy step')
          activeStep.value = DemoPadletPanelStep.ShareAndPrivacy
        } else if (learnMoreStepOption === LearnMoreMethod.PresentAsSlideshow) {
          trackEvent('Demo padlet onboarding', 'Go to present as slideshow step')
          activeStep.value = DemoPadletPanelStep.PresentAsSlideshow
        } else if (learnMoreStepOption === LearnMoreMethod.Personalize) {
          trackEvent('Demo padlet onboarding', 'Go to personalize step')
          activeStep.value = DemoPadletPanelStep.Personalize
        }
        break
      default:
        activeStep.value = DemoPadletPanelStep.Landing
    }
    // Every time we change steps we reset progress
    setActiveStepStatus(StepStatus.NotStarted)
  }

  const currentHeader = computed(() => getHeaderContent(activeStep.value))
  function getHeaderContent(step): HeaderContent {
    switch (step) {
      case DemoPadletPanelStep.Topic:
        return {
          title: __('Set a topic'),
          number: 1,
        }
      case DemoPadletPanelStep.Post:
        return {
          title: __('Post'),
          number: 2,
        }
      case DemoPadletPanelStep.Share:
        return {
          title: __('Share and collaborate'),
          number: 3,
        }
      case DemoPadletPanelStep.Done:
        return {
          title: __('Congratulations'),
          icon: 'firework_explosion',
        }
      default:
        return {
          title: '',
          number: 0,
        }
    }
  }

  const sectionNames = [__('Favorite places'), __('Wishlist destinations'), __('No-go zones')]
  const postData = computed<PostAttributes[]>(() => {
    const sections = useSurfaceSectionsStore().sortedSections
    return getPostDataWithSections(sections)
  })
  const commentData = computed(() => getCommentData())
  const postAttributionData = computed(() => getPostAttributionData())

  // ------------------------------
  // Hiding UI
  // ------------------------------

  const demoPadletPanelVisibility = computed<DemoPadletPanelVisibility>(() => {
    // If the demo padlet panel is not desktop, we don't need to hide any UI elements so we show everything
    if (!isDemoPadletPanelDesktop.value) {
      return {
        showSurfaceActionBar: true,
        showSurfaceActionBarButtons: true,
        showSurfaceAddPost: true,
        showSurfaceSearchBar: true,
        showModifiedSurfaceAddPostButton: false,
        showSurfaceAiChat: true,
        showDialogPermitNotification: true,
        showSurfaceOpenInAppButton: true,
        showSurfaceModifyGearIcon: true,
        showModifiedSurfaceActionBarShareButton: false,
        showModifiedSurfaceActionBarSettingsButton: false,
        showModifiedSurfaceActionBarSlideshowButton: false,
      }
    }
    // Note when adding a new piece of UI, every step previous to the step it's shown should be hidden
    switch (activeStep.value) {
      case DemoPadletPanelStep.Landing:
        return {
          showSurfaceActionBar: false,
          showSurfaceActionBarButtons: false,
          showSurfaceAddPost: false,
          showSurfaceSearchBar: false,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: false,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: false,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.Topic:
        return {
          showSurfaceActionBar: false,
          showSurfaceActionBarButtons: false,
          showSurfaceAddPost: false,
          showSurfaceSearchBar: false,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: false,
          showSurfaceModifyGearIcon: false,
          showSurfaceOpenInAppButton: false,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.Post:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: false,
          showSurfaceAddPost: !hasAddedPost.value,
          showSurfaceSearchBar: false,
          showModifiedSurfaceAddPostButton: true,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: false,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.Share:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: false,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: false,
          showModifiedSurfaceActionBarShareButton: isStepNotStarted.value,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.Done:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          showSurfaceSearchBarButtons: true,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.LearnMore:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          showSurfaceSearchBarButtons: true,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.ShareAndPrivacy:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          showSurfaceSearchBarButtons: true,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: true,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.Personalize:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          showSurfaceSearchBarButtons: true,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: true,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.PresentAsSlideshow:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          showSurfaceSearchBarButtons: true,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: true,
        }
      // After the post step (anything done step and beyond) we show everything, thus we use the default case
      default:
        // By default we show everything
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          // By default we don't show the modified SurfaceAddPostButton
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: true,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: true,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
    }
  })

  // ------------------------------
  // Landing step
  // ------------------------------

  const isLandingStep = computed(() => {
    return (
      // TODO: ENG-15161 Refactor to remove this temporary check against activeStep and only read values from surfaceStartingStateStore (this will be after the upserter is implemented)
      // using this line: surfaceStartingStateStore.startingState.onboardingFlow?.wallCreatedFromType === WallCreatedFromType.demo
      activeStep.value === DemoPadletPanelStep.Landing
    )
  })

  // ------------------------------
  // Topic step
  // ------------------------------
  // ------------------------------
  // Post step
  // ------------------------------

  const isPostStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.Post
  })

  const hasAddedPost = computed(() => {
    return surfacePostsStore.currentPostsCount > 0
  })

  // ------------------------------
  // Share step
  // ------------------------------

  const isShareStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.Share
  })

  const handleStartShareAnimation = (): void => {
    surfaceDemoPadletAnimationsStore.startShareAnimation()
  }

  watch(
    () => postComposerModalStore.hasDockedComposerModal,
    (hasDockedComposerModal: boolean) => {
      if (isPostStep.value) {
        if (hasDockedComposerModal && !isStepInProgress.value) {
          setActiveStepStatus(StepStatus.InProgress)
        } else if (!hasDockedComposerModal && !isStepCompleted.value) {
          setActiveStepStatus(StepStatus.NotStarted)
        }
      }
    },
  )

  // ------------------------------
  // Done step
  // ------------------------------

  // ------------------------------
  // Learn more step
  // ------------------------------
  const isLearnMoreStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.LearnMore
  })

  // ------------------------------
  // Share and privacy step
  // ------------------------------
  const isShareAndPrivacyStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.ShareAndPrivacy
  })

  // ------------------------------
  // Present as slideshow step
  // ------------------------------
  const isPresentAsSlideshowStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.PresentAsSlideshow
  })

  // ------------------------------
  // Personalize step
  // ------------------------------
  const isPersonalizeStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.Personalize
  })

  return {
    // ------------------------------
    // General
    // ------------------------------
    isDemoPadletPanel,
    isDemoPadletPanelMobile,
    isDemoPadletPanelDesktop,
    activeStep,
    activeStepStatus,
    setActiveStepStatus,
    isStepNotStarted,
    isStepInProgress,
    isStepCompleted,
    goToNextStep,
    getHeaderContent,
    currentHeader,
    sectionNames,
    postData,
    postAttributionData,
    commentData,
    // ------------------------------
    // Hiding UI
    // ------------------------------
    demoPadletPanelVisibility,
    // ------------------------------
    // Landing step
    // ------------------------------
    isLandingStep,
    // ------------------------------
    // Topic step
    // ------------------------------
    // ------------------------------
    // Post step
    // ------------------------------
    isPostStep,
    hasAddedPost,
    // ------------------------------
    // Share step
    // ------------------------------
    isShareStep,
    handleStartShareAnimation,
    // ------------------------------
    // Done step
    // ------------------------------
    // ------------------------------
    // Learn more step
    // ------------------------------
    isLearnMoreStep,
    // ------------------------------
    // Share and privacy step
    // ------------------------------
    isShareAndPrivacyStep,
    // ------------------------------
    // Present as slideshow step
    // ------------------------------
    isPresentAsSlideshowStep,
    // ------------------------------
    // Personalize step
    // ------------------------------
    isPersonalizeStep,
  }
})
