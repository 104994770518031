// @file Enums

enum EmojiVersion {
  legacy = 'legacy',
  v8p0 = '8.0',
}

enum GroupByTypes {
  None = 'none',
  Section = 'section',
  Author = 'author',
  Color = 'color',
}

enum SortByTypes {
  ManualNewPostsFirst = 'manual_new_posts_first',
  ManualNewPostsLast = 'manual_new_posts_last',
  ReactionsAsc = 'reactions_asc',
  ReactionsDesc = 'reactions_desc',
  DatePublishedSortAsc = 'date_published_asc',
  DatePublishedSortDesc = 'date_published_desc',
  PostSubjectAsc = 'post_subject_asc',
  PostSubjectDesc = 'post_subject_desc',
  Shuffle = 'shuffle',
}

enum ReactionType {
  None = 'none',
  Like = 'like',
  Vote = 'vote',
  Star = 'star',
  Grade = 'grade',
}

export { EmojiVersion, GroupByTypes, ReactionType, SortByTypes }
