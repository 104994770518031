/** @file Encapsulates the connection to Brahms. */
import * as Phoenix from 'phoenix'

export type BrahmsChannelId = number
export type BrahmsChannelType = 'wall' | 'user'

export interface BrahmsInit {
  clientUid: string
  mozartHost?: string
  brahmsUrl?: string
  brahmsToken?: string // JWT
  brahmsShouldUseLongPoll?: boolean | undefined | null
  brahmsReconnectAfterMs?: ((tries: number) => number) | undefined
}

function buildBrahmsCredentialsEndpoint(mozartHost): string {
  // If no mozartHost is provided, default to current host
  const credentialsHost = mozartHost ? `https://${mozartHost}` : ''
  return `${credentialsHost}/api/6/brahms-starting-state`
}

export class BrahmsConnector {
  private init: BrahmsInit = { clientUid: '' }
  private socket?: Phoenix.Socket
  private channels: Record<BrahmsChannelType, Record<BrahmsChannelId, Phoenix.Channel | undefined>> = {
    wall: {},
    user: {},
  }

  constructor(init: BrahmsInit) {
    this.init = init
  }

  async getBrahmsCredentials() {
    const { brahmsUrl, brahmsToken } = this.init
    const isAlreadyFetched = brahmsUrl != null || brahmsToken != null
    return isAlreadyFetched ? this.init : await this.fetchBrahmsCredentials()
  }

  async fetchBrahmsCredentials() {
    const response = await fetch(buildBrahmsCredentialsEndpoint(this.init.mozartHost))
    const data = await response.json()

    const newInit = {
      ...this.init,
      brahmsUrl: `wss://${data.brahmsHost}/_/realtime`,
      brahmsToken: data.accessToken,
      brahmsDeviceId: data.deviceId,
    }
    this.init = newInit
    return newInit
  }

  async fetchOrCreateSocket(): Promise<Phoenix.Socket | undefined> {
    if (this.socket) return this.socket

    const init = await this.getBrahmsCredentials()
    const { brahmsUrl, brahmsToken, brahmsReconnectAfterMs, brahmsShouldUseLongPoll } = init

    if (!brahmsUrl) return undefined

    this.socket = new Phoenix.Socket(brahmsUrl, {
      params: { accessToken: brahmsToken },
      reconnectAfterMs: brahmsReconnectAfterMs,
      longpollerTimeout: 31000,
      transport: brahmsShouldUseLongPoll ? Phoenix.LongPoll : undefined,
    })
    this.socket?.connect()
    return this.socket
  }

  async fetchOrCreateChannel(
    brahmsChannelType: BrahmsChannelType,
    brahmsChannelId: BrahmsChannelId,
  ): Promise<Phoenix.Channel | undefined> {
    let channel = this.channels[brahmsChannelType][brahmsChannelId]
    if (channel != null) return channel

    const socket = await this.fetchOrCreateSocket()
    if (socket === undefined) return undefined

    channel = socket.channel(`${brahmsChannelType}:${brahmsChannelId}`)
    this.channels[brahmsChannelType][brahmsChannelId] = channel
    return channel
  }

  async subscribeToChannel(
    brahmsChannelType: BrahmsChannelType,
    brahmsChannelId: BrahmsChannelId,
    handler,
  ): Promise<number | undefined> {
    const channel = await this.fetchOrCreateChannel(brahmsChannelType, brahmsChannelId)

    channel?.join().receive('ok', async () => {
      // Connected
    })

    const refForUnsubscription = channel?.on('new_msg', (data) => {
      if (data.uid === this.init.clientUid) return
      const event = data.event
      const record = data.message
      handler(event, record)
    })

    return refForUnsubscription
  }
}
