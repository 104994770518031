// @file Vue composable for QR code logic used by Slideshow
import { trackEvent } from '@@/bits/analytics'
import { useSlideshowStore } from '@@/pinia/slideshow'
import { storeToRefs } from 'pinia'
import { Ref } from 'vue'

export const useSlideshowQRCode = (): {
  isQrCodeShown: Ref<boolean>
  setIsQrCodeShown: (value: boolean) => void
  toggleQRCode: () => void
} => {
  const slideshowStore = useSlideshowStore()
  const { isQrCodeShown } = storeToRefs(slideshowStore)

  const setIsQrCodeShown = (value: boolean): void => {
    isQrCodeShown.value = value
  }

  const toggleQRCode = (): void => {
    setIsQrCodeShown(!isQrCodeShown.value)
    trackEvent('Slideshow', isQrCodeShown.value ? 'Show QR code' : 'Hide QR code')
  }

  return {
    isQrCodeShown,

    setIsQrCodeShown,
    toggleQRCode,
  }
}
