/**
 * @file Pinia helpers
 */
import window from '@@/bits/global'
import { mapActions, mapState } from 'pinia'
import type { VueDecorator } from 'vue-class-component'
import { createDecorator } from 'vue-class-component'
import { Store as VuexStore } from 'vuex'

export function PiniaMapState(store): VueDecorator {
  return createDecorator((componentOptions, key: string) => {
    componentOptions.computed = {
      ...(componentOptions.computed ?? {}),
      ...mapState(store, [key]),
    }
  })
}

export function PiniaMapAction(store): VueDecorator {
  return createDecorator((componentOptions, key: string) => {
    componentOptions.methods = {
      ...(componentOptions.methods ?? {}),
      ...mapActions(store, [key]),
    }
  })
}

/**
 * Get the Vuex store that is initialized in the corresponding pack file.
 * Eg: For the dashboard_settings store, it is initialized in `packs/dashboard_settings.ts` by `window.app = vueInstance`
 *
 * @returns VuexStore
 * @example getVuexStore<DashboardSettingsState>()
 */
export function getVuexStore<T>(): VuexStore<T> | undefined {
  return window.app?.$store
}
