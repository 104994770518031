// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file get_wall_folder_mapping_response.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

import { WallFolderMapping } from "./WallFolderMapping";

export namespace GetWallFolderMappingsResponse {
  export class GetWallFolderMappingsResponseMessage extends Message<GetWallFolderMappingsResponseMessage> {
    /**
     * @generated from field: repeated proto.WallFolderMapping records = 1;
     */
    records: WallFolderMapping.WallFolderMappingMessage[] = [];

    constructor(data?: PartialMessage<GetWallFolderMappingsResponseMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.GetWallFolderMappingsResponse";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "records", kind: "message", T: WallFolderMapping.WallFolderMappingMessage, repeated: true },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetWallFolderMappingsResponseMessage {
      return new GetWallFolderMappingsResponseMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetWallFolderMappingsResponseMessage {
      return new GetWallFolderMappingsResponseMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetWallFolderMappingsResponseMessage {
      return new GetWallFolderMappingsResponseMessage().fromJsonString(jsonString, options);
    }

      static equals(a: GetWallFolderMappingsResponseMessage | PlainMessage<GetWallFolderMappingsResponseMessage> | undefined, b: GetWallFolderMappingsResponseMessage | PlainMessage<GetWallFolderMappingsResponseMessage> | undefined): boolean {
      return proto3.util.equals(GetWallFolderMappingsResponseMessage, a, b);
    }

    toObject(): GetWallFolderMappingsResponse {
      return {
        records: this.records.map((v) => v.toObject()),
      };
    }

    static fromObject(object: GetWallFolderMappingsResponse): GetWallFolderMappingsResponseMessage {
      return new GetWallFolderMappingsResponseMessage({
        records: object.records.map((v) => WallFolderMapping.WallFolderMappingMessage.fromObject(v)),
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): GetWallFolderMappingsResponse {
    return GetWallFolderMappingsResponseMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: GetWallFolderMappingsResponse): Uint8Array {
    return GetWallFolderMappingsResponseMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.GetWallFolderMappingsResponse
 */
export type GetWallFolderMappingsResponse = {
  /**
   * @generated from field: repeated proto.WallFolderMapping records = 1;
   */
  records: WallFolderMapping[];
}

