/**
 * @file Composes an HTML string for embedding URLs onto pages in iframes.
 * @author SY Quek
 */
import {
  isAmpablePage,
  isAudio,
  isFile,
  isPadlet,
  isPdf,
  isPhoto,
  isPlainText,
  isStatic,
  isVideo,
} from './link_helpers'
import { Image, Link } from './types'

interface IframeTagOptions {
  width?: number
  height?: number
  isFixedWidth?: boolean
}

function ampEmbedUrl(ampUrl: string): string {
  return 'https://cdn.ampproject.org/c/' + ampUrl.replace(/https?\:\/\//, '')
}

function plainTextEmbedUrl(url: string): string {
  return `https://padlet.com/beethoven/textview?url=${encodeURIComponent(url)}`
}

function downloadEmbedUrl(url: string): string {
  return `https://padlet.com/beethoven/download_file?url=${encodeURIComponent(url)}`
}

function audioEmbedUrl(url: string): string {
  return process.env.NODE_ENV === 'development'
    ? `https://padlet.dev/beethoven/play?url=${encodeURIComponent(url)}`
    : `https://padlet.com/beethoven/play?url=${encodeURIComponent(url)}`
}

function videoEmbedUrl(url: string): string {
  return `https://padlet.com/beethoven/play?url=${encodeURIComponent(url)}`
}

function proxyUrl(url: string): string {
  return `https://xp.padletcdn.com/proxy?url=${encodeURIComponent(url)}`
}

function makeIframeTag(url: string, options: IframeTagOptions = {}): string {
  const frameUrl = url
  let tag = `<iframe src="${frameUrl}" frameborder="0" allowfullscreen allowtransparency`
  if (options.isFixedWidth) {
    tag += ' class="is-fixed-width"'
  }
  if (options.width) {
    tag += ` width="${options.width}"`
  }
  if (options.height) {
    tag += ` height="${options.height}"`
  }
  tag += '></iframe>'
  return tag
}

function makeImgTag(url: string, width = 0, height = 0): string {
  const imgUrl = url
  if (width > 0 && height > 0) {
    return `<img src="${imgUrl}" width="${width}" height="${height}" />`
  }
  return `<img src="${imgUrl}" />`
}

function makeEmbedCode(link: Link, image: Image): { embedCode: string; embedWidth?: number; embedHeight?: number } {
  // Put audio without an embed code in an audio player.
  // (Sometimes, audios will have an embed code, e.g. Spotify)
  if (isAudio(link) && isStatic(link)) {
    return {
      embedCode: makeIframeTag(audioEmbedUrl(link.url), { width: 640, height: 640 }),
      embedWidth: 640,
      embedHeight: 640,
    }
  }
  // Put videos without an embed code in a video player.
  // (Sometimes, videos can have an embed code, e.g. YouTube)
  if (isVideo(link) && isStatic(link) && !link.embed_code) {
    return {
      embedCode: makeIframeTag(videoEmbedUrl(link.url), { width: 640, height: 360 }),
      embedWidth: 640,
      embedHeight: 360,
    }
  }
  // Put photos without an embed code in an image tag
  // Use the preview image, not the actual image.
  // (Sometimes, photos can have an embed code, e.g. Instagram)
  // If no preview image, use the actual image.
  if (isPhoto(link) && !link.embed_code) {
    if (image) {
      return {
        embedCode: makeImgTag(image.url, image.width, image.height),
        embedWidth: image.width,
        embedHeight: image.height,
      }
    }
    return {
      embedCode: makeImgTag(link.url),
    }
  }
  // Put downloadable files in a file view iframe.
  // The frame has a download button with the file size.
  if (isFile(link)) {
    return {
      embedCode: makeIframeTag(downloadEmbedUrl(link.url)),
    }
  }
  // Put PDF files directly in an iframe.
  // Proxy via an HTTPs proxy with the correct X-Frame headers.
  if (isPdf(link)) {
    return {
      embedCode: makeIframeTag(proxyUrl(link.url)),
    }
  }
  // Show text files in a text viev iframe.
  if (isPlainText(link)) {
    return {
      embedCode: makeIframeTag(plainTextEmbedUrl(link.url)),
    }
  }
  // Show AMPable pages in an iframe.
  // Proxy via Google's AMP proxy.
  if (isAmpablePage(link)) {
    return {
      embedCode: makeIframeTag(ampEmbedUrl(link.amp_url || ''), { isFixedWidth: true }),
    }
  }
  // We have some stupid code that sends an embed code for webpages that's just the
  // screenshot of the webpage. Ignore such nonsense.
  if (link.embed_code && link.embed_code.includes('snapview')) {
    return {
      embedCode: '',
    }
  }
  // Do not embed padlets.
  if (isPadlet(link)) {
    return {
      embedCode: '',
    }
  }
  // If the link has an embed code, use that.
  if (link.embed_code) {
    return {
      embedCode: link.embed_code,
    }
  }
  // Nothing else stuck, return blank.
  return {
    embedCode: '',
  }
}

export default makeEmbedCode
