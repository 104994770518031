export interface ValidatedErrorReason {
  field: string
  reason: string
}

export class ValidationError extends Error {
  public reasons?: ValidatedErrorReason[]
  public constructor (msg: string, reasons?: ValidatedErrorReason[]) {
    super(msg)
    if (reasons) this.reasons = reasons
  }
}

/**
 * Internal error that will be used as part of
 * validation process.
 * @ignore
 */
export class FieldValidationError extends Error {
  public reason?: ValidatedErrorReason
  public constructor (field: string, reason: string, errMsg: string) {
    super(errMsg)
    this.reason = { field, reason }
  }
}
