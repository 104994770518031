var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.OzBaseButton,{class:[
    // Resets <button> background.
    // Invariant: OzPlainButton doesn't have a background.
    // If you need a background, use OzContainedButton.
    'bg-transparent',

    'font-semibold',
    _vm.sizePreset && [
      // Remove the default focus ring since we're rounding the corners
      'focus-visible:outline-none',

      // Reset user agent styles
      // Don't set px-0 here so we won't have specificity issues
      'py-0',

      // Centralize button content
      'flex',
      'items-center',
      'justify-center',
    ],

    // Size presets
    _vm.sizePreset === _setup.OzPlainButtonSizePreset.H44px && [
      _vm.autoHeight ? 'min-h-11' : 'h-11',
      'text-heading-4',
      'px-2',
      'rounded-2xl',
      'focus-visible:ring-[2.5px]',
    ],
    _vm.sizePreset === _setup.OzPlainButtonSizePreset.H40px && [
      _vm.autoHeight ? 'min-h-10' : 'h-10',
      'text-heading-4',
      'px-2',
      'rounded-2xl',
      'focus-visible:ring-[2.5px]',
    ],
    _vm.sizePreset === _setup.OzPlainButtonSizePreset.H32px && [
      _vm.autoHeight ? 'min-h-8' : 'h-8',
      'text-body-small',
      'px-2',
      'rounded-xl',
      'focus-visible:ring-2',
    ],
    _vm.sizePreset === _setup.OzPlainButtonSizePreset.H28px && [
      _vm.autoHeight ? 'min-h-7' : 'h-7',
      'text-body-small',
      'px-1',
      'rounded-[10px]',
      'focus-visible:ring-2',
      _vm.$slots.icon && 'w-7',
    ],
    _vm.sizePreset === _setup.OzPlainButtonSizePreset.H24px && [
      _vm.autoHeight ? 'min-h-6' : 'h-6',
      'text-body-extra-small',
      'rounded-lg',
      'focus-visible:ring-2',
    ],
    _vm.sizePreset === _setup.OzPlainButtonSizePreset.H18px && [
      _vm.autoHeight ? 'min-h-4.5' : 'h-4.5',
      _vm.$slots.icon && 'w-4.5',
      'text-body-extra-small',
      'px-0',
      'rounded',
      'focus-visible:ring-[2.5px]',
    ],

    _vm.colorScheme && [
      // Disabled - Base
      {
        'aria-disabled:text-dark-text-400': _vm.darkMode === false,
        'aria-disabled:text-light-text-400': _vm.darkMode === true,
        'aria-disabled:text-dark-text-400 dark:aria-disabled:text-light-text-400': _vm.darkMode === 'auto',
      },
      // Disabled - Hover
      {
        'hover-hover:aria-disabled:text-dark-text-400': _vm.darkMode === false,
        'hover-hover:aria-disabled:text-light-text-400': _vm.darkMode === true,
        'hover-hover:aria-disabled:text-dark-text-400 hover-hover:dark:aria-disabled:text-light-text-400':
          _vm.darkMode === 'auto',
      },
    ],

    // Color schemes
    _vm.colorScheme === _setup.OzPlainButtonColorScheme.Primary && [
      // Base
      'text-padlet-pink',
      // Hovered
      {
        'hover-hover:hover:text-padlet-pink-700': _vm.darkMode === false,
        'hover-hover:hover:text-padlet-pink-600': _vm.darkMode === true,
        'hover-hover:hover:text-padlet-pink-700 hover-hover:dark:hover:text-padlet-pink-600': _vm.darkMode === 'auto',
      },
      // Active
      {
        'hover-hover:active:text-padlet-pink-100': _vm.darkMode === false,
        'hover-hover:active:text-padlet-pink-900': _vm.darkMode === true,
        'hover-hover:active:text-padlet-pink-100 hover-hover:dark:active:text-padlet-pink-900': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzPlainButtonColorScheme.Secondary && [
      // Base
      {
        'text-dark-text-200': _vm.darkMode === false,
        'text-light-text-200': _vm.darkMode === true,
        'text-dark-text-200 dark:text-light-text-200': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:text-grey-600': _vm.darkMode === false,
        'hover-hover:hover:text-grey-200': _vm.darkMode === true,
        'hover-hover:hover:text-grey-600 hover-hover:dark:hover:text-grey-200': _vm.darkMode === 'auto',
      },
      // Active
      {
        'hover-hover:active:text-dark-text-400': _vm.darkMode === false,
        'hover-hover:active:text-light-text-400': _vm.darkMode === true,
        'hover-hover:active:text-dark-text-400 hover-hover:dark:active:text-light-text-400': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzPlainButtonColorScheme.SecondaryIcon && [
      // Base
      {
        'text-dark-text-200': _vm.darkMode === false,
        'text-light-text-200': _vm.darkMode === true,
        'text-dark-text-200 dark:text-light-text-200': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:text-dark-text-100': _vm.darkMode === false,
        'hover-hover:hover:text-light-text-100': _vm.darkMode === true,
        'hover-hover:hover:text-dark-text-100 hover-hover:dark:hover:text-light-text-100': _vm.darkMode === 'auto',
      },
      // Active
      {
        'hover-hover:active:text-grape-500': _vm.darkMode === false,
        'hover-hover:active:text-canary-500': _vm.darkMode === true,
        'hover-hover:active:text-grape-500 hover-hover:dark:active:text-canary-500': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzPlainButtonColorScheme.SecondaryIconInverse && [
      // Base
      {
        'text-light-text-200': _vm.darkMode === false,
        'text-dark-text-200': _vm.darkMode === true,
        'text-light-text-200 dark:text-dark-text-200': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:text-light-text-100': _vm.darkMode === false,
        'hover-hover:hover:text-dark-text-100': _vm.darkMode === true,
        'hover-hover:hover:text-light-text-100 hover-hover:dark:hover:text-dark-text-100': _vm.darkMode === 'auto',
      },
      // Active
      {
        'hover-hover:active:text-canary-500': _vm.darkMode === false,
        'hover-hover:active:text-grape-500': _vm.darkMode === true,
        'hover-hover:active:text-canary-500 hover-hover:dark:active:text-grape-500': _vm.darkMode === 'auto',
      },
      // Focus
      {
        'focus-visible:ring-canary-500': _vm.darkMode === false,
        'focus-visible:ring-grape-500': _vm.darkMode === true,
        'focus-visible:ring-canary-500 dark:focus-visible:ring-grape-500': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzPlainButtonColorScheme.TertiaryIcon && [
      // Base
      {
        'text-dark-text-300': _vm.darkMode === false,
        'text-light-text-300': _vm.darkMode === true,
        'text-dark-text-300 dark:text-light-text-300': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:text-dark-text-200': _vm.darkMode === false,
        'hover-hover:hover:text-light-text-200': _vm.darkMode === true,
        'hover-hover:hover:text-dark-text-200 hover-hover:dark:hover:text-light-text-200': _vm.darkMode === 'auto',
      },
      // Active
      {
        'hover-hover:active:text-grape-500': _vm.darkMode === false,
        'hover-hover:active:text-canary-500': _vm.darkMode === true,
        'hover-hover:active:text-grape-500 hover-hover:dark:active:text-canary-500': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzPlainButtonColorScheme.Active && [
      // Base
      {
        'text-grape-500': _vm.darkMode === false,
        'text-canary-500': _vm.darkMode === true,
        'text-grape-500 dark:text-canary-500': _vm.darkMode === 'auto',
      },
    ],
    // Focused
    _vm.colorScheme !== _setup.OzPlainButtonColorScheme.SecondaryIconInverse && {
      'focus-visible:ring-grape-500': _vm.darkMode === false,
      'focus-visible:ring-canary-500': _vm.darkMode === true,
      'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500': _vm.darkMode === 'auto',
    },
  ],attrs:{"disabled":_vm.disabled,"href":_vm.href,"target":_vm.hrefTarget,"href-mode":_vm.hrefMode,"title":!_vm.xTitle ? undefined : _vm.title || _vm.text},on:{"mouseenter":function($event){return _vm.$emit('mouseenter', $event)},"mouseleave":function($event){return _vm.$emit('mouseleave', $event)},"mousedown":function($event){return _vm.$emit('mousedown', $event)},"keydown":function($event){return _vm.$emit('keydown', $event)},"click":function($event){return _vm.$emit('click', $event)},"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"touchstart":function($event){return _vm.$emit('touchstart', $event)},"pointerdown":function($event){return _vm.$emit('pointerdown', $event)}}},[(_vm.iconLeft)?_vm._t("icon"):_vm._e(),_vm._v(" "),(_vm.text)?_c('span',{class:['flex-1', _vm.shouldTruncateText && 'truncate']},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_vm._v(" "),(!_vm.iconLeft)?_vm._t("icon"):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }