// @file fetch comments
// TODO: should import this from main file but it's not exported there and we import it like in other places too so just to this for now
import { FetchOptionQueryHash } from '@padlet/fetch/src/types'
import { jsonApiFetch, JsonAPIResponse } from './json_api_fetch'
import { Comment, CommentsFetchQuery } from './types'

/**
 * @param commentQuery [CommentsFetchQuery] available queries:
 *                    - wallId [number] id of the wall
 */
export default function fetchComments(commentQuery: CommentsFetchQuery): Promise<JsonAPIResponse<Comment>> {
  const query: FetchOptionQueryHash = { wall_id: commentQuery.wallId?.toString() || '' }
  if (commentQuery.pageStart) {
    query.page_start = commentQuery.pageStart
  }
  return jsonApiFetch<Comment>('comments', { query })
}
