// @file fetch themes from api.padlet.[dev|io|com]/api/:version/themes
import { jsonApiFetch, JsonAPIResponse } from './json_api_fetch'
import { WallTheme } from './types'

/**
 * Fetch Themes
 * @return Promise that resolves to a collection of Themes.
 */
export default function fetchThemes(): Promise<JsonAPIResponse<WallTheme>> {
  return jsonApiFetch<WallTheme>('themes')
}
