// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file wall_reaction_data.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum proto.ReactionType
 */
export enum ReactionType {
  /**
   * @generated from enum value: REACTION_TYPE_UNSPECIFIED = 0;
   */
  Unspecified = 0,
  /**
   * @generated from enum value: REACTION_TYPE_GRADE = 1;
   */
  Grade = 1,
  /**
   * @generated from enum value: REACTION_TYPE_VOTE = 2;
   */
  Vote = 2,
  /**
   * @generated from enum value: REACTION_TYPE_STAR = 3;
   */
  Star = 3,
  /**
   * @generated from enum value: REACTION_TYPE_LIKE = 4;
   */
  Like = 4,
}

export namespace WallReactionData {
  export class WallReactionDataMessage extends Message<WallReactionDataMessage> {
    /**
     * @generated from field: proto.ReactionType type = 1;
     */
    type: ReactionType = ReactionType.Unspecified;
    /**
     * @generated from field: int32 max_value = 2;
     */
    maxValue: number = 0;

    constructor(data?: PartialMessage<WallReactionDataMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.WallReactionData";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(ReactionType) },
      { no: 2, name: "max_value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WallReactionDataMessage {
      return new WallReactionDataMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WallReactionDataMessage {
      return new WallReactionDataMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WallReactionDataMessage {
      return new WallReactionDataMessage().fromJsonString(jsonString, options);
    }

      static equals(a: WallReactionDataMessage | PlainMessage<WallReactionDataMessage> | undefined, b: WallReactionDataMessage | PlainMessage<WallReactionDataMessage> | undefined): boolean {
      return proto3.util.equals(WallReactionDataMessage, a, b);
    }

    toObject(): WallReactionData {
      return {
        type: this.type,
        maxValue: this.maxValue,
      };
    }

    static fromObject(object: WallReactionData): WallReactionDataMessage {
      return new WallReactionDataMessage({
        type: object.type,
        maxValue: object.maxValue,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): WallReactionData {
    return WallReactionDataMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: WallReactionData): Uint8Array {
    return WallReactionDataMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.WallReactionData
 */
export type WallReactionData = {
  /**
   * @generated from field: proto.ReactionType type = 1;
   */
  type: ReactionType;
  /**
   * @generated from field: int32 max_value = 2;
   */
  maxValue: number;
}

proto3.util.setEnumType(ReactionType, "proto.ReactionType", [
  { no: 0, name: "REACTION_TYPE_UNSPECIFIED" },
  { no: 1, name: "REACTION_TYPE_GRADE" },
  { no: 2, name: "REACTION_TYPE_VOTE" },
  { no: 3, name: "REACTION_TYPE_STAR" },
  { no: 4, name: "REACTION_TYPE_LIKE" },
]);

