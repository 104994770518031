// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file wall_folder_mapping.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

import { Timestamp } from "@bufbuild/protobuf";

export namespace WallFolderMapping {
  export class WallFolderMappingMessage extends Message<WallFolderMappingMessage> {
    /**
     * @generated from field: uint64 id = 1;
     */
    id: bigint = protoInt64.zero;
    /**
     * @generated from field: uint64 wall_id = 2;
     */
    wallId: bigint = protoInt64.zero;
    /**
     * @generated from field: uint64 folder_id = 3;
     */
    folderId: bigint = protoInt64.zero;
    /**
     * @generated from field: uint64 tenant_id = 4;
     */
    tenantId: bigint = protoInt64.zero;
    /**
     * @generated from field: google.protobuf.Timestamp created_at = 5;
     */
    createdAt: Timestamp;
    /**
     * @generated from field: google.protobuf.Timestamp updated_at = 6;
     */
    updatedAt: Timestamp;

    constructor(data?: PartialMessage<WallFolderMappingMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.WallFolderMapping";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 2, name: "wall_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 3, name: "folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 4, name: "tenant_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
      { no: 5, name: "created_at", kind: "message", T: Timestamp },
      { no: 6, name: "updated_at", kind: "message", T: Timestamp },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WallFolderMappingMessage {
      return new WallFolderMappingMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WallFolderMappingMessage {
      return new WallFolderMappingMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WallFolderMappingMessage {
      return new WallFolderMappingMessage().fromJsonString(jsonString, options);
    }

      static equals(a: WallFolderMappingMessage | PlainMessage<WallFolderMappingMessage> | undefined, b: WallFolderMappingMessage | PlainMessage<WallFolderMappingMessage> | undefined): boolean {
      return proto3.util.equals(WallFolderMappingMessage, a, b);
    }

    toObject(): WallFolderMapping {
      return {
        id: this.id,
        wallId: this.wallId,
        folderId: this.folderId,
        tenantId: this.tenantId,
        createdAt: this.createdAt.toJson() as string,
        updatedAt: this.updatedAt.toJson() as string,
      };
    }

    static fromObject(object: WallFolderMapping): WallFolderMappingMessage {
      return new WallFolderMappingMessage({
        id: object.id,
        wallId: object.wallId,
        folderId: object.folderId,
        tenantId: object.tenantId,
        createdAt: Timestamp.fromJson(object.createdAt),
        updatedAt: Timestamp.fromJson(object.updatedAt),
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): WallFolderMapping {
    return WallFolderMappingMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: WallFolderMapping): Uint8Array {
    return WallFolderMappingMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.WallFolderMapping
 */
export type WallFolderMapping = {
  /**
   * @generated from field: uint64 id = 1;
   */
  id: bigint;
  /**
   * @generated from field: uint64 wall_id = 2;
   */
  wallId: bigint;
  /**
   * @generated from field: uint64 folder_id = 3;
   */
  folderId: bigint;
  /**
   * @generated from field: uint64 tenant_id = 4;
   */
  tenantId: bigint;
  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt: string;
  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt: string;
}

