// @file Expanded post store
import appCan from '@@/bits/app_can'
import device from '@@/bits/device'
import { anonymous, blankUser, isRegistered } from '@@/bits/user_model'
import { useSurfaceStore } from '@@/pinia/surface'
import { useCommentsStore } from '@@/pinia/surface_comments'
import { useSurfaceMediaPlayerStore } from '@@/pinia/surface_media_player'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { Cid, Comment, HashId, Id, Post, User } from '@@/types'
import { isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

interface CommentWithUser extends Comment {
  user: User
}

interface ExpandedPostWithComments extends Post {
  attachment_frame_url: string
  body_frame_url: string
  author_display_name: string
  author_avatar_url: string
  comments: CommentWithUser[]
}

// Versioning of the shape of `expanded_post_data` is tracked here https://stackoverflow.com/c/padlet/articles/1921
interface ExpandedPostData {
  posts: Array<{
    id: Id
    cid: Cid
    has_attachment: boolean
  }>
  expanded_post: ExpandedPostWithComments
}

export const useExpandedPostStore = defineStore('expandedPost', () => {
  const surfaceUserContributorStore = useSurfaceUserContributorsStore()
  const commentsStore = useCommentsStore()
  const windowSizeStore = useWindowSizeStore()
  const surfaceStore = useSurfaceStore()
  const surfacePostsStore = useSurfacePostsStore()

  // State
  const expandedPostCid = ref<Cid | null>(null)
  const xExpandedPostTextPanel = ref(false)
  const shouldFocusExpandedPostAddCommentInput = ref<boolean>(false)

  // TODO: [to-be-migrated][post_action]
  const expandedPost = computed<Post | undefined>(() => {
    if (expandedPostCid.value === null) return undefined
    return surfacePostsStore.postEntitiesByCid[expandedPostCid.value]
  })
  const expandedPostId = computed<Id | null>(() => expandedPost.value?.id ?? null)
  const expandedPostHashid = computed<HashId | null>(() => expandedPost.value?.hashid ?? null)
  const xExpandedPostPanel = computed(() => expandedPostCid.value != null && !device.app)
  const expandedPostPreviousPostCid = computed<Cid | null>(() => {
    const currentPostIndex = expandedPostPostsToNavigate.value.findIndex((p: Post) => p.cid === expandedPostCid.value)
    const previousPostIndex = currentPostIndex - 1
    const previousPost = expandedPostPostsToNavigate.value[previousPostIndex]
    return previousPost?.cid ?? null
  })
  const expandedPostNextPostCid = computed<Cid | null>(() => {
    const currentPostIndex = expandedPostPostsToNavigate.value.findIndex((p: Post) => p.cid === expandedPostCid.value)
    const nextPostIndex = currentPostIndex + 1
    const nextPost = expandedPostPostsToNavigate.value[nextPostIndex]
    return nextPost?.cid ?? null
  })

  const isExpandedPostPreviewPost = computed<boolean>(() => {
    if (expandedPost.value === undefined) return false
    return expandedPost.value.id === surfaceStore.coverPostId
  })

  const doesExpandedPostHaveAttachment = computed<boolean>(
    () => !isEmpty(expandedPost.value?.attachment) || !isEmpty(expandedPost.value?.wish_content?.attachment_props?.url),
  )

  const expandedPostCommentsWithUser = computed<CommentWithUser[]>(() => {
    const comments = expandedPostId.value != null ? commentsStore.commentsByPostId[expandedPostId.value] ?? [] : []
    const commentsWithUser = comments.map((c) => ({
      ...c,
      user: surfaceUserContributorStore.getUserById(c.user_hashid ?? (c.user_id as number)) as User,
    }))
    return commentsWithUser
  })

  const expandedPostCommenterIds = computed(() => expandedPostCommentsWithUser.value.map((c) => c.user_id))

  const expandedPostPostsToNavigate = computed<Post[]>(() => {
    // If surface is sectioned, get sorted + filtered posts grouped by sections. Flatten them.
    if (surfaceStore.isSectioned) {
      return surfacePostsStore.postsBySortedGroups.flat()
    }
    // Else just get sorted + filtered posts
    return surfacePostsStore.currentSortedPosts
  })

  const expandedPostServerId = computed<number | null>(() => {
    if (expandedPostCid.value == null) return null
    return expandedPost.value?.id ?? null
  })

  const expandedPostMobileFrameUrl = computed<string>(() => {
    if (isEmpty(surfaceStore.publicKey) || expandedPostServerId.value == null) {
      return 'about:blank'
    }
    return `/padlets/${surfaceStore.publicKey}/frames/post_v2/${expandedPostServerId.value}`
  })

  const expandedPostMobileBodyFrameUrl = computed<string>(() => {
    if (isEmpty(surfaceStore.publicKey) || expandedPostServerId.value == null) {
      return 'about:blank'
    }
    return `/padlets/${surfaceStore.publicKey}/frames/body${expandedPostServerId.value}`
  })

  const expandedPostData = computed<ExpandedPostData | {}>(() => {
    if (expandedPost.value == null || !appCan('nativeExpandedPostV1')) return {}

    const foundAuthor =
      expandedPost.value?.author_id != null
        ? surfaceUserContributorStore.getUserById(expandedPost.value?.author_hashid ?? expandedPost.value?.author_id)
        : null
    const author = foundAuthor ?? blankUser()

    const authorDisplayName = isRegistered(author) ? author.name ?? author.username : anonymous

    const authorAvatarUrl = author.avatar

    return {
      posts: expandedPostPostsToNavigate.value.map((p) => ({
        id: p.id,
        cid: p.cid,
        has_attachment: Boolean(p.attachment),
      })),
      expanded_post: {
        ...expandedPost.value,
        attachment_frame_url: expandedPostMobileFrameUrl.value,
        body_frame_url: expandedPostMobileBodyFrameUrl.value,
        author_display_name: authorDisplayName,
        author_avatar_url: authorAvatarUrl,
        comments: expandedPostCommentsWithUser.value,
      },
    }
  })

  const expandPost = (payload: { postCid: Cid; xTextPanel?: boolean; shouldFocusInput?: boolean }): void => {
    useSurfaceMediaPlayerStore().unsetCurrentlyPlayingMedia()
    expandedPostCid.value = payload.postCid

    if (payload.xTextPanel !== undefined) {
      xExpandedPostTextPanel.value = payload.xTextPanel
    }
    // Only focus input if text panel is also open
    if (payload.xTextPanel === true && payload.shouldFocusInput === true) {
      shouldFocusExpandedPostAddCommentInput.value = true
    }
  }

  const unexpandPost = (): void => {
    if (windowSizeStore.isSmallerThanTabletPortrait && expandedPost.value != null) {
      // At this viewport we're closing ExpandedPostTextPanelMobile.
      // When the user closes the panel, discard any new comment-related state.
      // Otherwise, the comment edit drawer would pop up.
      commentsStore.resetWritingNewComment({ post: expandedPost.value })
    }
    expandedPostCid.value = null
    useSurfaceMediaPlayerStore().unsetCurrentlyPlayingMedia()
    shouldFocusExpandedPostAddCommentInput.value = false
  }

  const toggleExpandedPostTextPanel = (payload?: { status: boolean }): void => {
    let status: boolean | undefined
    if (payload?.status !== undefined) {
      status = payload.status
    }
    if (status !== undefined) {
      xExpandedPostTextPanel.value = status
    } else {
      xExpandedPostTextPanel.value = !xExpandedPostTextPanel.value
    }
  }

  return {
    // State
    xExpandedPostTextPanel,
    expandedPostCid,
    shouldFocusExpandedPostAddCommentInput,
    expandedPostServerId,

    // Getters
    xExpandedPostPanel,
    expandedPost,
    expandedPostId,
    expandedPostHashid,
    expandedPostPreviousPostCid,
    expandedPostNextPostCid,
    isExpandedPostPreviewPost,
    doesExpandedPostHaveAttachment,
    expandedPostPostsToNavigate,
    expandedPostData,
    expandedPostCommenterIds,

    // Actions
    expandPost,
    unexpandPost,
    toggleExpandedPostTextPanel,
  }
})
