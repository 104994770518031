// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file wall_wish_arrangement.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

export namespace WallWishArrangement {
  /**
   * @generated from enum proto.WallWishArrangement.SortBy
   */
  export enum SortBy {
    /**
     * @generated from enum value: SORT_BY_UNSPECIFIED = 0;
     */
    Unspecified = 0,
    /**
     * @generated from enum value: SORT_BY_ASCENDING = 1;
     */
    Ascending = 1,
    /**
     * @generated from enum value: SORT_BY_DESCENDING = 2;
     */
    Descending = 2,
    /**
     * @generated from enum value: SORT_BY_REACTIONS_ASCENDING = 3;
     */
    ReactionsAscending = 3,
    /**
     * @generated from enum value: SORT_BY_REACTIONS_DESCENDING = 4;
     */
    ReactionsDescending = 4,
    /**
     * @generated from enum value: SORT_BY_MANUAL_NEW_POSTS_FIRST = 5;
     */
    ManualNewPostsFirst = 5,
    /**
     * @generated from enum value: SORT_BY_MANUAL_NEW_POSTS_LAST = 6;
     */
    ManualNewPostsLast = 6,
    /**
     * @generated from enum value: SORT_BY_DATE_PUBLISHED_ASCENDING = 7;
     */
    DatePublishedAscending = 7,
    /**
     * @generated from enum value: SORT_BY_DATE_PUBLISHED_DESCENDING = 8;
     */
    DatePublishedDescending = 8,
    /**
     * @generated from enum value: SORT_BY_POST_SUBJECT_ASCENDING = 9;
     */
    PostSubjectAscending = 9,
    /**
     * @generated from enum value: SORT_BY_POST_SUBJECT_DESCENDING = 10;
     */
    PostSubjectDescending = 10,
    /**
     * @generated from enum value: SORT_BY_SHUFFLE = 11;
     */
    Shuffle = 11,
  }

  /**
   * @generated from enum proto.WallWishArrangement.GroupBy
   */
  export enum GroupBy {
    /**
     * @generated from enum value: GROUP_BY_UNSPECIFIED = 0;
     */
    Unspecified = 0,
    /**
     * @generated from enum value: GROUP_BY_SECTION = 1;
     */
    Section = 1,
    /**
     * @generated from enum value: GROUP_BY_AUTHOR = 2;
     */
    Author = 2,
    /**
     * @generated from enum value: GROUP_BY_COLOR = 3;
     */
    Color = 3,
  }

  export class WallWishArrangementMessage extends Message<WallWishArrangementMessage> {
    /**
     * @generated from field: int64 default_section_id = 1;
     */
    defaultSectionId: bigint = protoInt64.zero;
    /**
     * @generated from field: proto.WallWishArrangement.GroupBy group_by = 2;
     */
    groupBy: WallWishArrangement.GroupBy = WallWishArrangement.GroupBy.Unspecified;
    /**
     * @generated from field: proto.WallWishArrangement.SortBy sort_by = 3;
     */
    sortBy: WallWishArrangement.SortBy = WallWishArrangement.SortBy.Unspecified;
    /**
     * @generated from field: bool is_sectionable = 4;
     */
    isSectionable: boolean = false;
    /**
     * @generated from field: int32 version = 5;
     */
    version: number = 0;

    constructor(data?: PartialMessage<WallWishArrangementMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.WallWishArrangement";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "default_section_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 2, name: "group_by", kind: "enum", T: proto3.getEnumType(WallWishArrangement.GroupBy) },
      { no: 3, name: "sort_by", kind: "enum", T: proto3.getEnumType(WallWishArrangement.SortBy) },
      { no: 4, name: "is_sectionable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 5, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WallWishArrangementMessage {
      return new WallWishArrangementMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WallWishArrangementMessage {
      return new WallWishArrangementMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WallWishArrangementMessage {
      return new WallWishArrangementMessage().fromJsonString(jsonString, options);
    }

      static equals(a: WallWishArrangementMessage | PlainMessage<WallWishArrangementMessage> | undefined, b: WallWishArrangementMessage | PlainMessage<WallWishArrangementMessage> | undefined): boolean {
      return proto3.util.equals(WallWishArrangementMessage, a, b);
    }

    toObject(): WallWishArrangement {
      return {
        defaultSectionId: this.defaultSectionId,
        groupBy: this.groupBy,
        sortBy: this.sortBy,
        isSectionable: this.isSectionable,
        version: this.version,
      };
    }

    static fromObject(object: WallWishArrangement): WallWishArrangementMessage {
      return new WallWishArrangementMessage({
        defaultSectionId: object.defaultSectionId,
        groupBy: object.groupBy,
        sortBy: object.sortBy,
        isSectionable: object.isSectionable,
        version: object.version,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): WallWishArrangement {
    return WallWishArrangementMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: WallWishArrangement): Uint8Array {
    return WallWishArrangementMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.WallWishArrangement
 */
export type WallWishArrangement = {
  /**
   * @generated from field: int64 default_section_id = 1;
   */
  defaultSectionId: bigint;
  /**
   * @generated from field: proto.WallWishArrangement.GroupBy group_by = 2;
   */
  groupBy: WallWishArrangement.GroupBy;
  /**
   * @generated from field: proto.WallWishArrangement.SortBy sort_by = 3;
   */
  sortBy: WallWishArrangement.SortBy;
  /**
   * @generated from field: bool is_sectionable = 4;
   */
  isSectionable: boolean;
  /**
   * @generated from field: int32 version = 5;
   */
  version: number;
}

proto3.util.setEnumType(WallWishArrangement.SortBy, "proto.WallWishArrangement.SortBy", [
  { no: 0, name: "SORT_BY_UNSPECIFIED" },
  { no: 1, name: "SORT_BY_ASCENDING" },
  { no: 2, name: "SORT_BY_DESCENDING" },
  { no: 3, name: "SORT_BY_REACTIONS_ASCENDING" },
  { no: 4, name: "SORT_BY_REACTIONS_DESCENDING" },
  { no: 5, name: "SORT_BY_MANUAL_NEW_POSTS_FIRST" },
  { no: 6, name: "SORT_BY_MANUAL_NEW_POSTS_LAST" },
  { no: 7, name: "SORT_BY_DATE_PUBLISHED_ASCENDING" },
  { no: 8, name: "SORT_BY_DATE_PUBLISHED_DESCENDING" },
  { no: 9, name: "SORT_BY_POST_SUBJECT_ASCENDING" },
  { no: 10, name: "SORT_BY_POST_SUBJECT_DESCENDING" },
  { no: 11, name: "SORT_BY_SHUFFLE" },
]);

proto3.util.setEnumType(WallWishArrangement.GroupBy, "proto.WallWishArrangement.GroupBy", [
  { no: 0, name: "GROUP_BY_UNSPECIFIED" },
  { no: 1, name: "GROUP_BY_SECTION" },
  { no: 2, name: "GROUP_BY_AUTHOR" },
  { no: 3, name: "GROUP_BY_COLOR" },
]);

