// @file fetch link with json api
import { Invoice } from './types'
import { jsonApiFetch, JsonAPIResponse } from './json_api_fetch'

/**
 * Fetch a link object and response in Json API format
 * @param url url to fetch
 */
export default function fetchInvoices(): Promise<JsonAPIResponse<Invoice>> {
  return jsonApiFetch<Invoice>('invoices')
}
