// @file utilities for json api
import { fetchJson, FetchOptions, HTTPAuthenticationScheme } from '@padlet/fetch'
import { FetchOptionCredentials, FetchOptionMode } from '@padlet/fetch/src/types'
import { deferredGetConfiguration, getConfiguration } from './configure'

function versionedJsonApiPathWithLeadingSlash(path: string): string {
  const { jsonApiVersion } = getConfiguration()
  const pathWithLeadingSlash = path.startsWith('/') ? path : `/${path}`
  return `/api/${jsonApiVersion}${pathWithLeadingSlash}`
}

async function jsonApiFetch<T>(path: string, ...options: FetchOptions[]): Promise<JsonAPIResponse<T>> {
  const {
    jsonApiHost,
    token: { oauthToken },
  } = await deferredGetConfiguration()
  const moreOptions: FetchOptions = {
    mode: FetchOptionMode.cors,
    credentials: FetchOptionCredentials.omit,
  }
  if (oauthToken) {
    moreOptions.authorization = [HTTPAuthenticationScheme.bearer, oauthToken]
  }
  const url = `https://${jsonApiHost}${versionedJsonApiPathWithLeadingSlash(path)}`
  return fetchJson<JsonAPIResponse<T>>(url, moreOptions, ...options)
}

interface JsonApiLinkObject {
  href: string
  meta: Record<string, any>
}

interface JsonAPIResource<T> {
  id: number | string
  type: string
  attributes: T
  relationships?: Record<string, unknown>
  links?: Record<string, string | JsonApiLinkObject>
}

/**
 * JSON API update request type.
 * The PATCH request MUST include a single resource object as primary data.
 * The resource object MUST contain type and id members.
 * https://jsonapi.org/format/#crud-updating
 */
interface JsonAPIUpdateRequest<T> {
  data: JsonAPIResource<T>
}

/**
 * JSON API response type.
 */
interface JsonAPIResponse<T> {
  data?: JsonAPIResource<T>[] | JsonAPIResource<T>
  meta?: Record<string, any>
  links?: Record<string, string>
}

export { jsonApiFetch }
export type { JsonApiLinkObject, JsonAPIResource, JsonAPIUpdateRequest, JsonAPIResponse }
