// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file wish_content.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

import { Timestamp } from "@bufbuild/protobuf";

export namespace PollChoice {
  export class PollChoiceMessage extends Message<PollChoiceMessage> {
    /**
     * @generated from field: string choice_text = 1;
     */
    choiceText: string = "";

    constructor(data?: PartialMessage<PollChoiceMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.PollChoice";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "choice_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollChoiceMessage {
      return new PollChoiceMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollChoiceMessage {
      return new PollChoiceMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollChoiceMessage {
      return new PollChoiceMessage().fromJsonString(jsonString, options);
    }

      static equals(a: PollChoiceMessage | PlainMessage<PollChoiceMessage> | undefined, b: PollChoiceMessage | PlainMessage<PollChoiceMessage> | undefined): boolean {
      return proto3.util.equals(PollChoiceMessage, a, b);
    }

    toObject(): PollChoice {
      return {
        choiceText: this.choiceText,
      };
    }

    static fromObject(object: PollChoice): PollChoiceMessage {
      return new PollChoiceMessage({
        choiceText: object.choiceText,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): PollChoice {
    return PollChoiceMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: PollChoice): Uint8Array {
    return PollChoiceMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.PollChoice
 */
export type PollChoice = {
  /**
   * @generated from field: string choice_text = 1;
   */
  choiceText: string;
}

export namespace PollChoiceResult {
  export class PollChoiceResultMessage extends Message<PollChoiceResultMessage> {
    /**
     * @generated from field: string choice_text = 1;
     */
    choiceText: string = "";
    /**
     * @generated from field: int64 poll_choice_id = 2;
     */
    pollChoiceId: bigint = protoInt64.zero;
    /**
     * @generated from field: int64 total_votes = 3;
     */
    totalVotes: bigint = protoInt64.zero;
    /**
     * @generated from field: bool user_voted = 4;
     */
    userVoted: boolean = false;

    constructor(data?: PartialMessage<PollChoiceResultMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.PollChoiceResult";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "choice_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "poll_choice_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 3, name: "total_votes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 4, name: "user_voted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollChoiceResultMessage {
      return new PollChoiceResultMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollChoiceResultMessage {
      return new PollChoiceResultMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollChoiceResultMessage {
      return new PollChoiceResultMessage().fromJsonString(jsonString, options);
    }

      static equals(a: PollChoiceResultMessage | PlainMessage<PollChoiceResultMessage> | undefined, b: PollChoiceResultMessage | PlainMessage<PollChoiceResultMessage> | undefined): boolean {
      return proto3.util.equals(PollChoiceResultMessage, a, b);
    }

    toObject(): PollChoiceResult {
      return {
        choiceText: this.choiceText,
        pollChoiceId: this.pollChoiceId,
        totalVotes: this.totalVotes,
        userVoted: this.userVoted,
      };
    }

    static fromObject(object: PollChoiceResult): PollChoiceResultMessage {
      return new PollChoiceResultMessage({
        choiceText: object.choiceText,
        pollChoiceId: object.pollChoiceId,
        totalVotes: object.totalVotes,
        userVoted: object.userVoted,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): PollChoiceResult {
    return PollChoiceResultMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: PollChoiceResult): Uint8Array {
    return PollChoiceResultMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.PollChoiceResult
 */
export type PollChoiceResult = {
  /**
   * @generated from field: string choice_text = 1;
   */
  choiceText: string;
  /**
   * @generated from field: int64 poll_choice_id = 2;
   */
  pollChoiceId: bigint;
  /**
   * @generated from field: int64 total_votes = 3;
   */
  totalVotes: bigint;
  /**
   * @generated from field: bool user_voted = 4;
   */
  userVoted: boolean;
}

export namespace PollAttachmentProps {
  export class PollAttachmentPropsMessage extends Message<PollAttachmentPropsMessage> {
    /**
     * @generated from field: string question = 1;
     */
    question: string = "";
    /**
     * @generated from field: repeated proto.PollChoice poll_choices = 2;
     */
    pollChoices: PollChoice.PollChoiceMessage[] = [];
    /**
     * @generated from field: repeated proto.PollChoiceResult poll_choice_results = 3;
     */
    pollChoiceResults: PollChoiceResult.PollChoiceResultMessage[] = [];
    /**
     * @generated from field: bool has_user_voted = 4;
     */
    hasUserVoted: boolean = false;
    /**
     * @generated from field: int64 poll_id = 5;
     */
    pollId: bigint = protoInt64.zero;
    /**
     * @generated from field: google.protobuf.Timestamp closed_at = 6;
     */
    closedAt: Timestamp;

    constructor(data?: PartialMessage<PollAttachmentPropsMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.PollAttachmentProps";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "poll_choices", kind: "message", T: PollChoice.PollChoiceMessage, repeated: true },
      { no: 3, name: "poll_choice_results", kind: "message", T: PollChoiceResult.PollChoiceResultMessage, repeated: true },
      { no: 4, name: "has_user_voted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 5, name: "poll_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 6, name: "closed_at", kind: "message", T: Timestamp },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollAttachmentPropsMessage {
      return new PollAttachmentPropsMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollAttachmentPropsMessage {
      return new PollAttachmentPropsMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollAttachmentPropsMessage {
      return new PollAttachmentPropsMessage().fromJsonString(jsonString, options);
    }

      static equals(a: PollAttachmentPropsMessage | PlainMessage<PollAttachmentPropsMessage> | undefined, b: PollAttachmentPropsMessage | PlainMessage<PollAttachmentPropsMessage> | undefined): boolean {
      return proto3.util.equals(PollAttachmentPropsMessage, a, b);
    }

    toObject(): PollAttachmentProps {
      return {
        question: this.question,
        pollChoices: this.pollChoices.map((v) => v.toObject()),
        pollChoiceResults: this.pollChoiceResults.map((v) => v.toObject()),
        hasUserVoted: this.hasUserVoted,
        pollId: this.pollId,
        closedAt: this.closedAt.toJson() as string,
      };
    }

    static fromObject(object: PollAttachmentProps): PollAttachmentPropsMessage {
      return new PollAttachmentPropsMessage({
        question: object.question,
        pollChoices: object.pollChoices.map((v) => PollChoice.PollChoiceMessage.fromObject(v)),
        pollChoiceResults: object.pollChoiceResults.map((v) => PollChoiceResult.PollChoiceResultMessage.fromObject(v)),
        hasUserVoted: object.hasUserVoted,
        pollId: object.pollId,
        closedAt: Timestamp.fromJson(object.closedAt),
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): PollAttachmentProps {
    return PollAttachmentPropsMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: PollAttachmentProps): Uint8Array {
    return PollAttachmentPropsMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.PollAttachmentProps
 */
export type PollAttachmentProps = {
  /**
   * @generated from field: string question = 1;
   */
  question: string;
  /**
   * @generated from field: repeated proto.PollChoice poll_choices = 2;
   */
  pollChoices: PollChoice[];
  /**
   * @generated from field: repeated proto.PollChoiceResult poll_choice_results = 3;
   */
  pollChoiceResults: PollChoiceResult[];
  /**
   * @generated from field: bool has_user_voted = 4;
   */
  hasUserVoted: boolean;
  /**
   * @generated from field: int64 poll_id = 5;
   */
  pollId: bigint;
  /**
   * @generated from field: google.protobuf.Timestamp closed_at = 6;
   */
  closedAt: string;
}

export namespace LinkAttachmentProps {
  export class LinkAttachmentPropsMessage extends Message<LinkAttachmentPropsMessage> {
    /**
     * @generated from field: string url = 1;
     */
    url: string = "";
    /**
     * @generated from field: string hotlink = 2;
     */
    hotlink: string = "";

    constructor(data?: PartialMessage<LinkAttachmentPropsMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.LinkAttachmentProps";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "hotlink", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LinkAttachmentPropsMessage {
      return new LinkAttachmentPropsMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LinkAttachmentPropsMessage {
      return new LinkAttachmentPropsMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LinkAttachmentPropsMessage {
      return new LinkAttachmentPropsMessage().fromJsonString(jsonString, options);
    }

      static equals(a: LinkAttachmentPropsMessage | PlainMessage<LinkAttachmentPropsMessage> | undefined, b: LinkAttachmentPropsMessage | PlainMessage<LinkAttachmentPropsMessage> | undefined): boolean {
      return proto3.util.equals(LinkAttachmentPropsMessage, a, b);
    }

    toObject(): LinkAttachmentProps {
      return {
        url: this.url,
        hotlink: this.hotlink,
      };
    }

    static fromObject(object: LinkAttachmentProps): LinkAttachmentPropsMessage {
      return new LinkAttachmentPropsMessage({
        url: object.url,
        hotlink: object.hotlink,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): LinkAttachmentProps {
    return LinkAttachmentPropsMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: LinkAttachmentProps): Uint8Array {
    return LinkAttachmentPropsMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.LinkAttachmentProps
 */
export type LinkAttachmentProps = {
  /**
   * @generated from field: string url = 1;
   */
  url: string;
  /**
   * @generated from field: string hotlink = 2;
   */
  hotlink: string;
}

export namespace WishContent {
  export class WishContentMessage extends Message<WishContentMessage> {
    /**
     * @generated from field: int64 id = 1;
     */
    id: bigint = protoInt64.zero;
    /**
     * @generated from field: string subject = 2;
     */
    subject: string = "";
    /**
     * @generated from field: string body = 3;
     */
    body: string = "";
    /**
     * @generated from field: bool is_processed = 4;
     */
    isProcessed: boolean = false;
    /**
     * @generated from field: int64 version = 5;
     */
    version: bigint = protoInt64.zero;
    /**
     * @generated from oneof proto.WishContent.attachment_props
     */
    attachmentProps:
      | { case: "linkAttachmentProps"; value: LinkAttachmentProps.LinkAttachmentPropsMessage }
      | { case: "pollAttachmentProps"; value: PollAttachmentProps.PollAttachmentPropsMessage }
      | { case: undefined; value?: undefined } = { case: undefined };

    constructor(data?: PartialMessage<WishContentMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.WishContent";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 2, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 4, name: "is_processed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 5, name: "version", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
      { no: 6, name: "link_attachment_props", kind: "message", T: LinkAttachmentProps.LinkAttachmentPropsMessage, oneof: "attachment_props" },
      { no: 7, name: "poll_attachment_props", kind: "message", T: PollAttachmentProps.PollAttachmentPropsMessage, oneof: "attachment_props" },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WishContentMessage {
      return new WishContentMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WishContentMessage {
      return new WishContentMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WishContentMessage {
      return new WishContentMessage().fromJsonString(jsonString, options);
    }

      static equals(a: WishContentMessage | PlainMessage<WishContentMessage> | undefined, b: WishContentMessage | PlainMessage<WishContentMessage> | undefined): boolean {
      return proto3.util.equals(WishContentMessage, a, b);
    }

    toObject(): WishContent {
      return {
        id: this.id,
        subject: this.subject,
        body: this.body,
        isProcessed: this.isProcessed,
        version: this.version,
        attachmentProps: (() => {
          switch (this.attachmentProps.case) {
            case undefined:
              return { case: undefined };
            case "linkAttachmentProps":
              return { case: "linkAttachmentProps", value: this.attachmentProps.value.toObject() };
            case "pollAttachmentProps":
              return { case: "pollAttachmentProps", value: this.attachmentProps.value.toObject() };
          }
        })(),
      };
    }

    static fromObject(object: WishContent): WishContentMessage {
      return new WishContentMessage({
        id: object.id,
        subject: object.subject,
        body: object.body,
        isProcessed: object.isProcessed,
        version: object.version,
        attachmentProps: (() => {
          switch (object.attachmentProps.case) {
            case undefined:
              return { case: undefined };
            case "linkAttachmentProps":
              return { case: "linkAttachmentProps", value: LinkAttachmentProps.LinkAttachmentPropsMessage.fromObject(object.attachmentProps.value) };
            case "pollAttachmentProps":
              return { case: "pollAttachmentProps", value: PollAttachmentProps.PollAttachmentPropsMessage.fromObject(object.attachmentProps.value) };
          }
        })(),
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): WishContent {
    return WishContentMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: WishContent): Uint8Array {
    return WishContentMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.WishContent
 */
export type WishContent = {
  /**
   * @generated from field: int64 id = 1;
   */
  id: bigint;
  /**
   * @generated from field: string subject = 2;
   */
  subject: string;
  /**
   * @generated from field: string body = 3;
   */
  body: string;
  /**
   * @generated from field: bool is_processed = 4;
   */
  isProcessed: boolean;
  /**
   * @generated from field: int64 version = 5;
   */
  version: bigint;
  /**
   * @generated from oneof proto.WishContent.attachment_props
   */
  attachmentProps:
    | { case: "linkAttachmentProps"; value: LinkAttachmentProps }
    | { case: "pollAttachmentProps"; value: PollAttachmentProps }
    | { case: undefined; value?: undefined };
}

