// @generated by protoc-gen-padlet v0.0.1 with parameter "target=ts,import_extension=none"
// @generated from file common.proto (package proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

export namespace Color {
  export class ColorMessage extends Message<ColorMessage> {
    /**
     * @generated from field: uint32 red = 1;
     */
    red: number = 0;
    /**
     * @generated from field: uint32 green = 2;
     */
    green: number = 0;
    /**
     * @generated from field: uint32 blue = 3;
     */
    blue: number = 0;

    constructor(data?: PartialMessage<ColorMessage>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "proto.Color";
    static readonly fields = proto3.util.newFieldList(() => [
      { no: 1, name: "red", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
      { no: 2, name: "green", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
      { no: 3, name: "blue", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ColorMessage {
      return new ColorMessage().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ColorMessage {
      return new ColorMessage().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ColorMessage {
      return new ColorMessage().fromJsonString(jsonString, options);
    }

      static equals(a: ColorMessage | PlainMessage<ColorMessage> | undefined, b: ColorMessage | PlainMessage<ColorMessage> | undefined): boolean {
      return proto3.util.equals(ColorMessage, a, b);
    }

    toObject(): Color {
      return {
        red: this.red,
        green: this.green,
        blue: this.blue,
      };
    }

    static fromObject(object: Color): ColorMessage {
      return new ColorMessage({
        red: object.red,
        green: object.green,
        blue: object.blue,
      });
    }
  }

  export function fromBinary(bytes: Uint8Array): Color {
    return ColorMessage.fromBinary(bytes).toObject();
  }

  export function toBinary(json: Color): Uint8Array {
    return ColorMessage.fromObject(json).toBinary();
  }

}

/**
 * @generated from message proto.Color
 */
export type Color = {
  /**
   * @generated from field: uint32 red = 1;
   */
  red: number;
  /**
   * @generated from field: uint32 green = 2;
   */
  green: number;
  /**
   * @generated from field: uint32 blue = 3;
   */
  blue: number;
}

