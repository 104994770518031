var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    // SHAPE
    _vm.orientation === _setup.OzDividerOrientation.Horizontal && {
      'h-[0.5px]': _vm.thickness === 0.5,
      'h-2': _vm.thickness === 8,
      'h-3': _vm.thickness === 12,
    },
    _vm.orientation === _setup.OzDividerOrientation.Vertical && {
      'w-[0.5px]': _vm.thickness === 0.5,
      'w-2': _vm.thickness === 8,
      'w-3': _vm.thickness === 12,
    },

    // COLOR
    _vm.colorScheme === _setup.OzDividerColorScheme.Solid && [
      {
        'bg-divider-gray dark:bg-divider-gray-dark': _vm.darkMode === 'auto',
        'bg-divider-gray': _vm.darkMode === false,
        'bg-divider-gray-dark': _vm.darkMode === true,
      },
    ],
    _vm.colorScheme === _setup.OzDividerColorScheme.Translucent && [
      'bg-opacity-30',
      {
        'bg-grey-200 dark:bg-white-400': _vm.darkMode === 'auto',
        'bg-grey-200': _vm.darkMode === false,
        'bg-white-400': _vm.darkMode === true,
      },
    ],
    _vm.colorScheme === _setup.OzDividerColorScheme.Popover && [
      {
        'bg-divider-popover dark:bg-divider-popover-dark': _vm.darkMode === 'auto',
        'bg-divider-popover': _vm.darkMode === false,
        'bg-divider-popover-dark': _vm.darkMode === true,
      },
    ],
    _vm.colorScheme === _setup.OzDividerColorScheme.Selection && [
      {
        'bg-grape-500 dark:bg-canary-500': _vm.darkMode === 'auto',
        'bg-grape-500': _vm.darkMode === false,
        'bg-canary-500': _vm.darkMode === true,
      },
    ],
  ],attrs:{"role":"separator"}})
}
var staticRenderFns = []

export { render, staticRenderFns }