// @file utility fetch functions to access api directly

import { fetchJson, HTTPAuthenticationScheme, type FetchOptions } from '@padlet/fetch'
import { FetchOptionCredentials, FetchOptionMode } from '@padlet/fetch/src/types'
import { deferredGetConfiguration } from './configure'

/**
 * Use to fetch directly from our API source, i.e. mozart.
 * @param pathWithLeadingSlash basically if a URL is https://padlet.com/walls/1, "/walls/1" should be this argument.
 * @param [...options] FetchOptions. Look into `@padlet/fetch` for details.
 */
async function apiFetch<T>(pathWithLeadingSlash: string, ...options: FetchOptions[]): Promise<T> {
  const {
    host,
    token: { csrfToken, oauthToken },
  } = await deferredGetConfiguration()
  const moreOptions: FetchOptions = {}
  if (oauthToken) {
    moreOptions.authorization = [HTTPAuthenticationScheme.bearer, oauthToken]
  }
  if (csrfToken) {
    const actualToken = (csrfToken as Function)()
    moreOptions.headers = { 'X-CSRF-Token': actualToken }
  }
  const url = `https://${host}${pathWithLeadingSlash}`
  return fetchJson<T>(url, moreOptions, ...options)
}

/**
 * Use to fetch directly from our API source via CDN.
 * @param pathWithLeadingSlash basically if a URL is https://padlet.com/walls/1, "/walls/1" should be this argument.
 * @param option FetcHOptions. Look into @padlet/fetch for details.
 */
async function cdnFetch<T>(pathWithLeadingSlash: string, ...options: FetchOptions[]): Promise<T> {
  const { cdnHost } = await deferredGetConfiguration()
  const moreOptions: FetchOptions = {
    mode: FetchOptionMode.cors,
    credentials: FetchOptionCredentials.omit,
  }
  const url = `https://${cdnHost}${pathWithLeadingSlash}`
  return fetchJson<T>(url, moreOptions, ...options)
}

export { apiFetch, cdnFetch, FetchOptions }
