// @file Handles URL hash shortcuts for Surface
import window from '@@/bits/global'
import { getVuexStore } from '@@/bits/pinia'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceActivityPanelStore } from '@@/pinia/surface_activity_panel'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfaceSharePanelStore } from '@@/pinia/surface_share_panel'

const HASH_SHORTCUTS = {
  Activity: '#activity',
  Remake: '#remake',
  Settings: '#settings',
  Share: '#share',
}

function resetHash(): void {
  history.replaceState('', document.title, window.location.pathname)
}

export function useSurfaceUrlHashShortcuts(): void {
  const surfaceVuexStore = getVuexStore()

  const currentHash = window.location.hash
  if (!Object.values(HASH_SHORTCUTS).includes(currentHash)) return

  switch (currentHash) {
    case HASH_SHORTCUTS.Activity:
      useSurfaceActivityPanelStore().showActivityPanel()
      break
    case HASH_SHORTCUTS.Remake:
      if (useSurfacePermissionsStore().canIRemake) void surfaceVuexStore?.dispatch('showRemakePanelOld')
      break
    case HASH_SHORTCUTS.Settings:
      if (useSurfaceStore().isFrozen || !useSurfacePermissionsStore().canIAdminister) break
      void surfaceVuexStore?.dispatch('settings/showSettingsPanel')
      break
    case HASH_SHORTCUTS.Share:
      useSurfaceSharePanelStore().xSurfaceSharePanel = true
      break
  }

  resetHash()
}
