import { resource, FetchResource, FetchOptions, HTTPAuthenticationScheme } from '@padlet/fetch'
import { ApiEntity, Environment } from './types'
import { getConfiguration } from './configure'

function path(resourceType: ResourceType): string {
  switch (resourceType) {
    case 'walls':
    case 'backgrounds':
      return `/${resourceType}`
    default:
      throw new Error(`Unknown resource type: ${resourceType as string}`)
  }
}

function createResource<T>(resourceType: ResourceType): FetchResource<T> {
  const {
    host,
    token: { csrfToken, oauthToken },
  } = getConfiguration()

  // authentication if required
  const fetchOptions: FetchOptions = {}
  if (oauthToken) {
    fetchOptions.authorization = [HTTPAuthenticationScheme.bearer, oauthToken]
  } else if (csrfToken) {
    const actualToken = (csrfToken as Function)()
    fetchOptions.headers = { 'X-CSRF-Token': actualToken }
  }
  return resource<T>(`https://${host}${path(resourceType)}`, fetchOptions)
}

type ResourceType = 'walls' | 'backgrounds'
let cache: { [resType in ResourceType]?: FetchResource<ApiEntity> } = {}
let lastConfig: Environment | null = null

function hasConfigChanged(): boolean {
  if (lastConfig === null) return true
  const currConfig = getConfiguration()
  return currConfig.host !== lastConfig.host || currConfig.token !== lastConfig.token
}

function getResource(resourceType: ResourceType): FetchResource<ApiEntity> {
  // reset the cache if the configuration has changed
  if (hasConfigChanged()) {
    cache = {}
    lastConfig = getConfiguration()
  }
  if (!cache[resourceType]) {
    cache[resourceType] = createResource<ApiEntity>(resourceType)
  }
  return cache[resourceType] as FetchResource<ApiEntity>
}

function destroyResources(): void {
  cache = {}
}

function wall(): FetchResource<ApiEntity> {
  return getResource('walls')
}

function wallBackground(): FetchResource<ApiEntity> {
  return getResource('backgrounds')
}

export { path, destroyResources, wall, wallBackground }
