import { isBrowser } from '@padlet/platform'

const testImages = {
  lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
  lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
  alpha:
    'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
  animation:
    'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
}

enum CacheResult {
  yes,
  no,
  unknown,
}

/**
 * Read saved webp support value from localstorage
 */
const getFromCache = (): CacheResult => {
  try {
    const value = localStorage.getItem('webp')
    if (value === '1') {
      return CacheResult.yes
    } else if (value === '0') {
      return CacheResult.no
    }
  } catch (e) {
    return CacheResult.unknown
  }
  return CacheResult.unknown
}

/**
 * Save webp support value to cache
 * @param value Whether webp is supported or not
 * @returns {boolean} Whether the storage was successful
 */
const saveToCache = (value: boolean): boolean => {
  try {
    localStorage.setItem('webp', value ? '1' : '0')
    return true
  } catch (e) {
    return false
  }
}

let isSupportedResult: boolean

if (isBrowser) {
  // I know that browser detection is frowned upon, but it is honestly a fast way
  // to detect webp support. It also improves the first user experience. We know the folllowing:
  // - All Chromes support webp
  // - All Firefoxes support webp
  // - Safari v14 and above supports webp but only for iOS (all iOS versions) or macOS 11 Big Sur and later (https://caniuse.com/webp),
  //   checking is kind of complicated so we don't do it for now
  // - Edge Chromium supports webp
  const userAgent = navigator.userAgent.toLowerCase()
  const vendor = navigator.vendor.toLowerCase()

  if (vendor.includes('google')) {
    // Chrome and Edge Chromium
    isSupportedResult = true
  } else if (userAgent.includes('firefox')) {
    // Firefox
    isSupportedResult = true
  } else {
    const cacheResult = getFromCache()
    if (cacheResult === CacheResult.unknown) {
      const img = new Image()
      img.onload = (): void => {
        isSupportedResult = img.width > 0 && img.height > 0
        saveToCache(isSupportedResult)
      }
      img.onerror = (): void => {
        isSupportedResult = false
        saveToCache(isSupportedResult)
      }
      img.src = 'data:image/webp;base64,' + testImages.lossless
      isSupportedResult = false
    } else {
      isSupportedResult = cacheResult === CacheResult.yes
    }
  }
} else {
  // Not a browser
  isSupportedResult = false
}

const isSupported = (): boolean => {
  return isSupportedResult
}

export { isSupported }
