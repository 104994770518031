// @file Dash collections pinia store
import { convertBigIntToNumber } from '@@/bits/big_int'
import {
  assertPresent,
  devLog,
  extractEmojiFromFolderName,
  filterDisplayName,
  galleryVizes,
  getAccountCollectionValue,
  getCollectionFetchLimits,
  getEmptyFilterCollectionCopy,
  getEmptyFolderCollectionCopy,
  getEmptyGroupFolderCollectionCopy,
  getEmptySearchCollectionCopy,
  isActiveFilter,
  isCollectionBookmark,
  isCollectionInactiveWallsCollection,
  isCollectionSearch,
  isCollectionShared,
  isCollectionSharedCollection,
  LIBRARY_ACCOUNT_FILTERS,
  OLD_LIBRARY_ACCOUNT_FILTERS,
  OLD_TENANT_USER_ACCOUNT_FILTERS,
  OLD_USER_ACCOUNT_FILTERS,
  setAccountCollectionValue,
  TENANT_USER_ACCOUNT_FILTERS,
  transformAccountCollectionValue,
  transformAccountsCollectionsValues,
  USER_ACCOUNT_FILTERS,
} from '@@/bits/collections_helper'
import currentLibrary from '@@/bits/current_library'
import currentUser from '@@/bits/current_user'
import dal from '@@/bits/data_access_layer'
import { captureException, captureNonNetworkFetchError } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { isValidFolderName, MAX_FOLDER_NAME_LENGTH } from '@@/bits/folder'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { currentHostname, transformCurrentUrl } from '@@/bits/location'
import PromiseQueue from '@@/bits/promise_queue'
import {
  buildLibraryIdCacheKey,
  buildTenantIdCacheKey,
  buildUserIdCacheKey,
  fetchCachedQuery,
  refetchCachedQueries,
} from '@@/bits/query_client'
import { safeLocalStorage } from '@@/bits/safe_storage'
import { NATIVE_HOST } from '@@/bits/url'
import { vDel, vSet } from '@@/bits/vue'
import { Folder as FolderApi, UserGroups as UserGroupsApi } from '@@/dashboard/padlet_api'
import { FetchJsonStatus, SnackbarNotificationType } from '@@/enums'
import renameDark from '@@/images/rename-dark.svg'
import renameLight from '@@/images/rename-light.svg'
import wastebasketDark from '@@/images/wastebasket-dark.svg'
import wastebasketLight from '@@/images/wastebasket-light.svg'
import { OzConfirmationDialogBoxButtonScheme } from '@@/library/v4/components/OzConfirmationDialogBox.vue'
import type { PopoverAnchor } from '@@/library/v4/components/OzPopoverModal.vue'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import { useDashStore } from '@@/pinia/dash_store'
import { useDashWallBulkActionsStore, WallActionType } from '@@/pinia/dash_wall_bulk_actions_store'
import { useDashWallSingleActionsStore } from '@@/pinia/dash_wall_single_actions_store'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useGlobalInputDialogStore } from '@@/pinia/global_input_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { usePadletPickerStore } from '@@/pinia/padlet_picker'
import { useUserAccountsStore } from '@@/pinia/user_accounts_store'
import { useWindowSizeStore } from '@@/pinia/window_size'
import { fetchJson } from '@@/surface/api_fetch'
import type {
  AccountKey,
  Folder,
  FolderApiResponse,
  FolderId,
  Id,
  JsonApiData,
  JsonApiResponse,
  LibraryId,
  SharedCollection,
  SharedCollectionAccountKey,
  TenantId,
  UserGroup,
  UserGroupFolderApiResponse,
  UserGroupId,
  UserId,
  WallCamelCase as Wall,
  WallId,
  WallViz,
} from '@@/types'
import type {
  CollectionKey,
  CollectionsSubtree,
  CollectionsTree,
  WallsFilter,
  WallSortAttribute,
  WallSortDirection,
} from '@@/types/collections'
import { CollectionKeyTypes } from '@@/types/collections'
import { MobilePage } from '@@/types/dash'
import type { JsonAPIResource } from '@padlet/arvo'
import { cloneDeep, keyBy, mapValues, sortBy, uniq } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

// Avoid race conditions that cause walls and folders to dis/appear etc unexpectedly.
const q = new PromiseQueue()

export const useDashCollectionsStore = defineStore('dashCollectionsStore', () => {
  // External stores
  const globalSnackbarStore = useGlobalSnackbarStore()
  const dashWallBulkActionsStore = useDashWallBulkActionsStore()
  const dashStore = useDashStore()
  const dashAccountsStore = useDashAccountsStore()
  const globalInputDialogStore = useGlobalInputDialogStore()
  const darkModeStore = useDarkModeStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
  const windowSizeStore = useWindowSizeStore()
  const dashWallSingleActionsStore = useDashWallSingleActionsStore()
  const userAccountsStore = useUserAccountsStore()
  const padletPickerStore = usePadletPickerStore()

  function genericFetchError(payload: { error: any; source: string }): void {
    captureNonNetworkFetchError(payload.error, { source: payload.source })
    globalSnackbarStore.genericFetchError()
  }

  /**
   * ==================== BOOKMARKS ====================
   */

  const isActiveCollectionBookmark = computed((): boolean => isCollectionBookmark(activeCollectionKey.value))
  const isActiveWallBookmarked = computed((): boolean => isWallIdBookmarked(activeWallId.value as WallId))

  function isWallIdBookmarked(wallId: WallId): boolean {
    if (wallId == null) return false

    const isInFavorites = isWallIdInFavorites(wallId)
    if (isInFavorites) return true
    const userId = activeUserIdCursor.value
    if (userId == null) return false

    const foldersHash = usersCollectionsWallIds.value[userId]?.folderId ?? {}
    const containingFolder = Object.values(foldersHash).find((folderWallIds) => folderWallIds.includes(wallId))
    return !(containingFolder == null)
  }

  /**
   * ==================== FOLDERS ====================
   */

  const folderActionsAnchor = ref<PopoverAnchor | null>(null)
  const folderActionsFolder = ref<Folder | null>(null)
  const foldersById = ref<Record<FolderId, Folder>>({})

  const xFolderActions = computed((): boolean => folderActionsFolder.value !== null)
  const activeUserFolderIds = computed((): FolderId[] => {
    const userId = activeUserIdCursor.value as UserId
    const foldersTree = usersCollectionsWallIds.value[userId]?.folderId ?? {}
    return Object.keys(foldersTree).map((folderId) => parseInt(folderId))
  })
  const activeWallFolderIds = computed((): FolderId[] => {
    const wallId = activeWallId.value
    const userId = activeUserIdCursor.value
    if (userId == null || wallId == null) return []

    const foldersHash = usersCollectionsWallIds.value[userId]?.folderId
    if (foldersHash === undefined) return []

    const containingFolderIds = activeUserFolderIds.value.filter((folderId: FolderId) => {
      const isWallInFolder = foldersHash[folderId]?.find((id) => id === wallId)
      return isWallInFolder
    })
    return containingFolderIds
  })
  const activeUserFolders = computed((): Folder[] => {
    // Admins can access any folder in the tenant, including those owned by others.
    // We need to filter those out.
    const folders = activeUserFolderIds.value
      .map((id: FolderId) => foldersById.value[id])
      .filter((folder: Folder) => folder)
      .map((folder) => {
        const trimmedFolderName = folder.name.trim()

        // We attempt to get the folder icon if the folder
        // does not have an icon
        const emoji = extractEmojiFromFolderName(trimmedFolderName)
        if (folder.icon == null && emoji !== '') {
          return { ...folder, icon: emoji, name: trimmedFolderName.substring(emoji.length) }
        }
        return folder
      })
    return sortBy(folders, (folder) => folder.name.toLowerCase())
  })
  const activeFolder = computed((): Folder | null => {
    const collectionKey = activeCollectionKey.value
    if (collectionKey.typeKey !== CollectionKeyTypes.FolderId) return null
    return foldersById.value[collectionKey.indexKey]
  })
  const activeFolderId = computed((): FolderId | null => activeFolder.value?.id ?? null)
  const foldersArray = computed((): Folder[] => Object.values(foldersById.value))
  const wallIdsByFolderId = computed(
    (): Record<FolderId, WallId[]> => usersCollectionsWallIds.value[activeUserIdCursor.value as UserId]?.folderId ?? {},
  )
  const isActiveWallInFavorites = computed((): boolean => isWallIdInFavorites(activeWallId.value as WallId))
  const favoritesFolderWallIds = computed((): WallId[] => {
    const filtersHash = usersCollectionsWallIds.value[activeUserIdCursor.value as UserId]?.filter ?? {}
    return filtersHash.favorites ?? []
  })
  const xFolders = computed((): boolean => isCollectionBookmark(activeCollectionKey.value))

  function showFolderActions(payload: { popoverAnchor: PopoverAnchor; folder: Folder }): void {
    folderActionsFolder.value = payload.folder
    folderActionsAnchor.value = payload.popoverAnchor
  }

  function closeFolderActions(): void {
    folderActionsFolder.value = null
    folderActionsAnchor.value = null
  }

  function promptNameForActiveUserNewFolder(): void {
    globalInputDialogStore.openInputDialog({
      title: __('New folder'),
      inputPlaceholder: __('Folder name'),
      inputMaxLength: MAX_FOLDER_NAME_LENGTH,
      inputAriaLabel: __('Enter the folder name'),
      submitActions: [createFolderForActiveUser],
      validationActions: [validateFolderName],
    })
  }

  async function createFolderForActiveUser({ inputValue }: { inputValue: string }): Promise<void> {
    const userId = activeUserIdCursor.value as UserId
    const name = inputValue.trim()
    if (!assertPresent({ userId, name })) return

    const { success }: { success: boolean } = validateFolderNameUnique(name)
    if (!success) return
    try {
      const response = await FolderApi.create({ name })
      const folder = (response.data as JsonAPIResource<Folder>).attributes
      cacheFolders([folder])
      addFolderForUser({ userId, folderId: folder.id })
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Created new folder'),
      })
      // Add current active wall (if any) to the new folder created
      if (activeWallId.value != null) {
        await dashWallSingleActionsStore.addBookmark({ folderId: folder.id, wallId: activeWallId.value })
        return
      }

      // If there are selected walls, we add them to the newly created folder
      if (dashWallBulkActionsStore.isMultiSelecting) {
        await dashWallBulkActionsStore.handleBulkAction(WallActionType.AddBookmarks, { folderId: folder.id }, true)
      }
    } catch (error) {
      genericFetchError({ error, source: 'DashCollectionsCreateFolderForActiveUser' })
    }
  }

  function validateFolderNameUnique(name: string): { success: boolean } {
    const isNameTaken = foldersArray.value.map((folder) => folder.name).includes(name?.trim())
    if (!isNameTaken) return { success: true }
    globalSnackbarStore.setSnackbar({
      notificationType: SnackbarNotificationType.error,
      message: __('Name is taken by another folder. Please pick another name.'),
    })
    return { success: false }
  }

  function addFolderForUser(payload: { userId: UserId; folderId: FolderId }): void {
    const addFolderId = (oldFoldersState): CollectionsSubtree<WallId[]> => ({
      ...oldFoldersState,
      [payload.folderId]: [],
    })
    transformAndUpdateUserCollectionType(payload.userId, CollectionKeyTypes.FolderId, addFolderId)
  }

  function validateFolderName({
    inputValue,
    disallowedNames = [],
  }: {
    inputValue: string
    disallowedNames?: string[]
  }): void {
    const disabled = !isValidFolderName(inputValue) || disallowedNames.includes(inputValue)
    globalInputDialogStore.setSubmitButtonDisable(disabled)
  }

  function promptRenameFolder(payload: { folderId: FolderId }): void {
    const folder = foldersById.value[payload.folderId]
    const originalName = folder?.name

    globalInputDialogStore.openInputDialog({
      title: __('Rename folder'),
      inputPlaceholder: originalName,
      inputValue: originalName,
      inputMaxLength: MAX_FOLDER_NAME_LENGTH,
      iconSrc: darkModeStore.isDarkMode ? renameDark : renameLight,
      inputAriaLabel: __('Enter the new folder name'),
      validationActions: [({ inputValue }) => validateFolderName({ inputValue, disallowedNames: [originalName] })],
      submitActions: [
        ({ inputValue }) => {
          void renameFolder({ folderId: payload.folderId, name: inputValue })
        },
        closeFolderActions,
      ],
    })
  }

  async function renameFolder({ folderId, name }: { folderId: FolderId; name: string }): Promise<void> {
    const isNoop = name?.trim() === foldersById.value[folderId]?.name
    if (isNoop) return
    const { success }: { success: boolean } = validateFolderNameUnique(name)
    if (!success) return

    try {
      const { data } = (await FolderApi.update(folderId, { name })) as { data: JsonAPIResource<Folder> }
      const folder = data.attributes
      cacheFolders([folder])

      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Folder renamed'),
      })
    } catch (error) {
      genericFetchError({ error, source: 'DashCollectionsRenameFolder' })
    }
  }

  function confirmDeleteFolder({ folderId }: { folderId: FolderId }): void {
    const folderName = foldersById.value[folderId].name
    globalConfirmationDialogStore.openConfirmationDialog({
      shouldFadeIn: false,
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
      iconSrc: darkModeStore.isDarkMode ? wastebasketDark : wastebasketLight,
      title: __('Delete folder "%{folderName}"?', { folderName }),
      body: __('The padlets within this folder will not be deleted.'),
      confirmButtonText: __('Delete'),
      cancelButtonText: __('Nevermind'),
      afterConfirmActions: [
        closeFolderActions,
        () => switchViewToAvoidFolder({ folderId }),
        () => {
          void deleteFolder({ folderId })
        },
      ],
    })
  }

  function switchViewToAvoidFolder({ folderId }: { folderId: FolderId }): void {
    if (activeFolderId.value !== folderId) return
    const collectionKey: CollectionKey = { typeKey: 'filter', indexKey: 'favorites' }
    dashStore.switchView({ mobilePage: MobilePage.CollectionsMenu, collectionKey })
    window?.history?.pushState(null, '', '/dashboard/bookmarks?mobile_page=Collection&filter=favorites')
  }

  async function deleteFolder({ folderId }: { folderId: FolderId }): Promise<void> {
    try {
      await FolderApi.delete(folderId)
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Folder deleted'),
      })
      removeFolderForAllUsers(folderId)
      // View should have been switched prior. This is backup.
      switchViewToAvoidFolder({ folderId })

      void refetchCachedQueries({
        cacheKey: ['fetchFolders', activeUserIdCursor.value as UserId],
        exact: true,
      })
    } catch (error) {
      genericFetchError({ error, source: 'DashCollectionsDeleteFolder' })
    }
  }

  function removeFolderForAllUsers(folderId: FolderId): void {
    Object.values(usersCollectionsWallIds.value).forEach((userCollectionsWallIds) => {
      vDel(userCollectionsWallIds.folderId, folderId)
    })
    vDel(foldersById.value, folderId)
  }

  const fetchFoldersStatus = ref(FetchJsonStatus.Fetching)
  const isFetchingFolders = computed((): boolean => fetchFoldersStatus.value === FetchJsonStatus.Fetching)

  async function fetchFolders(): Promise<void> {
    const userId = activeUserIdCursor.value as UserId
    const accountKey: AccountKey = { type: 'user', id: userId }

    try {
      if (isAppUsing('dashboardFolderDalfe')) {
        await dal.folders.subscribeV1(
          { userId },
          {
            addFolder(folder) {
              const coercedFolder: Folder = convertBigIntToNumber(folder)
              cacheFolders([coercedFolder])
              addFolderForUser({ userId, folderId: coercedFolder.id })
              globalSnackbarStore.setSnackbar({
                notificationType: SnackbarNotificationType.success,
                message: __('Created new folder'),
              })
            },
            updateFolder(folder) {
              cacheFolders([folder])
              globalSnackbarStore.setSnackbar({
                notificationType: SnackbarNotificationType.success,
                message: __('Folder renamed'),
              })
            },
            removeFolder(folder) {
              const coercedFolder: Folder = convertBigIntToNumber(folder)
              removeFolderForAllUsers(coercedFolder.id)
              // View should have been switched prior. This is backup.
              switchViewToAvoidFolder({ folderId: coercedFolder.id })
              globalSnackbarStore.setSnackbar({
                notificationType: SnackbarNotificationType.success,
                message: __('Folder deleted'),
              })
            },
          },
        )
        const folderWallIdMap: Record<Id, Id[] | undefined> = {}
        const wallFolderMappings = convertBigIntToNumber(await dal.wallFolderMappings.allV1())
        wallFolderMappings.forEach(({ folderId, wallId }) => {
          folderWallIdMap[folderId] = [...(folderWallIdMap[folderId] ?? []), wallId]
        })
        const folders: Folder[] = convertBigIntToNumber(await dal.folders.allV1())
        folders.forEach(({ id }) => {
          const collectionKey: CollectionKey = { typeKey: 'folderId', indexKey: id }
          setCollectionWallIds({ accountKey, collectionKey, wallIds: folderWallIdMap[id] ?? [] })
        })
        cacheFolders(folders)
        const folderIds = folders.map((folder) => folder.id)
        updateFolderListForUser({ userId, folderIds })
        fetchFoldersStatus.value = FetchJsonStatus.Completed
      } else {
        await fetchCachedQuery<JsonApiResponse<FolderApiResponse>>({
          cacheKey: ['fetchFolders', buildUserIdCacheKey(userId)],
          queryFn: async () => await FolderApi.fetch(),
          onFetchSuccess: (response) => {
            const data = response.data as Array<JsonApiData<FolderApiResponse>>
            const folders: Folder[] = []
            data.forEach(({ attributes: { id, name, wallIds } }) => {
              folders.push({ id, name })
              const collectionKey: CollectionKey = { typeKey: 'folderId', indexKey: id }
              setCollectionWallIds({ accountKey, collectionKey, wallIds })
            })
            cacheFolders(folders)
            const folderIds = folders.map((folder) => folder.id)
            updateFolderListForUser({ userId, folderIds })
            fetchFoldersStatus.value = FetchJsonStatus.Completed
          },
        })
      }
    } catch (error) {
      fetchFoldersStatus.value = FetchJsonStatus.Error
      genericFetchError({ error, source: 'DashCollectionsFetchFolders' })
    }
  }

  function cacheFolders(folders): void {
    foldersById.value = { ...foldersById.value, ...keyBy(folders, 'id') }
  }

  function updateFolderListForUser(payload: { userId: UserId; folderIds: FolderId[] }): void {
    transformAndUpdateUserCollectionType<Record<FolderId, WallId[]>>(
      payload.userId,
      CollectionKeyTypes.FolderId,
      (oldFoldersState) => {
        oldFoldersState = oldFoldersState ?? {}
        return payload.folderIds.reduce((newFoldersState, folderId) => {
          newFoldersState[folderId] = oldFoldersState[folderId] ?? []
          return newFoldersState
        }, {})
      },
    )
  }

  function transformAndUpdateUserCollectionType<T>(
    userId: UserId,
    collectionKeyType: CollectionKeyTypes,
    transform: (subtree: CollectionsSubtree<T>) => CollectionsSubtree<T>,
  ): void {
    if (userId == null) return
    const collectionTree = usersCollectionsWallIds.value[userId] ?? {}

    vSet(collectionTree, collectionKeyType, transform(collectionTree[collectionKeyType]))
    usersCollectionsWallIds.value = { ...usersCollectionsWallIds.value, [userId]: collectionTree }
  }

  function transformAndUpdateLibraryCollectionType<T>(
    libraryId: LibraryId,
    collectionKeyType: CollectionKeyTypes,
    transform: (subtree: CollectionsSubtree<T>) => CollectionsSubtree<T>,
  ): void {
    if (libraryId == null) return
    const collectionTree = librariesCollectionsWallIds.value[libraryId] ?? {}

    vSet(collectionTree, collectionKeyType, transform(collectionTree[collectionKeyType]))
    librariesCollectionsWallIds.value = { ...librariesCollectionsWallIds.value, [libraryId]: collectionTree }
  }

  function isWallIdInFavorites(wallId: WallId): boolean {
    const userId = activeUserIdCursor.value
    if (userId == null || wallId == null) return false

    const filtersHash = usersCollectionsWallIds.value[userId]?.filter ?? {}
    const favoritesWallIds = filtersHash.favorites ?? []
    return favoritesWallIds.includes(wallId)
  }

  /**
   * ==================== USER GROUP FOLDERS ====================
   */

  const groupFoldersById = ref<Record<UserGroupId, Folder>>({})

  const activeUserGroupFolderIds = computed((): FolderId[] => {
    const userId = activeUserIdCursor.value as UserId
    const foldersTree = usersCollectionsWallIds.value[userId]?.groupFolderId ?? {}
    return Object.keys(foldersTree).map((groupId) => parseInt(groupId))
  })

  const activeLibraryGroupFolderIds = computed((): FolderId[] => {
    const accountKey = activeAccountKeyCursor.value as AccountKey
    const foldersTree = librariesCollectionsWallIds.value[accountKey.id]?.groupFolderId ?? {}
    return Object.keys(foldersTree).map((groupId) => parseInt(groupId))
  })

  const activeGroupFolderIds = computed((): FolderId[] => {
    return activeAccountKeyCursor.value?.type === 'user'
      ? activeUserGroupFolderIds.value
      : activeLibraryGroupFolderIds.value
  })

  const activeUserGroups = computed((): UserGroup[] => {
    const groups = activeGroupFolderIds.value
      .map((id: UserGroupId) => groupFoldersById.value[id])
      .filter((folder: UserGroup) => folder)

    return sortBy(groups, (folder) => folder.name.toLowerCase())
  })
  const activeUserGroup = computed((): Folder | null => {
    const collectionKey = activeCollectionKey.value
    if (collectionKey.typeKey !== CollectionKeyTypes.GroupFolderId) return null
    return groupFoldersById.value[collectionKey.indexKey]
  })
  const xUserGroupsSection = computed((): boolean => {
    if (!dashAccountsStore.isBackpack && !dashAccountsStore.isCurrentLibrarySchool) return false
    return activeUserGroups.value?.length > 0
  })

  async function fetchUserGroupFolders(libraryId?: number): Promise<void> {
    const userId = activeUserIdCursor.value as UserId
    const isLibrary = libraryId != null
    const accountKey: AccountKey = {
      type: isLibrary ? 'library' : 'user',
      id: isLibrary ? libraryId : userId,
    }
    try {
      await fetchCachedQuery<JsonApiResponse<UserGroupFolderApiResponse>>({
        cacheKey: [
          'fetchUserGroupFolders',
          buildUserIdCacheKey(activeUserIdCursor.value as UserId),
          buildTenantIdCacheKey(userAccountsStore.currentTenant?.id as TenantId),
          buildLibraryIdCacheKey(libraryId as LibraryId),
        ],
        queryFn: async () => await UserGroupsApi.fetchUserGroupFolders(libraryId),
        onFetchSuccess: (response) => {
          const userGroupFolders = response.data as Array<JsonApiData<UserGroupFolderApiResponse>>
          const groupFolders: Array<{ id: UserGroupId; name: string }> = []

          userGroupFolders.forEach(({ attributes: { id, name, wallIds } }) => {
            groupFolders.push({ id, name })
            const collectionKey: CollectionKey = { typeKey: CollectionKeyTypes.GroupFolderId, indexKey: id }
            setCollectionWallIds({ accountKey, collectionKey, wallIds })
          })

          cacheUserGroupFolders(groupFolders)
          const groupFolderIds = groupFolders.map((group) => group.id)
          isLibrary
            ? updateGroupFolderListForLibrary({ libraryId, groupFolderIds })
            : updateGroupFolderListForUser({ userId, groupFolderIds })
        },
      })
    } catch (error) {
      genericFetchError({ error, source: 'DashCollectionsFetchUserGroupFolders' })
    }
  }

  async function fetchCurrentLibraryUserGroupFolders(): Promise<void> {
    const libraryId = dashAccountsStore.currentLibrary?.id
    if (libraryId == null) return
    await fetchUserGroupFolders(libraryId)
  }

  async function fetchAllSchoolUserGroupFolders(): Promise<void> {
    dashAccountsStore.librariesArray.forEach((library) => {
      void fetchUserGroupFolders(library.id)
    })
  }

  function cacheUserGroupFolders(groupFolders): void {
    groupFoldersById.value = { ...groupFoldersById.value, ...keyBy(groupFolders, 'id') }
  }

  function updateGroupFolderListForUser(payload: { userId: UserId; groupFolderIds: UserGroupId[] }): void {
    transformAndUpdateUserCollectionType<Record<UserGroupId, WallId[]>>(
      payload.userId,
      CollectionKeyTypes.GroupFolderId,
      (oldFoldersState) => {
        oldFoldersState = oldFoldersState ?? {}
        return payload.groupFolderIds.reduce((newGroupFolder, groupFolderId) => {
          newGroupFolder[groupFolderId] = oldFoldersState[groupFolderId] ?? []
          return newGroupFolder
        }, {})
      },
    )
  }

  function updateGroupFolderListForLibrary(payload: { libraryId: LibraryId; groupFolderIds: UserGroupId[] }): void {
    transformAndUpdateLibraryCollectionType<Record<UserGroupId, WallId[]>>(
      payload.libraryId,
      CollectionKeyTypes.GroupFolderId,
      (oldFoldersState) => {
        oldFoldersState = oldFoldersState ?? {}
        return payload.groupFolderIds.reduce((newGroupFolder, groupFolderId) => {
          newGroupFolder[groupFolderId] = oldFoldersState[groupFolderId] ?? []
          return newGroupFolder
        }, {})
      },
    )
  }

  /**
   * ==================== SHARED ====================
   */

  const sharedCollections = ref<SharedCollection[]>([])
  const activeSharedCollectionAccountKey = ref<SharedCollectionAccountKey | null>(null)

  const xSharedCollections = computed((): boolean => {
    if (
      !dashAccountsStore.isNativeAccount ||
      (sharedCollections.value.length === 0 && !windowSizeStore.isSmallerThanTabletPortrait)
    )
      return false
    return isCollectionShared(activeCollectionKey.value)
  })
  const isActiveCollectionShared = computed((): boolean => isCollectionShared(activeCollectionKey.value))
  const isActiveCollectionSharedCollection = computed(() => isCollectionSharedCollection(activeCollectionKey.value))
  const isSharedCollectionsEmpty = computed((): boolean => sharedCollections.value.length === 0)

  function viewSharedCollection(payload: {
    collectionKey: CollectionKey
    sharedCollectionAccountKey: SharedCollectionAccountKey
  }): void {
    switchCollectionKey(payload.collectionKey)
    activeSharedCollectionAccountKey.value = payload.sharedCollectionAccountKey
    void fetchActiveCollectionWallsNow()
  }

  async function fetchSharedCollections(): Promise<void> {
    try {
      await fetchCachedQuery<JsonApiResponse<SharedCollection>>({
        cacheKey: ['fetchSharedCollections', buildUserIdCacheKey(activeUserIdCursor.value as UserId)],
        queryFn: async () =>
          await fetchJson(`api/1/libraries/combined_shared/collections`, {
            query: {
              userId: String(activeUserIdCursor.value),
            },
          }),
        onFetchSuccess: (response) => {
          const sharedCollectionsData = (response.data as Array<JsonApiData<SharedCollection>>).map((item) => {
            return { ...item.attributes }
          })

          sharedCollections.value = [...sharedCollectionsData]
        },
      })
    } catch (error) {
      genericFetchError({ error, source: 'DashCollectionsFetchSharedCollections' })
    }
  }

  function isCollectionCrossLibrary(collectionKey: CollectionKey): boolean {
    if (isCollectionBookmark(collectionKey) || isCollectionSharedCollection(collectionKey)) return true
    const crossLibraryCollectionIndexKeys = isAppUsing('padletHome')
      ? ['combined_recents', 'combined_shared', 'gallery', 'search']
      : ['combined_recents', 'combined_shared', 'gallery']
    return crossLibraryCollectionIndexKeys.includes(collectionKey.indexKey as string)
  }

  /**
   * ==================== GALLERY ====================
   */

  const galleryViz = ref<WallViz | 'all'>('all')

  function switchGalleryViz(payload: WallViz): void {
    galleryViz.value = payload
    if (activeCollectionKey.value.indexKey === 'gallery') return
    switchCollectionKey({ typeKey: 'filter', indexKey: 'gallery' })
  }

  /**
   * ==================== ACCOUNT COLLECTIONS (PERSONAL AND LIBRARY ACCOUNTS) ====================
   */

  const activeAccountKeyCursor = ref<AccountKey>(
    currentLibrary.id == null ? { type: 'user', id: currentUser.id } : { type: 'library', id: currentLibrary.id },
  )
  const activeUserIdCursor = ref<UserId | null>(null)
  const usersCollectionsWallIds = ref<Record<UserId, CollectionsTree<WallId[]>>>({})
  const librariesCollectionsWallIds = ref<Record<LibraryId, CollectionsTree<WallId[]>>>({})
  const DEFAULT_COLLECTION_KEY: CollectionKey = { typeKey: 'filter', indexKey: 'recents' }
  const activeCollectionKeyCursor = ref<CollectionKey>(DEFAULT_COLLECTION_KEY)
  const isLibrariesCollectionsFetchErrored = ref<Record<LibraryId, CollectionsTree<boolean>>>({})
  const isLibrariesCollectionsFetching = ref<Record<LibraryId, CollectionsTree<boolean>>>({})
  const isLibrariesCollectionsFetched = ref<Record<LibraryId, CollectionsTree<boolean>>>({})
  const isUsersCollectionsFetchErrored = ref<Record<UserId, CollectionsTree<boolean>>>({})
  const isUsersCollectionsFetching = ref<Record<UserId, CollectionsTree<boolean>>>({})
  const isUsersCollectionsFetched = ref<Record<UserId, CollectionsTree<boolean>>>({})
  const wallsById = ref<Record<WallId, Wall>>({})

  const currentAccountKey = computed((): AccountKey => activeAccountKeyCursor.value)
  const currentUserAccountKey = computed((): AccountKey => {
    return { type: 'user', id: dashAccountsStore.currentUser.id }
  })
  const activeCollectionKey = computed((): CollectionKey => {
    const collectionKeyCursor = activeCollectionKeyCursor.value

    // If activeFolderId populated from local storage is not valid for this user, ignore it for now.
    if (
      collectionKeyCursor.typeKey === 'folderId' &&
      activeUserFolderIds.value.find((id: FolderId) => String(id) === String(collectionKeyCursor.indexKey)) !==
        undefined
    ) {
      return collectionKeyCursor
    } else if (
      collectionKeyCursor.typeKey === CollectionKeyTypes.GroupFolderId &&
      activeGroupFolderIds.value.find((id: UserGroupId) => String(id) === String(collectionKeyCursor.indexKey)) !==
        undefined
    ) {
      return collectionKeyCursor
    } else if (collectionKeyCursor.typeKey === 'filter') {
      return collectionKeyCursor
    } else if (isAppUsing('padletHome') && collectionKeyCursor.typeKey === 'search') {
      return collectionKeyCursor
    } else {
      return DEFAULT_COLLECTION_KEY
    }
  })
  const isActiveCollectionCrossLibrary = computed((): boolean => isCollectionCrossLibrary(activeCollectionKey.value))
  const isActiveCollectionInactiveWallsCollection = computed((): boolean =>
    isCollectionInactiveWallsCollection(activeCollectionKey.value),
  )
  const activeCollectionName = computed((): string => {
    if (activeFolder.value != null) return activeFolder.value.name
    if (activeUserGroup.value != null) return activeUserGroup.value.name
    const filter = activeCollectionKey.value.indexKey
    if (isActiveCollectionSharedCollection.value) {
      return (
        sharedCollections.value.find((collection) => {
          return (
            collection.id === activeSharedCollectionAccountKey.value?.id &&
            collection.type === activeSharedCollectionAccountKey.value?.type
          )
        })?.name ?? ''
      )
    }
    if (isActiveCollectionShared.value && isSharedCollectionsEmpty.value) {
      return windowSizeStore.isSmallerThanTabletLandscape || !isAppUsing('padletHome') ? __('Shared') : __('External')
    }
    if (isActiveFilter(activeCollectionKey.value, 'gallery')) {
      if (isAppUsing('galleryTemplates')) {
        return __('Gallery')
      }
      return galleryVizes.find((vizData) => vizData.viz === galleryViz.value)?.text ?? ''
    }
    return filterDisplayName(
      filter as WallsFilter,
      dashAccountsStore.wallViewableAndVisibleLibrariesArray.length,
      windowSizeStore.isSmallerThanTabletLandscape,
    )
  })
  const activeCollectionWalls = computed((): Wall[] => {
    if (currentAccountKey.value == null) return []
    const { type, id } = currentAccountKey.value
    const isLibrary = type === 'library'

    // Backend filtering
    let wallIds
    if (!isAppUsing('padletHome')) {
      const wallIdsTree = isLibrary ? librariesCollectionsWallIds.value : usersCollectionsWallIds.value
      wallIds = getAccountCollectionValue(wallIdsTree, id, activeCollectionKey.value) ?? []
    } else {
      // The search wall ids collection belongs to the userCollectionsWallIds tree. On the new padlet home,
      // we've added a new search collection that available for all user types. We should still use usersCollectionsWallIds
      // even if the current active account key is a library.
      if (!isCollectionSearch(activeCollectionKeyCursor.value)) {
        const wallIdsTree = isLibrary ? librariesCollectionsWallIds.value : usersCollectionsWallIds.value
        wallIds = getAccountCollectionValue(wallIdsTree, id, activeCollectionKey.value) ?? []
      } else {
        wallIds =
          getAccountCollectionValue(
            usersCollectionsWallIds.value,
            currentUserAccountKey.value.id,
            activeCollectionKey.value,
          ) ?? []
      }
    }

    // Folder wallIds might include IDs for walls that are not yet loaded.
    const walls = wallIds.map((wallId: WallId) => wallsById.value[wallId]).filter((wall: Wall) => wall)

    // Frontend filtering
    const identityFilter = (): true => true
    const galleryFilter = (wall: Wall): boolean => {
      const viz = galleryViz.value
      return viz === 'all' || viz === wall.viz
    }
    const currentFilter = activeCollectionKey.value.indexKey === 'gallery' ? galleryFilter : identityFilter
    const frontendFilteredWalls = walls.filter(currentFilter)

    // only sort on the frontend when all walls have been fetched
    if (isActiveCollectionFetched.value === true) {
      const sorter = {
        name: (wall: Wall): string => (wall.title ?? '').trim(),
        date: (wall: Wall): number => +new Date(wall[activeCollectionDateSortAttribute.value]),
      }[activeSortAttribute.value]
      const sortedFrontendFilteredWalls = sortBy(frontendFilteredWalls, sorter)
      if (activeSortDirectionGetter.value === 'desc') sortedFrontendFilteredWalls.reverse()
      return sortedFrontendFilteredWalls
    }

    return frontendFilteredWalls
  })
  const isActiveCollectionFetching = computed((): boolean | null => {
    if (currentAccountKey.value == null) return true
    const { type, id } = currentAccountKey.value
    const isLibrary = type === 'library'
    if (isAppUsing('padletHome')) {
      const isFetching =
        isLibrary && !isCollectionSearch(activeCollectionKeyCursor.value)
          ? isLibrariesCollectionsFetching.value
          : isUsersCollectionsFetching.value
      const accountId =
        isLibrary && !isCollectionSearch(activeCollectionKeyCursor.value) ? id : activeUserIdCursor.value
      return getAccountCollectionValue(isFetching, accountId, activeCollectionKey.value)
    } else {
      const isFetching = isLibrary ? isLibrariesCollectionsFetching.value : isUsersCollectionsFetching.value
      return getAccountCollectionValue(isFetching, id, activeCollectionKey.value)
    }
  })
  const isActiveCollectionFetched = computed((): boolean | null => {
    if (currentAccountKey.value == null) return true
    const { type, id } = currentAccountKey.value
    const isLibrary = type === 'library'
    if (isAppUsing('padletHome')) {
      const isFetched =
        isLibrary && !isCollectionSearch(activeCollectionKeyCursor.value)
          ? isLibrariesCollectionsFetched.value
          : isUsersCollectionsFetched.value
      const accountId =
        isLibrary && !isCollectionSearch(activeCollectionKeyCursor.value) ? id : activeUserIdCursor.value
      return getAccountCollectionValue(isFetched, accountId, activeCollectionKey.value)
    } else {
      const isFetched = isLibrary ? isLibrariesCollectionsFetched.value : isUsersCollectionsFetched.value
      return getAccountCollectionValue(isFetched, id, activeCollectionKey.value)
    }
  })
  const isActiveCollectionFetchErrored = computed((): boolean | null => {
    if (currentAccountKey.value == null) return true
    const { type, id } = currentAccountKey.value
    const isLibrary = type === 'library'
    if (isAppUsing('padletHome')) {
      const isErrored =
        isLibrary && !isCollectionSearch(activeCollectionKeyCursor.value)
          ? isLibrariesCollectionsFetchErrored.value
          : isUsersCollectionsFetchErrored.value
      const accountId =
        isLibrary && !isCollectionSearch(activeCollectionKeyCursor.value) ? id : activeUserIdCursor.value
      return getAccountCollectionValue(isErrored, accountId, activeCollectionKey.value)
    } else {
      const isErrored = isLibrary ? isLibrariesCollectionsFetchErrored.value : isUsersCollectionsFetchErrored.value
      return getAccountCollectionValue(isErrored, id, activeCollectionKey.value)
    }
  })
  const emptyActiveCollectionCopy = computed((): [string, string] => {
    const { typeKey, indexKey }: CollectionKey = activeCollectionKey.value
    if (typeKey === CollectionKeyTypes.Filter) return getEmptyFilterCollectionCopy(indexKey)
    if (typeKey === CollectionKeyTypes.Search) return getEmptySearchCollectionCopy()
    if (typeKey === CollectionKeyTypes.FolderId) return getEmptyFolderCollectionCopy()
    if (typeKey === CollectionKeyTypes.GroupFolderId) return getEmptyGroupFolderCollectionCopy()
    return ['', '']
  })
  const activeAccountWallFilters = computed((): WallsFilter[] => {
    const accountKey = currentAccountKey.value
    const collectionKey = activeCollectionKey.value
    return wallFiltersForAccountCollection({
      accountKey,
      collectionKey,
      canCurrentAccountArchiveWall: dashAccountsStore.canCurrentAccountArchiveWall,
    })
  })
  const activeWallIdCursor = ref<WallId | null>(null)
  const activeWallId = computed((): WallId | null => activeWallIdCursor.value)
  const activeWall = computed((): Wall | null =>
    activeWallIdCursor.value != null ? wallsById.value[activeWallIdCursor.value] : null,
  )
  const isActiveWallInsideLibrary = computed((): boolean => activeWall.value?.libraryId != null)

  function wallFiltersForAccountCollection(payload: {
    accountKey: AccountKey
    collectionKey: CollectionKey
    canCurrentAccountArchiveWall: boolean
  }): WallsFilter[] {
    if (payload.accountKey == null || payload.collectionKey == null) return []

    // Dont' show for any of the other cross-library collections
    if (!isAppUsing('padletHome') && isCollectionCrossLibrary(payload.collectionKey)) return []

    let wallsFilter: WallsFilter[] = []
    // Library accounts
    if (payload.accountKey.type === 'library') {
      wallsFilter =
        isAppUsing('padletHome') && !windowSizeStore.isSmallerThanTabletLandscape
          ? LIBRARY_ACCOUNT_FILTERS
          : OLD_LIBRARY_ACCOUNT_FILTERS
    } else {
      // Non-alexandria user account
      const isNativeHost = currentHostname().endsWith(NATIVE_HOST)
      if (isNativeHost) {
        wallsFilter =
          isAppUsing('padletHome') && !windowSizeStore.isSmallerThanTabletLandscape
            ? USER_ACCOUNT_FILTERS
            : OLD_USER_ACCOUNT_FILTERS
      } else {
        wallsFilter =
          isAppUsing('padletHome') && !windowSizeStore.isSmallerThanTabletLandscape
            ? TENANT_USER_ACCOUNT_FILTERS
            : OLD_TENANT_USER_ACCOUNT_FILTERS
      }
    }

    return payload.canCurrentAccountArchiveWall ? wallsFilter : wallsFilter.filter((filter) => filter !== 'archived')
  }

  function setActiveUserId(userId: UserId): void {
    activeUserIdCursor.value = userId
  }

  function switchAccountKey(accountKey: AccountKey): void {
    const collectionKey: CollectionKey = { typeKey: 'filter', indexKey: 'all' }
    setAccountCollectionCursor({ accountKey, collectionKey })
  }

  function switchCollectionKey(collectionKey: CollectionKey): void {
    if (isAppUsing('padletHome')) resetActiveSearchQuery()
    let accountKey = currentAccountKey.value

    const invalidCollectionForLibrary =
      accountKey?.type === 'library' &&
      (collectionKey.typeKey !== 'filter' || !OLD_LIBRARY_ACCOUNT_FILTERS.includes(collectionKey.indexKey))

    if (invalidCollectionForLibrary && !isAppUsing('padletHome')) {
      accountKey = { type: 'user', id: activeUserIdCursor.value as UserId }
    }

    setAccountCollectionCursor({ accountKey, collectionKey })
  }

  function setAccountCollectionCursor(payload: { accountKey: AccountKey; collectionKey: CollectionKey }): void {
    activeCollectionKeyCursor.value = payload.collectionKey
    activeAccountKeyCursor.value = payload.accountKey
  }

  function getWallsByCollectionKey(collectionKey: CollectionKey): Wall[] {
    const userId = activeUserIdCursor.value as UserId
    const wallIds = getAccountCollectionValue(usersCollectionsWallIds.value, userId, collectionKey) ?? []
    return wallIds.map((wallId) => wallsById.value[wallId])
  }

  function updateWallAttribute(payload: {
    wallId: WallId
    targetAttribute: keyof Wall
    newAttributeValue: Wall[keyof Wall]
  }): void {
    let targetWall = wallsById.value[payload.wallId]
    targetWall = { ...targetWall, [payload.targetAttribute]: payload.newAttributeValue }
    wallsById.value = { ...wallsById.value, [payload.wallId]: { ...targetWall } }
  }

  function removeWallIdFromCollection(payload: {
    accountKey: AccountKey | null
    collectionKey: CollectionKey
    wallId: WallId
  }): void {
    const { type, id } = payload.accountKey as AccountKey
    const isLibrary = type === 'library'
    const wallIdsTree = isLibrary ? librariesCollectionsWallIds.value : usersCollectionsWallIds.value
    const removeWallId = (oldWallIds): WallId[] => {
      if (oldWallIds === null) {
        return []
      }

      return oldWallIds.filter((oldWallId) => oldWallId !== payload.wallId)
    }
    transformAccountCollectionValue(wallIdsTree, id, payload.collectionKey, removeWallId)
  }

  function removeWallIdFromAllCollections(wallId: WallId): void {
    const transform = (wallIds?: WallId[]): WallId[] => (wallIds ?? []).filter((id) => id !== wallId)
    transformAccountsCollectionsValues<WallId[]>(usersCollectionsWallIds.value, transform)
    transformAccountsCollectionsValues<WallId[]>(librariesCollectionsWallIds.value, transform)
  }

  function addWallIdsToCurrentCollection(wallIds: WallId[]): void {
    const { type, id } = currentAccountKey.value
    const isLibrary = type === 'library'
    const wallIdsTree = isLibrary ? librariesCollectionsWallIds.value : usersCollectionsWallIds.value
    const addWallIds = (oldWallIds): WallId[] => uniq([...(oldWallIds ?? []), ...wallIds])
    transformAccountCollectionValue(wallIdsTree, id, activeCollectionKey.value, addWallIds)
  }

  /**
   * ==================== SORT WALLS ====================
   */

  const activeSortAttribute = ref<WallSortAttribute>('date')
  const activeSortDirection = ref<WallSortDirection | null>(null)

  const activeSortDirectionGetter = computed((): WallSortDirection => {
    return activeSortDirection.value != null
      ? activeSortDirection.value
      : activeSortAttribute.value === 'date'
      ? ('desc' as WallSortDirection)
      : ('asc' as WallSortDirection)
  })

  const activeCollectionDateSortAttribute = computed((): WallSortAttribute => {
    return ({
      combined_recents: 'lastPresentAt',
      gallery: 'createdAt',
      trashed: 'trashedAt',
    }[activeCollectionKey.value.indexKey] ?? 'updatedAt') as WallSortAttribute
  })
  const activeCollectionDateSortAttributeText = computed((): string => {
    return (
      {
        createdAt: __('Date created'),
        updatedAt: __('Last modified'),
        lastPresentAt: __('Last viewed'),
        trashedAt: __('Deletion date'),
      }[activeCollectionDateSortAttribute.value] ?? __('Date')
    )
  })

  function sortWalls(sortAttribute: WallSortAttribute): void {
    if (sortAttribute === activeSortAttribute.value) {
      activeSortDirection.value = activeSortDirection.value === 'asc' ? 'desc' : 'asc'
    } else {
      activeSortAttribute.value = sortAttribute
      activeSortDirection.value = null
    }
  }

  /**
   * ==================== SEARCH====================
   */

  const activeSearchQuery = ref<string>('')

  const isActiveSearchQueryEmpty = computed((): boolean => activeSearchQuery.value === '')

  async function fetchSearchResults(payload: { activeSearchQuery: string }): Promise<void> {
    const userId = activeUserIdCursor.value as UserId
    if (userId == null) return

    const collectionKey: CollectionKey = {
      typeKey: 'search',
      indexKey: payload.activeSearchQuery,
    }

    setAccountCollectionValue(isUsersCollectionsFetchErrored.value, userId, collectionKey, false)
    setActiveSearchQuery({ activeSearchQuery: payload.activeSearchQuery })

    if (activeSearchQuery.value === '') return

    try {
      const searchEndpoint = 'api/1/search/padlets.json'
      setAccountCollectionValue(isUsersCollectionsFetching.value, userId, collectionKey, true)
      const searchResults = await fetchJson(searchEndpoint, {
        query: {
          q: activeSearchQuery.value,
        },
      })
      const walls = searchResults.hits
      if (walls.length > 0) {
        padletPickerStore.addSearchSuggestionToSafeStorage(activeSearchQuery.value)
      }

      const wallsToCache = walls.filter((wall) => wallsById.value[wall.id] == null)
      cacheWalls(wallsToCache)

      const accountKey: AccountKey = { type: 'user', id: userId }
      const wallIds = walls.map((wall) => wall.id)
      setCollectionWallIds({ accountKey, collectionKey, wallIds })
      setAccountCollectionValue(isUsersCollectionsFetched.value, userId, collectionKey, true)
    } catch (e) {
      setAccountCollectionValue(isUsersCollectionsFetchErrored.value, userId, collectionKey, true)
    } finally {
      setAccountCollectionValue(isUsersCollectionsFetching.value, userId, collectionKey, false)
    }
  }

  function setActiveSearchQuery(payload: { activeSearchQuery: string }): void {
    activeSearchQuery.value = payload.activeSearchQuery
  }

  function resetActiveSearchQuery(): void {
    activeSearchQuery.value = ''
  }

  async function ensureWallsFetchedForCollection(payload: { collectionKey: CollectionKey }): Promise<void> {
    const accountKey: AccountKey = { id: activeUserIdCursor.value as UserId, type: 'user' }

    if (
      (isCollectionFetched(payload.collectionKey) == null || isCollectionFetched(payload.collectionKey) === false) &&
      (isCollectionFetching(payload.collectionKey) == null || isCollectionFetching(payload.collectionKey) === false)
    ) {
      await fetchCollectionWalls({ accountKey, collectionKey: payload.collectionKey })
    }
  }

  function isCollectionFetching(collectionKey: CollectionKey): boolean | null {
    return getAccountCollectionValue(isUsersCollectionsFetching.value, activeUserIdCursor.value, collectionKey)
  }

  function isCollectionFetchErrored(collectionKey: CollectionKey): boolean | null {
    return getAccountCollectionValue(isUsersCollectionsFetchErrored.value, activeUserIdCursor.value, collectionKey)
  }

  function isCollectionFetched(collectionKey: CollectionKey): boolean | null {
    return getAccountCollectionValue(isUsersCollectionsFetched.value, activeUserIdCursor.value, collectionKey)
  }

  /**
   * ==================== FETCH WALLS ====================
   */

  async function fetchActiveCollectionWallsNow(): Promise<void> {
    await haltFetchCollectionWalls()
    void fetchActiveCollectionWalls()
  }

  async function haltFetchCollectionWalls(): Promise<void> {
    const key = 'fetchCollectionWalls'
    // Plant the unqueueKey event first so that the next promise in line doesn't start executing
    // once we resolve the current promise
    const unqueueKeyPromise = q.unqueueKey(key)

    // Give the halt signal to the promise that is in flight.
    if (q.currentKey === key) {
      const inFlightPromise = q.resolveCurrentPromiseNow([])
      inFlightPromise?.metadata?.haltChain()
    }

    // Terminate queued fetches
    await unqueueKeyPromise.then((unqueuedFetches) => {
      unqueuedFetches.forEach((unqueuedFetch) => {
        const { accountKey, collectionKey } = unqueuedFetch.metadata
        fetchWallsHalt({ accountKey, collectionKey })
      })
    })
  }

  function fetchWallsHalt(payload: { accountKey: AccountKey; collectionKey: CollectionKey }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const isFetching = isLibrary ? isLibrariesCollectionsFetching.value : isUsersCollectionsFetching.value
    setAccountCollectionValue(isFetching, id, payload.collectionKey, false)
  }

  async function fetchActiveCollectionWalls(): Promise<void> {
    const accountKey = currentAccountKey.value
    const collectionKey: CollectionKey = activeCollectionKey.value

    const fetchCollectionWallsParams: {
      accountKey: AccountKey
      collectionKey: CollectionKey
      pageSize?: number
      fetchNextPage?: boolean
    } = {
      accountKey,
      collectionKey,
    }

    // for certain collections we only fetch a max number of walls
    const fetchLimits = getCollectionFetchLimits(collectionKey)
    if (fetchLimits != null) {
      // use a single fetch if such a limit exists
      fetchCollectionWallsParams.pageSize = fetchLimits
      fetchCollectionWallsParams.fetchNextPage = false
    }

    await fetchCollectionWalls(fetchCollectionWallsParams)
  }

  async function fetchCollectionWalls({
    accountKey,
    collectionKey,
    accumulatedWallIds,
    pageSize = 100,
    hasNextPage,
    nextPage,
    fetchNextPage = true,
  }: {
    accountKey: AccountKey
    collectionKey: CollectionKey
    accumulatedWallIds?: WallId[]
    pageSize?: number
    hasNextPage?: boolean
    nextPage?: number | null
    fetchNextPage?: boolean
  }): Promise<void> {
    devLog('fetchCollectionWalls', { accountKey, collectionKey, accumulatedWallIds, hasNextPage, nextPage })

    let isChainHalted = false
    const haltChain = (): boolean => (isChainHalted = true)
    const isFirstFetchInChain = nextPage === 0 || nextPage == null

    if (isFirstFetchInChain) fetchWallsStart({ accountKey, collectionKey })
    const promise = async (): Promise<void> => {
      try {
        const urlObject: { path: string; search: Record<string, any> } = {
          path: '/api/7/walls',
          search: {},
        }

        if (collectionKey.typeKey === CollectionKeyTypes.GroupFolderId) {
          urlObject.search.userGroupId = collectionKey.indexKey
        } else if (collectionKey.typeKey === CollectionKeyTypes.FolderId) {
          urlObject.search.folderId = collectionKey.indexKey
        } else if (collectionKey.typeKey === CollectionKeyTypes.Filter) {
          urlObject.search.filter = collectionKey.indexKey
          if (accountKey.type === 'user') {
            urlObject.search.userId = accountKey.id
          } else if (accountKey.type === 'library') {
            if (
              isAppUsing('padletHome') &&
              (collectionKey.indexKey === 'combined_recents' ||
                collectionKey.indexKey === 'combined_shared' ||
                collectionKey.indexKey === 'combined_shared_library' ||
                collectionKey.indexKey === 'combined_shared_user' ||
                collectionKey.indexKey === 'favorites' ||
                collectionKey.indexKey === 'gallery')
            ) {
              urlObject.search.userId = activeUserIdCursor.value
            } else {
              if (collectionKey.indexKey === 'trashed') {
                urlObject.search.userId = activeUserIdCursor.value
              }
              urlObject.search.libraryId = accountKey.id
            }
          }

          if (activeSharedCollectionAccountKey.value?.type === 'user') {
            urlObject.search.targetUserId = activeSharedCollectionAccountKey.value?.id
          } else if (activeSharedCollectionAccountKey.value?.type === 'library') {
            urlObject.search.targetLibraryId = activeSharedCollectionAccountKey.value?.id
          }
        }

        // match page[size] and page[number] in backend
        urlObject.search['page[size]'] = pageSize
        urlObject.search['page[number]'] = nextPage == null ? 0 : nextPage

        const sortAttributeMapping = {
          lastPresentAt: '-last_present_at',
          updatedAt: '-updated_at',
          title: 'title',
          createdAt: '-created_at',
          trashedAt: '-trashed_at',
        }
        urlObject.search.sort = sortAttributeMapping[activeCollectionDateSortAttribute.value]

        // Fetch current page
        await fetchCachedQuery<JsonApiResponse<Wall>>({
          cacheKey: [
            'fetchCollectionWalls',
            `pageNumber:${urlObject.search['page[number]'] as number}`,
            asciiSafeStringify(collectionKey),
            buildUserIdCacheKey(dashAccountsStore.currentUser.id),
            buildTenantIdCacheKey(userAccountsStore.currentTenant?.id as TenantId),
            buildLibraryIdCacheKey(dashAccountsStore.currentLibrary?.id as LibraryId),
          ],
          queryFn: async () => await fetchJson(transformCurrentUrl({}, urlObject)),
          onCacheFetchSuccess: (response) => {
            // Update the state with cached data if it exists
            const wallsNodes = response.data as Array<JsonApiData<Wall>>
            const walls = wallsNodes.map((wall) => wall.attributes)
            const wallIds = walls.map((wall) => wall.id)

            cacheWalls(walls)
            addWallIdsToCollection({ accountKey, collectionKey, wallIds })
          },
          onFetchSuccess: (response) => {
            // Update the state with the latest data and fetch the next page if there is one
            const wallsNodes = response.data as Array<JsonApiData<Wall>>
            const walls = wallsNodes.map((wall) => wall.attributes)
            const wallIds = walls.map((wall) => wall.id)
            const hasNextPage: boolean | undefined = Boolean(response?.meta?.page?.hasNextPage)
            const newAccumulatedWallIds = uniq([...(accumulatedWallIds ?? []), ...wallIds])

            // Show new walls as they come. Deleted walls will be hidden later after the last fetch in the chain.
            cacheWalls(walls)
            addWallIdsToCollection({ accountKey, collectionKey, wallIds })

            if (!isChainHalted && Boolean(hasNextPage) && fetchNextPage) {
              void fetchCollectionWalls({
                accountKey,
                collectionKey,
                accumulatedWallIds: newAccumulatedWallIds,
                pageSize,
                hasNextPage,
                nextPage: response?.meta?.page?.nextPage,
                fetchNextPage,
              })
            } else {
              fetchWallsComplete({ accountKey, collectionKey })
              // If walls have been removed from current collection, reflect that fact.
              setCollectionWallIds({ accountKey, collectionKey, wallIds: newAccumulatedWallIds })
            }
          },
        })
      } catch (error) {
        captureException(error)
        fetchWallsError({ accountKey, collectionKey })
      }
    }
    return await q.enqueue('fetchCollectionWalls', promise, {
      metadata: { accountKey, collectionKey, haltChain },
    })
  }

  function fetchWallsStart(payload: { accountKey: AccountKey; collectionKey: CollectionKey }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const isErrored = isLibrary ? isLibrariesCollectionsFetchErrored.value : isUsersCollectionsFetchErrored.value
    const isFetching = isLibrary ? isLibrariesCollectionsFetching.value : isUsersCollectionsFetching.value
    // Reset error to false only if previous fetch resulted in error for this collectionKey
    if (getAccountCollectionValue(isErrored, id, payload.collectionKey) != null) {
      setAccountCollectionValue(isErrored, id, payload.collectionKey, false)
    }
    setAccountCollectionValue(isFetching, id, payload.collectionKey, true)
  }

  function fetchWallsComplete(payload: { accountKey: AccountKey; collectionKey: CollectionKey }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const isFetching = isLibrary ? isLibrariesCollectionsFetching.value : isUsersCollectionsFetching.value
    const isFetched = isLibrary ? isLibrariesCollectionsFetched.value : isUsersCollectionsFetched.value
    setAccountCollectionValue(isFetched, id, payload.collectionKey, true)
    setAccountCollectionValue(isFetching, id, payload.collectionKey, false)
  }

  function fetchWallsError(payload: { accountKey: AccountKey; collectionKey: CollectionKey }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const isErrored = isLibrary ? isLibrariesCollectionsFetchErrored.value : isUsersCollectionsFetchErrored.value
    const isFetching = isLibrary ? isLibrariesCollectionsFetching.value : isUsersCollectionsFetching.value
    setAccountCollectionValue(isFetching, id, payload.collectionKey, false)
    setAccountCollectionValue(isErrored, id, payload.collectionKey, true)
  }

  function cacheWalls(walls): void {
    wallsById.value = { ...wallsById.value, ...keyBy(walls, 'id') }
  }

  function addWallIdsToCollection(payload: {
    accountKey: AccountKey
    collectionKey: CollectionKey
    wallIds: WallId[]
  }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const wallIdsTree = isLibrary ? librariesCollectionsWallIds.value : usersCollectionsWallIds.value
    const addWallIds = (oldWallIds): WallId[] => uniq([...(oldWallIds ?? []), ...payload.wallIds])
    transformAccountCollectionValue(wallIdsTree, id, payload.collectionKey, addWallIds)
  }

  function setCollectionWallIds(payload: {
    accountKey: AccountKey
    collectionKey: CollectionKey
    wallIds: WallId[]
  }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const wallIdsTree = isLibrary ? librariesCollectionsWallIds.value : usersCollectionsWallIds.value
    setAccountCollectionValue(wallIdsTree, id, payload.collectionKey, payload.wallIds)
  }

  /**
   * ==================== LOCAL CACHE ====================
   */

  function saveWallsLocally(): void {
    let wallsByIdState = cloneDeep(wallsById.value)
    let usersCollectionsWallIdsState = cloneDeep(usersCollectionsWallIds.value)
    let librariesCollectionsWallIdsState = cloneDeep(librariesCollectionsWallIds.value)

    // Prune if payload is too big
    const maxWalls = 90
    if (Object.keys(wallsByIdState).length > maxWalls) {
      const walls = activeCollectionWalls.value.slice(0, maxWalls)
      wallsByIdState = keyBy(walls, 'id')

      const pruneIds = (item): any => {
        if (Array.isArray(item)) return item.filter((id) => wallsByIdState[id])
        if (typeof item === 'object') return mapValues(item, pruneIds)
        return item
      }
      usersCollectionsWallIdsState = pruneIds(usersCollectionsWallIdsState)
      librariesCollectionsWallIdsState = pruneIds(librariesCollectionsWallIdsState)
    }

    devLog('saveWallsLocally', { wallsByIdState, usersCollectionsWallIdsState, librariesCollectionsWallIdsState })

    safeLocalStorage.setItem(`wallsById`, asciiSafeStringify(wallsByIdState))
    safeLocalStorage.setItem(`usersCollectionsWallIds`, asciiSafeStringify(usersCollectionsWallIdsState))
    safeLocalStorage.setItem(`librariesCollectionsWallIds`, asciiSafeStringify(librariesCollectionsWallIdsState))
  }

  function loadWallsFromLocalCache(): void {
    const wallsByIdData = safeLocalStorage.getItem('wallsById')
    const usersCollectionsWallIdsData = safeLocalStorage.getItem('usersCollectionsWallIds')
    const librariesCollectionsWallIdsData = safeLocalStorage.getItem('librariesCollectionsWallIds')

    // clear local cache right after loading to prevent data staleness
    safeLocalStorage.removeItem('wallsById')
    safeLocalStorage.removeItem('usersCollectionsWallIds')
    safeLocalStorage.removeItem('librariesCollectionsWallIds')

    // If strings are found in local storage, we expect them to be parseable.
    if (wallsByIdData != null) {
      wallsById.value = JSON.parse(wallsByIdData)
    }
    if (usersCollectionsWallIdsData != null) {
      usersCollectionsWallIds.value = JSON.parse(usersCollectionsWallIdsData)
    }
    if (librariesCollectionsWallIdsData !== null) {
      librariesCollectionsWallIds.value = JSON.parse(librariesCollectionsWallIdsData)
    }
  }

  return {
    /**
     * ==================== BOOKMARKS ====================
     */

    isActiveCollectionBookmark,
    isActiveWallBookmarked,
    isWallIdBookmarked,

    /**
     * ==================== FOLDERS ====================
     */

    folderActionsAnchor,
    folderActionsFolder,
    foldersById,
    xFolderActions,
    activeUserFolderIds,
    activeWallFolderIds,
    activeUserFolders,
    activeFolder,
    activeFolderId,
    foldersArray,
    wallIdsByFolderId,
    isActiveWallInFavorites,
    favoritesFolderWallIds,
    xFolders,
    isFetchingFolders,
    showFolderActions,
    closeFolderActions,
    promptNameForActiveUserNewFolder,
    createFolderForActiveUser,
    promptRenameFolder,
    renameFolder,
    confirmDeleteFolder,
    isWallIdInFavorites,
    fetchFolders,

    /**
     * ==================== USER GROUP FOLDERS ====================
     */

    groupFoldersById,
    activeUserGroupFolderIds,
    activeUserGroups,
    activeUserGroup,
    xUserGroupsSection,
    fetchUserGroupFolders,
    fetchCurrentLibraryUserGroupFolders,
    fetchAllSchoolUserGroupFolders,
    cacheUserGroupFolders,
    updateGroupFolderListForUser,

    /**
     * ==================== SHARED ====================
     */

    sharedCollections,
    activeSharedCollectionAccountKey,
    xSharedCollections,
    isActiveCollectionShared,
    isActiveCollectionSharedCollection,
    isSharedCollectionsEmpty,
    viewSharedCollection,
    fetchSharedCollections,
    isCollectionCrossLibrary,

    /**
     * ==================== GALLERY ====================
     */

    galleryViz,
    switchGalleryViz,

    /**
     * ==================== ACCOUNT COLLECTIONS (PERSONAL AND LIBRARY ACCOUNTS) ====================
     */

    activeAccountKeyCursor,
    activeUserIdCursor,
    usersCollectionsWallIds,
    librariesCollectionsWallIds,
    activeCollectionKeyCursor,
    isLibrariesCollectionsFetchErrored,
    isLibrariesCollectionsFetching,
    isLibrariesCollectionsFetched,
    isUsersCollectionsFetchErrored,
    isUsersCollectionsFetching,
    isUsersCollectionsFetched,
    wallsById,
    currentAccountKey,
    currentUserAccountKey,
    activeCollectionKey,
    isActiveCollectionCrossLibrary,
    isActiveCollectionInactiveWallsCollection,
    activeCollectionName,
    activeCollectionWalls,
    isActiveCollectionFetching,
    isActiveCollectionFetched,
    isActiveCollectionFetchErrored,
    emptyActiveCollectionCopy,
    activeAccountWallFilters,
    activeWallIdCursor,
    activeWallId,
    activeWall,
    isActiveWallInsideLibrary,
    wallFiltersForAccountCollection,
    setActiveUserId,
    switchAccountKey,
    switchCollectionKey,
    setAccountCollectionCursor,
    getWallsByCollectionKey,
    updateWallAttribute,
    removeWallIdFromCollection,
    removeWallIdFromAllCollections,
    addWallIdsToCurrentCollection,

    /**
     * ==================== SORT WALLS ====================
     */

    activeSortAttribute,
    activeSortDirection,
    activeCollectionDateSortAttribute,
    activeCollectionDateSortAttributeText,
    sortWalls,

    /**
     * ==================== SEARCH====================
     */

    activeSearchQuery,
    isActiveSearchQueryEmpty,
    fetchSearchResults,
    setActiveSearchQuery,
    resetActiveSearchQuery,
    ensureWallsFetchedForCollection,
    isCollectionFetching,
    isCollectionFetchErrored,
    isCollectionFetched,

    /**
     * ==================== FETCH WALLS ====================
     */

    fetchActiveCollectionWallsNow,
    haltFetchCollectionWalls,
    fetchWallsHalt,
    fetchActiveCollectionWalls,
    fetchCollectionWalls,
    fetchWallsStart,
    fetchWallsComplete,
    fetchWallsError,
    cacheWalls,
    addWallIdsToCollection,
    setCollectionWallIds,

    /**
     * ==================== LOCAL CACHE ====================
     */

    saveWallsLocally,
    loadWallsFromLocalCache,
  }
})
