// @file Utils for processing posts on Surface and Slideshow

import { getDisplayAttributes } from '@@/bits/beethoven'
import device from '@@/bits/device'
import { $ } from '@@/bits/jquery'
import { getHostnameFromUrl, transformUrl } from '@@/bits/location'
import { mathifyElement } from '@@/bits/math'
import { PADLET_DOMAIN_REGEX } from '@@/bits/regex'
import { NATIVE_HOST, isInternalUrl } from '@@/bits/url'
import { BRIDGED_LINK_TYPE_ATTRIBUTE } from '@@/native_bridge/anchor'
import { MENTION_ELEMENT_TAG_NAME } from '@padlet/universal-post-editor'

const setRedirectUrlsForExternalLinks = (element: HTMLElement): void => {
  $(element)
    .find('a[href^=h]')
    .attr({ target: '_blank', rel: 'noopener nofollow ugc' })
    .each((_, el) => {
      const href = el.getAttribute('href')?.trim() ?? ''
      if (!isInternalUrl(href)) {
        el.setAttribute('href', transformToSafeRedirectUrl(href))
      }
    })
}

const transformToSafeRedirectUrl = (url: string): string => {
  return transformUrl(`https://${NATIVE_HOST}`, {
    path: 'redirect',
    search: {
      url,
    },
  })
}

const setExternalLinksForNativeBridge = (element: HTMLElement): void => {
  $(element)
    .find('a[href^=h]')
    .attr({ target: '_blank', rel: 'noopener nofollow ugc', [BRIDGED_LINK_TYPE_ATTRIBUTE]: 'external' })
}

const isSlideshowUrl = (url: string): boolean => {
  const urlObject = new URL(url)
  const pathElements = urlObject.pathname.split('/')
  return PADLET_DOMAIN_REGEX.test(urlObject.host) && pathElements.length > 3 && pathElements[3] === 'slideshow'
}

const isMentionLink = (el: Element): boolean => {
  return el.tagName === 'A' && el.parentElement?.tagName === MENTION_ELEMENT_TAG_NAME.toUpperCase()
}

// check to see if the url links to a padlet and set data-bridged-link to internal
const makeBodyPadletLinksInternalForNativeBridge = async (element: HTMLElement): Promise<void> => {
  if (!device.app) return

  const bodyAnchorTags = element.querySelectorAll('a[href^=h]')
  for (const anchorTag of bodyAnchorTags) {
    const hrefUrl = anchorTag.getAttribute('href')
    if (hrefUrl == null) continue

    const hrefHost = getHostnameFromUrl(hrefUrl)
    if (PADLET_DOMAIN_REGEX.test(hrefHost)) {
      if (isMentionLink(anchorTag)) {
        anchorTag.setAttribute(BRIDGED_LINK_TYPE_ATTRIBUTE, 'profile')
      } else if (isSlideshowUrl(hrefUrl)) {
        anchorTag.setAttribute(BRIDGED_LINK_TYPE_ATTRIBUTE, 'slideshow')
      } else {
        // the fetchLink request in bits/beethoven.ts is memoized so this shouldn't fire too many requests
        const urlDisplayAttributes = await getDisplayAttributes(hrefUrl)
        // All pages from padlet have content_subcategory === 'padlet'
        // provider_name for a wall is the builder's name, "padlet" should be used only for landside pages (e.g. padlet.com, padlet.com/features)
        const isPadletWallUrl =
          urlDisplayAttributes?.content_subcategory === 'padlet' &&
          urlDisplayAttributes?.provider_name.toLowerCase() !== 'padlet'

        if (isPadletWallUrl) {
          anchorTag.setAttribute(BRIDGED_LINK_TYPE_ATTRIBUTE, 'padlet')
        }
      }
    }
  }
}

const makeBodyLinksOpenInExternalBrowserForZoomApp = async (element: HTMLElement): Promise<void> => {
  const zoomSdk = (await import('@zoom/appssdk')).default
  element.querySelectorAll('a').forEach((a: HTMLAnchorElement) => {
    a.addEventListener('click', (e: MouseEvent) => {
      const href = a.href
      if (href == null) return
      e.preventDefault()
      void zoomSdk.openUrl({ url: href })
    })
  })
}

function mathifyPostBody(element: HTMLElement): void {
  const mathEls = element.querySelectorAll('var')
  if (mathEls.length > 0) {
    mathEls.forEach((el) => {
      if (el.firstElementChild == null || el.firstElementChild.className !== 'katex') {
        void mathifyElement(el)
      }
    })
  }
}

const MATCH_ANY_WORD_REGEX = /\w+/g
function getNumberOfWords(text?: string | null): number {
  if (text == null || text.length === 0) return 0
  const matches = text.match(MATCH_ANY_WORD_REGEX)
  return matches != null ? matches.length : 0
}

export {
  getNumberOfWords,
  isMentionLink,
  isSlideshowUrl,
  makeBodyLinksOpenInExternalBrowserForZoomApp,
  makeBodyPadletLinksInternalForNativeBridge,
  mathifyPostBody,
  setExternalLinksForNativeBridge,
  setRedirectUrlsForExternalLinks,
  transformToSafeRedirectUrl,
}
