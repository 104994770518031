import * as imgix from './imgix'
import * as inhouseService from './inhouse_service'
import { RequestOptions, TransformOptions } from './types'
import { getTransformOptions } from './options_parser'
import { buildProxyUrl } from './url_builder'
import { cleanUrlForInhouse } from './url_parser'

const processedUrl = (url: string, options: RequestOptions = {}): string => {
  if (!url) {
    console.info(`[vivaldi-client] url is blank`)
  }

  // if url is blank or url is relative, just return it. There isn't much we can do.
  if (!url || url.startsWith('/')) return url

  // Setting options
  const transformOptions: TransformOptions = getTransformOptions(options)

  // We have to use unsplash's own image api per policy.
  if (imgix.canServe(url, options)) {
    return imgix.processedUrl(url, transformOptions)
  } else {
    const cleanUrl = cleanUrlForInhouse(url)
    return inhouseService.processedUrl(cleanUrl, transformOptions)
  }
}

const proxiedUrl = buildProxyUrl

export { processedUrl, proxiedUrl }
