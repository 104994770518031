// @file fetch reactions
// TODO: should import this from main file but it's not exported there and we import it like in other places too so just to this for now
import { FetchOptionQueryHash } from '@padlet/fetch/src/types'
import { jsonApiFetch, JsonAPIResponse } from './json_api_fetch'
import { Reaction, ReactionsFetchQuery } from './types'

/**
 * @param reactionQuery [ReactionsFetchQuery] available queries:
 *                    - wallId [number] id of the wall
 */
export default function fetchReactions(reactionQuery: ReactionsFetchQuery): Promise<JsonAPIResponse<Reaction>> {
  const query: FetchOptionQueryHash = { wall_id: reactionQuery.wallId.toString() }
  if (reactionQuery.pageStart) {
    query.page_start = reactionQuery.pageStart
  }
  return jsonApiFetch<Reaction>('reactions', { query })
}
