// @file Dashboard store
import { trackPageview } from '@@/bits/analytics'
import { isActiveFilter } from '@@/bits/collections_helper'
import {
  dashViewUrl,
  getDashViewFromUrlParams,
  getDashViewUrlFromStorage,
  saveDashViewUrlIntoStorage,
} from '@@/bits/dash_helper'
import device from '@@/bits/device'
import { captureFetchException, captureNonNetworkFetchError, setScope } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import {
  buildUrlFromPath,
  currentHostWithProtocol,
  getAndClearSearchParam,
  getSearchParam,
  navigateTo,
  setSearchParam,
  transformCurrentUrl,
  transformUrl,
} from '@@/bits/location'
import { buildUserIdCacheKey, fetchCachedQuery } from '@@/bits/query_client'
import { isValidWallUrlFormat } from '@@/bits/url'
import { GoogleAppLicensingSettings as GoogleAppLicenseApi } from '@@/dashboard/padlet_api'
import { FetchJsonStatus, LibraryType, SnackbarNotificationType } from '@@/enums'
import type { PopoverAnchor } from '@@/library/v4/components/OzPopoverModal.vue'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import { useDashCollectionsStore } from '@@/pinia/dash_collections_store'
import { useDashGalleryStore } from '@@/pinia/dash_gallery_store'
import { useDashLayoutPickerStore } from '@@/pinia/dash_layout_picker_store'
import { useDashLearnStore } from '@@/pinia/dash_learn_store'
import { useDashMakeStore } from '@@/pinia/dash_make_store'
import { useDashNavigationStore } from '@@/pinia/dash_navigation_store'
import { useDashWallBulkActionsStore } from '@@/pinia/dash_wall_bulk_actions_store'
import { useElectronAppStore } from '@@/pinia/electron_app_store'
import { useExperimentsStore } from '@@/pinia/experiments'
import { useGlobalInputDialogStore } from '@@/pinia/global_input_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { LibraryUpgradeStep, useLibraryPlansStore } from '@@/pinia/library_plans'
import { useMagicPadletPanelStore } from '@@/pinia/magic_padlet_panel_store'
import { UpgradeSource, usePersonalPlansStore } from '@@/pinia/personal_plans_store'
import { useUserAccountsStore } from '@@/pinia/user_accounts_store'
import type {
  Folder,
  GoogleAppLicensingValidateResponse,
  Library,
  LibraryId,
  TenantToLibraryMigrationState,
  User,
  UserOptions,
  WallTemplate,
  WallViz,
} from '@@/types'
import type { DashView } from '@@/types/dash'
import { MobilePage } from '@@/types/dash'
import type { JsonAPIResource } from '@padlet/arvo'
import { fetchJson } from '@padlet/fetch'
import { isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

interface DashboardStartingState {
  user: User
  userOptions: UserOptions
  brahmsToken: string
  currentUserWallTemplates: WallTemplate[]
  libraryToWallTemplates: Record<LibraryId, WallTemplate[]>
  migrationBannerInfo?: TenantToLibraryMigrationState
}

export const useDashStore = defineStore('dashStore', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const dashLayoutPickerStore = useDashLayoutPickerStore()
  const dashMakeStore = useDashMakeStore()
  const personalPlansStore = usePersonalPlansStore()
  const libraryPlansStore = useLibraryPlansStore()
  const dashCollectionsStore = useDashCollectionsStore()
  const globalInputDialogStore = useGlobalInputDialogStore()
  const dashAccountsStore = useDashAccountsStore()
  const magicPadletPanelStore = useMagicPadletPanelStore()
  const electronAppStore = useElectronAppStore()
  const dashNavigationStore = useDashNavigationStore()
  const userAccountsStore = useUserAccountsStore()
  const dashGalleryStore = useDashGalleryStore()
  const dashLearnStore = useDashLearnStore()
  const dashWallBulkActionsStore = useDashWallBulkActionsStore()
  const experimentsStore = useExperimentsStore()

  // State

  const isInitialized = ref<boolean>(false)
  const currentMobilePage = ref<MobilePage>(MobilePage.AccountsMenu)
  const xWallsSearchModal = ref<boolean>(false)
  const xGettingStarted = ref<boolean>(false)
  const accountMoreAnchor = ref<PopoverAnchor | null>(null)
  const tenantToLibraryMigrationState = ref<Record<string, any>>()

  // Getters

  const xMigrationBanner = computed(
    (): boolean => tenantToLibraryMigrationState.value != null && !dashAccountsStore.isNativeAccount,
  )
  const migrationProgress = computed((): number => tenantToLibraryMigrationState.value?.completionWeight ?? 0)
  const hasMigrationCompleted = computed((): boolean => migrationProgress.value >= 100)
  const migrationLibraryDashboardUrl = computed(
    (): string => tenantToLibraryMigrationState.value?.libraryDashboardUrl ?? '',
  )

  const xGettingStartedRow = computed((): boolean => {
    if (
      dashCollectionsStore.currentAccountKey == null ||
      !dashAccountsStore.isCurrentAccountLibrary ||
      !dashAccountsStore.canCurrentUserOnboardOntoLibrary
    )
      return false

    return dashAccountsStore.isOnboardingForLibrary(dashCollectionsStore.currentAccountKey.id)
  })
  const isCurrentUserPersonalAccountOverQuota = computed((): boolean => {
    if (!dashAccountsStore.isNativeAccount) return false
    if (dashAccountsStore.isCurrentAccountLibrary) return false
    if (dashAccountsStore.currentUser.quota.can_make) return false
    // We only return true if the walls_used > walls_limit since can_make returns false if walls_used === walls_limit
    return dashAccountsStore.currentUser.quota.walls_used > dashAccountsStore.currentUser.quota.walls_limit
  })
  const xOverWallsQuotaWarning = computed((): boolean => {
    if (!isInitialized.value) return false
    // We show the over wall quota warning only for the user's personal collection
    if (!isAppUsing('padletHome') && dashCollectionsStore.isActiveCollectionShared) return false
    if (!isAppUsing('padletHome') && dashCollectionsStore.isActiveCollectionBookmark) return false
    if (!isAppUsing('padletHome') && dashCollectionsStore.isActiveCollectionCrossLibrary) return false
    return isCurrentUserPersonalAccountOverQuota.value
  })
  const xDesktopQuotaMeter = computed((): boolean => {
    if (!isAppUsing('dashboardUpgradeCta')) return false
    if (dashCollectionsStore.isActiveCollectionShared) return false
    if (dashCollectionsStore.isActiveCollectionBookmark) return false
    if (dashCollectionsStore.isActiveCollectionCrossLibrary) return false
    return true
  })
  const xMobileQuotaMeter = computed((): boolean => {
    if (!isAppUsing('dashboardUpgradeCta')) return false
    if (dashCollectionsStore.isActiveCollectionShared) return false
    if (dashCollectionsStore.isActiveCollectionBookmark) return false
    if (dashCollectionsStore.isActiveCollectionCrossLibrary) return false
    if (
      isActiveFilter(dashCollectionsStore.activeCollectionKey, 'trashed') &&
      currentMobilePage.value === MobilePage.Collection
    )
      return false
    if (
      isActiveFilter(dashCollectionsStore.activeCollectionKey, 'archived') &&
      currentMobilePage.value === MobilePage.Collection
    )
      return false
    return true
  })
  const xLibraryQuotaMeter = computed<boolean>(() => {
    return dashAccountsStore.isCurrentAccountLibrary && dashAccountsStore.isCurrentUserLibraryOwner
  })

  const xPersonalQuotaMeter = computed<boolean>(() => {
    return dashAccountsStore.isNativeAccount && dashAccountsStore.currentAccountKey?.type === 'user'
  })
  const xAccountMore = computed((): boolean => accountMoreAnchor.value != null)
  const defaultDashboardUrl = computed((): string => {
    if (!dashAccountsStore.isNativeAccount) return '/dashboard?mobile_page=AccountsMenu'
    // We return the user's personal dashboard url
    if (dashAccountsStore.defaultLibrary === undefined) return '/dashboard?mobile_page=AccountsMenu'
    const defaultLibraryUrl = transformUrl(dashAccountsStore.defaultLibrary.dashboardUrl, {
      searchParams: {
        mobile_page: MobilePage.Collection,
        filter: 'all',
      },
    })

    // We need to remove the currentHost from the defaultLibraryUrl since
    // getDashViewFromUrlParams handles pathname and not the entire url
    return defaultLibraryUrl.replace(currentHostWithProtocol(), '')
  })

  const isGalleryPage = computed(() => {
    return dashCollectionsStore.activeCollectionKey?.indexKey === 'gallery'
  })

  /**
   * ==================== INITIALIZE STORES ====================
   */

  function initializePreSelectedWallIdsFromParamsForPermanentlyDelete(): void {
    const filter = getSearchParam('filter')
    const wallIds = getAndClearSearchParam('wallIds')
    if (wallIds != null && filter === 'trashed') {
      const wallIdsArray = wallIds.split(',').map((id) => parseInt(id, 10))
      dashWallBulkActionsStore.preselectedWallIds = wallIdsArray
    }
  }

  async function initialize(): Promise<void> {
    dashCollectionsStore.setActiveUserId(dashAccountsStore.currentUser.id)
    setScope({ userId: dashAccountsStore.currentUser.id })

    // Early switch tab to show the correct page
    if (isAppUsing('padletHome')) {
      dashNavigationStore.switchTabAndViewFromUrlParams()
    }

    // fetchFolders and initialize before switchViewToUrlParams
    const dashboardInitializationPromises = [
      fetchDashStartingState(),
      dashAccountsStore.initialize(),
      dashCollectionsStore.fetchFolders(),
    ]
    if (dashAccountsStore.isBackpack) dashboardInitializationPromises.push(dashCollectionsStore.fetchUserGroupFolders())
    if (dashAccountsStore.isCurrentLibrarySchool)
      dashboardInitializationPromises.push(dashCollectionsStore.fetchCurrentLibraryUserGroupFolders())

    await Promise.all(dashboardInitializationPromises)

    // Init gallery store
    void dashGalleryStore.initialize()
    void dashLearnStore.fetchLearnVideosIfNecessary()

    initializePreSelectedWallIdsFromParamsForPermanentlyDelete()

    if (dashAccountsStore.currentUser.isEmailRecentlyLoggedInAndVerified as boolean) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Email verified'),
      })
    }

    if (window.ww.vueStartingState.trashedWallFromSurface === true) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Moved to trash'),
        timeout: 5000,
      })
    }

    if (window.ww.vueStartingState.showUpgradeModal === true) {
      personalPlansStore.userTriggeredUpgrade({
        user: dashAccountsStore.currentUser,
        upgradeSource: UpgradeSource.WallQuota,
      })
    }

    // Display snackbar if displaySnackbarMessage is not null and not undefined
    if (window.ww.vueStartingState.displaySnackbarMessage != null) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: window.ww.vueStartingState.displaySnackbarMessage as string,
        timeout: 5000,
      })
    }

    // Display error snackbar if displayErrorSnackbarMessage is not null and not undefined
    if (window.ww.vueStartingState.displayErrorSnackbarMessage != null) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.error,
        message: window.ww.vueStartingState.displayErrorSnackbarMessage as string,
        timeout: 5000,
      })
    }

    void dashCollectionsStore.fetchSharedCollections()

    dashNavigationStore.switchToInitialTabAndView()

    void dashCollectionsStore.fetchActiveCollectionWallsNow()

    // Initialize the electron app store
    electronAppStore.setCurrentUser(dashAccountsStore.currentUser)

    // Eagerly fetch data
    void personalPlansStore.initialize()
    void dashAccountsStore.fetchWallViewableLibraries()
    void dashAccountsStore.fetchWallCreatableLibraries()
    void dashAccountsStore.fetchUserLibraryMemberships()
    void dashCollectionsStore.fetchAllSchoolUserGroupFolders()
    if (isAppUsing('padletHome')) {
      void dashLearnStore.fetchLearnVideosIfNecessary()
    }
    if (isAppUsing('googleAppLicensing')) {
      void validateGoogleAppLicense()
    }

    experimentsStore.setUpgradeModalCopyAExperimentVariant(
      window.ww.vueStartingState.upgradeModalCopyAExperimentVariant,
    )
    experimentsStore.setUpgradeModalSingleStepExperimentVariant(
      window.ww.vueStartingState.upgradeModalSingleStepExperimentVariant,
    )

    // Reveal dashboard items
    isInitialized.value = true
  }

  const fetchDashStartingStateStatus = ref(FetchJsonStatus.Fetching)
  const isFetchingHomePageData = computed(() => {
    // TODO: remove this check after releasing dashboard caching publicly
    if (isInitialized.value) return false
    return (
      fetchDashStartingStateStatus.value === FetchJsonStatus.Fetching ||
      userAccountsStore.isFetchingAccounts ||
      dashCollectionsStore.isFetchingFolders
    )
  })

  async function fetchDashStartingState(): Promise<void> {
    try {
      await fetchCachedQuery<DashboardStartingState>({
        cacheKey: ['fetchDashStartingState', buildUserIdCacheKey(dashAccountsStore.currentUser.id)],
        queryFn: async () => await fetchJson('/api/1/dashboard/starting-state'),
        onFetchSuccess: (response) => {
          const { userOptions, brahmsToken, currentUserWallTemplates, libraryToWallTemplates, migrationBannerInfo } =
            response
          dashAccountsStore.updateUserOptions({ userOptions })
          electronAppStore.setBrahmsToken(brahmsToken)
          dashLayoutPickerStore.initializeState({
            currentUserWallTemplates,
            libraryToWallTemplates,
          })
          if (isAppUsing('padletHome')) {
            void dashMakeStore.initialize({
              currentUserWallTemplates,
              libraryToWallTemplates,
            })
          }
          if (migrationBannerInfo != null) tenantToLibraryMigrationState.value = migrationBannerInfo
          fetchDashStartingStateStatus.value = FetchJsonStatus.Completed
        },
      })
    } catch (e) {
      captureFetchException(e, { source: 'DashFetchDashStartingState' })
      fetchDashStartingStateStatus.value = FetchJsonStatus.Error
    }
  }

  async function initializeOld(): Promise<void> {
    const dashboardStartingState = await fetchJson('/api/1/dashboard/starting-state')

    const { userOptions, brahmsToken, currentUserWallTemplates, libraryToWallTemplates } =
      dashboardStartingState as DashboardStartingState

    setScope({ userId: dashAccountsStore.currentUser.id })

    initializePreSelectedWallIdsFromParamsForPermanentlyDelete()

    if (dashAccountsStore.currentUser.isEmailRecentlyLoggedInAndVerified as boolean) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Email verified'),
      })
    }

    if (window.ww.vueStartingState.trashedWallFromSurface === true) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Moved to trash'),
        timeout: 5000,
      })
    }

    dashAccountsStore.updateUserOptions({ userOptions })

    if (window.ww.vueStartingState.showUpgradeModal === true) {
      personalPlansStore.userTriggeredUpgrade({
        user: dashAccountsStore.currentUser,
        upgradeSource: UpgradeSource.WallQuota,
      })
    }

    // Display snackbar if displayErrorSnackbarMessage is not null and not undefined
    if (window.ww.vueStartingState.displayErrorSnackbarMessage != null) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.error,
        message: window.ww.vueStartingState.displayErrorSnackbarMessage as string,
        timeout: 5000,
      })
    }

    // initialize before switchViewToUrlParams
    await dashAccountsStore.initialize()

    dashCollectionsStore.setActiveUserId(dashAccountsStore.currentUser.id)
    dashCollectionsStore.switchAccountKey({ type: 'user', id: dashAccountsStore.currentUser.id })
    void dashCollectionsStore.fetchSharedCollections()
    // fetchFolders before switchViewToUrlParams
    await dashCollectionsStore.fetchFolders()
    if (dashAccountsStore.isBackpack) await dashCollectionsStore.fetchUserGroupFolders()
    if (dashAccountsStore.isCurrentLibrarySchool) await dashCollectionsStore.fetchCurrentLibraryUserGroupFolders()

    if (isAppUsing('padletHome')) dashNavigationStore.switchToInitialTabAndView()
    void dashCollectionsStore.fetchActiveCollectionWallsNow()

    // Initialize layout picker store after the `collections` and `accounts` stores have been initialized
    await dashLayoutPickerStore.initializeState({
      currentUserWallTemplates,
      libraryToWallTemplates,
    })
    if (isAppUsing('padletHome')) {
      void dashMakeStore.initialize({
        currentUserWallTemplates,
        libraryToWallTemplates,
      })
    }

    // Initialize the electron app store
    electronAppStore.setCurrentUser(dashAccountsStore.currentUser)
    electronAppStore.setBrahmsToken(brahmsToken)

    if (!isAppUsing('padletHome')) switchViewToUrlParams()

    // Eagerly fetch data
    void personalPlansStore.initialize()
    void dashAccountsStore.fetchWallViewableLibraries()
    void dashAccountsStore.fetchWallCreatableLibraries()
    void dashAccountsStore.fetchUserLibraryMemberships()
    void dashCollectionsStore.fetchAllSchoolUserGroupFolders()
    if (isAppUsing('padletHome')) {
      void dashLearnStore.fetchLearnVideosIfNecessary()
    }
    if (isAppUsing('googleAppLicensing')) {
      void validateGoogleAppLicense()
    }

    experimentsStore.setUpgradeModalCopyAExperimentVariant(
      window.ww.vueStartingState.upgradeModalCopyAExperimentVariant,
    )
    experimentsStore.setUpgradeModalSingleStepExperimentVariant(
      window.ww.vueStartingState.upgradeModalSingleStepExperimentVariant,
    )

    // Reveal dashboard items
    isInitialized.value = true
  }

  async function validateGoogleAppLicense(): Promise<void> {
    try {
      const validationResponse = await GoogleAppLicenseApi.checkLicense()
      const licenseStatus = (validationResponse?.data as JsonAPIResource<GoogleAppLicensingValidateResponse>).attributes
      if (!licenseStatus.invalidLicense) return
      navigateTo(licenseStatus.nextUrl as string, { method: 'post' })
    } catch (error) {
      globalSnackbarStore.genericFetchError()
      captureNonNetworkFetchError(error)
    }
  }

  /**
   * ==================== NAVIGATION ====================
   */

  function updatePageTitleAndFocusOnH1(): void {
    const prevPageTitle = window.document.title
    const focusOnH1Interval = setInterval(() => {
      const h1Element = document.querySelector('h1')
      if (h1Element?.textContent != null && prevPageTitle !== h1Element.textContent.trim()) {
        window.document.title = h1Element.textContent.trim()
        h1Element.focus()
        clearInterval(focusOnH1Interval)
      }
    }, 100)
  }
  function switchView(dashView: DashView): void {
    // We save the tab the user is currently in before switching view
    saveDashViewUrlIntoStorage()

    // Special cases
    const page = dashView.page
    const gallery = dashView.gallery
    // filter is for convenience
    const filter = dashView.filter
    const collectionKey =
      dashView.collectionKey ?? (filter !== undefined ? { typeKey: 'filter', indexKey: filter } : null)
    const accountKey = dashView.accountKey
    const mobilePage = dashView.mobilePage
    const sharedCollectionAccountKey = dashView.sharedCollectionAccountKey
    // Track dashboard page views
    const filterInSentenceCase = (filter: string): string =>
      (filter.charAt(0).toUpperCase() + filter.slice(1)).replace(/_/g, ' ')
    if (filter !== undefined) {
      trackPageview('Dashboard', filterInSentenceCase(filter))
    } else if (gallery === 'all') {
      trackPageview('Dashboard', 'Gallery')
    }

    // SPECIAL CASE: getting started
    if (
      page === MobilePage.GettingStarted ||
      // Auto-detect
      (accountKey?.type === 'library' &&
        filter === undefined &&
        collectionKey == null &&
        mobilePage === undefined &&
        dashAccountsStore.isOnboardingForLibrary(accountKey?.id))
    ) {
      if (accountKey != null) {
        dashCollectionsStore.switchAccountKey(accountKey)
      }

      xGettingStarted.value = true
      currentMobilePage.value = MobilePage.GettingStarted
      if (xGettingStartedRow.value && !isAppUsing('padletHome')) {
        // only need to append param, dont need to add to history so use replaceState not pushState in setSearchParam
        setSearchParam('page', MobilePage.GettingStarted, true)
      }
      return
    } else {
      if (xGettingStarted.value) {
        xGettingStarted.value = false
        currentMobilePage.value = MobilePage.AccountsMenu
      }
    }

    // SPECIAL CASE: Layout picker
    if (page === 'layoutPicker') {
      // We switch accountKey here else the accountKey will default to personal library in the layout picker
      if (accountKey !== undefined) {
        dashCollectionsStore.switchAccountKey(accountKey)
      }
      dashLayoutPickerStore.openLayoutPicker()
      currentMobilePage.value = MobilePage.LayoutPicker
      return
    }

    // View the correct page on mobile
    if (mobilePage !== undefined) {
      currentMobilePage.value = mobilePage
    }

    // Set the account-collection pair on the collections store
    if (accountKey != null && collectionKey != null) {
      // If both are set, we assume that it's a valid account-collection pair.
      dashCollectionsStore.setAccountCollectionCursor({ accountKey, collectionKey })
    } else if (accountKey != null) {
      // Automatically switches to valid collectionKey where possible.
      dashCollectionsStore.switchAccountKey(accountKey)
    } else if (collectionKey != null) {
      // Automatically switches to valid accountKey where possible.
      dashCollectionsStore.switchCollectionKey(collectionKey)
    } else if (gallery !== undefined) {
      dashCollectionsStore.switchGalleryViz(gallery as WallViz)
    }

    // Special case shared collection
    if (
      (collectionKey?.indexKey === 'combined_shared_user' || collectionKey?.indexKey === 'combined_shared_library') &&
      !(sharedCollectionAccountKey == null)
    ) {
      dashCollectionsStore.viewSharedCollection({
        collectionKey,
        sharedCollectionAccountKey,
      })
      return
    }

    // "View" the collection
    if (accountKey != null || collectionKey != null || gallery !== undefined || mobilePage === MobilePage.Collection) {
      void dashCollectionsStore.fetchActiveCollectionWallsNow()
    }

    updatePageTitleAndFocusOnH1()
  }

  function switchViewToUrlParams(): void {
    let dashView: DashView = getDashViewFromUrlParams()
    const dashViewUrlFromStorage = getDashViewUrlFromStorage()

    const isLibraryHidden = (): boolean => {
      // exit early if there are no relevant library slug data in dashView
      if (dashView.librarySlug === undefined) return false

      const libraryId = dashAccountsStore.wallViewableAndVisibleLibrariesArray.find(
        (library: Library) => library.slug === dashView.librarySlug,
      )?.id
      if (libraryId !== undefined) {
        // Update the dashView with the current libraryId to display it on the dashboard
        dashView = { ...dashView, accountKey: { type: 'library', id: libraryId } }
        return false
      }

      return true
    }

    const isAccessingHiddenPersonalAccountView = (): boolean => {
      return dashView.librarySlug === undefined && dashAccountsStore.currentUser.visible === false
    }

    // Load last opened view URL if visiting the dashboard without params
    if (isEmpty(dashView) && dashViewUrlFromStorage !== null) {
      window.history.pushState(null, '', dashViewUrlFromStorage)
      dashView = getDashViewFromUrlParams()
      if (isLibraryHidden() || isAccessingHiddenPersonalAccountView()) {
        switchToRecentsOrDefaultLibraryView()
        return
      }
    }

    if (isEmpty(dashView)) {
      switchToRecentsOrDefaultLibraryView()
      return
    }

    // We check if the mobile_page has been altered by the user
    const isMobilePageInvalid = (): boolean => {
      if (dashView.mobilePage === undefined) return false
      return MobilePage[dashView.mobilePage as string] === undefined
    }

    // Assumes that accounts and onboarding data has been fetched prior
    const isLibrarySlugInvalid = (): boolean => {
      // exit early if there are no relevant library slug data in dashView
      if (dashView.librarySlug === undefined) return false

      const libraryId = dashAccountsStore.librariesArray.find(
        (library: Library) => library.slug === dashView.librarySlug,
      )?.id
      if (libraryId !== undefined) {
        // Update the dashView with the current libraryId to display it on the dashboard
        dashView = { ...dashView, accountKey: { type: 'library', id: libraryId } }
        return false
      }

      return true
    }

    // Assumes that folder data has been fetched prior
    const isFolderIdInvalid = (): boolean => {
      // exit early if there are no relevant folder data in dashView
      const collectionKey = dashView.collectionKey
      if (collectionKey == null || collectionKey.typeKey !== 'folderId') return false
      if (isNaN(collectionKey.indexKey)) return true

      return !dashCollectionsStore.foldersArray.some((folder: Folder) => folder.id === collectionKey.indexKey)
    }

    const isNavigatedFromDashboardSettings = (): boolean => {
      const dashboardSettingsUrl = buildUrlFromPath('dashboard/settings')
      return document.referrer.startsWith(dashboardSettingsUrl)
    }

    // If no valid mobile page exists or library slug exists for the slug or if folder id is invalid
    // we redirect the user to either recents or user > all
    if (
      isMobilePageInvalid() ||
      isLibrarySlugInvalid() ||
      isFolderIdInvalid() ||
      // We still allow people to visit a hidden library dashboard via URL or redirected from other pages
      // There is a case when the user is on a library dashboard -> go to /dashboard/settings/dashboard -> hide the library -> go back to dashboard
      // The app should display Recents or the default library view instead of the hidden library view
      (isLibraryHidden() && isNavigatedFromDashboardSettings())
    ) {
      switchToRecentsOrDefaultLibraryView()
      return
    }

    const isMobilePageNavATabView = (): boolean => {
      const mobilePageNavTabs = [
        MobilePage.AccountsMenu,
        MobilePage.Search,
        MobilePage.LayoutPicker,
        MobilePage.Join,
        MobilePage.More,
      ]
      return dashView.mobilePage !== undefined && mobilePageNavTabs.includes(dashView.mobilePage)
    }

    // Ensure that the layout picker will display the default library on the first render on mobile view
    // Context: When you first visit a tab on the mobile nav bar, then open the layout picker, the default library should be selected. See the details in the method setInitialLibrarySelected in app/javascript/pinia/dash_layout_picker_store.ts
    if (isMobilePageNavATabView() && dashAccountsStore.defaultLibrary !== undefined) {
      dashView = { ...dashView, accountKey: { type: 'library', id: dashAccountsStore.defaultLibrary.id } }
    }

    switchView(dashView)

    // Switch to "recents" or "default library" if current account cannot see "archived".
    // Note that this needs to be after switchView due to before switchView call we set the accountKey to be `user` instead of `library`.
    if (!dashAccountsStore.canCurrentAccountArchiveWall && dashView.filter === 'archived') {
      switchToRecentsOrDefaultLibraryView()
    }
  }

  function switchToRecentsOrDefaultLibraryView(): void {
    if (dashAccountsStore.user.hasRecentPadlets === true) {
      window?.history?.pushState(
        null,
        '',
        isAppUsing('padletHome')
          ? dashViewUrl({ mobilePage: MobilePage.Collection, filter: 'combined_recents' }, { path: '/dashboard' })
          : dashViewUrl({ mobilePage: MobilePage.Collection }, { path: '/dashboard/recents' }),
      )
      const dashView = getDashViewFromUrlParams()
      switchView(dashView)
    } else {
      window?.history?.pushState(null, '', defaultDashboardUrl.value)
      let dashView = getDashViewFromUrlParams()

      if (dashAccountsStore.isNativeAccount && dashAccountsStore.defaultLibrary !== undefined) {
        // We need to update the accountKey since it is the native user accountKey by default
        dashView = { ...dashView, accountKey: { type: 'library', id: dashAccountsStore.defaultLibrary.id } }
      }
      switchView(dashView)
    }
  }

  /**
   * ==================== JOIN PADLET ====================
   */

  function promptURLForJoinPadlet(): void {
    globalInputDialogStore.openInputDialog({
      title: __('Enter URL'),
      shouldFadeIn: true,
      inputPlaceholder: 'https://padlet.com/gallery/burgers',
      inputAriaLabel: __('Enter the padlet URL'),
      submitActions: [joinPadletURLAction],
      validationActions: [checkJoinPadletUrlValidity],
    })
  }

  function joinPadletURLAction({ inputValue }: { inputValue: string }): void {
    navigateTo(inputValue, { target: '_blank' })
  }

  function checkJoinPadletUrlValidity({ inputValue }: { inputValue: string }): void {
    globalInputDialogStore.setSubmitButtonDisable(!isValidWallUrlFormat(inputValue))
  }

  async function createWallAndRefetchData(payload: { layout: string; groupBySection?: boolean }): Promise<void> {
    await dashLayoutPickerStore.createWall({ viz: payload.layout, groupBySection: payload.groupBySection })

    if (device.electronApp) {
      void dashCollectionsStore.fetchActiveCollectionWallsNow()
      dashAccountsStore.refreshWallQuotas()
      void personalPlansStore.initialize()
    }
  }

  /**
   * ==================== LAYOUT PICKER ====================
   */

  function closeLayoutPicker(): void {
    dashLayoutPickerStore.closeLayoutPicker()
    currentMobilePage.value = MobilePage.CollectionsMenu
  }

  function closeLayoutPickerAndUpgrade(payload: { user: User | null; librarySelected: Library | null }): void {
    closeLayoutPicker()
    handleUpgrade(payload)
  }

  function handleUpgrade({ user, librarySelected }: { user: User | null; librarySelected: Library | null }): void {
    // Native user
    if (librarySelected == null && user != null) {
      void personalPlansStore.quotaTriggeredUpgrade({ user })
      return
    }

    // Library user
    if (librarySelected != null && librarySelected.libraryType === LibraryType.Classroom) {
      // Classroom libraries only have an annual plan, so when classroom owners upgrade to Gold on the dashboard (the account menu and the layout picker)
      // the app should direct them to the checkout page.
      if (!isAppUsing('stripeCheckout')) {
        void libraryPlansStore.fetchPlansAndNavigateToCheckoutUrl({
          libraryId: librarySelected.id,
          libraryType: LibraryType.Classroom,
        })
      } else {
        const nextUrl = transformCurrentUrl(
          {},
          { path: '/memberships/confirmation', search: { next: librarySelected.dashboardUrl } },
        )
        void libraryPlansStore.fetchPlansAndNavigateToStripeCheckoutUrl({
          libraryId: librarySelected.id,
          nextUrl,
        })
      }
    } else if (librarySelected != null) {
      void libraryPlansStore.fetchPlansAndShowUpgradeModal({
        upgradeStep: LibraryUpgradeStep.ChooseTierPadletQuota,
        library: librarySelected,
      })
    }
  }

  function closeLayoutPickerAndOpenMagicPadletPanel(): void {
    closeLayoutPicker()
    magicPadletPanelStore.openMagicPadletPanel()
  }

  /**
   * ==================== SEARCH ====================
   */

  function closeWallsSearchModal(): void {
    xWallsSearchModal.value = false
    dashCollectionsStore.setActiveSearchQuery({ activeSearchQuery: '' })
  }

  function openWallsSearchModal(): void {
    xWallsSearchModal.value = true
  }

  /**
   * ==================== MORE MENU ====================
   */
  function hideAccountMore(): void {
    accountMoreAnchor.value = null
  }

  function showAccountMore(payload: { popoverAnchor: PopoverAnchor }): void {
    updateAccountMoreAnchor({ popoverAnchor: payload.popoverAnchor })
  }

  function updateAccountMoreAnchor(payload: { popoverAnchor: PopoverAnchor }): void {
    accountMoreAnchor.value = payload.popoverAnchor
  }

  return {
    // State

    // Getters
    xMigrationBanner,
    migrationProgress,
    hasMigrationCompleted,
    migrationLibraryDashboardUrl,
    xGettingStarted,
    xGettingStartedRow,
    currentMobilePage,
    isCurrentUserPersonalAccountOverQuota,
    xOverWallsQuotaWarning,
    xDesktopQuotaMeter,
    xMobileQuotaMeter,
    xLibraryQuotaMeter,
    xPersonalQuotaMeter,
    isInitialized,
    xAccountMore,
    accountMoreAnchor,
    xWallsSearchModal,
    isGalleryPage,
    isFetchingHomePageData,

    /**
     * ==================== INITIALIZE STORES ====================
     */

    initialize,
    initializeOld,
    validateGoogleAppLicense,

    /**
     * ==================== NAVIGATION ====================
     */

    switchView,
    switchViewToUrlParams,
    switchToRecentsOrDefaultLibraryView,

    /**
     * ==================== JOIN PADLET ====================
     */

    promptURLForJoinPadlet,
    createWallAndRefetchData,

    /**
     * ==================== LAYOUT PICKER ====================
     */
    closeLayoutPicker,
    closeLayoutPickerAndUpgrade,
    handleUpgrade,
    closeLayoutPickerAndOpenMagicPadletPanel,

    /**
     * ==================== SEARCH ====================
     */

    closeWallsSearchModal,
    openWallsSearchModal,

    /**
     * ==================== MORE MENU ====================
     */

    hideAccountMore,
    showAccountMore,
    updateAccountMoreAnchor,
  }
})
