var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.OzBaseButton,{class:[
    /**
     * SIZE/SHAPE PRESETS
     */

    // Common styles
    _vm.sizePreset && [
      // Remove the default focus ring since we're rounding the corners
      'focus:outline-none',

      // Centralize button content
      'flex',
      'items-center',
      'justify-center',

      // Text-alignment
      _vm.textAlign === 'start' && 'text-start',
      _vm.textAlign === 'center' && 'text-center',
      _vm.textAlign === 'end' && 'text-end',
    ],

    // Vertical padding presets
    _vm.sizePreset && {
      // Reset user agent styles
      // Don't set px-0 here so we won't have specificity issues
      'py-0': _vm.sizePreset !== _setup.OzContainedButtonSizePreset.H80px,
      'py-4': _vm.sizePreset === _setup.OzContainedButtonSizePreset.H80px,
    },

    // Presets
    _vm.sizePreset === _setup.OzContainedButtonSizePreset.H80px && [
      _vm.autoHeight ? 'min-h-20' : 'h-20',
      'box-border',
      'px-7',
      'rounded-3xl',
      'text-heading-4',
      'font-semibold',
      'focus-visible:ring',

      'ring-offset-1',
      // no icon design provided at the time of creating this OzContainedButtonSizePreset. to be added when there is a use case
    ],
    _vm.sizePreset === _setup.OzContainedButtonSizePreset.H64px && [
      _vm.autoHeight ? 'min-h-16' : 'h-16',
      'rounded-[26px]',
      'text-heading-2-large',
      'font-semibold',
      'focus-visible:ring-[3px]',

      // No ring offset when only icon. Offset is present otherwise.
      !_vm.$slots.icon && [
        // Needs to be on a new line for the JIT to work -_-
        'ring-offset-[3px]',
      ],

      // Expect at most one of the icon slots to be filled.
      _vm.$slots.icon && ['w-16'],
      _vm.$slots.leftIcon && !_vm.centerText && ['pe-5', 'ps-4', 'gap-2'],
      _vm.$slots.leftIcon && _vm.centerText && ['pe-11', 'ps-4', 'gap-2'], // pe-11 = ps-4 + 20px + gap-2
      _vm.$slots.rightIcon && !_vm.centerText && ['pe-2', 'ps-5', 'gap-1'],
      _vm.$slots.rightIcon && _vm.centerText && ['pe-2', 'ps-8', 'gap-1'], // ps-8 = pe-2 + 20px + gap-1
      !_vm.$slots.icon && !_vm.$slots.leftIcon && !_vm.$slots.rightIcon && ['px-8'],
    ],
    _vm.sizePreset === _setup.OzContainedButtonSizePreset.H56px && [
      _vm.autoHeight ? 'min-h-14' : 'h-14',
      'rounded-[22px]',
      'text-heading-2',
      'font-semibold',
      'focus-visible:ring-[3px]',

      // No ring offset when only icon. Offset is present otherwise.
      !_vm.$slots.icon && [
        // Needs to be on a new line for the JIT to work -_-
        'ring-offset-2',
      ],

      // Expect at most one of the icon slots to be filled.
      _vm.$slots.icon && ['w-14'],
      _vm.$slots.leftIcon && !_vm.centerText && ['pe-5', 'ps-4', 'gap-2'],
      _vm.$slots.leftIcon && _vm.centerText && ['pe-11', 'ps-4', 'gap-2'], // pe-11 = ps-4 + 20px + gap-2
      _vm.$slots.rightIcon && !_vm.centerText && ['pe-2', 'ps-5', 'gap-1'],
      _vm.$slots.rightIcon && _vm.centerText && ['pe-2', 'ps-8', 'gap-1'], // ps-8 = pe-2 + 20px + gap-1
      !_vm.$slots.icon && !_vm.$slots.leftIcon && !_vm.$slots.rightIcon && ['px-7'],
    ],
    _vm.sizePreset === _setup.OzContainedButtonSizePreset.H40px && [
      _vm.autoHeight ? 'min-h-10' : 'h-10',
      'rounded-2xl',
      'text-heading-4',
      'font-semibold',
      'focus-visible:ring-[2.5px]',

      // No ring offset when only icon. Offset is present otherwise.
      !_vm.$slots.icon && [
        // Needs to be on a new line for the JIT to work -_-
        'ring-offset-[1.5px]',
      ],

      // Expect at most one of the icon slots to be filled.
      _vm.$slots.icon && ['w-10'],
      _vm.$slots.leftIcon && !_vm.centerText && ['pe-5', 'ps-4', 'gap-2'],
      _vm.$slots.leftIcon && _vm.centerText && ['pe-11', 'ps-4', 'gap-2'], // pe-11 = ps-4 + 20px + gap-2
      _vm.$slots.rightIcon && !_vm.centerText && ['pe-2', 'ps-5', 'gap-1'],
      _vm.$slots.rightIcon && _vm.centerText && ['pe-2', 'ps-8', 'gap-1'], // ps-8 = pe-2 + 20px + gap-1
      !_vm.$slots.icon && !_vm.$slots.leftIcon && !_vm.$slots.rightIcon && ['px-5'],
    ],
    _vm.sizePreset === _setup.OzContainedButtonSizePreset.H32px && [
      _vm.autoHeight ? 'min-h-8' : 'h-8',
      'rounded-xl',
      'text-body-small',
      'font-semibold',
      'ring-offset-1',
      'focus-visible:ring-2',
      // Only have one icon at a time.
      // And if there's text, there'll only be a left or right icon.
      _vm.$slots.icon && 'h-8 w-8',
      !_vm.$slots.icon && 'px-3',
    ],
    _vm.sizePreset === _setup.OzContainedButtonSizePreset.H24px && [
      _vm.autoHeight ? 'min-h-6' : 'h-6',
      'rounded-lg',
      'text-body-extra-small',
      'font-semibold',
      'ring-offset-1',
      'focus-visible:ring-2',
      _vm.$slots.icon && 'h-6 w-6',
      !_vm.$slots.icon && 'px-3',
    ],

    _vm.sizePreset === _setup.OzContainedButtonSizePreset.H32pxW56px && [
      _vm.autoHeight ? 'min-h-8 min-w-14' : 'h-8 w-14',
      'rounded-2xl',
      'text-heading-4',
      'font-semibold',
      'focus-visible:ring-[2.5px]',

      // No ring offset when only icon. Offset is present otherwise.
      !_vm.$slots.icon && [
        // Needs to be on a new line for the JIT to work -_-
        'ring-offset-[1.5px]',
      ],

      // Expect at most one of the icon slots to be filled.
      _vm.$slots.icon && ['w-14'],
      _vm.$slots.leftIcon && !_vm.centerText && ['pe-5', 'ps-4', 'gap-2'],
      _vm.$slots.leftIcon && _vm.centerText && ['pe-11', 'ps-4', 'gap-2'], // pe-11 = ps-4 + 20px + gap-2
      _vm.$slots.rightIcon && !_vm.centerText && ['pe-2', 'ps-5', 'gap-1'],
      _vm.$slots.rightIcon && _vm.centerText && ['pe-2', 'ps-8', 'gap-1'], // ps-8 = pe-2 + 20px + gap-1
      !_vm.$slots.icon && !_vm.$slots.leftIcon && !_vm.$slots.rightIcon && ['px-5'],
    ],
    _vm.sizePreset === _setup.OzContainedButtonSizePreset.H40W124px && [
      _vm.autoHeight ? 'min-h-10' : 'h-10',
      'rounded-2xl',
      'text-heading-4',
      'font-semibold',
      'focus-visible:ring-[2.5px]',
      'w-31',

      // No ring offset when only icon. Offset is present otherwise.
      !_vm.$slots.icon && [
        // Needs to be on a new line for the JIT to work -_-
        'ring-offset-[1.5px]',
      ],

      // Expect at most one of the icon slots to be filled.
      _vm.$slots.icon && ['w-10'],
      _vm.$slots.leftIcon && !_vm.centerText && ['pe-5', 'ps-4', 'gap-2'],
      _vm.$slots.leftIcon && _vm.centerText && ['pe-11', 'ps-4', 'gap-2'], // pe-11 = ps-4 + 20px + gap-2
      _vm.$slots.rightIcon && !_vm.centerText && ['pe-2', 'ps-5', 'gap-1'],
      _vm.$slots.rightIcon && _vm.centerText && ['pe-2', 'ps-8', 'gap-1'], // ps-8 = pe-2 + 20px + gap-1
      !_vm.$slots.icon && !_vm.$slots.leftIcon && !_vm.$slots.rightIcon && ['px-5'],
    ],

    /**
     * COLOR SCHEMES
     */

    // Common
    _vm.colorScheme && [
      // Focus ring
      {
        'ring-grape-500': _vm.darkMode === false,
        'ring-canary-500': _vm.darkMode === true,
        'ring-grape-500 dark:ring-canary-500': _vm.darkMode === 'auto',
      },
      _vm.ringOffsetColorClasses || {
        // Tailwind uses box shadows for focus rings.
        // We'll have to deviate from TW paradigm if we want truly transparent ring-offset color.
        'ring-offset-white': _vm.darkMode === false,
        'ring-offset-grey-900': _vm.darkMode === true,
        'dark:ring-offset-grey-900 ring-offset-white': _vm.darkMode === 'auto',
      },
      // Disabled - Base
      {
        'aria-disabled:bg-button-disabled-light': _vm.darkMode === false,
        'aria-disabled:bg-button-disabled-dark': _vm.darkMode === true,
        'aria-disabled:bg-button-disabled-light dark:aria-disabled:bg-button-disabled-dark': _vm.darkMode === 'auto',
      },
      {
        'aria-disabled:text-dark-text-400': _vm.darkMode === false,
        'aria-disabled:text-light-text-400': _vm.darkMode === true,
        'aria-disabled:text-dark-text-400 dark:aria-disabled:text-light-text-400': _vm.darkMode === 'auto',
      },
      // Disabled - Hover
      {
        'hover-hover:aria-disabled:bg-button-disabled-light': _vm.darkMode === false,
        'hover-hover:aria-disabled:bg-button-disabled-dark': _vm.darkMode === true,
        'hover-hover:aria-disabled:bg-button-disabled-light hover-hover:dark:aria-disabled:bg-button-disabled-dark':
          _vm.darkMode === 'auto',
      },
      {
        'hover-hover:aria-disabled:text-dark-text-400': _vm.darkMode === false,
        'hover-hover:aria-disabled:text-light-text-400': _vm.darkMode === true,
        'hover-hover:aria-disabled:text-dark-text-400 hover-hover:dark:aria-disabled:text-light-text-400 ':
          _vm.darkMode === 'auto',
      },
      // Active
      _vm.colorScheme !== _setup.OzContainedButtonColorScheme.SecondaryLightHover && [
        {
          'hover-hover:active:bg-grape-500': _vm.darkMode === false,
          'hover-hover:active:bg-canary-500': _vm.darkMode === true,
          'hover-hover:active:bg-grape-500 hover-hover:dark:active:bg-canary-500': _vm.darkMode === 'auto',
        },
        {
          'hover-hover:active:text-light-text-100': _vm.darkMode === false,
          'hover-hover:active:text-dark-text-100': _vm.darkMode === true,
          'hover-hover:active:text-light-text-100 hover-hover:dark:active:text-dark-text-100': _vm.darkMode === 'auto',
        },
      ],
      _vm.colorScheme === _setup.OzContainedButtonColorScheme.SecondaryLightHover && [
        {
          'hover-hover:active:bg-grey-200': _vm.darkMode === false,
          'hover-hover:active:bg-grey-600': _vm.darkMode === true,
          'hover-hover:active:bg-grey-200 hover-hover:dark:active:bg-grey-600': _vm.darkMode === 'auto',
        },
        {
          'hover-hover:active:text-dark-text-200': _vm.darkMode === false,
          'hover-hover:active:text-light-text-200': _vm.darkMode === true,
          'hover-hover:active:text-dark-text-200 hover-hover:dark:active:text-light-text-200': _vm.darkMode === 'auto',
        },
      ],
      _vm.colorScheme === _setup.OzContainedButtonColorScheme.Purple && [
        {
          'hover-hover:active:bg-grape-500': _vm.darkMode === false,
          'hover-hover:active:bg-canary-500': _vm.darkMode === true,
          'hover-hover:active:bg-grape-500 hover-hover:dark:active:bg-canary-500': _vm.darkMode === 'auto',
        },
        {
          'hover-hover:active:text-light-text-100': _vm.darkMode === false,
          'hover-hover:active:text-dark-text-100': _vm.darkMode === true,
          'hover-hover:active:text-light-text-100 hover-hover:dark:active:text-dark-text-100': _vm.darkMode === 'auto',
        },
      ],
    ],

    // Schemes
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.Primary && [
      // Base
      'bg-padlet-pink',
      'text-light-text-100',
      // Hovered
      'hover-hover:hover:bg-padlet-pink-600',
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.PrimaryPulsing && [
      // Base
      'bg-padlet-pink',
      'text-light-text-100',

      // Hovered
      'hover-hover:hover:animate-pulse-bg-color',
      'pulse-bg-from-padlet-pink-500',
      {
        'pulse-bg-to-grape-500': _vm.darkMode === false,
        'pulse-bg-to-canary-500': _vm.darkMode === true,
        'pulse-bg-to-grape-500 dark:pulse-bg-to-canary-500': _vm.darkMode === 'auto',
      },

      // Disabled - Hover
      'hover-hover:aria-disabled:hover:animate-none',
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.Secondary && [
      // Base
      {
        'bg-grey-100': _vm.darkMode === false,
        'bg-grey-700': _vm.darkMode === true,
        'bg-grey-100 dark:bg-grey-700': _vm.darkMode === 'auto',
      },
      {
        'text-dark-text-200': _vm.darkMode === false,
        'text-light-text-200': _vm.darkMode === true,
        'text-dark-text-200 dark:text-light-text-200': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:bg-grey-200': _vm.darkMode === false,
        'hover-hover:hover:bg-grey-600': _vm.darkMode === true,
        'hover-hover:hover:bg-grey-200 hover-hover:dark:hover:bg-grey-600': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.SecondaryActive && [
      // Base
      {
        'bg-grey-100': _vm.darkMode === false,
        'bg-grey-700': _vm.darkMode === true,
        'bg-grey-100 dark:bg-grey-700': _vm.darkMode === 'auto',
      },
      {
        'text-grape-500': _vm.darkMode === false,
        'text-canary-500': _vm.darkMode === true,
        'text-grape-500 dark:text-canary-500': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:bg-grey-200': _vm.darkMode === false,
        'hover-hover:hover:bg-grey-600': _vm.darkMode === true,
        'hover-hover:hover:bg-grey-200 hover-hover:dark:hover:bg-grey-600': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.SecondaryContrast && [
      // Base
      {
        'bg-grey-100': _vm.darkMode === false,
        'bg-grey-700': _vm.darkMode === true,
        'bg-grey-100 dark:bg-grey-700': _vm.darkMode === 'auto',
      },
      {
        'text-dark-text-100': _vm.darkMode === false,
        'text-light-text-100': _vm.darkMode === true,
        'text-dark-text-100 dark:text-light-text-100': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:bg-grey-200': _vm.darkMode === false,
        'hover-hover:hover:bg-grey-600': _vm.darkMode === true,
        'hover-hover:hover:bg-grey-200 hover-hover:dark:hover:bg-grey-600': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.SecondaryDestructive && [
      // Disabled - Base
      'aria-disabled:text-danger-50',
      // Disabled - Hover
      'hover-hover:aria-disabled:text-danger-50',
      // Base
      {
        'bg-grey-100': _vm.darkMode === false,
        'bg-grey-700': _vm.darkMode === true,
        'bg-grey-100 dark:bg-grey-700': _vm.darkMode === 'auto',
      },
      'text-danger-100',
      // Hovered
      {
        'hover-hover:hover:bg-grey-200': _vm.darkMode === false,
        'hover-hover:hover:bg-grey-600': _vm.darkMode === true,
        'hover-hover:hover:bg-grey-200 hover-hover:dark:hover:bg-grey-600': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.SecondaryClear && [
      // Base
      'bg-transparent',
      {
        'text-dark-text-200': _vm.darkMode === false,
        'text-light-text-200': _vm.darkMode === true,
        'text-dark-text-200 dark:text-light-text-200': _vm.darkMode === 'auto',
      },
      // Hovered
      'hover-hover:hover:bg-opacity-10',
      {
        'hover-hover:hover:bg-overlay-text-bg-light-mode-light-hover': _vm.darkMode === false || _vm.darkMode === 'auto',
        'hover-hover:hover:bg-overlay-text-bg-dark-mode-dark-hover': _vm.darkMode === true,
        'hover-hover:dark:hover:bg-overlay-text-bg-dark-mode-dark-hover': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.SecondaryClearDark && [
      // Disabled - Base
      'aria-disabled:bg-transparent',
      // Disabled - Hover
      'hover-hover:aria-disabled:bg-transparent',
      // Base
      'bg-transparent',
      {
        'text-dark-text-200': _vm.darkMode === false,
        'text-light-text-200': _vm.darkMode === true,
        'text-dark-text-200 dark:text-light-text-200': _vm.darkMode === 'auto',
      },
      // Hovered
      'hover-hover:hover:bg-opacity-100',
      {
        'hover-hover:hover:bg-light-text-400': _vm.darkMode === false || _vm.darkMode === 'auto',
        'hover-hover:hover:bg-dark-text-400': _vm.darkMode === true,
        'hover-hover:dark:hover:bg-dark-text-400': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.SecondaryClearText && [
      // Base
      'bg-transparent',
      {
        'text-dark-text-200': _vm.darkMode === false,
        'text-light-text-200': _vm.darkMode === true,
        'text-dark-text-200 dark:text-light-text-200': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:text-dark-text-100': _vm.darkMode === false,
        'hover-hover:hover:text-light-text-100': _vm.darkMode === true,
        'hover-hover:hover:text-dark-text-100 hover-hover:dark:hover:text-light-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.SecondaryClearActive && [
      // Base
      'bg-transparent',
      {
        'text-grape-500': _vm.darkMode === false,
        'text-canary-500': _vm.darkMode === true,
        'text-grape-500 dark:text-canary-500': _vm.darkMode === 'auto',
      },
      // Hovered
      'hover-hover:hover:bg-opacity-10',
      {
        'hover-hover:hover:bg-overlay-text-bg-light-mode-light-hover': _vm.darkMode === false || _vm.darkMode === 'auto',
        'hover-hover:hover:bg-overlay-text-bg-dark-mode-dark-hover': _vm.darkMode === true,
        'hover-hover:dark:hover:bg-overlay-text-bg-dark-mode-dark-hover': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.Alternative && [
      // Base
      {
        'bg-grey-900': _vm.darkMode === false,
        'bg-grey-0': _vm.darkMode === true,
        'bg-grey-900 dark:bg-grey-0': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === false,
        'text-dark-text-100': _vm.darkMode === true,
        'text-light-text-100 dark:text-dark-text-100': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:bg-grey-800': _vm.darkMode === false,
        'hover-hover:hover:bg-grey-100': _vm.darkMode === true,
        'hover-hover:hover:bg-grey-800 hover-hover:dark:hover:bg-grey-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.AlternativeSecondary && [
      // Base
      'bg-grey-0',
      'text-dark-text-100',
      // Hovered
      {
        'hhover:bg-grey-50': _vm.darkMode === false,
        'hhover:bg-grey-100': _vm.darkMode === true,
        'hhover:bg-grey-50 dark:hhover:bg-grey-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.AlternativeHeavy && [
      // Base
      {
        'bg-grey-900': _vm.darkMode === false,
        'bg-grey-0': _vm.darkMode === true,
        'bg-grey-900 dark:bg-grey-0': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === false,
        'text-dark-text-100': _vm.darkMode === true,
        'text-light-text-100 dark:text-dark-text-100': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:bg-grey-800': _vm.darkMode === false,
        'hover-hover:hover:bg-grey-100': _vm.darkMode === true,
        'hover-hover:hover:bg-grey-800 hover-hover:dark:hover:bg-grey-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.Transparent && [
      // Disabled - Base
      'aria-disabled:bg-transparent',
      // Disabled - Hover
      'hover-hover:aria-disabled:bg-transparent',
      // Base
      {
        'bg-transparent-button-light': _vm.darkMode === false,
        'bg-transparent-button-dark': _vm.darkMode === true,
        'bg-bg-transparent-button-light dark:bg-transparent-button-dark': _vm.darkMode === 'auto',
      },
      {
        'text-dark-text-200': _vm.darkMode === false,
        'text-light-text-200': _vm.darkMode === true,
        'text-dark-text-200 dark:text-light-text-200': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:bg-transparent-button-hover-light': _vm.darkMode === false,
        'hover-hover:hover:bg-transparent-button-hover-dark': _vm.darkMode === true,
        'hover-hover:hover:bg-transparent-button-hover-light hover-hover:dark:hover:bg-transparent-button-hover-dark':
          _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.Orange && [
      // Base
      'bg-tangerine-500',
      'text-light-text-100',
      // Hovered
      'hover-hover:hover:bg-tangerine-600',
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.Destructive && [
      // Base
      'bg-danger-100',
      'text-light-text-100',
      // Hovered
      'hover-hover:hover:bg-scarlet-600',
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.DestructiveClear && [
      // Disabled - Base
      'aria-disabled:bg-transparent',
      'aria-disabled:text-danger-50',
      // Disabled - Hover
      'hover-hover:aria-disabled:bg-transparent',
      'hover-hover:aria-disabled:text-danger-50',
      // Base
      'bg-transparent',
      'text-danger-100',
      // Hovered
      {
        'hover-hover:hover:bg-scarlet-100': _vm.darkMode === false,
        'hover-hover:hover:bg-scarlet-1000': _vm.darkMode === true,
        'hover-hover:hover:bg-scarlet-100 hover-hover:dark:hover:bg-scarlet-1000': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.Green && [
      // Base
      'bg-park-500',
      'text-light-text-100',
      // Hovered
      'hover-hover:hover:bg-park-600',
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.Grey && [
      // Base
      {
        'bg-grey-50': _vm.darkMode === false,
        'bg-grey-800': _vm.darkMode === true,
        'bg-grey-50 dark:bg-grey-800': _vm.darkMode === 'auto',
      },
      {
        'text-dark-text-200': _vm.darkMode === false,
        'text-light-text-200': _vm.darkMode === true,
        'text-dark-text-200 dark:text-light-text-200': _vm.darkMode === 'auto',
      },
      // Hovered
      {
        'hover-hover:hover:bg-grey-100': _vm.darkMode === false,
        'hover-hover:hover:bg-grey-700': _vm.darkMode === true,
        'hover-hover:hover:bg-grey-100 hover-hover:dark:hover:bg-grey-700': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.Active && [
      // Base
      {
        'bg-grape-500': _vm.darkMode === false,
        'bg-canary-500': _vm.darkMode === true,
        'bg-grape-500 dark:bg-canary-500': _vm.darkMode === 'auto',
      },
      {
        'text-light-text-100': _vm.darkMode === false,
        'text-dark-text-100': _vm.darkMode === true,
        'text-light-text-100 dark:text-dark-text-100': _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.Purple && [
      // Base
      'bg-grape-500',
      'text-light-text-100',
      // Hovered
      'hover-hover:hover:bg-grape-600',
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.Blue && [
      // Base
      'bg-oceanic-500',
      'text-light-text-100',
      // Hovered
      'hover-hover:hover:bg-oceanic-600',
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.BlueClear && [
      // Base
      'bg-transparent',
      'text-oceanic-500',
      {
        'hover-hover:hover:bg-transparent-button-hover-light': _vm.darkMode === false,
        'hover-hover:hover:bg-transparent-button-hover-dark': _vm.darkMode === true,
        'hover-hover:hover:bg-transparent-button-hover-light hover-hover:dark:hover:bg-transparent-button-hover-dark':
          _vm.darkMode === 'auto',
      },
    ],
    _vm.colorScheme === _setup.OzContainedButtonColorScheme.GreenClear && [
      // Base
      'bg-transparent',
      'text-park-500',
      {
        'hover-hover:hover:bg-transparent-button-hover-light': _vm.darkMode === false,
        'hover-hover:hover:bg-transparent-button-hover-dark': _vm.darkMode === true,
        'hover-hover:hover:bg-transparent-button-hover-light hover-hover:dark:hover:bg-transparent-button-hover-dark':
          _vm.darkMode === 'auto',
      },
    ],
  ],attrs:{"disabled":_vm.disabled,"href":_vm.href,"href-mode":_vm.hrefMode,"title":_vm.title || _vm.text},on:{"mouseenter":function($event){return _vm.$emit('mouseenter', $event)},"mouseleave":function($event){return _vm.$emit('mouseleave', $event)},"mousedown":function($event){return _vm.$emit('mousedown', $event)},"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"click":function($event){return _vm.$emit('click', $event)},"pointerdown":function($event){return _vm.$emit('pointerdown', $event)}}},[_vm._t("leftIcon"),_vm._v(" "),(_vm.text)?_c('span',{class:['flex-1', _vm.shouldTruncateText && 'truncate']},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_vm._v(" "),_vm._t("rightIcon"),_vm._v(" "),_vm._t("icon"),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }