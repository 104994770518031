/**
 * @file generate metadata for a link
 */
import prettyFileSize from 'filesize'
import { isFile } from './link_helpers'
import { Link } from './types'

function makeMetadata(link: Link): Record<string, any> | null {
  const meta: { pretty_size?: string } = {}
  if (isFile(link)) {
    const size = link.size
    const prettySize = prettyFileSize(size || 0, { round: 0 })
    meta.pretty_size = prettySize
    return { ...link.metadata, ...meta }
  }
  return link.metadata
}

export default makeMetadata
