/**
 * @file Browser and platform sniffing.
 * Device.ts will eventually be replaced by this.
 * Device does a bit too much (modifying HTML etc).
 */
import window from '@@/bits/global'

type BrowserOs = 'windows' | 'iphone' | 'ipad' | 'mac' | 'ios' | 'unknown' | 'android'

const navigator = window.navigator
const userAgent = navigator.userAgent.toLowerCase()
const screen = {
  width: window.screen.width,
  height: window.screen.height,
}
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth

// Helper function to check if user agent includes a string.
// We can use String.prototype.includes here because polyfill adds it.
// However, i'd like this bit to be an independent module, capable of
// running on old devices as well.
const uaIncludes = (str: string): boolean => {
  return userAgent.includes(str)
}

const isTests: { [key: string]: () => boolean } = {}
const memoizedIsTests: { [key: string]: boolean } = {}

const canTests: { [key: string]: () => boolean } = {}
const memoizedCanTests: { [key: string]: boolean } = {}

// Check if Internet Explorer
isTests.ie = (): boolean => {
  return uaIncludes('msie') || (navigator.appName === 'Netscape' && uaIncludes('trident'))
}

isTests.iphone = (): boolean => {
  return uaIncludes('iphone')
}

isTests.ipadmobile = (): boolean => {
  return uaIncludes('ipad')
}

// Check if iPad in desktop mode. New iPadOS has this on by default
isTests.ipadmac = (): boolean => {
  return uaIncludes('macintosh') && 'ontouchend' in document
}

// Check if iPad
isTests.ipad = (): boolean => {
  return isTests.ipadmobile() || isTests.ipadmac()
}

isTests.ipod = (): boolean => {
  return uaIncludes('ipod')
}

isTests.android = (): boolean => {
  return uaIncludes('android')
}

isTests.windows = (): boolean => {
  return uaIncludes('windows')
}

isTests.mac = (): boolean => {
  return uaIncludes('mac') && !('ontouchend' in document)
}

isTests.ios = (): boolean => {
  return isTests.iphone() || isTests.ipad() || isTests.ipod()
}

declare global {
  interface Window {
    chrome?: {
      app?: object
      // Reference: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/chrome/index.d.ts
      // TODO: use @types/chrome when the package is fully implemented (currently it doesn't have chrome.app)
      desktopCapture?: {
        /**
         * Shows desktop media picker UI with the specified set of sources.
         * @param sources Set of sources that should be shown to the user.
         * @param callback The callback parameter should be a function that looks like this:
         * function(string streamId) {...};
         * Parameter streamId: An opaque string that can be passed to getUserMedia() API to generate media stream that corresponds to the source selected by the user. If user didn't select any source (i.e. canceled the prompt) then the callback is called with an empty streamId. The created streamId can be used only once and expires after a few seconds when it is not used.
         */
        chooseDesktopMedia(sources: string[], callback: (streamId: string) => void): number

        /**
         * Shows desktop media picker UI with the specified set of sources.
         * @param sources Set of sources that should be shown to the user.
         * @param targetTab Optional tab for which the stream is created. If not specified then the resulting stream can be used only by the calling extension. The stream can only be used by frames in the given tab whose security origin matches tab.url.
         * @param callback The callback parameter should be a function that looks like this:
         * function(string streamId) {...};
         * Parameter streamId: An opaque string that can be passed to getUserMedia() API to generate media stream that corresponds to the source selected by the user. If user didn't select any source (i.e. canceled the prompt) then the callback is called with an empty streamId. The created streamId can be used only once and expires after a few seconds when it is not used.
         */
        chooseDesktopMedia(sources: string[], targetTab: any, callback: (streamId: string) => void): number

        /**
         * Hides desktop media picker dialog shown by chooseDesktopMedia().
         * @param desktopMediaRequestId Id returned by chooseDesktopMedia()
         */
        cancelChooseDesktopMedia(desktopMediaRequestId: number): void
      }
    }
  }
}
// Check for supported browsers including Chrome, Edge, Opera, Brave etc
isTests.chromiumBased = (): boolean => {
  return !(window?.chrome?.app == null)
}

// Check if cookies are enabled
canTests.cookies = (): boolean => {
  return navigator.cookieEnabled
}

// Safari will say cookies are enabled even when they are not.
// If we are sure that our page will have cookies, this extra check
// ensures that the browser is not lying about its ability to set cookies.
canTests.cookiesForReal = (): boolean => {
  return navigator.cookieEnabled && document.cookie !== ''
}

// Check if browser supports lazy loading natively.
canTests.lazyLoad = (): boolean => {
  return 'loading' in HTMLImageElement.prototype
}

// Check if browser supports WebAssembly
canTests.webAssembly = (): boolean => {
  // hat tip to https://stackoverflow.com/a/47880734/669
  return typeof WebAssembly === 'object' && typeof WebAssembly.instantiate === 'function'
}

// Check if browser supports visualViewport API: https://developer.mozilla.org/en-US/docs/Web/API/Visual_Viewport_API
canTests.visualViewport = (): boolean => {
  return typeof window.visualViewport === 'object'
}

// Check if browser supports geolocation API
canTests.geolocation = (): boolean => {
  return 'geolocation' in navigator
}

// Check if browser supports Web Share API: https://developer.mozilla.org/en-US/docs/Web/API/Web_Share_API
canTests.share = (): boolean => {
  return 'share' in navigator
}

// Check if browser supports Broadcast Channel API: https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
canTests.BroadcastChannel = (): boolean => {
  return 'BroadcastChannel' in window
}

function is(query: string): boolean {
  if (typeof memoizedIsTests[query] === 'undefined') {
    if (typeof isTests[query] !== 'undefined') {
      memoizedIsTests[query] = isTests[query]()
    } else {
      throw new Error(`Unsupported query ${query}`)
    }
  }
  return memoizedIsTests[query]
}

function can(query: string): boolean {
  if (typeof memoizedCanTests[query] === 'undefined') {
    if (typeof canTests[query] !== 'undefined') {
      memoizedCanTests[query] = canTests[query]()
    } else {
      throw new Error(`Unsupported query ${query}`)
    }
  }
  return memoizedCanTests[query]
}

function isTouchScreen(): boolean {
  if (window.matchMedia('(hover: none)').matches || window.matchMedia('(pointer: coarse)').matches) {
    return true
  } else {
    return false
  }
}
function isDarkModeEnabled(): boolean {
  if (window?.matchMedia('(prefers-color-scheme: dark)').matches) {
    return true
  } else {
    return false
  }
}
function isPrefersReducedMotion(): boolean {
  if (window?.matchMedia('(prefers-reduced-motion: reduce)').matches) {
    return true
  } else {
    return false
  }
}

const os = (): BrowserOs => {
  if (is('windows')) return 'windows'
  if (is('android')) return 'android'
  if (is('ios')) return 'ios'
  if (is('mac')) return 'mac'
  return 'unknown'
}

// if visualViewport API is not supported, fallback to window size values
const visualViewportHeight = can('visualViewport') ? window.visualViewport.height : windowHeight
const visualViewportWidth = can('visualViewport') ? window.visualViewport.width : windowWidth
const visualViewportScale = can('visualViewport') ? window.visualViewport.scale : 1

export {
  can as browserCan,
  is as browserIs,
  can,
  is,
  isDarkModeEnabled,
  isPrefersReducedMotion,
  isTouchScreen,
  os,
  screen,
  userAgent,
  visualViewportHeight,
  visualViewportScale,
  visualViewportWidth,
  windowHeight,
  windowWidth,
}
export type { BrowserOs }
