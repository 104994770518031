// @file Internalization (i18n) utilities.
// Like services/rails/app/javascript/bits/intl.ts but without:
// - `translatePrice`
// - `Ntranslate`

type Translations = Record<string, any>

declare global {
  interface Window {
    $upe_intl: Record<string, Translations>
  }
}

const EN = 'en'
const UNDERSCORE_EN = '-e'
let allTranslations: Record<string, Translations> = {}
let currentLocale = EN
let currentLocaleTranslations: Translations = {}
let pluralRules = new Intl.PluralRules(EN)

const refreshCurrentTranslations = (): void => {
  let translations = {}
  if (currentLocale !== EN) {
    if (allTranslations[currentLocale]) {
      translations = allTranslations[currentLocale]
    } else if (currentLocale.includes('-')) {
      const genericLocale = currentLocale.split('-').shift()
      const keys = Object.keys(allTranslations)
      for (const key of keys) {
        if (key.split('-').shift() === genericLocale) {
          translations = allTranslations[key]
          break
        }
      }
    }
  }
  currentLocaleTranslations = translations
}

const setLocale = (locale: string): void => {
  currentLocale = locale.replace('_', '-')
  pluralRules = new Intl.PluralRules(locale === UNDERSCORE_EN ? EN : locale)
  refreshCurrentTranslations()
}

const loadTranslations = async (locale: string): Promise<Translations | undefined> => {
  let translations
  try {
    // Our translation files use underscores while the locale is hyphenated -> we need to convert.
    translations = await import(`../../intl/translations/${locale.replace('-', '_')}.json`)
  } catch {
    try {
      // If that doesn't work, try the generic locale.
      translations = await import(`../../intl/translations/${locale.split('-').shift()}.json`)
    } catch {
      // If that also doesn't work, the language is not supported.
      // User will see English.
    }
  }
  return translations?.default
}

/**
 * Loads and installs translations for a given locale. Required for translation methods to work.
 * @param locale The locale of the translations to install.
 */
const installTranslations = async (locale: string): Promise<void> => {
  const translations = await loadTranslations(locale)
  if (translations != null) {
    window.$upe_intl = { [locale]: translations }
  }
  setLocale(locale)
  allTranslations = window.$upe_intl ?? {}
  refreshCurrentTranslations()
}

type PlaceholderReplacements = Record<string, string>

interface TranslationMessage {
  phrase?: string
  singularPhrase?: string
  pluralPhrase?: string
  context?: string
}

const interpolate = (text: string, replacements?: PlaceholderReplacements): string => {
  if (!replacements) return text
  return text.replace(/%?\{(.*?)}/g, (match, r) =>
    replacements[r] === undefined || replacements[r] === null ? match : replacements[r],
  )
}

/**
 * Translates a phrase (no plurals).
 * @param phrase The phrase to translate.
 * @param replacements Optional replacements for placeholders.
 * @returns Translated phrase.
 * @example
 * translate('Good morning!')
 * translate('Hello, %{name}!', { name: 'World' })
 */
const translate = (phrase: string, replacements?: PlaceholderReplacements): string => {
  let translation = phrase
  if (currentLocale !== EN) {
    const possibleTranslation = currentLocaleTranslations[phrase]
    if (possibleTranslation) translation = possibleTranslation
  }
  return interpolate(translation, replacements)
}

const getTranslationKey = (message: TranslationMessage): string => {
  let key = message.phrase
  if (!key) key = `{COUNT, plural, one {${message.singularPhrase}} other {${message.pluralPhrase}}}`
  if (message.context) key = message.context + '|' + key
  return key
}

const pluralTranslate = (message: TranslationMessage, num: number, replacements?: PlaceholderReplacements): string => {
  let translation = num === 1 ? message.singularPhrase : message.pluralPhrase
  if (currentLocale !== EN) {
    const tkey = getTranslationKey(message)
    const possibleTranslations = currentLocaleTranslations[tkey]
    if (possibleTranslations) {
      const cldr = pluralRules.select(num)
      if (possibleTranslations[cldr]) translation = possibleTranslations[cldr]
    }
  }
  return interpolate(translation || '', replacements)
}

/**
 * Translates a phrase in 2 forms: singular and plural.
 * @param singularPhrase The phrase in singular.
 * @param pluralPhrase The phrase in plural.
 * @param num The number to determine which form to use.
 * @param replacements Optional replacements for placeholders.
 * @returns Translated phrase.
 * @example
 * ntranslate('You have %{num} message.', 'You have %{num} messages.', 5, { num: 5 })
 */
const ntranslate = (
  singularPhrase: string,
  pluralPhrase: string,
  num: number,
  replacements?: PlaceholderReplacements,
): string => {
  return pluralTranslate({ singularPhrase, pluralPhrase }, num, replacements)
}

/**
 * Translates a phrase with context (no plurals).
 * @param context The context of the phrase.
 * @param phrase The phrase to translate.
 * @param replacements Optional replacements for placeholders.
 * @returns Translated phrase.
 * @example
 * ptranslate('Represents duration. Used for configuration. For example: 3s', '%{number}s', { number })
 */
const ptranslate = (context: string, phrase: string, replacements?: PlaceholderReplacements): string => {
  let translation = phrase
  if (currentLocale !== EN) {
    const tkey = getTranslationKey({ context, phrase })
    const possibleTranslation = currentLocaleTranslations[tkey]
    if (possibleTranslation) translation = possibleTranslation
  }
  return interpolate(translation, replacements)
}

/**
 * Translates a phrase with context in 2 forms: singular and plural.
 * @param context The context of the phrase.
 * @param singularPhrase The phrase in singular.
 * @param pluralPhrase The phrase in plural.
 * @param num The number to determine which form to use.
 * @param replacements Optional replacements for placeholders.
 * @returns Translated phrase.
 * @example
 * nptranslate('Represents duration. Used for configuration. For example: 3 seconds', '%{number} second', '%{number} seconds', number, { number })
 */
const nptranslate = (
  context: string,
  singularPhrase: string,
  pluralPhrase: string,
  num: number,
  replacements?: PlaceholderReplacements,
): string => {
  return pluralTranslate({ context, singularPhrase, pluralPhrase }, num, replacements)
}

export { translate as __, installTranslations, ntranslate as n__, nptranslate as np__, ptranslate as p__ }
