/** @file An object representing wall folder mappings query. */
import type { MozartConnection } from '../core/DataConnections'
import { GetWallFolderMappingsResponse, WallFolderMapping } from '../gen'

class WallFolderMappingsResource {
  constructor(private connections: MozartConnection) {}

  async allV1(): Promise<WallFolderMapping[]> {
    const message = await this.connections.mozart.requestProtobuf('/api/dal/wall-folder-mappings', {
      parseProtobuf: GetWallFolderMappingsResponse.fromBinary,
    })
    return message.records
  }
}

export { WallFolderMappingsResource }
