/**
 * @file URL parsing helpers.
 */
import { INHOUSE_HOST_LIST } from './hosts'

function isTokenizedGcsUrl(url: string): boolean {
  return !!/^https:\/\/padlet.+\.storage\.googleapis\.com\/.+\?t=.+$/.exec(url)
}

function tryActualUrlFromVivaldiApiUrl(url: string): string {
  const urlObj = new URL(url)
  const isVivaldiHostname = INHOUSE_HOST_LIST.includes(urlObj.hostname)
  if (isVivaldiHostname) return urlObj.searchParams.get('url') || ''
  return ''
}

function tokenlessGcsUrl(url: string): string {
  return url.split('?')[0]
}

function tokenFromGcsUrl(url: string): string {
  const urlObj = new URL(url)
  return urlObj.searchParams.get('t') || ''
}

function cleanUrlForInhouse(url: string): string {
  if (isTokenizedGcsUrl(url)) return tokenlessGcsUrl(url)
  const possiblyRealUrl = tryActualUrlFromVivaldiApiUrl(url)
  if (possiblyRealUrl) return possiblyRealUrl
  return url
}

export { cleanUrlForInhouse, isTokenizedGcsUrl, tokenlessGcsUrl, tokenFromGcsUrl }
